import React from 'react'
import { t } from 'i18next'
import { useSelector } from 'react-redux'

import '../../../styles/components/product-color.css'
import { COLOR_TYPE } from '../../../module/Constants'
import { STATE } from '../../../constants/ProductConstants'
import { getActiveColor } from '../../../filters/product'

export function ProductColorContainer() {
  const product = useSelector(state => state.product)
  const { menuInstanceRef } = useSelector(state => state.menuContainer)

  const activeColor = getActiveColor(product)

  if (
    product.state !== STATE.FETCHED ||
    !activeColor ||
    activeColor.type === COLOR_TYPE.UNDEFINED ||
    product.colors.length <= 1
  ) {
    return null
  }

  const {
    active: { color }
  } = product

  const style = {
    ...(color.type === COLOR_TYPE.HEX ? { backgroundColor: color.data } : null),
    ...(color.type === COLOR_TYPE.IMAGE
      ? { backgroundImage: `url(${color.data})` }
      : null)
  }

  return (
    <div className="product-color-wrapper">
      <button
        type="button"
        className="product-color"
        style={style}
        onClick={() =>
          menuInstanceRef && menuInstanceRef.setActive('product-color')
        }
      />
      {t('select.product.color', 'Farba produktu')}
    </div>
  )
}
