import "@babel/polyfill";
import 'react-app-polyfill/ie11';
import * as Creator from './Creator'

window.Creator = Creator

if (typeof window.creatorAsyncInit === 'function') {
  window.creatorAsyncInit()
}

export default Creator
