import { Rectangle } from 'pixi.js-legacy'
import { PixiController } from '../../core-module/pixi/PixiController'
import config from '../config'

export function isObjectOutOfZone(displayObjectReducer, views) {
  if (!views || !displayObjectReducer) {
    return true
  }

  if (displayObjectReducer.sideIndex > views.length) {
    return true
  }

  const view = views[displayObjectReducer.sideIndex]

  if (!view.areas || !view.areas.length) {
    return false
  }

  const displayObject = PixiController.spriteContainer.children.find(
    sprite => sprite.uuid === displayObjectReducer.uuid
  )

  if (!displayObject) {
    return false
  }

  const anchor = displayObject.anchor || { x: 0.5, y: 0.5 }

  const { left, top, bottom, right } = new Rectangle(
    displayObject.x - anchor.x * displayObject.width,
    displayObject.y - anchor.y * displayObject.height,
    displayObject.width,
    displayObject.height
  )

  return !!view.areas.find(
    area =>
      left < area.x ||
      right > area.x + area.width * config.data.scaleMultiply ||
      bottom > area.y + area.height * config.data.scaleMultiply ||
      top < area.y
  )
}
