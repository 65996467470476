import React from 'react'
import PropTypes from 'prop-types'

import MenuComponent from '../../../core-module/components/MenuComponent'
import MenuItem from '../../components/MenuItemComponent'
import config from '../../../module/config'

const MOTIVE_KEY = 'motive'
const TEXT_KEY= 'doormat';
const MIN_HEIGHT_WINDOW_TO_OPEN_MENU = 814

const LeftSide = ({ menuItems }) => {
  const title = config.data.title ? (
    <h1 className="title">{config.data.title}</h1>
  ) : null
  const defaultMenuOpened = config.data.useTemplatesForCategories.includes(config.data.defaultCategoryID) ? TEXT_KEY : MOTIVE_KEY
  return (
    <section className="left">
      {title}
      <MenuComponent
        items={menuItems}
        menuItem={MenuItem}
        defaultSelected={
          window.innerHeight > MIN_HEIGHT_WINDOW_TO_OPEN_MENU ? defaultMenuOpened : ''
        }
      />
    </section>
  )
}

LeftSide.propTypes = {
  menuItems: PropTypes.array.isRequired
}

export default LeftSide
