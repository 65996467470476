import React from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import PropTypes from 'prop-types'

const TRANSITION_DURATION = 380 // update `navigation.css .nav li > div` duration

// todo: REFACTOR REFACTOR REFACTOR!!!
class MenuItemComponent extends React.PureComponent {
  onClickHandle() {
    const { index, onClick } = this.props
    onClick(index)
  }

  createComponent() {
    const { component, menuComponentFirstRun, isActive } = this.props
    if (!component) return null

    return (
      <CSSTransition
        in={isActive}
        timeout={TRANSITION_DURATION}
        classNames="itemContainer">
        <div
          // lag fix due to overflow: auto/scroll
          className={classNames('navigation-container', {
            'itemContainer-enter-done': menuComponentFirstRun && isActive,
            itemContainer: !menuComponentFirstRun || !isActive
          })}>
          {component}
        </div>
      </CSSTransition>
    )
  }

  render() {
    const {
      backgroundColor,
      isActive,
      className,
      index,
      icon,
      iconBorderColor,
      text,
      component,
      isLoading
    } = this.props

    return (
      <li
        className={classNames(`menu-${index}`, className, {
          active: isActive
        })}>
        <a
          className={classNames({ pageLoading: isLoading })}
          style={{
            backgroundColor
          }}
          role="menuitem"
          onClick={() => this.onClickHandle()}>
          {icon ? (
            <img
              src={icon}
              alt="icon"
              style={{ borderColor: iconBorderColor }}
            />
          ) : null}
          <span className="text">{text}</span>
          {component ? (
            <div>
              <i
                className={classNames('arrow', {
                  up: isActive,
                  bottom: !isActive
                })}
              />
            </div>
          ) : null}
        </a>
        {this.createComponent()}
      </li>
    )
  }
}

MenuItemComponent.propTypes = {
  index: PropTypes.string.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  component: PropTypes.object,
  className: PropTypes.string,
  iconBorderColor: PropTypes.string,
  menuComponentFirstRun: PropTypes.bool,
  isLoading: PropTypes.bool
}

MenuItemComponent.defaultProps = {
  icon: '',
  isActive: false,
  component: null,
  className: '',
  iconBorderColor: '',
  backgroundColor: '',
  text: '',
  onClick: f => f,
  menuComponentFirstRun: false,
  isLoading: false
}

export default MenuItemComponent
