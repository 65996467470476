import sha1 from 'sha1'
import { postDataWithCache } from '../core-module/utils/fetch'
import { getApiPriceUrl } from '../constants/api'
import configs from '../module/config'

const cache = new Map()
function priceRequestWithCache(data, hash) {
  if (cache.has(hash)) {
    return cache.get(hash)
  }

  const promise = postDataWithCache(getApiPriceUrl(), data, hash)
  cache.set(hash, promise)
  return promise
}

export function fetchPrice(
  combinationId,
  sprites,
  mySprites,
  myTexts,
  quantity = 1
) {
  const ownMotives = []
  const ownTexts = []
  const motives = []
  sprites.forEach(sprite => motives.push({ id: sprite.id }))
  for (let i = 0; i < mySprites.length; i += 1) ownMotives.push({ id: 0 })
  for (let i = 0; i < myTexts.length; i += 1) ownTexts.push({ id: 0 })

  const maxQuantity = quantity < 10000 ? quantity : 10000

  const data = {
    combination: combinationId,
    currency: configs.data.currency.title,
    quantity: maxQuantity,
    motives,
    ownMotives,
    ownTexts
  }

  return priceRequestWithCache(
    data,
    sha1(getApiPriceUrl() + JSON.stringify(data))
  )
}
