import {
  ACTION_TOGGLE_DISPLAY_FILTERS,
  ACTION_TOGGLE_DISPLAY_INFO
} from '../constants/ShortcutConstants'

export function actionToggleDisplayInfo() {
  return {
    type: ACTION_TOGGLE_DISPLAY_INFO
  }
}

export function actionToggleFilterSettings() {
  return {
    type: ACTION_TOGGLE_DISPLAY_FILTERS
  }
}
