import { render } from 'react-dom'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import isBoolean from 'lodash/isBoolean'

import data from '../core-module/creator-init'
import configs, { mergeConfigs, setConfig } from './config'
import {
  getSavedData,
  saveData,
  removeSavedData
} from '../core-module/editor/StorageManager'
import { clearApplication } from '../actions/application'
import Tools from '../core-module/utils/Tools'
import '../core-module/pixi/helper/AutoSave'
import { anyInNetworkQueue } from '../core-module/utils/fetch'
import EditorManager from '../core-module/editor/EditorManager'
import { VERSION } from './Creator'

const DEFAULT_SAVE_KEY = 'creator-data'

/**
 * Convenience class to create a new Creator application.
 * @example
 * // Create the application
 * new Creator.Application();
 * console.log(Creator.Application.getSavedData());
 *
 * @class
 * @memberOf Creator
 */
class Application {
  /**
   * @param {object} [config={}] The optional parameters
   * @param {(string|HTMLElement)} [config.rootElement="root"] The id of root element or HTML element
   * @param {boolean} [config.importBodyCss=true] Import body style ( margin, padding, position )
   * @param {string} [config.apiUrl="https://kreativator.inqool.cz"] Source address of all API requests.
   * @param {string} [config.language="sk"] Language (included localized api requests ).
   * @param {{title: string, symbol: string}} [config.currency={title: 'eur', symbol: 'Є', decimalPlaces: 2}"] Currency.
   * @param {boolean} [config.importAdditionalCss=true] Import additional style. For example: It disables pull down to refresh on mobile.
   * @param {number} [config.pixelRatio=1.9] Pixel ratio in editor canvas. Be careful with property, it could slow down app.
   * @param {number} [config.mobilePixelRatio=1.9] Pixel ratio in mobile canvas.
   * @param {boolean} [config.autoLoadLastSave=true] Auto-load last session.
   * @param {boolean} [config.autoSave=true] Allow auto save mechanism.
   * @param {(number|undefined)} [config.productId=undefined] Product ID which will be load otherwise it tries get it from save/url/inner default id.
   * @param {boolean} [config.buttonZoneSwitcher=true] Button for zone switching between edit mode and preview mode on mobiles and tablets.
   * @param {boolean} [config.enableProductSides=true] Display product sides ( under the product ).
   * @param {(string|Data)} [config.initData=null] Initialize product with data.
   * @param {number[]} [config.productDisplayStockQuantityInCategories=[]] Ids (of product categories) to display stock quantity in product info
   * @param {("auto"|"visible"|"invisible")} [config.mobileMenuVisible="auto"] On mobile visibility attribute of back arrow in menu.
   * @param {string} [config.loadDesignUrl="http://kreativator-app.inqool.cz/?{key}"] URL address to load design. Example: http://kreativator-app.inqool.cz/?editor-data=134
   * @param {string} [config.queryDataUrl="editor-data"] To load design from URL
   * @param {Creator.Constants.PANEL[]} [config.disablePanel=[]] Array of disabled (hidden) panels.
   * @param {Creator.Constants.MENU[]} [config.disableMenuItem=[]] Array of disabled (hidden) menu items.
   * @param {Creator.Constants.RIGHT_MENU[]} [config.disableRightMenuItem=[]] Array of disabled (hidden) menu items in right panel.
   * @param {boolean} [config.enableGenerateImageAfterChange=true] Generate preview of image after graphic change.
   * @param {boolean} [config.enableShortcuts=true] Enable editor shortcuts.
   * @param {string} [config.title="Návrhár"] Title of graphical editor. Empty title will hide element. ( only web )
   * @param {number} [config.mainProductOpacity=1] Main product opacity in range <0,1>.
   * @param {boolean} [config.enableBackgroundPattern=false] Display black tile map at background.
   * @param {(string|undefined)} [config.userId=undefined] User id. Undefined will generate random UUID hash.
   * @param {(string)} [config.designId="design1"] Design id.
   * @param {boolean} [config.captureExceptions=false] Capture error exceptions to external system.
   * @param {(number|undefined)} [config.defaultCategoryID=undefined] Default product category.
   * @param {(number|undefined)} [config.defaultSubCategoryID=undefined] Default product sub category.
   * @param {(number|undefined)} [config.defaultManufactureID=undefined] Default manufacture category.
   * @param {boolean} [config.__enableAutomaticChangeSide=false] [Experimental] Enable automatic change product side if is it necessary.
   * @param {boolean} [config.enableAutomaticThumbnail=true] Automatic thumbnail is created when graphic changed. For example: it's used in cart order as small product preview.
   * @param {boolean} [config.enableLogs=false] Switcher for application logs.
   * @param {boolean} [config.exportMissingTranslations=false] Log missing translations to external system.
   * @param {{maxWidth: number, maxHeight: number, quality: number, backgroundColor: string}} [config.cartThumbnail={maxWidth: 200, maxHeight: 200, quality: 0.92, backgroundColor: "#FFFFFF"}] Thumbnail options which will be send in cart.
   * @param {{maxWidth: number, maxHeight: number, generatorMaxHeight: number, generatorMaxWidth: number}} [config.printingSize={maxWidth: 1100, maxHeight: 1100, generatorMaxHeight: 2000, generatorMaxWidth: 2000}] Maximal dimensions of printing. If you wanna use generator printing size so set each number.
   * @param {{default: Creator.Constants.FONT_TYPE, categoryRules: Array<{id: number, type: Creator.Constants.FONT_TYPE}>}} [config.fonts={default: Creator.Constants.FONT_TYPE.DEFAULT, categoryRules: []}] Fonts config
   * @param {number} [config.spritesAlphaOutOfZone=0.1] Opacity of objects which are out of active zones.
   * @param {number} [config.minPriceToFreeShipping=33] Minimal price to free shipping.
   * @param {number[]} [config.useTemplatesForCategories=[]] Enable to use templates for categories.
   * @param {number[]} [config.mapCategoryIDToMainCategory=[]] Map ID category as main category
   * @param {number} [config.scaleMultiply=1] Scale multiplier. Important: It's working only for preview!!!
   * @param {number} [config.forceCanvas=false] Instead of WebGL use canvas.
   * @param {number} [config.transparentBackground=false] If the render view is transparent, default false
   * @param {number} [config.backgroundColor=0xffffff] The background color of the rendered area (shown if not transparent).
   * @param {number} [config.preserveDrawingBuffer=false] Enables drawing buffer preservation, enable this if you need to call toDataUrl on the webgl context
   * @param {number} [config.legacy=false] true to ensure compatibility with older / less advanced devices. If you experience unexplained flickering try setting this to true. webgl only
   * @param {number} [config.autoResize=true] Config for PIXI Canvas
   * @param {boolean} [config.helloMessage=true] Console hello message
   * @param {boolean} [config.displayMessageOnAddToCart=true] Display toast when "add to cart will be succesfull"
   * @param {{ padding: number, miterLimit: number }} [config.text={ padding: 35, miterLimit: 10 }] Padding: Occasionally some fonts are cropped. Adding some padding will prevent this from happening by adding padding to all sides of the text. MiterLimit: The miter limit to use when using the 'miter' lineJoin mode. This can reduce or increase the spikiness of rendered text.
   * @param {{enable: boolean, redirectLink: string}} [config.editDesign={enable: false, redirectLink: ''}] Enable edit design instead of 'add to cart'.
   * @param {boolean} [config.enableSpriteFilters = false] 
   */
  constructor(config = {}) {
    mergeConfigs(config)
    setConfig('initConfig', { ...config })

    if (configs.data.importBodyCss) {
      // eslint-disable-next-line global-require
      require('../styles/_body.css')
    }

    if (configs.data.importAdditionalCss) {
      // eslint-disable-next-line global-require
      require('../styles/_additional.css')
    }

    Tools.preConnectTo(configs.data.apiUrl)

    Application.instance = this

    if (configs.data.helloMessage) {
      // eslint-disable-next-line no-console
      console.info(
        '%c Creator.Application v%s ♥',
        [
          'background: white',
          'border: 1px solid #3E0E02',
          'color: rgb(51, 102, 153)',
          'line-height: 20px'
        ].join(';'),
        VERSION
      )
    }

    render(
      data,
      typeof configs.data.rootElement === 'string'
        ? document.getElementById(configs.data.rootElement)
        : configs.data.rootElement
    )
  }

  /**
   * Save current data to local storage
   * @param {string} [key=creator-data]
   * @returns {boolean} returns whether save was succeeded
   */
  static saveData(key = DEFAULT_SAVE_KEY) {
    return saveData(key)
  }

  /**
   * Get data from local storage.
   * @param {string} [key=creator-data]
   * @returns {object|null}
   */
  static getSavedData(key = DEFAULT_SAVE_KEY) {
    return getSavedData(key)
  }

  /**
   * Remove data from local storage.
   * @param {string} [key=creator-data]
   * @returns {boolean} returns whether delete was succeeded
   */
  static removeSavedData(key = DEFAULT_SAVE_KEY) {
    return removeSavedData(key)
  }

  /**
   * Set application config parameter runtime.
   *
   * @example
   * Creator.Application.setConfig("designId", "design2");
   * Creator.Application.setConfig("cartThumbnail.quality", 0.5);
   *
   * @param {string} key
   * @param {any} value
   */
  static setConfig(key, value) {
    setConfig(key, value)
  }

  static setFilterConfig(path, value) {
    const config = get(configs.data.filters, path, undefined)

    if (config === undefined) {
      throw new Error(`Config ${path} does not exists`)
    }

    if (!isNumber(config) && !isBoolean(config)) {
      throw new Error(
        `Wrong path to config '${path}'. It has to be primitive type.`
      )
    }

    if (!EditorManager.isProductEditorActive()) {
      throw new Error(`Product is not active.`)
    }

    setConfig(`filters.${path}`, value)
    EditorManager.getActiveProductEditor().setFilters()
  }

  /**
   * Return value of current config
   * @param {string} key
   * @returns {*}
   */
  static getConfig(key) {
    return configs.data[key]
  }

  /**
   * Remove all data from current session and from local storage.
   */
  static clear() {
    clearApplication()
  }

  /**
   * Check if editor has something in network queue.
   * @return {boolean}
   */
  static anyInNetworkQueue() {
    return anyInNetworkQueue()
  }
}

export default Application
