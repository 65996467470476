import * as apiV1 from '../constants/api'
import * as constant from '../constants/CategoriesConstant'
import CategoriesWrapper from '../api/CategoriesWrapper'
import { myFetch } from '../core-module/utils/fetch'
import Console from '../core-module/utils/console'

export function loadPrintingAndProductCategories() {
  return async dispatch => {
    dispatch({
      type: constant.TYPE_LOADING,
      result: constant.STATE.LOADING
    })

    try {
      const response = await myFetch(apiV1.getApiProductAndPrintingCategories())
      const body = await response.json()

      if (response.ok) {
        dispatch({
          type: constant.TYPE_FETCHED,
          body: CategoriesWrapper(body)
        })
      } else {
        dispatch({
          type: constant.TYPE_ERROR
        })
      }
    } catch (e) {
      dispatch({
        type: constant.TYPE_ERROR
      })
      Console.log('CATEGORIES', 'Load failed.')
    }
  }
}
