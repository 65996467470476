import { Point } from 'pixi.js-legacy'
import get from 'lodash/get'

import { PixiController, CONTENT_CONTAINER_ID } from '../pixi/PixiController'
import * as action from '../../actions/editor'
import EditorManager from './EditorManager'
import Tools from '../utils/Tools'
import store from '../../store/MyStore'
import SpritesController from '../pixi/helper/SpriteController'
import { isMobile, isMobileOnly } from '../utils/device-detect'
import Observer from '../pixi/helper/Observer'
import configs from '../../module/config'
import { notify } from '../containers/PixiActionContainer'

const DRAGGING_SPRITE_ALPHA = 0.4
const SCALE_BOUNDS = {
  MIN: 1,
  MAX: 500
}

const OBJECT_BORDER = {
  minX: -15,
  maxX: 500,
  minY: -30,
  maxY: 600
}

function isObjectInEditor(x, y) {
  return (
    x > OBJECT_BORDER.minX &&
    x < OBJECT_BORDER.maxX &&
    y > OBJECT_BORDER.minY &&
    y < OBJECT_BORDER.maxY
  )
}

function isPixiEventDisable(pixiEvent) {
  if (
    !pixiEvent.data.originalEvent ||
    !pixiEvent.data.originalEvent.srcElement
  ) {
    return false
  }

  return pixiEvent.data.originalEvent.srcElement.classList.contains(
    'disable-propagation'
  )
}

class ControlsManager {
  constructor() {
    this.resizeObserver = new Observer()

    PixiController.createBottomControls()
    PixiController.createTopControls()
    PixiController.createSpriteControls()
    PixiController.getEditControl().on('pointerdown', event => {
      event.stopPropagation()
      this.startEditingText()
    })

    if (!isMobileOnly) {
      PixiController.getActiveDownLayerControl().on('pointerdown', event => {
        event.stopPropagation()
        this.activeSpriteDownLayer()
      })

      PixiController.getActiveUpLayerControl().on('pointerdown', event => {
        event.stopPropagation()
        this.activeSpriteTopLayer()
      })

      PixiController.getActiveFlipControl().on('pointerdown', event => {
        event.stopPropagation()
        this.flipHorizontalActiveSprite()
      })

      PixiController.getActiveCopyControl().on('pointerdown', event => {
        event.stopPropagation()
        this.createCopyOfActive()
      })

      PixiController.getDefaultZoomControl().on('pointerdown', event => {
        event.stopPropagation()
        PixiController.zoomDefault()
      })
    }

    if (configs.data.buttonZoneSwitcher && isMobile) {
      // Create Zone Switcher
      PixiController.createLeftBottomContainerControls()
      PixiController.getZoneSwitcher().on('pointerdown', event => {
        event.stopPropagation()
        PixiController.toggleMode()
      })
    }

    PixiController.getTrashControl().on('pointerdown', event => {
      event.stopPropagation()
      ControlsManager.onRemoveObject()
    })

    PixiController.getFiltersControl().on('pointerdown', event =>{
      event.stopPropagation();
      const activeSpriteReducer = store.getState().spritesContainer.activeSprite
      const activeSprite = EditorManager.getActiveProductEditor().activePixiSprite
      store.dispatch(action.actionShowFiltersModal(
        activeSpriteReducer.isText() ? activeSprite : activeSpriteReducer  
      ))
    })


    PixiController.getResizeControl()
      .on('pointerdown', this.onResizeObjectStart)
      .on('pointermove', this.onResizeObjectMove)
      .on('pointerup', this.onResizeObjectEnd)
      .on('pointerupoutside', this.onResizeObjectEnd)

    PixiController.getRotateControl()
      .on('pointerdown', this.onRotateObjectStart)
      .on('pointermove', this.onRotateObjectMove)
      .on('pointerup', this.onRotateObjectEnd)
      .on('pointerupoutside', this.onRotateObjectEnd)

    PixiController.getControlRotatePreview().on('pointerdown', event => {
      event.stopPropagation()
      PixiController.blurTextInputShadowIfApplicable()
      PixiController.rotatePreviewClockwise()
    })

    PixiController.getZoomInControl().on('pointerdown', event => {
      event.stopPropagation()
      PixiController.blurTextInputShadowIfApplicable()
      PixiController.zoomIn()
    })

    PixiController.getZoomOutControl().on('pointerdown', event => {
      event.stopPropagation()
      PixiController.blurTextInputShadowIfApplicable()
      PixiController.zoomOut()
    })

    PixiController.getBackControl().on('pointerdown', event => {
      event.stopPropagation()
      PixiController.blurTextInputShadowIfApplicable()
      store.dispatch(action.actionGoBackStep())
    })
    PixiController.hideBackControl();

    PixiController.getForwardControl().on('pointerdown', event => {
      event.stopPropagation()
      PixiController.blurTextInputShadowIfApplicable()
      store.dispatch(action.actionGoForwardStep())
    })
    PixiController.hideForwardControl();

  }

  interactiveContent(contentContainer) {
    contentContainer
      .on('pointerdown', this.onStageDragStart)
      .on('pointerup', this.onStageDragEnd)
      .on('pointerupoutside', this.onStageDragEnd)
      .on('pointermove', this.onStageDragMove)
  }

  interactiveTextColor(textColor) {
    // eslint-disable-next-line
    textColor.interactive = true

    // eslint-disable-next-line
    textColor.buttonMode = true

    textColor.on('pointerdown', this.onTextColorClick)
  }

  // eslint-disable-next-line
  onTextColorClick() {
    const point = this.getGlobalPosition(new Point(0, 0), true)
    notify.displayColorPicker(point.x, point.y)
  }

  onStageDragStart(event) {
    if (!PixiController.canControlContainer) {
      return
    }

    if (
      get(event.target, 'containerId') &&
      event.target.containerId === CONTENT_CONTAINER_ID
    ) {
      this.dragging = true
    }

    this.data = event.data
    this.startPosition = this.data.getLocalPosition(this.parent)
    this.startPosition.x -= this.position.x
    this.startPosition.y -= this.position.y

    event.stopPropagation()
  }

  onStageDragMove(event) {
    if (!PixiController.canControlContainer) {
      return
    }

    if (this.dragging) {
      const newPosition = this.data.getLocalPosition(this.parent)
      this.position.set(
        newPosition.x - this.startPosition.x,
        newPosition.y - this.startPosition.y
      )

      event.stopPropagation()
    }
  }

  onStageDragEnd() {
    this.dragging = false
  }

  /**
   *
   * @this  {PIXI.DisplayObject}
   * @param {PIXI.InteractionEvent} event
   */
  static onObjectDragStart(event) {
    // Discard touch
    if (this.lastMoveTimestamp && Date.now() - this.lastMoveTimestamp < 220) {
      return
    }

    event.stopPropagation();

    PixiController.showOuterControls();

    EditorManager.getActiveProductEditor().disableStoreUpdate()
    this.data = event.data

    this.startPosition = this.data.getLocalPosition(this.parent)
    this.startPosition.x -= this.position.x
    this.startPosition.y -= this.position.y
    this.originalSprite = SpritesController.get(this)
    this.spriteStartingPosition = {
      x: this.originalSprite.x,
      y: this.originalSprite.y
    }
    this.dragging = true
    this.deactivateActiveSprite = !!EditorManager.getActiveProductEditor()
      .activePixiSprite

    this.lastMoveTimestamp = Date.now()

    PixiController.hideSpriteAndLineControls(true)

    if (this.originalSprite) {
      this.originalSprite.alpha = DRAGGING_SPRITE_ALPHA
    }

    PixiController.setVisibilityDashedLinesByObjectDistance(
      this.originalSprite.x,
      this.originalSprite.y
    )
  }

  /**
   *
   * @this {PIXI.DisplayObject}
   */
  static onObjectDragMove(event) {
    if (this && this.dragging && this.originalSprite) {
      PixiController.graphicUpdatedNotifyAll()

      const newPosition = this.data.getLocalPosition(this.parent)
      newPosition.x -= this.startPosition.x
      newPosition.y -= this.startPosition.y

      const newPositionsIfObjectIsNearToCenter = PixiController.getNewPositionsIfObjectIsNearToCenter(
        newPosition.x,
        newPosition.y
      )
      newPosition.x = newPositionsIfObjectIsNearToCenter.x
      newPosition.y = newPositionsIfObjectIsNearToCenter.y

      if (!isObjectInEditor(newPosition.x, newPosition.y)) {
        event.data.originalEvent.preventDefault()
        event.stopPropagation()
        return
      }

      this.deactivateActiveSprite = false

      this.position.set(newPosition.x, newPosition.y)
      this.originalSprite.position.set(newPosition.x, newPosition.y)

      this.positionUpdated = true

      PixiController.setVisibilityDashedLinesByObjectDistance(
        newPosition.x,
        newPosition.y
      )

      notify.stateOfObjectZone(
        PixiController.isObjectOutOfZone(this.originalSprite)
      )

      event.data.originalEvent.preventDefault()
      event.stopPropagation()
    }
  }

  /**
   *
   * @this {PIXI.DisplayObject}
   */
  static onObjectDragEnd(event) {
    if (!this.originalSprite || isPixiEventDisable(event)) {
      return
    }

    event.stopPropagation()

    if (PixiController.isWholeObjectOutOfZone(this.originalSprite)) {
      this.originalSprite.x = this.spriteStartingPosition.x
      this.originalSprite.y = this.spriteStartingPosition.y
      this.x = this.spriteStartingPosition.x
      this.y = this.spriteStartingPosition.y   
    }

    if (this.deactivateActiveSprite) {
      EditorManager.getActiveProductEditor().clearActiveSpriteAndDispatch()
    } else {
      EditorManager.getActiveProductEditor().setActiveSprite(
        this.originalSprite
      )
    }

    if (this.positionUpdated) {
        store.dispatch(action.updatePositionOnActiveObject(this.x, this.y))
        PixiController.graphicUpdatedNotifyAll()
    } else {
      if (this.originalSprite.isText()) {
        PixiController.createTextInputShadow(
          this.originalSprite,
          store
            .getState()
            .spritesContainer.sprites.find(s => s.uuid === this.uuid)
        )
        setTimeout(() => {
          PixiController._textShadowInput.focus()
        })
      }
    }

   

    this.dragging = false
    this.data = null
    this.positionUpdated = false

    this.originalSprite.alpha = 1

    PixiController.hideDashedLines()
    PixiController.displayZoneContainers()
    EditorManager.getActiveProductEditor().enableStoreUpdate()
    notify.stateOfObjectZone(
      PixiController.isObjectOutOfZone(this.originalSprite)
    )
  }

  static inputFocussed(pixiText) {
    EditorManager.getActiveProductEditor().setActiveSprite(pixiText)
  }

  /**
   *
   * @this {PIXI.DisplayObject}
   */
  static onRemoveObject() {
    const activeProductEditor = EditorManager.getActiveProductEditor()
    EditorManager.getActiveProductEditor().disableStoreUpdate()
    store.dispatch(action.actionRemoveActiveObject())
    PixiController.hideTextInputShadow()
    PixiController.hideControls()
    PixiController.removeSpriteFromContainer(
      activeProductEditor.activePixiSprite
    )
    activeProductEditor.clearActiveSpriteAndDispatch()
    PixiController.hideTooltip()
    EditorManager.getActiveProductEditor().enableStoreUpdate()
  }

  // ****** RESIZE START ******
  /**
   *
   * @this {PIXI.DisplayObject}
   * @param {PIXI.InteractionEvent} event
   */
  onResizeObjectStart(event) {
    event.stopPropagation()
    PixiController.blurTextInputShadowIfApplicable()
    const activeProductEditor = EditorManager.getActiveProductEditor()

    const active = activeProductEditor.activePixiSprite

    if (!active) {
      return
    }

    activeProductEditor.disableStoreUpdate()

    this.startPosition = event.data.getLocalPosition(
      PixiController.contentContainer
    )

    if (active.isMotive()) {
      this.startScale = new Point(
        active.scale.x / configs.data.scaleMultiply,
        active.scale.y / configs.data.scaleMultiply
      )
    } else {
      this.startFontSize = store.getState().spritesContainer.activeSprite.fontSize
    }

    this.data = event.data
    PixiController.disableTooltip()
  }

  /**
   *
   * @this {PIXI.DisplayObject}
   */
  onResizeObjectMove(event) {
    if (this.startPosition) {
      let newScaleValue

      const active = EditorManager.getActiveProductEditor().activePixiSprite

      if (active.isText()) {
        let newFontSize = ControlsManager.resizeText(
          active,
          this.data.getLocalPosition(PixiController.contentContainer),
          this.startPosition,
          this.startFontSize
        )

        newFontSize = Number(newFontSize.toFixed(3))

        this.newFontSize = newFontSize > 0 ? newFontSize : this.newFontSize

        if (this.newFontSize <= 0) {
          event.data.originalEvent.preventDefault()
          return
        }

        if (active.isCurved()) {
          const activeDisplayObject = store.getState().spritesContainer
            .activeSprite

          /**
           * 1. Change object font size
           * 2. Redraw object
           * 3. After all changes save new font size to reducer
           */
          activeDisplayObject.setFontSize(this.newFontSize)

          EditorManager.getActiveProductEditor().forceUpdateActiveObject()
        } else {
          active.style.fontSize = this.newFontSize
        }
        newScaleValue = this.newFontSize
      } else {
        newScaleValue = ControlsManager.resizeSprite(
          active,
          this.data.getLocalPosition(PixiController.contentContainer),
          this.startPosition,
          this.startScale
        )
      }

      this.canUpdate = true

      PixiController.setControlsPositionAroundSprite(active)
      if (newScaleValue) {
        PixiController.controlsManager.resizeObserver.notifyAll(newScaleValue)
      }
      PixiController.graphicUpdatedNotifyAll()
      notify.stateOfObjectZone(PixiController.isObjectOutOfZone(active))
      event.data.originalEvent.preventDefault()
      event.stopPropagation()
    }
  }

  static resizeText(text, localPosition, startPosition, startFontSize) {
    const scaleAttr = Tools.calculateScale(
      text.position,
      localPosition,
      startPosition
    )

    if (scaleAttr * startFontSize < 8) {
      return -1
    }

    return scaleAttr * startFontSize
  }

  static resizeSprite(sprite, localPosition, startPosition, startScale) {
    const scaleAttr = Tools.calculateScale(
      sprite.position,
      localPosition,
      startPosition
    )

    const newScale = new Point(
      startScale.x * scaleAttr,
      startScale.y * scaleAttr
    )

    const scaleBoundX = Math.abs(sprite.width * newScale.x)
    if (scaleBoundX < SCALE_BOUNDS.MIN || scaleBoundX > SCALE_BOUNDS.MAX) {
      return null
    }

    const scaleBoundY = Math.abs(sprite.height * newScale.y)
    if (scaleBoundY < SCALE_BOUNDS.MIN || scaleBoundY > SCALE_BOUNDS.MAX) {
      return null
    }

    // eslint-disable-next-line no-param-reassign
    sprite.scale = newScale

    const twinSprite = SpritesController.getCopy(sprite)
    if (twinSprite) {
      twinSprite.scale = newScale
    }

    return newScale
  }

  /**
   *
   * @this {PIXI.DisplayObject}
   */
  onResizeObjectEnd(event) {
    event.stopPropagation()
    if (
      this.canUpdate &&
      this.startFontSize &&
      this.newFontSize &&
      this.newFontSize > 0
    ) {
      store.dispatch(action.updateFontSizeOnActiveObject(this.newFontSize))
      delete this.startFontSize
    }

    if (this.canUpdate && this.startScale) {
      store.dispatch(
        action.updateSizeOnActiveObject(
          EditorManager.getActiveProductEditor().activePixiSprite.scale.x
        )
      )
      delete this.startScale
    }

    delete this.startPosition
    delete this.startScale
    delete this.canUpdate
    const active = EditorManager.getActiveProductEditor().activePixiSprite

    // PixiController.createTextInputShadow(active, store.getState().spritesContainer.activeSprite)

    EditorManager.getActiveProductEditor().enableStoreUpdate()
    PixiController.graphicUpdatedNotifyAll()
    PixiController.enableTooltip()
    notify.stateOfObjectZone(
      PixiController.isObjectOutOfZone(
        EditorManager.getActiveProductEditor().activePixiSprite
      )
    )
  }
  // ****** RESIZE END ******

  // ****** ROTATE START ******
  onRotateObjectStart(event) {
    event.stopPropagation()
    PixiController.blurTextInputShadowIfApplicable()
    if (!EditorManager.getActiveProductEditor().activePixiSprite) {
      return
    }

    EditorManager.getActiveProductEditor().disableStoreUpdate()
    this.startRotation = EditorManager.getActiveProductEditor().activePixiSprite.rotation
    PixiController.disableTooltip()
  }

  /**
   *
   * @param {PIXI.InteractionEvent} event
   */
  onRotateObjectMove(event) {
    if (typeof this.startRotation === 'number') {
      const activeSprite = EditorManager.getActiveProductEditor()
        .activePixiSprite
      this.spriteRotationAngle = Tools.calculateAngle(
        activeSprite,
        event.data.getLocalPosition(PixiController.contentContainer),
        this.startRotation
      )

      activeSprite.rotation = this.spriteRotationAngle

      const twinSprite = SpritesController.getCopy(activeSprite)
      if (twinSprite) {
        twinSprite.rotation = this.spriteRotationAngle
      }

      PixiController.setControlsPositionAroundSprite(activeSprite)
      PixiController.graphicUpdatedNotifyAll()
      notify.stateOfObjectZone(PixiController.isObjectOutOfZone(activeSprite))

      event.data.originalEvent.preventDefault()
      event.stopPropagation()
    }
  }

  onRotateObjectEnd(event) {
    event.stopPropagation()

    if (typeof this.spriteRotationAngle === 'number') {
      store.dispatch(
        action.updateRotationOnActiveObject(this.spriteRotationAngle)
      )
    }
    const activeSprite = EditorManager.getActiveProductEditor().activePixiSprite
    activeSprite.alpha = 1

    delete this.startRotation
    delete this.spriteRotationAngle

    EditorManager.getActiveProductEditor().enableStoreUpdate()
    PixiController.graphicUpdatedNotifyAll()
    PixiController.enableTooltip()
    notify.stateOfObjectZone(PixiController.isObjectOutOfZone(activeSprite))
  }

  // ****** ROTATE END ******

  activeSpriteDownLayer() {
    PixiController.setSpriteToBottomLayer(
      EditorManager.getActiveProductEditor().activePixiSprite
    )

    EditorManager.getActiveProductEditor().disableStoreUpdate()
    store.dispatch(action.setActiveObjectToBottomLayer())
    EditorManager.getActiveProductEditor().enableStoreUpdate()
  }

  activeSpriteTopLayer() {
    PixiController.setSpriteToTopLayer(
      EditorManager.getActiveProductEditor().activePixiSprite
    )

    EditorManager.getActiveProductEditor().disableStoreUpdate()
    store.dispatch(action.setActiveObjectToTopLayer())
    EditorManager.getActiveProductEditor().enableStoreUpdate()
  }

  flipHorizontalActiveSprite() {
    PixiController.flipSprite(
      EditorManager.getActiveProductEditor().activePixiSprite
    )
    EditorManager.getActiveProductEditor().disableStoreUpdate()
    store.dispatch(action.flipActiveObject())
    EditorManager.getActiveProductEditor().enableStoreUpdate()
  }

  createCopyOfActive() {
    store.dispatch(action.createCopyOfActiveObject())
  }

  static textChanged(reducer) {
    store.dispatch(action.replaceText(reducer, true))
  }

  startEditingText() {
    const active = EditorManager.getActiveProductEditor().activePixiSprite
    PixiController.createTextInputShadow(
      active,
      store.getState().spritesContainer.activeSprite
    )
    setTimeout(() => {
      PixiController._textShadowInput.focus()
    })
  }
}

export default ControlsManager
