import debounce from 'lodash/debounce'

import { PixiController } from '../PixiController'
import EditorManager from '../../editor/EditorManager'
import Extract from '../../../module/Extract'
import configs from '../../../module/config'

class AutoThumbnail {
  extractedImage = new Map()

  start() {
    this.listenerGraphicUpdated = debounce(this.listenerGraphicUpdated, 1113)
    PixiController.registerGraphicUpdated(this.listenerGraphicUpdated, this)
  }

  clearCache() {
    this.extractedImage = new Map()
  }

  listenerGraphicUpdated() {
    if (
      !configs.data.enableAutomaticThumbnail ||
      !PixiController.isPixiAppended ||
      !EditorManager.isProductEditorActive() ||
      PixiController.spriteCopiesContainer.children.length === 0
    ) {
      return
    }

    const { index } = EditorManager.getActiveProductEditor().getActiveSide()

    Extract.contentScreenshot()
      .then(result => result.base64())
      .then(base64 => {
        if (
          EditorManager.isProductEditorActive() &&
          EditorManager.getActiveProductEditor().getActiveSide().index === index
        ) {
          this.extractedImage.set(index, base64)
        }
      })
      .catch(f => f)
  }
}

const instance = new AutoThumbnail()

export default instance
