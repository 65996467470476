import React from 'react'
import PropTypes from 'prop-types'
import mapValues from 'lodash/mapValues'
import ActiveZoneImage from '../../pixi/module/ActiveZoneImage'
import { PixiController } from '../../pixi/PixiController'

const DEFAULT_SIDE_INDEX = 0

class PreviewImageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.imageRef = React.createRef()

    this.state = {
      extractedImageRefreshSignal: false
    }

    this.imageListener = this.imageListener.bind(this)
  }

  componentDidMount() {
    ActiveZoneImage.productImageObserver.subscribe(this.imageListener, this)
  }

  componentWillUnmount() {
    ActiveZoneImage.productImageObserver.unsubscribe(this.imageListener)
  }

  get extractedImage() {
    const { sideIndex } = this.props
    return ActiveZoneImage.currentExtractedImage.get(sideIndex)
  }

  get zoneData() {
    const { areas, width, height } = this.props
    const [firstArea] = areas
    return {
      left: width * firstArea.x,
      top: height * firstArea.y,
      width: width * firstArea.width,
      height: height * firstArea.height
    }
  }

  get scaledSimilarImageValues() {
    const { width, height } = this.props
    const { extractedImage, zoneData } = this

    return {
      width: width * extractedImage.data.spritesSizeToProductSize.width,
      height: height * extractedImage.data.spritesSizeToProductSize.height,
      left:
        extractedImage.data.spritesOffsetFromProduct.left * width -
        zoneData.left,
      top:
        extractedImage.data.spritesOffsetFromProduct.top * height - zoneData.top
    }
  }

  canMoveObjectsToCenter() {
    const { similarZone, width, height } = this.props

    if (similarZone) {
      return {
        value: false,
        data: {}
      }
    }

    if (!width || !height) {
      return { value: false, data: {} }
    }

    if (PixiController.spriteCopiesContainer.children.length > 1) {
      return {
        value: false,
        data: {}
      }
    }

    const currentImage = this.imageRef.current

    if (!currentImage || !currentImage.width) {
      return {
        value: false,
        data: {}
      }
    }

    const currentCanvasState = this.extractedImage.data

    return {
      value: true,
      data: {
        imageScaled:
          currentImage.width /
          width /
          currentCanvasState.spritesSizeToProductSize.width
      }
    }
  }

  imageListener(index) {
    const { sideIndex } = this.props

    if (sideIndex === index) {
      const { extractedImageRefreshSignal } = this.state
      this.setState({
        extractedImageRefreshSignal: !extractedImageRefreshSignal
      })
    }
  }

  createPreviewAtCenter() { 
    return (
      <div
        className="active-zone"
        style={mapValues(this.zoneData, value => `${value}px`)}>
        <img
          className="centeredContent"
          alt="Preview"
          src={this.extractedImage.image.src}
          ref={this.imageRef}
        />
      </div>
    )
  }

  createSimilarPreview() {
    return (
      <div
        className="active-zone"
        style={mapValues(this.zoneData, value => `${value}px`)}>
        <img
          alt="Preview"
          src={this.extractedImage.image.src}
          style={mapValues(
            this.scaledSimilarImageValues,
            value => `${value}px`
          )}
        />
      </div>
    )
  }

  render() {
    const { width, height, areas, similarZone } = this.props
    const { extractedImage } = this
    if (
      !width ||
      !height ||
      !areas ||
      !areas.length ||
      !extractedImage ||
      !extractedImage.image
    ) {
      return null
    }

    if (
      similarZone ||
      PixiController.spriteCopiesContainer.children.length > 1
    ) {
      return this.createSimilarPreview()
    }

    return this.createPreviewAtCenter()
  }
}

PreviewImageContainer.propTypes = {
  areas: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  sideIndex: PropTypes.number,
  similarZone: PropTypes.bool
}

PreviewImageContainer.defaultProps = {
  areas: [],
  width: 0,
  height: 0,
  sideIndex: DEFAULT_SIDE_INDEX,
  similarZone: false
}

export default PreviewImageContainer
