import Observer from '../core-module/pixi/helper/Observer'
import * as T from '../constants/ActionTypes'

export const listOfSpritesUpdatedObserver = new Observer()

export const ListOfSpritesMiddleware = () => next => action => {
  const result = next(action)

  if (
    action.type === T.ADD_SPRITE_ON_PRODUCT ||
    action.type === T.ADD_PRIMITIVE_OBJECT ||
    action.type === T.ADD_TEXT_ON_PRODUCT ||
    action.type === T.ADD_SPRITE_WITH_DATA ||
    action.type === T.REMOVE_ACTIVE_OBJECT ||
    action.type === T.REMOVE_ALL_SPRITES ||
    action.type === T.REMOVE_SPRITES_BY_ID ||
    action.type === T.CLEAR
  ) {
    listOfSpritesUpdatedObserver.notifyAll()
  }

  return result
}
