import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '../../components/MenuItemComponent'
import MenuWithCallbackComponent from '../../../core-module/components/MenuComponent/MenuWithCallbackComponent'

const MenuContainer = ({ menuItems }) => (
  <MenuWithCallbackComponent
    items={menuItems}
    menuItem={MenuItem}
    defaultSelected=""
    enableHandleHistory
  />
)

MenuContainer.propTypes = {
  menuItems: PropTypes.array
}

MenuContainer.defaultProps = {
  menuItems: []
}

export default MenuContainer
