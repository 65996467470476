import React from 'react'
import propTypes from 'prop-types'
import "../../../styles/components/checkbox.css";

const CheckBox = ({ value, onChange, label }) => (
  <label className="CheckBox_Container">
    <input type="checkbox" value={value} onChange={onChange} />
    <span className="checkMark" />
    {label}
  </label>
)

CheckBox.propTypes = {
  value: propTypes.bool,
  onChange: propTypes.func,
  label: propTypes.string
}

export default CheckBox
