import React from 'react'
import { t } from 'i18next'
import { IoMdWarning } from 'react-icons/io'
import '../../styles/components/outofzone.css'

export function OutOfZoneComponent() {
  return (
    <div className="out-of-zone">
      <IoMdWarning />{' '}
      {t('outofzone', 'Your design is outside of the active zone.')}
    </div>
  )
}
