import React from 'react'
import PropTypes from 'prop-types'
import MidContainer from './index'
import PixiComponent from '../../../core-module/components/CanvasComponent'
import PreviewSideComponent from '../../../core-module/components/PreviewSideComponent'
import RemoveBackgroundRequestContainer from '../../../core-module/containers/RemoveBackgroundRequestContainer'

const MidContainerWithPixi = ({ enableProductSides }) => {
  return (
  <MidContainer>
    <PixiComponent />
    {enableProductSides ? <PreviewSideComponent /> : null}
    <RemoveBackgroundRequestContainer />
  </MidContainer>
  )}

MidContainerWithPixi.propTypes = {
  enableProductSides: PropTypes.bool
}

MidContainerWithPixi.defaultProps = {
  enableProductSides: true
}

export default MidContainerWithPixi
