import React from 'react'
import PropTypes from 'prop-types'
import CancelablePromise from 'cancelable-promise'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getCombinationId } from '../../../filters/product'
import { fetchPrice } from '../../../api/PriceRequest'
import { STATE } from '../../../constants/ProductConstants'

function withPriceHOC(Component) {
  return class extends React.Component {
    priceFetchingPromise = null

    state = {
      price: {
        formatted: '0',
        originalFormatted: '0',
        originalRaw: 0,
        raw: 0,
        unitFormatted: '0',
        unitRaw: 0,
        motivePriceRaw: 0,
        motivePriceFormatted: '0'
      },
      priceLoading: false
    }

    static propTypes = {
      product: PropTypes.object.isRequired,
      sprites: PropTypes.array.isRequired
    }

    constructor(props) {
      super(props)
      this.getPriceWithCorrectQuantityHandler = this.getPriceWithCorrectQuantityHandler.bind(
        this
      )
      this.customQuantity = props.product.active.quantity
    }

    componentDidMount() {
      this.getPriceWithCorrectQuantityHandler(this.customQuantity).catch(f => f)
    }

    componentDidUpdate(prevProps) {
      const { sprites, product } = this.props
      const { sprites: prevSprites, product: prevProduct } = prevProps

      // SPRITES IN REDUX CHANGED
      if (prevSprites !== sprites) {
        this.getPriceWithCorrectQuantityHandler(this.customQuantity).catch(
          f => f
        )
        return
      }

      // PRODUCT DATA CHANGED
      if (
        product.state === STATE.FETCHED &&
        (prevProduct.id !== product.id ||
          prevProduct.active.color.data !== product.active.color.data ||
          prevProduct.active.size !== product.active.size)
      ) {
        this.getPriceWithCorrectQuantityHandler(this.customQuantity).catch(
          f => f
        )
        return
      }

      // PRODUCT CHANGED
      if (
        product.state === STATE.FETCHED &&
        prevProduct.state !== STATE.FETCHED
      ) {
        this.getPriceWithCorrectQuantityHandler(this.customQuantity).catch(
          f => f
        )
      }
    }

    componentWillUnmount() {
      if (this.priceFetchingPromise) {
        this.priceFetchingPromise.cancel()
      }
    }

    /**
     * Get price of product included data.
     *
     * @param {number} quantity
     * @return {Promise<any>}
     */
    getPriceWithCorrectQuantityHandler(quantity) {
      this.customQuantity = quantity

      return new CancelablePromise((resolve, reject) => {
        this.setState({ priceLoading: true })

        const { product, sprites } = this.props
        const correctQuantity =
          typeof quantity === 'number' && quantity > 0
            ? quantity
            : product.active.quantity

        if (correctQuantity <= 0) {
          return reject(new Error('set quantity'))
        }

        if (product.state !== STATE.FETCHED) {
          return reject(new Error('product is not fetched'))
        }

        const combinationID = getCombinationId(product)

        if (combinationID === -1) {
          return reject(new Error('combination id does not exist'))
        }

        const sidesLength = product.views.length

        const finalSprites = sprites.filter(
          sprite => sprite.sideIndex < sidesLength
        )

        const commercialSprites = finalSprites.filter(
          sprite => sprite.isMotive() && !sprite.myMotive
        )
        const mySprites = finalSprites.filter(
          sprite => sprite.isMotive() && sprite.myMotive
        )
        const texts = finalSprites.filter(sprite => sprite.isText())

        if (this.priceFetchingPromise) {
          this.priceFetchingPromise.cancel()
        }

        this.priceFetchingPromise = fetchPrice(
          combinationID,
          commercialSprites,
          mySprites,
          texts,
          correctQuantity
        )
          .then(result => {
            this.setState({ price: result, priceLoading: false })
            return result
          })
          .catch(error => {
            this.setState({ priceLoading: false })
            throw error
          })
          .then(resolve)
      })
    }

    render() {
      const { price, priceLoading } = this.state
      return (
        <Component
          {...this.props}
          price={price}
          updatePrice={this.getPriceWithCorrectQuantityHandler}
          priceLoading={priceLoading}
        />
      )
    }
  }
}

const mapStateToProps = state => ({
  sprites: state.spritesContainer.sprites,
  product: state.product
})

const withPrice = compose(
  connect(mapStateToProps),
  withPriceHOC
)

export default withPrice
