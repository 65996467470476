// temp solution:
/* eslint-disable jsx-a11y/interactive-supports-focus */

import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateSvgClassColorOnActive } from '../../../actions/editor'
import SimpleColorPickerComponent from '../../../core-module/components/ColorPickerComponent'
import Tools from '../../../core-module/utils/Tools'
import ImageCacheFactory from '../../../core-module/utils/ImageCacheFactory'
import { withActiveTechnology } from '../../../core-module/containers/TechnologyContainer'

const COLOR_PICKER_MARGIN = {
  LEFT: 36,
  TOP: -3
}

class SVGColorComponent extends React.PureComponent {
  static propTypes = {
    svgClasses: PropTypes.object.isRequired,
    updateSvgClassColorOnActive: PropTypes.func.isRequired,
    defaultClasses: PropTypes.object.isRequired,
    isActiveDigitalTechnology: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.updateColor = this.updateColor.bind(this)
  }

  state = { activeColorClassName: null, left: 0, top: 0 }

  updateColor(color) {
    if (color) {
      const { activeColorClassName } = this.state
      // eslint-disable-next-line react/destructuring-assignment
      this.props.updateSvgClassColorOnActive(activeColorClassName, color)
    }

    this.setState({ activeColorClassName: null })
  }

  printColors() {
    const { svgClasses } = this.props
    const colors = Object.keys(svgClasses).map((key, i) => {
      const fillAttr = get(svgClasses[key], 'fill', null)

      if (fillAttr) {
        return (
          <li key={key}>
            <button
              type="button"
              tabIndex={-i}
              onClick={e => {
                const position = Tools.getPageTopLeft(e.currentTarget)
                this.setState({
                  activeColorClassName: key,
                  left: position.left,
                  top: position.top
                })
              }}
              style={{
                backgroundColor: fillAttr
              }}
            />
          </li>
        )
      }

      return null
    })

    return <ul>{colors}</ul>
  }

  render() {
    const colors = this.printColors()
    if (colors.length === 0) {
      return null
    }

    const { activeColorClassName, left, top } = this.state
    const { defaultClasses, isActiveDigitalTechnology } = this.props

    let values = {
      defaultColor: '',
      title: `${t('select.color', 'Select color')}:`
    }

    if (isActiveDigitalTechnology) {
      const getDefaultStyle = get(defaultClasses, activeColorClassName, null)
      values = {
        defaultColor: getDefaultStyle ? get(getDefaultStyle, 'fill', '') : '',
        title: t('default.color', 'Default color')
      }
    }

    const colorPicker = activeColorClassName ? (
      <SimpleColorPickerComponent
        x={left + COLOR_PICKER_MARGIN.LEFT}
        y={top + COLOR_PICKER_MARGIN.TOP}
        onClick={this.updateColor}
        defaultColor={values.defaultColor}
        title={values.title}
      />
    ) : null

    return (
      <div id="svg-color-component">
        {colors}
        {colorPicker}
      </div>
    )
  }
}

const filterSVGClasses = spritesContainer => {
  const { activeSprite } = spritesContainer

  if (activeSprite && activeSprite.isMotive() && activeSprite.isSvg()) {
    return activeSprite.svgClasses
  }

  return {}
}

const getDefaultClasses = activeSprite => {
  if (!activeSprite || !activeSprite.isMotive() || !activeSprite.isSvg()) {
    return {}
  }

  const cache = ImageCacheFactory.getCache(activeSprite)
  return cache.parser ? cache.parser.getSvgStyleAttrs() : {}
}

const mapStateToProps = state => ({
  svgClasses: filterSVGClasses(state.spritesContainer),
  defaultClasses: getDefaultClasses(state.spritesContainer.activeSprite)
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSvgClassColorOnActive
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withActiveTechnology(SVGColorComponent))
