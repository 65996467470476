import Observer from '../core-module/pixi/helper/Observer'
import { ACTION_CHANGE_SIDE_INDEX } from '../constants/ProductConstants'

export const changeProductSideObserver = new Observer()

export const ChangeProductSideMiddleware = () => next => action => {
  const result = next(action)

  if (action.type === ACTION_CHANGE_SIDE_INDEX) {
    changeProductSideObserver.notifyAll(action.index)
  }

  return result
}
