import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { FaCheckCircle } from 'react-icons/fa'
import classNames from 'classnames'
import { isMobile } from '../../../utils/device-detect'
import PreviewImageContainer from '../../../containers/PreviewImageContainer/index'
import { withMenuContext } from '../../MenuComponent/MenuContext'
import configs from '../../../../module/config'

class ProductComponent extends React.PureComponent {
  static displayPrice(price, currentMotivePrices) {
    if (typeof price === 'number') {
      return (price + currentMotivePrices).toFixed(
        configs.data.currency.decimalPlaces
      )
    }
    if (typeof price === 'string') {
      return (parseFloat(price) + currentMotivePrices).toFixed(
        configs.data.currency.decimalPlaces
      )
    }
    return 0
  }

  constructor(props) {
    super(props)

    /**
     * @type {{current: PreviewImageContainer}}
     */
    this.previewRef = React.createRef()
  }

  state = {
    width: 0,
    height: 0
  }

  onClick(id) {
    const { onClick, onDone } = this.props

    const canMoveObjectsToCenter = this.previewRef.current
      ? this.previewRef.current.canMoveObjectsToCenter()
      : { value: false, data: {} }

    onClick(id, canMoveObjectsToCenter)
    onDone()
  }

  imageLoaded({ target: img }) {
    this.setState({ width: img.clientWidth, height: img.clientHeight })
  }

  render() {
    const {
      active,
      isAnyProductLoading,
      productData,
      displayQuantity,
      loading,
      currentMotivePrices
    } = this.props

    const { width, height } = this.state

    return (
      <a
        tabIndex={-1}
        className={classNames('productPreview', {
          active
        })}
        onClick={() =>
          active || isAnyProductLoading || this.onClick(productData.id)
        }>
        <div className="productImage">
          <img
            alt="Product"
            src={productData.thumbnail}
            onLoad={img => this.imageLoaded(img)}
          />
          {!isMobile ? (
            <PreviewImageContainer
              width={width}
              height={height}
              areas={productData.areas}
              ref={this.previewRef}
              similarZone={active}
            />
          ) : null}
        </div>
        <div className="productDescription">
          <div className="name">{productData.name}</div>
          <div>
            {productData.sizeRange} <br />
            {t('product.color', 'Colors')}: {productData.countColors}
            {displayQuantity ? (
              <>
                <br />
                {t('product.quantity', 'Quantity')}:{' '}
                <strong>{productData.quantity}</strong>
              </>
            ) : null}
          </div>
          <div>
            {ProductComponent.displayPrice(
              productData.price,
              currentMotivePrices
            )}
            {configs.data.currency.symbol}
          </div>
        </div>
        <div className="info-icon">
          {active ? <FaCheckCircle className="icon" /> : null}
          {loading ? <span className="dot-loading" /> : null}
        </div>
      </a>
    )
  }
}

ProductComponent.defaultProps = {
  active: false,
  onClick: f => f,
  onDone: f => f,
  displayQuantity: false,
  isAnyProductLoading: false,
  loading: false,
  currentMotivePrices: 0
}

ProductComponent.propTypes = {
  active: PropTypes.bool,
  productData: PropTypes.object.isRequired,
  isAnyProductLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onDone: PropTypes.func,
  displayQuantity: PropTypes.bool,
  loading: PropTypes.bool,
  currentMotivePrices: PropTypes.number
}

export default withMenuContext(ProductComponent)
