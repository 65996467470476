import { getSizes } from '../../filters/product'

export function isActiveSizeAvailableInStock(activeSize, state) {
  if (!activeSize) {
    return false
  }

  const sizes = getSizes(state)

  return !!sizes.find(size => size.name === activeSize && size.quantity > 0)
}

export function findAndSetDefaultSize(state) {
  const sizes = getSizes(state)

  const defaultSize = sizes.find(size => size.quantity > 0)
  if (defaultSize) {
    return defaultSize.name
  }

  return ''
}
