import {
  SET_ACTIVE_TAB,
  TYPE_CHANGE_REF
} from '../constants/ButtonProductConstant'

export function handleBottomProduct(state = { ref: null, tab: 0 }, action) {
  switch (action.type) {
    case TYPE_CHANGE_REF:
      return {
        ...state,
        ref: action.ref
      }

    case SET_ACTIVE_TAB:
      return {
        ...state,
        tab: action.tab
      }

    default:
      return state
  }
}
