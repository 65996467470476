import { compose } from 'redux'
import sha1 from 'sha1'

import Console from './console'
import SvgParser from './SvgParser'
import { updateSvgClasses } from '../../actions/editor'
import store from '../../store/MyStore'
import { computeSvgStyleAttributesIncludeTechnology } from '../../actions/preSpritesContainer'
import { myFetch } from './fetch'
import configs from '../../module/config'
import Tools from './Tools'
import { calculateScaleMultiplicand } from './calculateRatioByMaxDimensions'

function isUrlSvg(url) {
  const array = url.split('.')
  const last = array[array.length - 1].toLowerCase()
  return last === 'svg'
}

class _ImageCacheFactory {
  static imageCached = new Map()

  static get(url) {
    return compose(
      _ImageCacheFactory.getImageFromObject,
      _ImageCacheFactory.getObject
    )(url)
  }

  static getObject(url) {
    return compose(
      _ImageCacheFactory.asyncCache,
      _ImageCacheFactory.fetchImage,
      _ImageCacheFactory.getCache
    )(url)
  }

  static getImageFromObject(promise) {
    return promise
      .then(object => object.cached)
      .catch(error => {
        Console.error('IMAGE-CACHE', error)
        return error
      })
  }

  static asyncCache(promise) {
    return promise.then(object => {
      if (object.svg) {
        _ImageCacheFactory.imageCached.set(object.key, {
          cached: object.cached,
          parser: object.parser,
          scaledMultiplicand: object.scaledMultiplicand,
          naturalWidth: object.naturalWidth,
          naturalHeight: object.naturalHeight
        })
      } else {
        _ImageCacheFactory.imageCached.set(object.key, {
          cached: object.cached,
          scaledMultiplicand: object.scaledMultiplicand,
          naturalWidth: object.naturalWidth,
          naturalHeight: object.naturalHeight
        })
      }

      return object
    })
  }

  /**
   *
   * @param {(string|PrimitiveObjectReducer)} object
   * @return {*}
   */
  static getCache(object) {
    let url
    let svg
    let objectIsUrl

    if (typeof object === 'string') {
      url = object
      svg = isUrlSvg(url)
      objectIsUrl = true
    } else {
      url = object.imageSource
      svg = object.isMotive() && object.isSvg()
      objectIsUrl = false
    }

    const key = sha1(url)

    const cached = _ImageCacheFactory.imageCached.get(key)

    return {
      key,
      svg,
      url,
      ...(cached || { cached: null }),
      ...(objectIsUrl && { sprite: object })
    }
  }

  static optimizeImage(data) {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.origin = 'anonymous'
      image.crossOrigin = 'anonymous'
      image.onload = () => {
        let { maxWidth, maxHeight } = configs.data.printingSize

        if (
          configs.data.generatorMode &&
          configs.data.printingSize.generatorMaxHeight &&
          configs.data.printingSize.generatorMaxWidth
        ) {
          maxWidth = configs.data.printingSize.generatorMaxWidth
          maxHeight = configs.data.printingSize.generatorMaxHeight
        }

        if (image.width <= maxWidth && image.height <= maxHeight) {
          resolve({
            image,
            scaledMultiplicand: calculateScaleMultiplicand(
              image.width,
              image.height
            ),
            naturalWidth: image.width,
            naturalHeight: image.height
          })
          return
        }

        Tools.thumbnailWithMaxDimensions(image, maxWidth, maxHeight).then(
          canvas => {
            const scaledImage = new Image()
            scaledImage.onload = () => {
              Console.log(
                'Image_Cache',
                `Image were scaled to [${scaledImage.width},${scaledImage.height}]`
              )

              resolve({
                image: scaledImage,
                scaledMultiplicand: calculateScaleMultiplicand(
                  image.width,
                  image.height
                ),
                naturalWidth: image.width,
                naturalHeight: image.height
              })
            }
            scaledImage.src = canvas.toDataURL('image/png')
          }
        )
      }
      image.onerror = () =>
        reject(new Error(`image loading failed ${sha1(data)}.`))
      image.src = data
    })
  }

  static fetchImage(object) {
    return new Promise(resolve => {
      if (object.cached) {
        resolve({ ...object, fetch: false })
      } else if (object.svg) {
        myFetch(object.url).then(response => {
          response.text().then(text => {
            const parser = new SvgParser(text)

            // important feature! it's scaling big vector images to smaller.
            parser.optimizeSvg()

            const computedSvgClasses = computeSvgStyleAttributesIncludeTechnology(
              parser
            )

            _ImageCacheFactory
              .optimizeImage(parser.toSvgString(computedSvgClasses))
              .then(({ image, naturalWidth, naturalHeight }) =>
                resolve({
                  ...object,
                  cached: image,
                  fetch: true,
                  parser,
                  scaledMultiplicand: parser.scaledMultiplicand,
                  naturalWidth,
                  naturalHeight
                })
              )

            if (object.sprite) {
              store.dispatch(
                updateSvgClasses(object.sprite.uuid, computedSvgClasses)
              )
            }
          })
        })
      } else {
        _ImageCacheFactory
          .optimizeImage(object.url)
          .then(({ image, scaledMultiplicand, naturalWidth, naturalHeight }) =>
            resolve({
              ...object,
              cached: image,
              fetch: true,
              scaledMultiplicand,
              naturalWidth,
              naturalHeight
            })
          )
      }
    })
  }
}

export default _ImageCacheFactory
