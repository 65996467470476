import {
  ACTION_TOGGLE_DISPLAY_FILTERS,
  ACTION_TOGGLE_DISPLAY_INFO
} from '../constants/ShortcutConstants'

const defaultState = {
  displayInfo: false,
  displayFilters: false
}

export function handleShortcuts(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TOGGLE_DISPLAY_INFO:
      return {
        ...state,
        displayInfo: !state.displayInfo
      }
    case ACTION_TOGGLE_DISPLAY_FILTERS:
      return {
        ...state,
        displayFilters: !state.displayFilters
      }
    default:
      return state
  }
}
