import minBy from 'lodash/minBy'
import store from '../../store/MyStore'

function tryToCorrectSideIndex(index) {
  return index >= 0 ? index : 0
}

export function findAppropriateSideIndex(views) {
  const currentState = store.getState()

  const finalViews = views || currentState.product.views
  if (finalViews.length === 0) {
    return tryToCorrectSideIndex(-1)
  }

  const {
    spritesContainer: { sprites }
  } = currentState

  const firstSpriteAtProductBySideIndex = minBy(sprites, 'sideIndex')

  if (
    firstSpriteAtProductBySideIndex &&
    firstSpriteAtProductBySideIndex.sideIndex < finalViews.length
  ) {
    return tryToCorrectSideIndex(firstSpriteAtProductBySideIndex.sideIndex)
  }

  const currentSideIndex = tryToCorrectSideIndex(
    currentState.product.active.sideIndex
  )

  if (currentSideIndex < finalViews.length) {
    return currentSideIndex
  }

  return 0
}
