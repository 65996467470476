import React, { Suspense } from 'react'
import Console from '../../../core-module/utils/console'
import PreviewSideComponent from '../../../core-module/components/PreviewSideComponent'
import configs from '../../../module/config'

import {
  CartPricePanel,
  FeaturedPanel,
  InfoPanel,
  MenuPanel,
  SimilarProductsPanel
} from './DisplayPanel'
import PixiComponent from '../../../core-module/components/CanvasComponent'
import MenuWithLoading from '../MenuContainer/MenuWithLoading'
import { PANEL } from '../../../module/Constants'
import FeaturedLoading from '../../../core-module/containers/FeaturedProductsContainer/FeaturedLoading'
import { ProductColorContainer } from '../ProductColorContainer'
import FiltersModalContainer from '../../../core-module/containers/FiltersModalContainer'


function isPanelDisable(panel) {
  return (
    configs.data.disablePanel &&
    Array.isArray(configs.data.disablePanel) &&
    configs.data.disablePanel.includes(panel)
  )
}

const MobileContainer = () => {
  Console.info('DEVELOPMENT', 'Mobile version active')

  return (
    <section className="mobile tablet">
      {isPanelDisable(PANEL.MOBILE_MENU) ? null : (
        <Suspense fallback={<MenuWithLoading />}>
          <MenuPanel />
        </Suspense>
      )}
      <PixiComponent>
        <ProductColorContainer />
      </PixiComponent>
      <FiltersModalContainer />
      {configs.data.enableProductSides ? (
        <Suspense fallback="">
          <PreviewSideComponent />
        </Suspense>
      ) : null}
      {isPanelDisable(PANEL.MOBILE_PRICE_FOOTER) ? null : (
        <Suspense fallback="">
          <CartPricePanel />
        </Suspense>
      )}
      {isPanelDisable(PANEL.INFO) ? null : (
        <Suspense fallback="">
          <InfoPanel />
        </Suspense>
      )}
      {isPanelDisable(PANEL.SIMILAR_PRODUCTS) ? null : (
        <Suspense fallback={<FeaturedLoading translateKey="similarProducts" />}>
          <SimilarProductsPanel />
        </Suspense>
      )}
      {isPanelDisable(PANEL.FEATURED_PRODUCTS) ? null : (
        <Suspense fallback={<FeaturedLoading />}>
          <FeaturedPanel />
        </Suspense>
      )}
    </section>
  )
}

export default MobileContainer
