export function hideObjectsInSpriteContainer(container, uuid) {
  const finalUUIDS = typeof uuid === 'string' ? [uuid] : uuid

  container.children.forEach(sprite => {
    if (sprite && sprite.uuid && !finalUUIDS.includes(sprite.uuid)) {
      // eslint-disable-next-line no-param-reassign
      sprite.visible = false
    }
  })
}

export function displayObjectsInSpriteContainer(container) {
  container.children.forEach(sprite => {
    if (sprite && sprite.uuid) {
      // eslint-disable-next-line no-param-reassign
      sprite.visible = true
    }
  })
}
