import configs from '../../module/config'

function dimensionResultIsCorrect({
  width,
  height,
  maxWidth,
  maxHeight,
  ratio
}) {
  return width * ratio <= maxWidth && height * ratio <= maxHeight
}

export function calculateRatioByMaxDimensions(
  width,
  height,
  maxWidth,
  maxHeight
) {
  if (width <= maxWidth && height <= maxHeight) {
    return 1
  }

  if (width > maxWidth) {
    const ratio = maxWidth / width
    if (
      dimensionResultIsCorrect({
        ratio,
        width,
        height,
        maxWidth,
        maxHeight
      })
    ) {
      return ratio
    }
  }

  return maxHeight / height
}

export function calculateOptimalImageDimensions(
  width,
  height,
  maxWidth,
  maxHeight
) {
  const ratio = calculateRatioByMaxDimensions(
    width,
    height,
    maxWidth,
    maxHeight
  )

  return {
    width: width * ratio,
    height: height * ratio,
    ratio
  }
}

export function calculateScaleMultiplicand(width, height) {
  if (
    !configs.data.generatorMode ||
    !configs.data.printingSize.generatorMaxWidth ||
    !configs.data.printingSize.generatorMaxHeight
  ) {
    return 1
  }

  const imageInNormalCanvas = calculateOptimalImageDimensions(
    width,
    height,
    configs.data.printingSize.maxWidth,
    configs.data.printingSize.maxHeight
  )
  const imageInGenerator = calculateOptimalImageDimensions(
    width,
    height,
    configs.data.printingSize.generatorMaxWidth,
    configs.data.printingSize.generatorMaxHeight
  )

  return imageInNormalCanvas.ratio / imageInGenerator.ratio
}
