/* eslint-disable no-console */
import configs from '../../module/config'

class Console {
  static info(label, msg) {
    if (configs.data.enableLogs) {
      console.info(msg ? `[${label.toUpperCase()}] ${msg}` : label)
    }
  }

  static log(label, msg) {
    if (configs.data.enableLogs) {
      console.log(msg ? `[${label.toUpperCase()}] ${msg}` : label)
    }
  }

  static warn(label, msg) {
    if (configs.data.enableLogs) {
      console.warn(msg ? `[${label.toUpperCase()}] ${msg}` : label)
    }
  }

  static error(label, msg) {
    if (configs.data.enableLogs) {
      console.error(msg ? `[${label.toUpperCase()}] ${msg}` : label)
    }
  }

  static time(label, msg = null) {
    if (configs.data.enableLogs) {
      console.time(`[${label.toUpperCase()}]`)
      if (msg) console.log(msg ? `[${label.toUpperCase()}] ${msg}` : label)
    }
  }

  static timeEnd(label, msg = null) {
    if (configs.data.enableLogs) {
      console.timeEnd(`[${label.toUpperCase()}]`)
      if (msg) console.log(msg ? `[${label.toUpperCase()}] ${msg}` : label)
    }
  }
}

export default Console
