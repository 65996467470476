import get from 'lodash/get'
import store from '../store/MyStore'
import { PixiController } from '../core-module/pixi/PixiController'
import Tools from '../core-module/utils/Tools'
import { getCombinationId } from '../filters/product'
import { MY_IMAGE_STATE_UPLOADED } from '../constants/ActionTypes'
import { dataSchema } from './Extract/dataSchema'
import { CutZonesFromCanvas } from './utils/CutZonesFromCanvas'
import { isObjectOutOfZone } from './utils/OutOfZone'
import {
  displayObjectsInSpriteContainer,
  hideObjectsInSpriteContainer
} from './utils/HandleObjectsInContainer'

/**
 * This namespace provides plugins for exporting content. These plugins can be used for saving an image or export json data.
 *
 * @example
 * // Get extracted data from current content
 * const data = Creator.Extract.data()
 *
 * // Get base64 of current content
 * Creator.Extract.contentScreenshot().then(console.log)
 *
 * @namespace Creator.Extract
 */

/**
 * @typedef {Object} ScreenShot
 * @property {base64} base64 function to get image as encoded base64
 * @property {blobUrl} blobUrl function to get blob link of image
 * @property {download} download function to start download.
 * @property {blob} blob function to get file blob.
 * @memberOf Creator.Extract
 */

/**
 * The extract data provides json object of current content.
 *
 * @example
 * // Get extracted data from current content
 * const data = Creator.Extract.data()
 *
 * @memberOf Creator.Extract
 * @param {boolean} additionalData
 * @returns {Data}
 */
function data(additionalData = false) {
  const state = store.getState()

  const {
    product,
    spritesContainer: { sprites },
    imageLibrary
  } = state

  const sidesLength = product.views.length

  let index = 0
  const order = new Map()
  sprites.forEach(sprite => {
    if (!sprite.isUnknown() && sprite.sideIndex < sidesLength) {
      order.set(sprite.uuid, index)
      index += 1
    }
  })

  const currentSprites = sprites.filter(
    sprite => sprite.sideIndex < sidesLength
  )

  return {
    product: {
      id: product.id,
      color: product.active.color,
      quantity: product.active.quantity,
      size: product.active.size || '',
      combinationId: getCombinationId(product),
      mainCategory: product.mainCategory
    },
    motives: currentSprites
      .filter(sprite => !sprite.myMotive && sprite.isMotive())
      .map(sprite => ({
        id: sprite.id,
        imageSource: sprite.imageSource, // fixme: remove and load images depends on ID
        x: sprite.x,
        y: sprite.y,
        sideIndex: sprite.sideIndex,
        rotation: sprite.rotation,
        filters: sprite.filters,
        order: order.get(sprite.uuid),
        flipHorizontal: sprite.isHorizontalFlipped(),
        scale: sprite.scale,
        svg: sprite.svg,
        svgClasses: sprite.svg ? JSON.stringify(sprite.svgClasses) : '',
        ...(additionalData
          ? {
              width: sprite.width,
              height: sprite.height,
              isOutOfZone: isObjectOutOfZone(sprite, product.views)
            }
          : null)
      })),
    myMotives: currentSprites
      .filter(sprite => sprite.myMotive && sprite.isMotive())
      .filter(
        sprite =>
          get(
            imageLibrary.find(librarySprite => librarySprite.id === sprite.id),
            'state',
            undefined
          ) === MY_IMAGE_STATE_UPLOADED
      )
      .map(sprite => ({
        x: sprite.x,
        y: sprite.y,
        id: sprite.id,
        sideIndex: sprite.sideIndex,
        rotation: sprite.rotation,
        filters: sprite.filters,
        width: sprite.width,
        height: sprite.height,
        order: order.get(sprite.uuid),
        flipHorizontal: sprite.isHorizontalFlipped(),
        scale: sprite.scale,
        imageSource: sprite.temporalImageUrl || sprite.imageSource,
        ...(additionalData
          ? {
              width: sprite.width,
              height: sprite.height,
              isOutOfZone: isObjectOutOfZone(sprite, product.views)
            }
          : null)
      })),
    texts: currentSprites
      .filter(sprite => sprite.isText())
      .map(text => ({
        order: order.get(text.uuid),
        flipHorizontal: text.isHorizontalFlipped(),
        x: text.x,
        y: text.y,
        sideIndex: text.sideIndex,
        rotation: text.rotation,
        text: text.text,
        scale: text.scale,
        curvedText: text.curvedText,
        radius: text.radius,
        fontSize: text.fontSize,
        color: text.color,
        fontFamily: text.fontFamily,
        bold: text.bold,
        italic: text.italic,
        align: text.align,
        ...(additionalData
          ? {
              isOutOfZone: isObjectOutOfZone(text, product.views)
            }
          : null)
      }))
  }
}

/**
 * Return whether application data is valid or not.
 * @param {Data} extractedData
 *
 * @async
 * @memberOf Creator.Extract
 * @returns {Promise<boolean>}
 *
 * @example
 * Creator.Extract.areExtractedDataOkay({ name: 'jimmy', age: 'hi' }).then(console.log);
 */
function areExtractedDataOkay(extractedData) {
  return dataSchema.isValid(extractedData)
}

/**
 * Return whether application data is valid or not.
 * @param {Data} extractedData
 *
 * @async
 * @memberOf Creator.Extract
 * @returns {Promise<boolean>}
 *
 * @example
 * Creator.Extract.validatedExtractedData({ name: 'jimmy', age: 'hi' }).catch(function(err) {
 *  err.name; // => 'ValidationError'
 *  err.errors; // => ['id must be a number']
 * });
 */
function validatedExtractedData(extractedData) {
  return dataSchema.validate(extractedData)
}

function screenshotResult(canvas) {
  return {
    blob(mimeType, qualityArgument) {
      return new Promise(resolve => {
        canvas.toBlob(
          blob => {
            resolve(blob)
          },
          mimeType,
          qualityArgument
        )
      })
    },
    blobUrl(mimeType, qualityArgument) {
      return this.blob(mimeType, qualityArgument).then(blob =>
        URL.createObjectURL(blob)
      )
    },
    base64: (type, encoderOptions) => canvas.toDataURL(type, encoderOptions),
    download(fileName = 'screenshot', mimeType, qualityArgument) {
      return this.blobUrl(mimeType, qualityArgument).then(url => {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
        return null
      })
    }
  }
}

const defaultScreenshot = {
  maxWidth: 500,
  maxHeight: 500,
  productIncluded: true,
  clipSpritesByActiveZones: true,
  productOpacity: 1,
  fileName: 'screenshot.png',
  backgroundColor: undefined,
  includedActiveZonesSpaces: true,
  filters: true,
  centeredLinesVisible: false
}

/**
 * Will return a screenshot encoded string of current creator.
 *
 * @memberOf Creator.Extract
 *
 * @param {object} [options = {}] The optional parameters
 * @param {boolean} [options.productIncluded = true]
 * @param {boolean} [options.clipSpritesByActiveZones = true]
 * @param {boolean} [options.includedActiveZonesSpaces = true]
 * @param {number} [options.productOpacity = 1] Opacity of product background.
 * @param {boolean} [options.filters = true] possible to disable filters ( for example in engraving )
 * @param {boolean} [options.centeredLinesVisible = false]
 *
 * @return {Promise<Creator.Extract.ScreenShot|Error>}
 *
 * @example
 * Creator.Extract.contentScreenshot().then(result => result.blobUrl()).then(console.log);
 */
function contentScreenshot(options = {}) {
  const mergedOptions = { ...defaultScreenshot, ...options }

  return new Promise((resolve, reject) => {
    if (!PixiController.app) {
      return reject(new Error('App has not been initialized.'))
    }

    if (
      PixiController.spriteCopiesContainer.children.length === 0 &&
      !mergedOptions.productIncluded
    ) {
      return reject(new Error('Size of resulted image is 0x0.'))
    }

    if (!PixiController.isActiveZoneAdded) {
      return reject(new Error('Active zone was not added.'))
    }

    const previousValues = {
      activeZoneContainerVisible: PixiController.activeZoneContainer.visible,
      productOpacity: PixiController.productContainer.alpha,
      mode: PixiController.mode,
      filters: PixiController.parentSpriteContainer.filters,
      dashedLinesVisible: {
        container: PixiController.dashedLineContainer.visible,
        vertical: PixiController.verticalDashedLine.visible,
        horizontal: PixiController.horizontalDashedLine.visible
      }
    }

    PixiController.dashedLineContainer.visible =
      mergedOptions.centeredLinesVisible
    PixiController.verticalDashedLine.visible =
      mergedOptions.centeredLinesVisible
    PixiController.horizontalDashedLine.visible =
      mergedOptions.centeredLinesVisible
    PixiController.activeZoneContainer.visible = false

    PixiController.productContainer.alpha = mergedOptions.productOpacity

    if (!mergedOptions.productIncluded) {
      PixiController.productContainer.visible = false
    }

    if (!mergedOptions.filters) {
      PixiController.setFilters()
    }

    const container = mergedOptions.includedActiveZonesSpaces
      ? PixiController.contentContainer
      : PixiController.spriteContainer

    const spritesAlphaBackup = []
    PixiController.spriteContainer.children.forEach(sprite => {
      spritesAlphaBackup.push(sprite.alpha)
      sprite.alpha = 1 // eslint-disable-line no-param-reassign

      if (
        sprite.MASK_FLAG &&
        !mergedOptions.includedActiveZonesSpaces &&
        !mergedOptions.clipSpritesByActiveZones &&
        !mergedOptions.productIncluded
      ) {
        sprite.visible = false // eslint-disable-line no-param-reassign
      }
    })

    PixiController.switchToPreviewMode()

    let mask
    if (!mergedOptions.clipSpritesByActiveZones) {
      mask = PixiController.spriteContainer.mask // eslint-disable-line
      PixiController.spriteContainer.mask = null
    }

    if (
      mergedOptions.includedActiveZonesSpaces &&
      !mergedOptions.productIncluded &&
      PixiController.activeZoneMaskCopy
    ) {
      PixiController.spriteContainer.addChild(PixiController.activeZoneMaskCopy)
    }

    const imageResult = PixiController.app.renderer.plugins.extract.canvas(
      container
    )
    
    if (
      mergedOptions.includedActiveZonesSpaces &&
      !mergedOptions.productIncluded &&
      PixiController.activeZoneMaskCopy
    ) {
      PixiController.spriteContainer.removeChild(
        PixiController.activeZoneMaskCopy
      )
    }

    if (!mergedOptions.clipSpritesByActiveZones) {
      PixiController.spriteContainer.mask = mask
    }

    PixiController.spriteContainer.children.forEach((sprite, index) => {
      sprite.alpha = spritesAlphaBackup[index] // eslint-disable-line no-param-reassign

      if (sprite.MASK_FLAG) {
        sprite.visible = true // eslint-disable-line no-param-reassign
      }
    })

    if (!mergedOptions.filters) {
      PixiController.setFilters(previousValues.filters)
    }
    
    PixiController.changeMode(previousValues.mode)
    PixiController.productContainer.visible = true
    PixiController.productContainer.alpha = previousValues.productOpacity
    PixiController.dashedLineContainer.visible =
      previousValues.dashedLinesVisible.container
    PixiController.activeZoneContainer.visible =
      previousValues.activeZoneContainerVisible
    PixiController.verticalDashedLine.visible =
      previousValues.dashedLinesVisible.vertical
    PixiController.horizontalDashedLine.visible =
      previousValues.dashedLinesVisible.horizontal
    
    if (
      mergedOptions.includedActiveZonesSpaces ||
      (!mergedOptions.includedActiveZonesSpaces &&
        !mergedOptions.clipSpritesByActiveZones)
    ) {
      return resolve(imageResult)
    } else {
      return resolve(CutZonesFromCanvas(imageResult))
    }
  }).then(screenshotResult)
}

/**
 * Function will return a thumbnail of current creator. When you need only image screen so then use {@link Creator.Extract.contentScreenshot}.
 *
 * @param {object} [options = {}] The optional parameters
 * @param {boolean} [options.productIncluded = true]
 * @param {boolean} [options.clipSpritesByActiveZones = true]
 * @param {boolean} [options.includedActiveZonesSpaces = true]
 * @param {number} [options.maxWidth=500] maximal allowed width
 * @param {number} [options.maxHeight=500] maximal allowed height
 * @param {number} [options.productOpacity = 1] Opacity of product background.
 * @param {string} [options.backgroundColor = undefined] Background color
 * @param {boolean} [options.filters = true] possible to disable filters ( for example in engraving )
 * @param {boolean} [options.centeredLinesVisible = false]
 * @memberOf Creator.Extract
 * @returns {Promise<Creator.Extract.ScreenShot|Error>}
 *
 * @example
 * Creator.Extract.thumbnail({maxHeight: 64}).then(result => result.blobUrl()).then(console.log);
 */
function thumbnail(options = {}) {
  const mergedOptions = { ...defaultScreenshot, ...options }

  return contentScreenshot(mergedOptions)
    .then(result => result.base64())
    .then(base64 =>
      Tools.thumbnailWithMaxDimensions(
        base64,
        mergedOptions.maxWidth,
        mergedOptions.maxHeight,
        mergedOptions.backgroundColor
      )
    )
    .then(screenshotResult)
}

/**
 * Will return a screenshot of given objects.
 *
 * @memberOf Creator.Extract
 *
 * @param {string | string[]} [uuid] uuids of objects
 * @param {object} [options = {}] The optional parameters
 * @param {boolean} [options.productIncluded = true]
 * @param {boolean} [options.clipSpritesByActiveZones = true]
 * @param {boolean} [options.includedActiveZonesSpaces = true]
 * @param {number} [options.productOpacity = 1] Opacity of product background.
 * @param {boolean} [options.filters = true] possible to disable filters ( for example in engraving )
 * @param {boolean} [options.centeredLinesVisible = false]
 *
 * @return {Promise<Creator.Extract.ScreenShot|Error>}
 *
 * @example
 * Creator.Extract.contentScreenshot().then(result => result.blobUrl()).then(console.log);
 */
function objectsScreenshot(uuid, options = {}) {
  const finalOptions = {
    productIncluded: false,
    includedActiveZonesSpaces: false,
    ...options
  }

  hideObjectsInSpriteContainer(PixiController.spriteContainer, uuid)
  hideObjectsInSpriteContainer(PixiController.spriteCopiesContainer, uuid)

  return contentScreenshot(finalOptions)
    .then(result => {
      displayObjectsInSpriteContainer(PixiController.spriteContainer)
      displayObjectsInSpriteContainer(PixiController.spriteCopiesContainer)

      return result
    })
    .catch(error => {
      displayObjectsInSpriteContainer(PixiController.spriteContainer)
      displayObjectsInSpriteContainer(PixiController.spriteCopiesContainer)

      return error
    })
}

export default {
  data,
  contentScreenshot,
  thumbnail,
  areExtractedDataOkay,
  validatedExtractedData,
  objectScreenshot: objectsScreenshot
}
