import * as PIXI from 'pixi.js-legacy'
import * as PixiTextInput from 'pixi-text-input'
import { t } from 'i18next'
import times from 'lodash/times'
import chroma from 'chroma-js'

import './helper/DisplayObject'
import './helper/PixiDashedPolygonExtension'
import './helper/PixiDashLine'

import SpriteController from './helper/SpriteController'
import Console from '../utils/console'
import * as constant from '../../constants/SpriteConstants'
import Observer from './helper/Observer'
import ControlsManager from '../editor/ControlsManager'
import EditorManager from '../editor/EditorManager'
import Tools from '../utils/Tools'
import PixiCacheFactory from './module/PixiCacheFactory'
import { isMobileOnly, isMobile } from '../utils/device-detect'
import { createStar, createEmptyStar } from './helper/StarSprite'
import config from '../../module/config'
import { sortSpritesByReducer } from './helper/SortSpritesByReducer'
import { removeEmoji } from '../utils/removeEmojis'

// IMAGES
import trash from '../../assets/icons/trash.png'
import filters from '../../assets/icons/filtersIcon.png'
import edit from '../../assets/icons/edit.png'
import rotate from '../../assets/icons/rotate.png'
import resize from '../../assets/icons/resize.png'
import visibilityOn from '../../assets/icons/visibility_on.png'
import visibilityOff from '../../assets/icons/visibility_off.png'
import zoomIn from '../../assets/icons/zoom-in.png'
import zoomOut from '../../assets/icons/zoom-out.png'
import zoomOnProduct from '../../assets/icons/default-zoom.png'
import rotatePreview from '../../assets/icons/rotate_preview.png'
import layerDown from '../../assets/icons/layer-down.png'
import layerUp from '../../assets/icons/layer-up.png'
import clone from '../../assets/icons/clone.png'
import flip from '../../assets/icons/flip.png'
import backControl from '../../assets/icons/back.png'
import forwardControl from '../../assets/icons/forward.png'
import tile from '../../assets/tile.png'
import { filters as glfxFilters } from './helper/glfxFilters'

if (process.env.NODE_ENV === 'development') {
  window.PIXI = PIXI
}

let canvasParentElement

const canvasMobileDimension = {
  width: 518,
  height: 300
}

const canvasDesktopDimension = {
  width: 518,
  height: 518
}

export const canvasDefaultDimension = isMobileOnly
  ? canvasMobileDimension
  : canvasDesktopDimension

/**
 *
 * @type {{canvasWidth: number, canvasHeight: number}}
 */
export const canvasDimension = {
  canvasWidth: () =>
    canvasParentElement
      ? canvasParentElement.offsetWidth || canvasDefaultDimension.width
      : canvasDefaultDimension.width,
  canvasHeight: () => canvasDefaultDimension.height
}

/**
 *
 * @type {{x: number, y: number}}
 */
export const canvasCenter = {
  x: () => canvasDimension.canvasWidth() / 2,
  y: () => canvasDimension.canvasHeight() / 2
}

export const CONTENT_CONTAINER_ID = 743

/**
 *
 * @type {{backgroundColor: number, width: number, height: number}}
 */
const canvasOpts = {
  width: canvasDimension.canvasWidth(),
  height: canvasDimension.canvasHeight()
}

/**
 *
 * @type {{maxRopePoints: number, step: number}}
 */
const CurvedTextConstants = {
  maxRopePoints: 700,
  step: Math.PI / 700
}

export const MODE = {
  PREVIEW_MODE: 0,
  EDITABLE_MODE: 1
}

const OBJECT_NEAR_RANGE = 5

/*
  eslint class-methods-use-this: ["error",  {
    "exceptMethods": ["_configMainSprite", "_configSprite", "_calculateRopePoints", "createPixiText"] }]
*/
/**
 * PixiController
 * @class
 * @private
 * @singleton
 */
class _PixiController {
  /**
   *
   * @type {PIXI.Graphics}
   */
  activeZoneMask

  activeZoneMaskCopy

  /**
   *
   * @type {PIXI.Application}
   */
  app

  /**
   *
   * @type {PIXI.Container}
   */
  contentContainer

  /**
   *
   * @type {PIXI.Container}
   */
  parentSpriteContainer

  /**
   *
   * @type {PIXI.Container}
   */
  productContainer

  productContainerObserver

  /**
   *
   * @type {PIXI.Container}
   */
  spriteContainer

  /**
   *
   * @type {PIXI.Container}
   */
  spriteCopiesContainer

  /**
   *
   * @type {PIXI.Container}
   */
  activeZoneContainer

  /**
   *
   * @type {PIXI.Container}
   */
  controlsSpriteContainer

  /**
   *
   * @type {PIXI.Sprite}
   */
  _zoneSwitcher

  /**
   *
   * @type {PIXI.Sprite}
   */
  _zoomIn

  /**
   *
   * @type {PIXI.Sprite}
   */
  _zoomOut

  /**
   *
   * @type {PIXI.Sprite}
   */
  _defaultZoom

  /**
   *
   * @type {PIXI.Sprite}
   */
  _backButton

  /**
   *
   * @type {PIXI.Sprite}
   */
  _forwardButton

  /**
   *
   * @type {PIXI.Container}
   */
  leftTopControlsContainer

  /**
   *
   * @type {PIXI.Container}
   */
  leftBottomControlsContainer

  /**
   *
   * @private
   * @type {PIXI.Sprite}
   */
  _trash

  /**
   * @private
   * @type {PIXI.Sprite}
   */
  _filters

  /**
   *
   * @private
   * @type {PIXI.Sprite}
   */
  _edit

  /**
   *
   * @private
   * @type {PIXI.Sprite}
   */
  _resize

  /**
   *
   * @private
   * @type {PIXI.Sprite}
   */
  _rotate

  /**
   *
   * @private
   * @type {PIXI.Graphics}
   */
  _lineTL

  /**
   *
   * @private
   * @type {PIXI.Graphics}
   */
  _lineTR

  /**
   *
   * @private
   * @type {PIXI.Graphics}
   */
  _lineBL

  /**
   *
   * @private
   * @type {PIXI.Graphics}
   */
  _lineBR

  /**
   *
   * @type {ControlsManager}
   */
  controlsManager

  mode

  /**
   *
   * @type {Observer}
   */
  zoomObservers

  /**
   *
   * @type {Observer}
   */
  previewRotationObserver

  /**
   *
   * @type {Observer}
   */
  _graphicUpdated

  /**
   *
   * @type {PIXI.Container}
   */
  controlsLineContainer

  /**
   *
   * @type {PIXI.Container}
   */
  dashedLineContainer

  horizontalDashedLine

  verticalDashedLine

  /**
   *
   * @private
   * @type {PIXI.Graphics}
   */
  _controlFlip

  /**
   *
   * @private
   * @type {PIXI.Graphics}
   */
  _controlUpLayer

  /**
   *
   * @private
   * @type {PixiTextInput}
   */
  _textShadowInput

  /**
   * @private
   * @type {boolean}
   */
  _inputFocussed = false
  /**
   *
   * @private
   * @type {PIXI.Graphics}
   */
  _controlDownLayer

  /**
   *
   * @private
   * @type {PIXI.Graphics}
   */
  _controlCopy

  /**
   *
   * @private
   * @type {PIXI.Graphics}
   */
  _controlRotatePreview

  _qualityStarsContainer

  _currentQualityHelper

  /**
   *
   * @type {PIXI.Container}
   */
  tooltipContainer

  enableDisplayTooltip

  isPixiAppended

  pixiWasAppendedObserver = new Observer()

  _textColor

  canControlContainer

  _tooltipTimer

  _tileContainer

  objectControlsContainer

  outerControlsContainer

  constructor() {
    this.zoomObservers = new Observer()
    this.previewRotationObserver = new Observer()
    this._graphicUpdated = new Observer()
    this.productContainerObserver = new Observer()
    this.isPixiAppended = false

    this.windowResize = this.windowResize.bind(this)
  }

  /**
   * @public
   */
  initializeApplication(containerElement) {
    PIXI.settings.RESOLUTION = isMobileOnly
      ? config.data.mobilePixelRatio
      : config.data.pixelRatio

    PIXI.settings.MIPMAP_TEXTURES = false

    if (config.data.generatorMode) {
      PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false
    }

    PIXI.utils.skipHello()

    this._currentQualityHelper = -1

    this.app = new PIXI.Application({
      ...canvasOpts,
      forceCanvas: config.data.forceCanvas,
      transparent: config.data.transparentBackground,
      backgroundColor: config.data.backgroundColor,
      preserveDrawingBuffer: config.data.preserveDrawingBuffer,
      legacy: config.data.legacy,
      autoResize: config.data.autoResize,
      powerPreference: 'high-performance'
    })

    Console.log('pixi', `Resolution [${PIXI.settings.RESOLUTION}]`)
    Console.log('pixi', `Canvas [${canvasOpts.width}, ${canvasOpts.height}]`)

    this._tileContainer = new PIXI.Container()
    this._tileContainer.name = 'TileContainer'
    this.contentContainer = new PIXI.Container()
    this.contentContainer.name = 'ContentContainer'
    this.parentSpriteContainer = new PIXI.Container()
    this.parentSpriteContainer.name = 'ParentSpriteContainer'
    this.productContainer = new PIXI.Container()
    this.productContainer.name = 'ProductContainer'
    this.spriteContainer = new PIXI.Container()
    this.spriteContainer.name = 'SpritesContainer'
    this.spriteCopiesContainer = new PIXI.Container()
    this.spriteCopiesContainer.name = 'SpriteCopiesContainer'
    this.activeZoneContainer = new PIXI.Container()
    this.activeZoneContainer.name = 'ActiveZoneContainer'
    this.dashedLineContainer = new PIXI.Container()
    this.dashedLineContainer.name = 'DashedLineContainer'
    this.controlsSpriteContainer = new PIXI.Container()
    this.controlsSpriteContainer.name = 'ControlsSpriteContainer'
    this.controlsLineContainer = new PIXI.Container()
    this.controlsLineContainer.name = 'ControlsLineContainer'
    this.leftTopControlsContainer = new PIXI.Container()
    this.leftTopControlsContainer.name = 'LeftTopControlsContainer'
    this.leftBottomControlsContainer = new PIXI.Container()
    this.leftBottomControlsContainer.name = 'LeftBottomControlsContainer'
    this._zoneSwitcher = new PIXI.Container()
    this._zoneSwitcher.name = 'ZoneSwitcher'
    this._qualityStarsContainer = new PIXI.Container()
    this._qualityStarsContainer.name = 'Quality Stars Container'
    this.objectControlsContainer = new PIXI.Container()
    this.objectControlsContainer.name = 'ObjectControlsContainer'
    this.outerControlsContainer = new PIXI.Container()
    this.outerControlsContainer.name = 'OuterControlsContainer'

    this._textShadowInput = new PixiTextInput({ input: { multiline: true } })
    this._textShadowInput.anchor = new PIXI.Point(0.5, 0.5)
    this._textShadowInput.htmlInput.onmouseenter = () => {
      this.switchToEditMode()
    }

    // Disabled moving with container
    this.canControlContainer = false

    // ADD BACKGROUND PATTERN
    if (config.data.enableBackgroundPattern) {
      const tileTexture = PIXI.Texture.fromImage(tile)
      const tileSprite = new PIXI.TilingSprite(
        tileTexture,
        this.app.screen.width * 4,
        this.app.screen.height * 4
      )
      this._tileContainer.addChild(tileSprite)
    }

    this._tileContainer.addChild(this.contentContainer)

    this.parentSpriteContainer.addChild(this.spriteCopiesContainer)
    this.parentSpriteContainer.addChild(this.spriteContainer)
    this.contentContainer.addChild(this.productContainer)
    this.contentContainer.addChild(this.activeZoneContainer)
    this.contentContainer.addChild(this.parentSpriteContainer)
    this.contentContainer.addChild(this.dashedLineContainer)
    this.contentContainer.containerId = CONTENT_CONTAINER_ID

    this.contentContainer.interactive = true
    this.contentContainer.buttonMode = true
    this.controlsSpriteContainer.alpha = 0

    this.productContainer.alpha = config.data.mainProductOpacity

    if (isMobile) {
      this.initMobile()
    } else {
      this.initDesktop()
    }

    this.outerControlsContainer.addChild(this.leftBottomControlsContainer)
    this.outerControlsContainer.addChild(this.leftTopControlsContainer)

    this.objectControlsContainer.addChild(this.controlsLineContainer)
    this.objectControlsContainer.addChild(this._qualityStarsContainer)
    this.objectControlsContainer.addChild(this.controlsSpriteContainer)

    this.app.stage.addChild(this._tileContainer)
    this.app.stage.addChild(this.objectControlsContainer)
    this.app.stage.addChild(this.outerControlsContainer)

    this.controlsManager = new ControlsManager()
    this.controlsManager.interactiveContent(this.contentContainer)
    this.updateControlsPosition()

    this.initTooltip()

    this.createLineControls(0xffffff)

    this.appendPIXI(containerElement)

    if (isMobile) {
      this.setupClickOutsideZoneListeners()
    }
  }

  showOuterControls() {
    this.outerControlsContainer.visible = true
    this.activeZoneContainer.alpha = 1
  }

  hideOuterControls() {
    this.outerControlsContainer.visible = false
    this.activeZoneContainer.alpha = 0
  }

  setupClickOutsideZoneListeners() {
    let activeZoneTouched = false
    this.activeZoneContainer.interactive = true
    this.activeZoneContainer.on('pointerdown', event => {
      activeZoneTouched = true
    })

    this.app.stage.interactive = true
    this.app.stage.hitArea = new PIXI.Rectangle(
      0,
      0,
      canvasDimension.canvasWidth(),
      canvasDimension.canvasHeight()
    )

    this.app.stage.on('pointerdown', () => {
      if (activeZoneTouched) {
        PixiController.showOuterControls()
      } else {
        PixiController.hideOuterControls()
      }
      activeZoneTouched = false
    })
  }

  initTooltip() {
    this.tooltipContainer = new PIXI.Container()
    this.tooltipContainer.name = 'TooltipContainer'
    this.tooltipContainer.visible = false
    this.enableDisplayTooltip = true
    this.app.stage.addChild(this.tooltipContainer)
  }

  initMobile() {
    this.switchToEditMode()
    this.app.renderer.plugins.interaction.autoPreventDefault = false
    this.setAutomaticTouchAction()
  }

  initDesktop() {
    this.switchToEditMode()
  }

  appendPIXI(containerElement) {
    canvasParentElement = containerElement
    canvasParentElement.appendChild(this.app.view)

    this.resetScaleAndPositionOfContentContainer()

    this.isPixiAppended = true
    this.pixiWasAppendedObserver.notifyAll()

    return this.app.view
  }

  /**
   *
   * @param {(SpriteState|TextState)} displayObjectReducer
   * @returns {Promise<PIXI.DisplayObject>}
   */
  createSprite(displayObjectReducer) {
    return PixiCacheFactory.getFromSpriteState(
      displayObjectReducer
    ).then(texture => this._configSprite(texture, displayObjectReducer))
  }

  /**
   *
   * @param texture
   * @param {(SpriteState|TextState)} displayObjectReducer
   */
  _configSprite(texture, displayObjectReducer) {
    const sprite = this._applyGlfxFilter(
      new PIXI.Sprite(texture),
      displayObjectReducer
    )
    sprite.anchor.set(0.5)
    sprite.position.set(
      displayObjectReducer.x * config.data.scaleMultiply,
      displayObjectReducer.y * config.data.scaleMultiply
    )
    sprite.uuid = displayObjectReducer.uuid
    sprite.rotation = displayObjectReducer.rotation
    sprite.scale.set(
      displayObjectReducer.getScale().x * config.data.scaleMultiply,
      displayObjectReducer.getScale().y * config.data.scaleMultiply
    )
    sprite.myMotive = displayObjectReducer.myMotive
    sprite.sideIndex = displayObjectReducer.sideIndex
    SpriteController.set(sprite)
    SpriteController.createCopy(sprite)

    return sprite
  }

  /**
   *
   * @param texture
   * @param {(SpriteState|TextState)} displayObjectReducer
   */
  _applyGlfxFilter(sprite, displayObjectReducer) {
    if (displayObjectReducer.filters.length) {
      const filterSourceImage = this.app.renderer.extract.canvas(sprite)
      const filteredImage = glfxFilters[displayObjectReducer.filters[0]](
        filterSourceImage
      )
      const newSprite = PIXI.Sprite.from(filteredImage, {
        resolution: this.app.renderer.resolution,
        width: sprite.width,
        height: sprite.height
      })
      return newSprite
    } else {
      return sprite
    }
  }

  /**
   *
   * @param {string} imageSource
   * @param sideIndex
   * @return {Promise<PIXI.DisplayObject>}
   */
  createMainSpriteAtCenter(imageSource, sideIndex) {
    return this.getMainSprite(imageSource, sideIndex)
  }

  getMainSprite(imageSource, sideIndex) {
    return PixiCacheFactory.get(imageSource).then(texture =>
      this._configMainSprite(texture, sideIndex)
    )
  }

  getZoneSwitcher() {
    return this._zoneSwitcher
  }

  /**
   *
   * @private
   * @param texture
   * @param sideIndex
   * @return {PIXI.DisplayObject}
   */
  _configMainSprite(texture, sideIndex) {
    const sprite = new PIXI.Sprite(texture)
    sprite.position.set(0)
    sprite.scale.set(config.data.scaleMultiply)
    sprite.sideIndex = sideIndex
    return sprite
  }

  /**
   *
   * @param {PIXI.DisplayObject} object
   */
  addToSpriteContainer(object) {
    if (object.uuid) {
      this.removeSpriteFromContainerByUUID(object.uuid)
    }

    const copySprite = SpriteController.getCopy(object)
    if (copySprite) {
      this.spriteCopiesContainer.addChild(copySprite)
    }

    this.spriteContainer.addChild(object)

    // Guarantees the order of layers
    sortSpritesByReducer()

    this.graphicUpdatedNotifyAll()
  }

  get isActiveZoneAdded() {
    return !!this.spriteContainer.children.find(sprite => sprite.MASK_FLAG)
  }

  /**
   *
   *
   * @param object
   * @param position
   */
  addToSpriteContainerAt(object, position) {
    if (object.uuid) {
      this.removeSpriteFromContainerByUUID(object.uuid)
    }

    const copyObject = SpriteController.getCopy(object)

    if (copyObject) {
      if (position >= this.spriteCopiesContainer.children.length) {
        this.spriteCopiesContainer.addChild(copyObject)
      } else {
        this.spriteCopiesContainer.addChildAt(copyObject, position)
      }
    }

    const spriteContainerPosition =
      position +
      (this.isActiveZoneAdded // eslint-disable-line
        ? 1
        : 0)

    if (spriteContainerPosition >= this.spriteContainer.children.length) {
      this.spriteContainer.addChild(object)
    } else {
      this.spriteContainer.addChildAt(object, spriteContainerPosition)
    }

    // Guarantees the order of layers
    sortSpritesByReducer()

    this.graphicUpdatedNotifyAll()
  }

  removeSpriteFromContainerByUUID(uuid) {
    this.spriteContainer.children.forEach(sprite => {
      if (sprite.uuid && sprite.uuid === uuid) {
        this.removeSpriteFromContainer(sprite)
      }
    })
    this.graphicUpdatedNotifyAll()
  }

  sliceSpriteFromContainer(index) {
    this.spriteContainer.children.splice(index, 1)
  }

  getSpriteIndexFromContainerByUUID(uuid) {
    return this.spriteContainer.children.findIndex(
      sprite => sprite.uuid && sprite.uuid === uuid
    )
  }

  getSpriteFromContainerByUUID(uuid) {
    return this.spriteContainer.children.find(
      sprite => sprite.uuid && sprite.uuid === uuid
    )
  }

  getSpriteCopyFromContainerByUUID(uuid) {
    return this.spriteCopiesContainer.children.find(
      sprite => sprite.uuid && sprite.uuid === uuid
    )
  }

  /**
   *
   * @param {PIXI.DisplayObject} object
   */
  removeSpriteFromContainer(object) {
    const copySprite = this.spriteCopiesContainer.children.find(
      sprite =>
        sprite &&
        object &&
        sprite.uuid &&
        object.uuid &&
        sprite.uuid === object.uuid
    )
    if (copySprite) {
      this.spriteCopiesContainer.removeChild(copySprite)
    }

    this.spriteContainer.removeChild(object)

    this.graphicUpdatedNotifyAll()
  }

  clearSpriteContainerNotMask() {
    _PixiController.removeChildrenFromContainer(this.spriteContainer)
    _PixiController.removeChildrenFromContainer(this.spriteCopiesContainer)

    if (this.activeZoneMask) {
      this.addToSpriteContainer(this.activeZoneMask)
    }

    SpriteController.clear()
  }

  static removeChildrenFromContainer(container) {
    if (!container) {
      return
    }

    container.removeChildren()
  }

  _createTooltipForControl(control, text = '', inverseY = false) {
    if (isMobile) {
      control.on('touchend', e => this.createTooltip(e, text, inverseY, true))
    } else {
      control.on('mouseover', e => this.createTooltip(e, text, inverseY))
      control.on('mouseout', () => this.hideTooltip())
    }
  }

  createTooltip(event, text, inverseY, autoHide = false) {
    const { target } = event
    if (!target) {
      return
    }

    const position = target.getGlobalPosition()
    this.createAndDisplayTooltip(
      text,
      position.x - 25,
      position.y + (inverseY ? target.height : -target.height - 8)
    )

    if (autoHide) {
      clearTimeout(this._tooltipTimer)
      this._tooltipTimer = setTimeout(() => {
        this.hideTooltip()
      }, 500)
    }
  }

  createAndDisplayTooltip(text = '', x = 0, y = 0) {
    if (!this.enableDisplayTooltip) {
      return
    }

    const textSprite = new PIXI.Text(text, {
      fontFamily: 'Fira Sans',
      fontSize: 12,
      fill: 0x000000
    })

    const background = new PIXI.Sprite(PIXI.Texture.WHITE)
    background.position.set(-5, -5)
    background.width = textSprite.width + 10
    background.height = textSprite.height + 10

    this.tooltipContainer.addChild(background, textSprite)
    this.tooltipContainer.position.set(x, y)
    this.tooltipContainer.visible = true
  }

  hideTooltip() {
    _PixiController.removeChildrenFromContainer(this.tooltipContainer)
    this.tooltipContainer.visible = false
  }

  /**
   *
   * @param {PIXI.DisplayObject} object
   */
  changeProductContainerChildren(object) {
    this.clearFromProductContainer()
    this.productContainer.addChild(object)
    this.productContainerObserver.notifyAll()
  }

  clearFromProductContainer() {
    _PixiController.removeChildrenFromContainer(this.productContainer)
  }

  setActiveZoneMask(rect, lineColor = 0xffffff) {
    const mask = new PIXI.Graphics()
    const copyMask = new PIXI.Graphics()

    const objects = rect.map(object => ({
      ...object,
      x: object.x * config.data.scaleMultiply,
      y: object.y * config.data.scaleMultiply,
      width: object.width * config.data.scaleMultiply,
      height: object.height * config.data.scaleMultiply
    }))

    objects.forEach(object => {
      mask.beginFill(0xffffff, 1)
      mask.drawRect(
        object.x,
        object.y,
        object.width * config.data.scaleMultiply,
        object.height * config.data.scaleMultiply
      )
      mask.endFill()

      copyMask.beginFill(0xffffff, 0)
      copyMask.drawRect(
        object.x,
        object.y,
        object.width * config.data.scaleMultiply,
        object.height * config.data.scaleMultiply
      )
      copyMask.endFill()

      this.drawDashedLinesAndDisplay(object, lineColor)
    })

    mask.MASK_FLAG = true

    if (this.activeZoneMask) {
      this.spriteContainer.removeChild(this.activeZoneMask)
    }

    this.activeZoneMask = mask
    this.activeZoneMaskCopy = copyMask

    // BUG: Pixi https://github.com/pixijs/pixi.js/issues/1978
    this.addToSpriteContainerAt(this.activeZoneMask, 0)

    this.spriteContainer.mask = this.activeZoneMask
  }

  async drawDashedLinesAndDisplay(object, lineColor) {
    const polygons = []
    polygons.push({ x: object.x, y: object.y })
    polygons.push({ x: object.x + object.width, y: object.y })
    polygons.push({
      x: object.x + object.width,
      y: object.y + object.height
    })
    polygons.push({ x: object.x, y: object.y + object.height })

    const dashLineX = object.x + object.width / 2
    const dashLineY = object.y + object.height / 2

    const dash = 16
    const gap = 8

    const line = new PIXI.Graphics()
    line.lineStyle(1, lineColor, 1, 0.5, true)
    line.drawDashedPolygon(polygons, 0, 0, 0, dash, gap, 0)

    this.verticalDashedLine = new PIXI.Graphics()
    this.verticalDashedLine.position.set(dashLineX, object.y + 5)
    this.verticalDashedLine.lineStyle(1, lineColor, 1, 0.5, true)
    this.verticalDashedLine.moveTo(0, 0)
    this.verticalDashedLine.drawDashLine(0, object.height, dash, gap)

    this.horizontalDashedLine = new PIXI.Graphics()
    this.horizontalDashedLine.position.set(object.x + 2, dashLineY)
    this.horizontalDashedLine.lineStyle(1, lineColor, 1, 0.5, true)
    this.horizontalDashedLine.moveTo(0, 0)
    this.horizontalDashedLine.drawDashLine(object.width, 0, dash, gap)

    this.hideDashedLines()

    this.dashedLineContainer.addChild(
      this.verticalDashedLine,
      this.horizontalDashedLine
    )
    this.activeZoneContainer.hitArea = new PIXI.Polygon(polygons)
    this.activeZoneContainer.addChild(line)
  }

  clearActiveZone() {
    _PixiController.removeChildrenFromContainer(this.activeZoneContainer)
    _PixiController.removeChildrenFromContainer(this.dashedLineContainer)
    const index = this.spriteContainer.children.findIndex(
      sprite => sprite.MASK_FLAG
    )
    if (index >= 0) {
      this.spriteContainer.removeChildAt(index)
    }
  }

  displayZoneContainers() {
    this.activeZoneContainer.alpha = this.spriteCopiesContainer.alpha =
      config.data.spritesAlphaOutOfZone
    this.objectControlsContainer.alpha = 1
  }

  displayPreview() {
    this.activeZoneContainer.alpha = 0
    this.spriteCopiesContainer.alpha = 0
    this.objectControlsContainer.alpha = 0
  }

  createSpriteControls() {
    const scale = 0.4
    this._trash = Tools.createControl(trash, scale)
    this._resize = Tools.createControl(resize, scale)
    this._rotate = Tools.createControl(rotate, scale)
    this._filters = Tools.createControl(filters, scale)

    this._createTooltipForControl(this._trash, t('tooltip.remove'))
    this._createTooltipForControl(this._resize, t('tooltip.resize'))
    this._createTooltipForControl(this._rotate, t('tooltip.rotate'))
    this._createTooltipForControl(this._filters, t('tooltip.filters'))

    this.controlsSpriteContainer.addChild(this._trash)
    this.controlsSpriteContainer.addChild(this._resize)
    this.controlsSpriteContainer.addChild(this._rotate)
    this.controlsSpriteContainer.addChild(this._filters)

    if (!isMobile) {
      this._textColor = Tools.createCircle()
      this._createTooltipForControl(
        this._textColor,
        t('tooltip.textColor', 'Change text color')
      )
      this.controlsSpriteContainer.addChild(this._textColor)
    }

    this._edit = Tools.createControl(edit, scale)
    // this.controlsSpriteContainer.addChild(this._edit)

    if (!isMobileOnly) {
      this._controlCopy = Tools.createControl(clone, 0.5)
      this._controlFlip = Tools.createControl(flip, 0.5)
      this._controlUpLayer = Tools.createControl(layerUp, 0.5)
      this._controlDownLayer = Tools.createControl(layerDown, 0.5)
      this._createTooltipForControl(this._controlCopy, t('tooltip.duplicate'))
      this._createTooltipForControl(this._controlFlip, t('tooltip.flip'))
      this._createTooltipForControl(
        this._controlUpLayer,
        t('tooltip.topLayer', 'Move to top layer')
      )
      this._createTooltipForControl(
        this._controlDownLayer,
        t('tooltip.bottomLayer', 'Move to bottom layer')
      )

      this.controlsSpriteContainer.addChild(this._controlFlip)
      this.controlsSpriteContainer.addChild(this._controlCopy)
      this.controlsSpriteContainer.addChild(this._controlUpLayer)
      this.controlsSpriteContainer.addChild(this._controlDownLayer)
    }

    Console.log('PIXI', 'Sprite Controls has been created.')
  }

  removeOldAndCreateNewQualityStars(quality, color) {
    if (this._currentQualityHelper === quality) {
      return
    }

    this.clearQualityStars()
    times(4, i => {
      if (i < quality) {
        const starSprite = createStar(color)
        starSprite.scale.set(0.1)
        starSprite.position.set(i * 17, 0)
        this._qualityStarsContainer.addChild(starSprite)
      } else {
        const starSprite = createEmptyStar(color)
        starSprite.scale.set(0.077)
        starSprite.position.set(i * 17, 2)
        this._qualityStarsContainer.addChild(starSprite)
      }
    })

    this._currentQualityHelper = quality
  }

  clearQualityStars() {
    _PixiController.removeChildrenFromContainer(this._qualityStarsContainer)
    this._currentQualityHelper = -1
  }

  createTopControls() {
    const scale = 0.5

    this._backButton = Tools.createControl(backControl, scale)
    this._forwardButton = Tools.createControl(forwardControl, scale)

    this._createTooltipForControl(this._backButton, t('button.undo'), true)
    this._createTooltipForControl(this._forwardButton, t('button.redo'), true)

    if (!config.data.generatorMode) {
      this.outerControlsContainer.addChild(this._backButton)
      this.outerControlsContainer.addChild(this._forwardButton)
    }
  }

  createBottomControls() {
    const scale = 0.5
    this._zoomIn = Tools.createControl(zoomIn, scale)
    this._zoomOut = Tools.createControl(zoomOut, scale)

    this._createTooltipForControl(this._zoomIn, t('zoom.in', 'Zoom in'), true)
    this._createTooltipForControl(
      this._zoomOut,
      t('zoom.out', 'Zoom out'),
      true
    )

    const xPosition = 40
    const yPosition = 30
    this._zoomIn.position.set(40, yPosition)
    this._zoomOut.position.set(40 + xPosition, yPosition)

    if (!isMobileOnly) {
      this._defaultZoom = Tools.createControl(zoomOnProduct, scale)
      this._createTooltipForControl(
        this._defaultZoom,
        t('zoom.at.product', 'Zoom at product'),
        true
      )
      this._defaultZoom.position.set(40 + 2 * xPosition, yPosition)
      if (!config.data.generatorMode) {
        this.leftTopControlsContainer.addChild(this._defaultZoom)
      }
    }
    if (!config.data.generatorMode) {
      this.leftTopControlsContainer.addChild(this._zoomIn)
      this.leftTopControlsContainer.addChild(this._zoomOut)
    }

    this._controlRotatePreview = Tools.createControl(rotatePreview, scale)
    this._createTooltipForControl(
      this._controlRotatePreview,
      t('tooltip.rotateCanvas')
    )
    if (!config.data.generatorMode) {
      this.outerControlsContainer.addChild(this._controlRotatePreview)
    }

    Console.log('PIXI', 'Bottom Control has been created.')
  }

  createLeftBottomContainerControls() {
    const buttonVisibilityOn = Tools.createControl(visibilityOn)
    const buttonVisibilityOff = Tools.createControl(visibilityOff)
    buttonVisibilityOn.visible = !this.isPreviewMode()
    buttonVisibilityOff.visible = this.isPreviewMode()

    this._zoneSwitcher.addChild(buttonVisibilityOn)
    this._zoneSwitcher.addChild(buttonVisibilityOff)

    this._zoneSwitcher.interactive = true
    this._zoneSwitcher.buttonMode = true

    this._zoneSwitcher.position.set(canvasDimension.canvasHeight() - 50, 30)
    this.leftBottomControlsContainer.addChild(this._zoneSwitcher)
  }

  updateVisibilityControlZone() {
    if (
      !this._zoneSwitcher.parent ||
      this._zoneSwitcher.children.length !== 2
    ) {
      return
    }

    this._zoneSwitcher.children[0].visible = !this.isPreviewMode()
    this._zoneSwitcher.children[1].visible = this.isPreviewMode()
  }

  toggleMode() {
    if (this.isPreviewMode()) {
      this.switchToEditMode()
      return
    }

    this.switchToPreviewMode()
  }

  updateControlsPosition() {
    this._controlRotatePreview.position.set(
      canvasDimension.canvasWidth() - 50,
      canvasDimension.canvasHeight() - 40
    )

    this._zoneSwitcher.position.set(canvasDimension.canvasWidth() - 50, 70)

    this._forwardButton.position.set(canvasDimension.canvasWidth() - 50, 30)

    this._backButton.position.set(canvasDimension.canvasWidth() - 90, 30)
  }

  createLineControls(lineColor) {
    const lineLength = 20
    const lineWidth = 1.5

    this._lineTL = new PIXI.Graphics()
    this._lineTL.lineStyle(lineWidth, lineColor, 1, 0.5, true)
    this._lineTL.moveTo(0, lineLength)
    this._lineTL.lineTo(0, 0)
    this._lineTL.lineTo(lineLength, 0)
    this._lineTL.endFill()

    this._lineTR = new PIXI.Graphics()
    this._lineTR.lineStyle(lineWidth, lineColor, 1, 0.5, true)
    this._lineTR.moveTo(-lineLength, 0)
    this._lineTR.lineTo(0, 0)
    this._lineTR.lineTo(0, lineLength)
    this._lineTR.endFill()

    this._lineBL = new PIXI.Graphics()
    this._lineBL.lineStyle(lineWidth, lineColor, 1, 0.5, true)
    this._lineBL.moveTo(0, -lineLength)
    this._lineBL.lineTo(0, 0)
    this._lineBL.lineTo(lineLength, 0)
    this._lineBL.endFill()

    this._lineBR = new PIXI.Graphics()
    this._lineBR.lineStyle(lineWidth, lineColor, 1, 0.5, true)
    this._lineBR.moveTo(-lineLength, 0)
    this._lineBR.lineTo(0, 0)
    this._lineBR.lineTo(0, -lineLength)
    this._lineBR.endFill()

    this.controlsLineContainer.addChild(this._lineTL)
    this.controlsLineContainer.addChild(this._lineTR)
    this.controlsLineContainer.addChild(this._lineBR)
    this.controlsLineContainer.addChild(this._lineBL)

    Console.log('PIXI', 'Controls Line created.')
  }

  turnOffAlphaOfSpriteControls(includedVisibility = false) {
    this.controlsSpriteContainer.alpha = 0
    if (includedVisibility) {
      this.controlsSpriteContainer.visible = false
    }
  }

  hideLineControls() {
    this.controlsLineContainer.visible = false
  }

  hideQualityStarsContainer() {
    this._qualityStarsContainer.visible = false
  }

  hideControls() {
    this.hideSpriteAndLineControls()
  }

  hideSpriteAndLineControls(includedVisibility = false) {
    this.turnOffAlphaOfSpriteControls(includedVisibility)
    this.hideLineControls()
    this.hideQualityStarsContainer()
  }

  clearLineControlsContainer() {
    _PixiController.removeChildrenFromContainer(this.controlsLineContainer)
  }

  /**
   *
   * @param {PIXI.DisplayObject} sprite
   */
  showControlsAndSetPosition(sprite) {
    if (!sprite) {
      return
    }

    if (!isMobile) {
      this.displayTextColor(sprite)
    }

    this._edit.visible = sprite.isText()
    this._filters.visible = config.data.enableFilters && !sprite.isText()
    this.controlsSpriteContainer.visible = true
    this.controlsSpriteContainer.alpha = 1
    this.controlsLineContainer.visible = true
    this._qualityStarsContainer.visible = true
    this.setControlsPositionAroundSprite(sprite)
  }

  displayTextColor(sprite) {
    const visible = sprite.isText()

    if (this._textColor) {
      this.controlsSpriteContainer.removeChild(this._textColor)
      this._textColor = null
    }

    if (!visible) {
      return
    }

    const fillColor = (sprite.style && sprite.style.fill) || sprite.fillColor

    if (!fillColor) {
      return
    }

    const color = chroma(fillColor)

    this._textColor = Tools.createCircle(color.num())
    this.controlsManager.interactiveTextColor(this._textColor)
    this.controlsSpriteContainer.addChild(this._textColor)
  }

  /**
   *
   * @param {PIXI.DisplayObject} sprite
   */
  setControlsPositionAroundSprite(sprite) {
    // Trim texture
    const bounds =
      sprite.isText() && !sprite.isCurved()
        ? sprite.getTrimmedBounds()
        : sprite.getBounds()

    const controlMargin = 10
    const controlOffset = 10
    const lineOffset = 0

    const boundsCenterX = bounds.x + bounds.width / 2
    const boundsCenterY = bounds.y + bounds.height / 2

    const centerIconOffset = {
      x: 10,
      y: 32
    }

    this._trash.position.set(
      bounds.right + controlOffset,
      bounds.bottom + controlOffset
    )
    this._resize.position.set(
      bounds.right + controlOffset,
      bounds.top - controlOffset
    )
    this._rotate.position.set(
      bounds.left - controlOffset,
      bounds.top - controlOffset
    )

    if (this._textColor) {
      this._textColor.position.set(
        bounds.left - controlOffset,
        bounds.bottom + controlOffset
      )
    }
    if (config.data.enableFilters) {
      if (sprite.isText()) {
        this._filters.position.set(bounds.left - controlOffset, boundsCenterY)
      } else {
        this._filters.position.set(
          bounds.left - controlOffset,
          bounds.bottom + controlOffset
        )
      }
    }

    const qualityOffset = isMobileOnly ? -10 : 20

    this._qualityStarsContainer.position.set(
      boundsCenterX - 25,
      bounds.bottom + controlMargin + centerIconOffset.y + qualityOffset
    )

    if (isMobileOnly) {
      this._edit.position.set(
        bounds.left - controlOffset,
        bounds.top + bounds.height + controlOffset
      )
    } else {
      this._edit.position.set(bounds.left - controlOffset, boundsCenterY)
      this._controlCopy.position.set(
        boundsCenterX + controlMargin + centerIconOffset.x,
        bounds.top - controlOffset - centerIconOffset.y
      )

      this._controlFlip.position.set(
        boundsCenterX - controlMargin - centerIconOffset.x,
        bounds.top - controlOffset - centerIconOffset.y
      )

      this._controlUpLayer.position.set(
        boundsCenterX + controlMargin + centerIconOffset.x,
        bounds.bottom + controlOffset + centerIconOffset.y
      )

      this._controlDownLayer.position.set(
        boundsCenterX - controlMargin - centerIconOffset.x,
        bounds.bottom + controlOffset + centerIconOffset.y
      )
    }

    this._lineTL.position.set(bounds.x - lineOffset, bounds.y - lineOffset)
    this._lineTR.position.set(
      bounds.right + lineOffset,
      bounds.top - lineOffset
    )
    this._lineBL.position.set(
      bounds.left - lineOffset,
      bounds.bottom + lineOffset
    )
    this._lineBR.position.set(
      bounds.right + lineOffset,
      bounds.bottom + lineOffset
    )
  }

  /**
   *
   * @param {{x: number, y: number}} point
   * @param {number} scaleChange
   */
  zoomToPosition(point, scaleChange) {
    const oldPoint = this.contentContainer.toLocal(point)
    const scale = this.contentContainer.scale.x * scaleChange

    if (scale > 4.239986944200745 || scale <= 0.2) {
      return
    }

    this.contentContainer.scale.x = scale
    this.contentContainer.scale.y = scale

    const newPoint = this.contentContainer.toGlobal(oldPoint)

    this.setContentPosition(
      this.contentContainer.position.x + point.x - newPoint.x,
      this.contentContainer.position.y + point.y - newPoint.y
    )

    this.zoomObservers.notifyAll()
  }

  zoomIn() {
    this.zoomToPosition({ x: canvasCenter.x(), y: canvasCenter.y() }, 1.05)
  }

  zoomOut() {
    this.zoomToPosition({ x: canvasCenter.x(), y: canvasCenter.y() }, 0.95)
  }

  zoomDefault() {
    this.resetScaleAndPositionOfContentContainer()
    this.zoomObservers.notifyAll()
  }

  resetScaleAndPositionOfContentContainer() {
    this.windowResize()

    const { height, width } = this.productContainer

    const scaleWidth = width ? canvasDimension.canvasWidth() / width : 1

    const scaleHeight = height ? canvasDimension.canvasHeight() / height : 1

    const scale = Math.min(scaleHeight, scaleWidth)

    this.contentContainer.scale.set(scale)

    if (width) {
      this.setContentPosition(
        canvasCenter.x() - (width * this.contentContainer.scale.x) / 2,
        0
      )
    } else {
      this.setContentPosition(0, 0)
    }

    this.contentContainer.rotation = 0
  }

  setContentPosition(x, y) {
    this.contentContainer.position.set(x, y)
  }

  /**
   * Create Text Sprite
   * @param {(SpriteState|TextState)} displayObjectReducer
   */
  createText(displayObjectReducer) {
    const pixiText = this.createPixiText(displayObjectReducer)
    let createdObject = pixiText
    if (displayObjectReducer.isCurved()) {
      const curvedText = this.makeTextCurved(pixiText, displayObjectReducer)
      if (this._inputFocussed) {
        curvedText.visible = false
      }
      createdObject = curvedText
    }

    return createdObject
  }

  makeTextCurved(pixiText, displayObjectReducer) {
    if (displayObjectReducer.radius <= 0) {
      return null
    }

    return this.createMeshRopeForCurvedText(
      pixiText,
      pixiText.style,
      displayObjectReducer
    )
  }

  /**
   *
   * @param {PIXI.Texture} texture
   * @param {(SpriteState|TextState)} displayObjectReducer
   * @return {PIXI.Point[]}
   */
  _calculateRopePoints(texture, displayObjectReducer) {
    let ropePoints =
      CurvedTextConstants.maxRopePoints -
      Math.round(
        (texture.width / (displayObjectReducer.radius * Math.PI)) *
          CurvedTextConstants.maxRopePoints
      )
    ropePoints /= 2

    const invertY =
      displayObjectReducer.curvedText === constant.CURVED_TEXT.BOTTOM ? 1 : -1

    const points = []
    for (
      let i = CurvedTextConstants.maxRopePoints - ropePoints;
      i > ropePoints;
      i -= 1
    ) {
      const x =
        displayObjectReducer.radius * Math.cos(CurvedTextConstants.step * i)
      const y =
        displayObjectReducer.radius *
        invertY *
        Math.sin(CurvedTextConstants.step * i)
      points.push(new PIXI.Point(x, -y))
    }

    return points
  }

  /**
   *
   * @param {PIXI.Text} pixiText
   * @param style
   * @param {(SpriteState|TextState)} displayObjectReducer
   */
  createMeshRopeForCurvedText(pixiText, style, displayObjectReducer) {
    const points = this._calculateRopePoints(pixiText, displayObjectReducer)

    // in v5 refactored to Mesh
    const rope = new PIXI.SimpleRope(pixiText.texture, points)

    rope.uuid = displayObjectReducer.uuid
    rope.kType = constant.SPRITE_TYPE.TEXT
    rope.kCurvedText = displayObjectReducer.curvedText
    rope.kRadius = displayObjectReducer.radius

    rope.position.set(
      displayObjectReducer.x * config.data.scaleMultiply,
      displayObjectReducer.y * config.data.scaleMultiply
    )
    rope.scale.set(
      displayObjectReducer.getScale().x * config.data.scaleMultiply,
      displayObjectReducer.getScale().y * config.data.scaleMultiply
    )
    rope.rotation = displayObjectReducer.rotation

    // Rotation
    rope.pivot.y =
      (displayObjectReducer.curvedText === constant.CURVED_TEXT.BOTTOM
        ? -1
        : 1) * displayObjectReducer.radius

    if (SpriteController.getCopy(rope)) {
      this.spriteCopiesContainer.removeChild(SpriteController.getCopy(rope))
    }

    rope.fillColor = style && style.fill
    rope.style = style
    rope.sideIndex = displayObjectReducer.sideIndex

    SpriteController.set(rope)
    SpriteController.createCopy(rope)

    return rope
  }

  /**
   *
   * @param {PIXI.Text} pixiText
   * @param {(SpriteState|TextState)} reducer
   */

  hideTextInputShadow() {
    this.objectControlsContainer.removeChild(this._textShadowInput)
  }

  isInputFocussed() {
    return this._inputFocussed
  }

  createTextInputShadow(pixiText, reducer) {
    this._textShadowInput.rotation =
      reducer.rotation + this.contentContainer.rotation
    this._textShadowInput.setInputStyle(
      'width',
      reducer.isCurved()
        ? `${
            PIXI.TextMetrics.measureText(reducer.text, pixiText.style).width
          }px`
        : `${pixiText.width + 1}px`
    )
    this._textShadowInput.setInputStyle('height', `${pixiText.height}px`)
    this._textShadowInput.setInputStyle('fontSize', `${reducer.fontSize}px`)
    this._textShadowInput.setInputStyle(
      'line-height',
      `${1.2 * reducer.fontSize}px`
    )
    this._textShadowInput.setInputStyle('text-align', reducer.align)
    this._textShadowInput.setInputStyle('fontFamily', reducer.fontFamily)
    this._textShadowInput.setInputStyle('color', 'transparent')
    this._textShadowInput.setInputStyle('resize', 'none')
    this._textShadowInput.setInputStyle('overflow', 'hidden')
    this._textShadowInput.setInputStyle('caret-color', reducer.color)

    this._textShadowInput.setInputStyle(
      'fontWeight',
      reducer.bold ? 'bold' : 'normal'
    )
    this._textShadowInput.setInputStyle(
      'fontStyle',
      reducer.italic ? 'italic' : 'normal'
    )
    this._textShadowInput.setInputStyle('padding', config.data.text.padding)

    this._textShadowInput.scale.x = this.contentContainer.scale.x
    this._textShadowInput.scale.y = this.contentContainer.scale.x

    this._textShadowInput.text = reducer.text

    const isCurved = reducer.isCurved()

    this._textShadowInput.removeAllListeners('focus')
    this._textShadowInput.removeAllListeners('blur')
    this._textShadowInput.removeAllListeners('input')

    this._textShadowInput.on('focus', () => {
      this.switchToEditMode()
      this.showControlsAndSetPosition(pixiText)
      ControlsManager.inputFocussed(pixiText)
      this._inputFocussed = true
      if (isCurved) {
        pixiText.visible = false
        this._textShadowInput.setInputStyle('color', reducer.color)
      }
    })

    this._textShadowInput.on('blur', () => {
      this.hideTextInputShadow()
      if (reducer.text === '') {
        ControlsManager.onRemoveObject()
      }
      this._inputFocussed = false
      let active = EditorManager.getActiveProductEditor().activePixiSprite
      if (active) {
        active.visible = true
      }
    })

    this._textShadowInput.on('input', newText => {
      let filteredText = removeEmoji(newText)
      reducer.text = filteredText
      this._textShadowInput.text = filteredText
      ControlsManager.textChanged(reducer)
    })

    this._textShadowInput.pivot.set(pixiText.width / 2, pixiText.height / 2)
    const bounds = pixiText.getBounds()
    this._textShadowInput.position.set(
      bounds.x +
        bounds.width / 2 +
        Math.sin(reducer.rotation) * 0.1 * reducer.fontSize +
        (reducer.isCurved() ? (bounds.width - this._textShadowInput.width) / 2 : 0),
      bounds.y +
        bounds.height / 2 -
        Math.cos(reducer.rotation) * 0.1 * reducer.fontSize + 
        (reducer.isCurved() ? (bounds.height - this._textShadowInput.height) / 2 : 0)
    )
    this.objectControlsContainer.addChild(this._textShadowInput)
  }

  updateTextInputShadowSize(pixiText, reducer) {
    this._textShadowInput.setInputStyle(
      'width',
      reducer.isCurved()
        ? `${
            PIXI.TextMetrics.measureText(reducer.text, pixiText.style).width
          }px`
        : `${pixiText.width + 1}px`
    )
    this._textShadowInput.setInputStyle('height', `${pixiText.height}px`)
    this._textShadowInput.pivot.set(pixiText.width / 2, pixiText.height / 2)
    const bounds = pixiText.getBounds()
    this._textShadowInput.position.set(
      bounds.x +
        bounds.width / 2 +
        Math.sin(reducer.rotation) * 0.1 * reducer.fontSize +
        (reducer.isCurved() ? (bounds.width - this._textShadowInput.width) / 2 : 0),
      bounds.y +
        bounds.height / 2 -
        Math.cos(reducer.rotation) * 0.1 * reducer.fontSize + 
        (reducer.isCurved() ? (bounds.height - this._textShadowInput.height) / 2 : 0)
    )
  }

  blurTextInputShadowIfApplicable() {
    if (
      EditorManager.getActiveProductEditor().activePixiSprite &&
      EditorManager.getActiveProductEditor().activePixiSprite.isText()
    ) {
      this._textShadowInput.blur()
    }
  }

  /**
   *
   * @param {(SpriteState|TextState)} displayObjectReducer
   * @return {PIXI.Text}
   */
  createPixiText(displayObjectReducer) {
    /**
     * REQUIRED FOR GENERATOR:
     * [bug] padding is mandatory field due to excessive font (@see https://github.com/pixijs/pixi.js/issues/5864).
     * @type {PIXI.Text}
     */
    const pixiText = new PIXI.Text(displayObjectReducer.text, {
      fontSize: displayObjectReducer.fontSize,
      fontFamily: displayObjectReducer.fontFamily,
      fill: displayObjectReducer.color,
      fontWeight: displayObjectReducer.bold ? 'bold' : 'normal',
      fontStyle: displayObjectReducer.italic ? 'italic' : 'normal',
      align: displayObjectReducer.align,
      padding: config.data.text.padding,
      miterLimit: config.data.text.miterLimit
    })

    pixiText.anchor = new PIXI.Point(0.5, 0.5)
    pixiText.position.set(
      displayObjectReducer.x * config.data.scaleMultiply,
      displayObjectReducer.y * config.data.scaleMultiply
    )
    pixiText.rotation = displayObjectReducer.rotation
    pixiText.kRadius = 0

    if (!displayObjectReducer.isCurved()) {
      pixiText.scale.set(
        displayObjectReducer.getScale().x * config.data.scaleMultiply,
        displayObjectReducer.getScale().y * config.data.scaleMultiply
      )
    }

    pixiText.sideIndex = displayObjectReducer.sideIndex

    pixiText.uuid = displayObjectReducer.uuid
    pixiText.kType = constant.SPRITE_TYPE.TEXT
    pixiText.kCurvedText = constant.CURVED_TEXT.NONE

    if (!displayObjectReducer.isCurved()) {
      if (SpriteController.getCopy(pixiText)) {
        this.spriteCopiesContainer.removeChild(
          SpriteController.getCopy(pixiText)
        )
      }

      SpriteController.set(pixiText)
      SpriteController.createCopy(pixiText)
    }
    // To calculate texture (dimensions, ...)
    pixiText.updateText()

    return pixiText
  }

  /**
   *
   * @return {PIXI.Sprite}
   */
  getTrashControl() {
    return this._trash
  }

  /**
   * @return {PIXI.Sprite}
   */
  getFiltersControl() {
    return this._filters
  }

  /**
   *
   * @return {PIXI.Sprite}
   */
  getEditControl() {
    return this._edit
  }

  /**
   *
   * @return {PIXI.Sprite}
   */
  getRotateControl() {
    return this._rotate
  }

  /**
   *
   * @return {PIXI.Graphics}
   */
  getTextColorControl() {
    return this._textColor
  }

  /**
   *
   * @return {PIXI.Sprite}
   */
  getResizeControl() {
    return this._resize
  }

  /**
   *
   * @return {PIXI.Sprite}
   */
  getZoomInControl() {
    return this._zoomIn
  }

  /**
   *
   * @return {PIXI.Sprite}
   */
  getZoomOutControl() {
    return this._zoomOut
  }

  /**
   *
   * @return {PIXI.Sprite}
   */
  getDefaultZoomControl() {
    return this._defaultZoom
  }

  // todo: move this function to Controls Manager
  setSpritesInteractive(originalSprite) {
    if (originalSprite != null) {
      const spriteCopy = SpriteController.getCopy(originalSprite)
      if (spriteCopy) {
        spriteCopy.interactive = true
        spriteCopy.buttonMode = true
        spriteCopy
          .on('pointerdown', ControlsManager.onObjectDragStart)
          .on('pointermove', ControlsManager.onObjectDragMove)
          .on('pointerup', ControlsManager.onObjectDragEnd)
          .on('pointerupoutside', ControlsManager.onObjectDragEnd)
      }
    }
  }

  isPreviewMode() {
    return this.mode === MODE.PREVIEW_MODE
  }

  switchToPreviewMode() {
    this.changeMode(MODE.PREVIEW_MODE)
  }

  switchToEditMode() {
    this.changeMode(MODE.EDITABLE_MODE)
  }

  changeMode(mode) {
    this.mode = mode

    switch (this.mode) {
      case MODE.EDITABLE_MODE: {
        this.displayZoneContainers()
        break
      }

      default: {
        this.displayPreview()
        break
      }
    }

    this.updateVisibilityControlZone()
  }

  windowResize() {
    if (!this.isPixiAppended) {
      return
    }

    const width = canvasDimension.canvasWidth()
    const height = canvasDimension.canvasHeight()

    this.updateControlsPosition()

    this.app.renderer.view.style.width = `${width} px`
    this.app.renderer.view.style.height = `${height} px`
    this.app.renderer.resize(width, height)
  }

  getActiveFlipControl() {
    return this._controlFlip
  }

  getActiveUpLayerControl() {
    return this._controlUpLayer
  }

  getActiveDownLayerControl() {
    return this._controlDownLayer
  }

  getActiveCopyControl() {
    return this._controlCopy
  }

  getControlRotatePreview() {
    return this._controlRotatePreview
  }

  setSpriteToBottomLayer(sprite) {
    this.removeSpriteFromContainer(sprite)
    this.addToSpriteContainerAt(sprite, 0)
    this.graphicUpdatedNotifyAll()
  }

  setSpriteToTopLayer(sprite) {
    this.removeSpriteFromContainer(sprite)
    this.addToSpriteContainer(sprite)
    this.graphicUpdatedNotifyAll()
  }

  setSpriteToLayerPosition(sprite, position) {
    this.removeSpriteFromContainer(sprite)
    this.addToSpriteContainerAt(sprite, position)
    this.graphicUpdatedNotifyAll()
  }

  // eslint-disable-next-line class-methods-use-this
  flipSprite(sprite) {
    const spriteCopy = SpriteController.getCopy(sprite)
    if (spriteCopy) {
      spriteCopy.scale.set(spriteCopy.scale.x * -1, spriteCopy.scale.y)
    }
    sprite.scale.set(sprite.scale.x * -1, sprite.scale.y)
    this.graphicUpdatedNotifyAll()
  }

  rotatePreviewClockwise() {
    const content = this.contentContainer

    if (content.width !== 0) {
      content.rotation += Math.PI * 0.5
      this._updateContentPreviewPositionByRotation()
      const activeSprite = EditorManager.getActiveProductEditor()
        .activePixiSprite
      if (activeSprite) {
        this.setControlsPositionAroundSprite(activeSprite)
      }
      if (this.textShadowInput) {
        this.textShadowInput.rotation += Math.PI * 0.5
      }
      this.previewRotationObserver.notifyAll()
    }
  }

  // fixme: https://github.com/pixijs/pixi.js/issues/190#issuecomment-240740064
  _updateContentPreviewPositionByRotation() {
    const content = this.contentContainer

    switch (content.rotation) {
      case Math.PI * 0.5: {
        content.position.x += content.width
        break
      }
      case Math.PI: {
        content.position.y += content.height
        break
      }
      case Math.PI * 1.5:
        content.position.x -= content.width
        break
      default: {
        content.position.y -= content.height
        content.rotation = 0
        break
      }
    }
  }

  graphicUpdatedNotifyAll() {
    this._graphicUpdated.notifyAll()
  }

  registerGraphicUpdated(listener, scope) {
    this._graphicUpdated.subscribe(listener, scope)
  }

  unregisterGraphicUpdated(listener) {
    this._graphicUpdated.unsubscribe(listener)
  }

  enableTooltip() {
    this.enableDisplayTooltip = true
  }

  disableTooltip() {
    this.enableDisplayTooltip = false
    this.hideTooltip()
  }

  hideDashedLines() {
    if (this.verticalDashedLine && this.horizontalDashedLine) {
      this.verticalDashedLine.visible = false
      this.horizontalDashedLine.visible = false
    }
  }

  isObjectInCenter(x, y) {
    if (this.verticalDashedLine && this.horizontalDashedLine) {
      return {
        x: this.verticalDashedLine.x && this.verticalDashedLine.x === x,
        y: this.horizontalDashedLine.y && this.horizontalDashedLine.y === y
      }
    }
    return {
      x: undefined,
      y: undefined
    }
  }

  isObjectOutOfZone(sprite) {
    if (!this.activeZoneMask || !sprite) {
      return false
    }

    const { left, top, bottom, right } =
      sprite.isText() && !sprite.isCurved()
        ? sprite.getTrimmedBounds()
        : sprite.getBounds()

    const mask = this.activeZoneMask.getBounds()
    return (
      Math.round(left * 100) / 100 < mask.left ||
      Math.round(right * 100) / 100 > mask.right ||
      Math.round(bottom * 100) / 100 > mask.bottom ||
      Math.round(top * 100) / 100 < mask.top
    )
  }

  isWholeObjectOutOfZone(sprite) {
    if (!this.activeZoneMask || !sprite) {
      return false
    }

    const { left, top, bottom, right } =
      sprite.isText() && !sprite.isCurved()
        ? sprite.getTrimmedBounds()
        : sprite.getBounds()

    const mask = this.activeZoneMask.getBounds()
    return (
      Math.round(right * 100) / 100 < mask.left ||
      Math.round(left * 100) / 100 > mask.right ||
      Math.round(top * 100) / 100 > mask.bottom ||
      Math.round(bottom * 100) / 100 < mask.top
    )
  }

  getNewPositionsIfObjectIsNearToCenter(x, y) {
    return {
      x:
        this.verticalDashedLine.x - OBJECT_NEAR_RANGE <= x &&
        this.verticalDashedLine.x + OBJECT_NEAR_RANGE >= x
          ? this.verticalDashedLine.x
          : x,
      y:
        this.horizontalDashedLine.y - OBJECT_NEAR_RANGE <= y &&
        this.horizontalDashedLine.y + OBJECT_NEAR_RANGE >= y
          ? this.horizontalDashedLine.y
          : y
    }
  }

  setVisibilityDashedLinesByObjectDistance(x, y) {
    if (this.verticalDashedLine && this.horizontalDashedLine) {
      this.verticalDashedLine.visible = this.verticalDashedLine.x === x
      this.horizontalDashedLine.visible = this.horizontalDashedLine.y === y
    }
  }

  enableControlContainer() {
    this.canControlContainer = true
  }

  disableControlContainer() {
    this.canControlContainer = false
  }

  setAutomaticTouchAction() {
    // [BUG] http://www.html5gamedevs.com/topic/33024-scrollzoom-whole-html-body-making-a-swipe-on-canvas-on-android-autopreventdefault-false-not-work/
    this.app.renderer.view.style['touch-action'] = 'auto'
  }

  getBackControl() {
    return this._backButton
  }

  hideBackControl() {
    this._backButton.visible = false
    if (this.tooltipContainer) {
      this.hideTooltip()
    }
  }

  displayBackControl() {
    this._backButton.visible = true
  }

  displayForwardControl() {
    this._forwardButton.visible = true
  }

  hideForwardControl() {
    this._forwardButton.visible = false
    if (this.tooltipContainer) {
      this.hideTooltip()
    }
  }

  getForwardControl() {
    return this._forwardButton
  }

  setFilters(filters = []) {
    this.parentSpriteContainer.filters = filters
  }

  numberOfUniqueSprites(sideIndex) {
    if (!this.spriteCopiesContainer) {
      return 0
    }

    const unique = new Set()
    this.spriteCopiesContainer.children.forEach(
      sprite =>
        sprite.uuid &&
        sprite.texture.baseTexture.valid &&
        sprite.sideIndex === sideIndex &&
        unique.add(sprite.uuid)
    )

    let spriteContainerSize = 0
    this.spriteContainer.children.forEach(
      sprite => sprite.uuid && unique.has(sprite.uuid) && ++spriteContainerSize // eslint-disable-line no-plusplus
    )

    return spriteContainerSize
  }

  get isMainSpriteReady() {
    return this.productContainer.children.length === 1
  }
}

/**
 *
 * @export
 * @type {_PixiController}
 */
export const PixiController = new _PixiController()
