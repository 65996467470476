import React, { useState, useEffect } from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SmartSelect from '../../SmartSelect'
import { STATE } from '../../../../constants/ProductConstants'
import Observer from '../../../pixi/helper/Observer'
import { ALL_MANUFACTURES } from './index'

export const categoryIDChanged = new Observer()

function getDefaultCategory(productCategories) {
  if (productCategories && productCategories.length > 0) {
    return productCategories[0].value
  }

  return undefined
}

function CategoriesSelect({
  categories,
  category,
  onChange,
  loading,
  className
}) {
  return (
    <div className={className}>
      <SmartSelect
        options={categories}
        isLoading={loading}
        className={className}
        value={
          categories &&
          categories.find(el => el.value === (category.id || category))
        }
        onChange={onChange}
      />
    </div>
  )
}

CategoriesSelect.propTypes = {
  categories: PropTypes.array.isRequired,
  category: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string
}

CategoriesSelect.defaultProps = {
  loading: false,
  className: '',
  category: 0
}

function CategoryState(defaultValue, categories, onChangeCallback) {
  const [categoryID, setCategoryID] = useState(defaultValue)

  function onChange(value) {
    if (value !== categoryID) {
      setCategoryID(value)

      if (onChangeCallback) {
        onChangeCallback(value)
      }
    }
  }

  useEffect(() => {
    if (
      categories.state === STATE.FETCHED &&
      typeof categoryID === 'undefined'
    ) {
      onChange(getDefaultCategory(categories.products))
    }
  }, [categories.state])

  return {
    id: categoryID,
    onChange,
    set: setCategoryID
  }
}

/**
 * @return {[]}
 */
function getSubCategories(mainCategoryID, productCategories) {
  if (typeof mainCategoryID === 'undefined') {
    return []
  }

  const mainCategory = productCategories.find(
    productCategory => productCategory.value === mainCategoryID
  )

  if (!mainCategory || !mainCategory.children) {
    return []
  }

  return [
    { label: t('message.allProductCategories'), value: mainCategoryID }
  ].concat(mainCategory.children)
}

function SelectProductCategory({
  defaultCategory,
  defaultSubCategory,
  manufacture,
  setManufacture,
  categories,
  onCategoryChanged,
  loading,
  manufactures
}) {
  function onSubCategoryChangedCallback(id) {
    onCategoryChanged(id)
    categoryIDChanged.notifyAll(id)
  }

  const mainCategory = CategoryState(defaultCategory, categories)
  const subCategory = CategoryState(
    defaultSubCategory,
    categories,
    onSubCategoryChangedCallback
  )

  return (
    <>
      <CategoriesSelect
        categories={categories.products}
        category={mainCategory}
        onChange={item => {
          mainCategory.onChange(item.value)
          subCategory.onChange(item.value)
        }}
        className="categories"
      />

      <CategoriesSelect
        categories={getSubCategories(mainCategory.id, categories.products)}
        category={subCategory}
        onChange={item => {
          subCategory.onChange(item.value)
        }}
        loading={loading}
        className="subcategories"
      />

      {manufactures.length ? (
        <CategoriesSelect
          categories={[
            {
              label: t('message.allProductSubCategories'),
              value: ALL_MANUFACTURES
            }
          ].concat(
            manufactures.map(_manufacture => ({
              label: _manufacture.name,
              value: _manufacture.id
            }))
          )}
          onChange={item => setManufacture(item.value)}
          category={manufacture}
          loading={false}
          className="brands"
        />
      ) : null}
    </>
  )
}

SelectProductCategory.propTypes = {
  categories: PropTypes.object.isRequired,
  onCategoryChanged: PropTypes.func,
  loading: PropTypes.bool,
  defaultCategory: PropTypes.number,
  defaultSubCategory: PropTypes.number,
  manufacture: PropTypes.number,
  manufactures: PropTypes.array,
  setManufacture: PropTypes.func
}

SelectProductCategory.defaultProps = {
  onCategoryChanged: f => f,
  loading: false,
  defaultCategory: undefined,
  defaultSubCategory: undefined,
  manufacture: 0,
  manufactures: [],
  setManufacture: f => f
}

const mapStateToProps = state => ({
  categories: state.categories
})

const SelectProductCategoryConnected = connect(mapStateToProps)(
  React.memo(SelectProductCategory)
)

export default SelectProductCategoryConnected
