import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const modalRoot = document.getElementsByTagName('body')[0]

class Modal extends React.Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  }

  render() {
    const { children } = this.props
    return ReactDOM.createPortal(children, modalRoot)
  }
}

export default Modal
