import React from 'react'
import PropTypes from 'prop-types'
import { stopPropagationForAllEvents } from '../../utils/StopPropagation'

class ColorComponent extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string.isRequired,
    label: PropTypes.string,
    onClick: PropTypes.func
  }

  static defaultProps = {
    onClick: () => {},
    label: ''
  }

  state = {}

  render() {
    const { color, label, onClick } = this.props
    return (
      <button
        type="button"
        className="one-color-component disable-propagation"
        title={label}
        style={{ backgroundColor: color }}
        {...stopPropagationForAllEvents()}
        onClick={e => {
          e.stopPropagation()
          onClick(color)
        }}>
        &nbsp;
      </button>
    )
  }
}

export default ColorComponent
