import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

import Header from '../web/components/Header'
import {
  isMobileOnly,
  generateDefaultClassNames
} from '../core-module/utils/device-detect'
import WebContainer from '../web/containers/AppContainer/index'
import MobileContainer from '../mobile/containers/AppContainer/index'

const Container = styled.div`
  text-align: center;
`

function getContent() {
  if (isMobileOnly) {
    return <Route path="/" component={MobileContainer} />
  }

  return <Route path="/" component={WebContainer} />
}

function Routes() {
  return (
    <BrowserRouter>
      <Container className={generateDefaultClassNames()}>
        <Header />
        {getContent()}
        <ToastContainer />
      </Container>
    </BrowserRouter>
  )
}

export default Routes
