import store from '../../store/MyStore'
import { fetchProductConfig } from '../../actions/product'
import { actionSetSpritesAtCenterAndScaleToZone } from '../../actions/editor'

/**
 * OUTDATED!!! NEED UPDATE!
 * @param id
 * @param data
 * @returns {*}
 */
export async function fetchProductConfigAndHandleCurrentSprites(
  id,
  { data, value: canMoveToCenter }
) {
  const productConfig = await store.dispatch(fetchProductConfig(id))

  if (
    canMoveToCenter &&
    productConfig.views &&
    productConfig.views.length &&
    productConfig.views[0].areas &&
    productConfig.views[0].areas.length
  ) {
    const zone = productConfig.views[0].areas[0]
    store.dispatch(
      actionSetSpritesAtCenterAndScaleToZone(0, zone, data.imageScaled)
    )
  }
}
