import React, { useState } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Slider from 'rc-slider/es/Slider'

import '../../../styles/components/debugInfo.css'
import Modal from '../ModalComponent'
import configs from '../../../module/config'
import Application from '../../../module/Application'
import { selectAvailableMaterial } from '../../containers/ColorContainer'
import { STATE } from '../../../constants/ProductConstants'

function EnableFilter({ name, config }) {
  const [value, setValue] = useState(get(configs.data.filters, config))

  return (
    <>
      <input
        type="checkbox"
        checked={value}
        onChange={event => {
          Application.setFilterConfig(config, event.target.checked)
          setValue(event.target.checked)
        }}
      />
      {name}
    </>
  )
}

EnableFilter.propTypes = {
  name: PropTypes.string,
  config: PropTypes.string.isRequired
}

EnableFilter.defaultProps = {
  name: ''
}

function CustomSlider({ name, config, step, min, max }) {
  const [value, setValue] = useState(get(configs.data.filters, config))

  return (
    <>
      {name} [{value}]
      <Slider
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={newValue => {
          Application.setFilterConfig(config, newValue)
          setValue(newValue)
        }}
      />
    </>
  )
}

CustomSlider.propTypes = {
  name: PropTypes.string,
  config: PropTypes.string.isRequired,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number
}

CustomSlider.defaultProps = {
  step: 1,
  min: 0,
  max: 10,
  name: ''
}

function DisplayFilterComponent({ displayFilters, product }) {
  const [visible, setVisible] = useState(false)

  if (!displayFilters) {
    return null
  }

  if (product.state !== STATE.FETCHED) {
    return (
      <Modal>
        <div className="debug-info debug-left">
          <div className="content">
            <h2>Filter settings</h2>
            Product not loaded
          </div>
        </div>
      </Modal>
    )
  }

  const material = selectAvailableMaterial(product.material)

  if (!material) {
    return (
      <Modal>
        <div className="debug-info debug-left">
          <div className="content">
            <h2>Filter settings</h2>
            Please, select engravable product.
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal>
      <div className="debug-info debug-left">
        <div className="content">
          <h2>Filter settings</h2>
          Material: {material}
          <br />
          <input
            type="checkbox"
            checked={visible}
            onChange={event => {
              setVisible(event.target.checked)
            }}
          />{' '}
          Visible
        </div>
        {visible ? (
          <>
            <div className="content">
              <h2>
                <EnableFilter
                  config={`${material}.colorMatrix.enable`}
                  name="Color Filter:"
                />
              </h2>
              <EnableFilter
                config={`${material}.colorMatrix.sepia.enable`}
                name="Sepia"
              />
              <EnableFilter
                config={`${material}.colorMatrix.sepia.multiply`}
                name="Multiply"
              />
              <hr />
              <EnableFilter
                config={`${material}.colorMatrix.desaturate`}
                name="Desaturate"
              />
              <hr />
              <EnableFilter
                config={`${material}.colorMatrix.vintage.enable`}
                name="Vintage"
              />
              <EnableFilter
                config={`${material}.colorMatrix.vintage.multiply`}
                name="Multiply"
              />
              <hr />
              <EnableFilter
                config={`${material}.colorMatrix.browni.enable`}
                name="Browni"
              />

              <EnableFilter
                config={`${material}.colorMatrix.browni.multiply`}
                name="Multiply"
              />
              <hr />
              <EnableFilter
                config={`${material}.colorMatrix.saturate.enable`}
                name="Saturate"
              />
              <EnableFilter
                config={`${material}.colorMatrix.saturate.multiply`}
                name="Multiply"
              />
              <CustomSlider
                config={`${material}.colorMatrix.saturate.amount`}
                max={1}
                step={0.01}
                name="Amount"
              />
              <hr />
              <EnableFilter
                config={`${material}.colorMatrix.greyscale.enable`}
                name="Greyscale"
              />
              <EnableFilter
                config={`${material}.colorMatrix.greyscale.multiply`}
                name="Multiply"
              />
              <CustomSlider
                config={`${material}.colorMatrix.greyscale.scale`}
                max={1}
                step={0.01}
                name="Scale"
              />
              <hr />
              <EnableFilter
                config={`${material}.colorMatrix.contrast.enable`}
                name="Contrast"
              />
              <EnableFilter
                config={`${material}.colorMatrix.contrast.multiply`}
                name="Multiply"
              />
              <CustomSlider
                config={`${material}.colorMatrix.contrast.amount`}
                max={1}
                step={0.01}
                name="Amount"
              />
            </div>
            <div className="content">
              <h2>
                <EnableFilter config={`${material}.embossFilter.enable`} />{' '}
                Emboss Filter:
              </h2>
              <CustomSlider
                name="Strength"
                max={20}
                step={0.2}
                config={`${material}.embossFilter.strength`}
              />
            </div>
            <div className="content">
              <h2>
                <EnableFilter
                  name="Bevel Filter:"
                  config={`${material}.bevelFilter.enable`}
                />
              </h2>
              <CustomSlider
                name="Rotation"
                max={360}
                config={`${material}.bevelFilter.rotation`}
              />
              <CustomSlider
                name="Thickness"
                max={5}
                step={0.01}
                config={`${material}.bevelFilter.thickness`}
              />
              <CustomSlider
                name="Light Alpha"
                max={1}
                step={0.01}
                config={`${material}.bevelFilter.lightAlpha`}
              />
              <CustomSlider
                name="Shadow Alpha"
                max={1}
                step={0.01}
                config={`${material}.bevelFilter.shadowAlpha`}
              />
            </div>
            <div className="content">
              <h2>
                <EnableFilter
                  name="Alpha Filter:"
                  config={`${material}.alphaFilter.enable`}
                />
              </h2>
              <CustomSlider
                name="Alpha"
                max={1}
                step={0.01}
                config={`${material}.alphaFilter.alpha`}
              />
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  )
}

DisplayFilterComponent.propTypes = {
  displayFilters: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  displayFilters: state.shortcuts.displayFilters,
  product: state.product
})

export default connect(mapStateToProps)(DisplayFilterComponent)
