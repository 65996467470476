import * as types from '../constants/ActionTypes'

function setState(action, image) {
  if (!action.myMotive) {
    return types.IMAGE_STATE_COMMERCIAL
  }

  return image.uploaded
    ? types.MY_IMAGE_STATE_UPLOADED
    : types.MY_IMAGE_STATE_INIT
}

/**
 *
 * @param {{image: string, id: number, myMotive: boolean, visible: boolean}[]} state
 * @param action
 * @returns {*}
 */
export function handleImageLibrary(state = [], action) {
  switch (action.type) {
    case types.ADD_IMAGE_TO_LIBRARY:
      return [
        ...state,
        ...action.imageSources.map(image => ({
          image: image.image,
          id: image.id,
          myMotive: action.myMotive,
          visible: action.visible,
          state: setState(action, image)
        }))
      ]

    case types.SET_IMAGE_VISIBILITY:
      return state.map((object, index) => {
        if (index >= action.from && index < action.from + action.limit) {
          return { ...object, visible: action.visibility }
        }

        return object
      })

    case types.REPLACE_COMMERCIAL_IMAGES_IN_LIBRARY:
      return state
        .filter(sprite => sprite.myMotive)
        .concat(
          action.imageSources.map(image => ({
            image: image.image,
            id: image.id,
            myMotive: action.myMotive,
            visible: action.visible,
            thumbnail: image.thumbnail
          }))
        )

    case types.REMOVE_IMAGE_FROM_LIBRARY:
      return state.filter(object => object.id !== action.id)

    case types.SET_IMAGE_STATE_TO_UPLOADING:
      return state.map(sprite =>
        sprite.id === action.id
          ? {
              ...sprite,
              state: types.MY_IMAGE_STATE_UPLOADING
            }
          : sprite
      )

    case types.SET_IMAGE_STATE_TO_ERROR_UPLOAD:
      return state.map(sprite =>
        sprite.id === action.id
          ? {
              ...sprite,
              state: types.MY_IMAGE_STATE_UPLOAD_ERROR
            }
          : sprite
      )

    case types.SET_IMAGE_STATE_TO_UPLOADED:
      return state.map(sprite =>
        sprite.id === action.id
          ? {
              ...sprite,
              image: action.image,
              state: types.MY_IMAGE_STATE_UPLOADED,
              id: action.newId
            }
          : sprite
      )

    case types.CLEAR:
      return []

    default:
      return state
  }
}
