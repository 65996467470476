import EditorManager from '../core-module/editor/EditorManager'
import store from '../store/MyStore'
import { CLEAR } from '../constants/ActionTypes'
import { removeSavedData } from '../core-module/editor/StorageManager'
import { PixiController } from '../core-module/pixi/PixiController'

export function clearApplication() {
  PixiController.clearSpriteContainerNotMask()
  PixiController.hideControls()
  PixiController.clearActiveZone()
  PixiController.resetScaleAndPositionOfContentContainer()

  EditorManager.getActiveProductEditor().clear()
  removeSavedData()

  store.dispatch({
    type: CLEAR
  })
}
