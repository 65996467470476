import uuidv4 from 'uuid/v4'
import { ACTION_SET_HASH } from '../constants/UserContansts'

export function setUserHash(hash) {
  return {
    type: ACTION_SET_HASH,
    hash
  }
}

export function createUserHash() {
  const hash = uuidv4()

  return setUserHash(hash)
}
