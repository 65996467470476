import { compose } from 'redux'
import { Texture } from 'pixi.js-legacy'
import ImageCacheFactory from '../../utils/ImageCacheFactory'
import { getHashKey } from '../../utils/GetHashKey'

class PixiCacheFactory {
  static textureCached = new Map()

  static get(url) {
    return compose(
      PixiCacheFactory.getTexture,
      PixiCacheFactory.fetch,
      PixiCacheFactory.getCached
    )(url)
  }

  static getTexture(promise) {
    return promise.then(object => {
      return object.cached
    })
  }

  static getFromSpriteState(spriteState) {
    return PixiCacheFactory.get(spriteState)
  }

  static getSVGAndUpdateSVGSprite(object) {
    return ImageCacheFactory.getObject(object.svgSpriteState).then(
      SVGCache => ({
        key: getHashKey(
          object.svgSpriteState.imageSource,
          object.svgSpriteState.svgClasses
        ),
        data: SVGCache.parser.toSvgString(object.svgSpriteState.svgClasses)
      })
    )
  }

  static loadAndCacheTexture(key, image) {
    const cachedTexture = PixiCacheFactory.textureCached.get(key)

    if (cachedTexture) {
      return cachedTexture
    }

    const texture = Texture.fromLoader(image, key)
    PixiCacheFactory.textureCached.set(key, texture)
    return texture
  }

  static fetch(object) {
    return new Promise((resolve, reject) => {
      if (object.cached) {
        resolve(object)
      } else if (object.svg) {
        PixiCacheFactory.getSVGAndUpdateSVGSprite(object).then(data => {
          const image = new Image()
          image.onload = () => {
            const cached = PixiCacheFactory.loadAndCacheTexture(data.key, image)
            resolve({
              ...object,
              cached
            })
          }
          image.onerror = error => {
            console.error(error)
            reject(new Error('svg image loading failed.'))
          }
          image.src = data.data
        })
      } else if (object.url) {
        ImageCacheFactory.get(object.url)
          .then(loadedObject => {
            const cached = PixiCacheFactory.loadAndCacheTexture(
              object.url,
              loadedObject
            )
            resolve({
              ...object,
              cached
            })
          })
          .catch(() => new Error('Object load fail.'))
      } else {
        reject(new Error('Object load fail.'))
      }
    })
  }

  /**
   *
   * @param {(string|SpriteState|SvgSpriteState)} object
   * @return {{cached: object, svg: boolean, url: string}}
   */
  static getCached(object) {
    const url = typeof object === 'string' ? object : object.imageSource

    if (typeof object !== 'string' && object.isSvg()) {
      return {
        ...PixiCacheFactory.createResultObject(
          PixiCacheFactory.textureCached.get(object.getHashKey()),
          true,
          url
        ),
        svgSpriteState: object
      }
    }

    return PixiCacheFactory.createResultObject(
      PixiCacheFactory.textureCached.get(url),
      false,
      url
    )
  }

  static createResultObject(cached, svg, url) {
    return {
      cached,
      svg,
      url
    }
  }
}

export default PixiCacheFactory
