import React from 'react'

import MenuContainer from './index'

const menuItems = []

menuItems.push({
  key: 'loading-0',
  className: 'pageLoading green'
})

menuItems.push({
  key: 'loading-1',
  className: 'pageLoading yellow'
})

menuItems.push({
  key: 'loading-2',
  className: 'pageLoading purple'
})

menuItems.push({
  key: 'loading-3',
  className: 'pageLoading red'
})

const MenuWithLoading = () => {
  return <MenuContainer menuItems={menuItems} />
}

export default MenuWithLoading
