import { addToCartObserver } from '../core-module/containers/CartContainer'

/**
 * Class to handle cart
 *
 * @class
 * @memberOf Creator
 */
class Cart {
  /**
   * Subscribe to listen when user add product to cart.
   * @param {addedToCartCallback} listener
   * @param {scope} scope
   *
   * @example
   * myAwesomeFunction(successfully) {
   *   if (successfully) console.log("Product was added to cart!");
   * }
   *
   * Creator.Cart.subscribeToUserAddedProductToCart(this.myAwesomeFunction, this);
   */
  static subscribeToUserAddedProductToCart(listener, scope) {
    addToCartObserver.subscribe(listener, scope)
  }

  /**
   * Unsubscribe from `user added product to cart`.
   * @param {addedToCartCallback} listener
   */
  static unsubscribeUserAddedProductToCart(listener) {
    addToCartObserver.unregisterGraphicUpdated(listener)
  }
}

export default Cart
