import { COLOR_TYPE } from '../module/Constants'
import { STATE } from '../constants/ProductConstants'

function parseColor(color, productColor) {
  if (color && (color.hex || color.image)) {
    return color.image || color.hex
  }

  return productColor.data
}

export function getCombinationIdWithRandomSize(product, color = undefined) {
  const activeColor = parseColor(color, product.active.color)

  const findActiveColor = product.colors.find(
    productColor =>
      productColor.image === activeColor || productColor.hex === activeColor
  )

  if (!findActiveColor) {
    return {
      id: -1,
      size: null
    }
  }

  const size = findActiveColor.sizes.length ? findActiveColor.sizes[0] : null

  if (!size) {
    return {
      id: -1,
      size: null
    }
  }

  return {
    id: size.id,
    size
  }
}

export function getCombinationId(product, color = undefined) {
  if (product.state !== STATE.FETCHED) {
    return -1
  }

  const activeColor = parseColor(color, product.active.color)

  if (!activeColor) {
    return -1
  }

  const findActiveColor = product.colors.find(
    productColor =>
      productColor.image === activeColor || productColor.hex === activeColor
  )

  if (!findActiveColor) {
    return -1
  }

  const findActiveSize = findActiveColor.sizes.find(
    size => size.name === product.active.size
  )

  if (!findActiveSize) {
    return findActiveColor.id || -1
  }

  return findActiveSize.id || -1
}

export function getSizes(product) {
  const sizes = []
  const { colors, active } = product

  if (colors.length === 0) {
    return []
  }

  colors.forEach(obj => {
    const color = obj.image || obj.hex

    if (color === active.color.data && obj.sizes) {
      obj.sizes.forEach(size => {
        sizes.push(size)
      })
    }
  })

  return sizes
}

export function getActiveColor(product) {
  const {
    colors,
    active: { color: activeColor }
  } = product

  if (colors.length === 0) {
    return null
  }

  return colors.find(_color => {
    if (activeColor.type === COLOR_TYPE.HEX) {
      return _color.hex === activeColor.data
    }

    if (activeColor.type === COLOR_TYPE.IMAGE) {
      return _color.image === activeColor.data
    }

    return false
  })
}

export function getActiveSize(product) {
  const {
    active: { size }
  } = product

  if (!size) {
    return null
  }

  const color = getActiveColor(product)

  if (!color) {
    return null
  }

  return color.sizes.find(_size => _size.name === size)
}
