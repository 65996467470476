import React from 'react'
import { Provider } from 'react-redux'
import store from '../store/MyStore'
import Routes from '../routes/index'
import registerServiceWorker from './utils/registerServiceWorker'
import '../styles/main.css'
import { SentryErrorComponent } from './components/SentryErrorComponent'
import ConfigComponent from './components/config'
import { WaitForTranslations } from './containers/Translations'

registerServiceWorker()

const data = (
  <SentryErrorComponent>
    <Provider store={store}>
      <ConfigComponent />
      <WaitForTranslations>
        <Routes />
      </WaitForTranslations>
    </Provider>
  </SentryErrorComponent>
)

export default data
