import { Sprite, SimpleRope } from 'pixi.js-legacy'

class SpriteController {
  spritesBackgroundCopies

  sprites

  constructor() {
    this.spritesBackgroundCopies = new Map()
    this.sprites = new Map()
  }

  /**
   *
   * @param {(PIXI.DisplayObject|PrimitiveObjectReducer)} sprite
   * @returns {*}
   */
  get(sprite) {
    if (!Object.prototype.hasOwnProperty.call(sprite, 'uuid')) {
      return undefined
    }

    return this.sprites.get(sprite.uuid)
  }

  /**
   *
   * @param {(PIXI.DisplayObject|PrimitiveObjectReducer)} sprite
   * @returns {*}
   */
  getCopy(sprite) {
    if (!Object.prototype.hasOwnProperty.call(sprite, 'uuid')) {
      return undefined
    }

    return this.spritesBackgroundCopies.get(sprite.uuid)
  }

  /**
   *
   * @param {PIXI.DisplayObject} pixiDisplayObject
   */
  set(pixiDisplayObject) {
    if (!pixiDisplayObject.uuid) {
      return undefined
    }

    this.sprites.set(pixiDisplayObject.uuid, pixiDisplayObject)
    return pixiDisplayObject
  }

  /**
   *
   * @param {PIXI.DisplayObject} originalDisplayObject
   * @param {number} tintColor
   */
  createCopy(originalDisplayObject) {
    if (!originalDisplayObject.uuid) {
      return undefined
    }

    let obj
    if (originalDisplayObject.isText() && originalDisplayObject.isCurved()) {
      obj = SpriteController.createRope(originalDisplayObject)
    } else {
      obj = SpriteController.createSprite(originalDisplayObject)
    }

    obj.uuid = originalDisplayObject.uuid
    obj.isCopy = true
    obj.sideIndex = originalDisplayObject.sideIndex

    this.spritesBackgroundCopies.set(originalDisplayObject.uuid, obj)
    return obj
  }

  static createSprite(originalDisplayObject) {
    const copyObj = new Sprite(originalDisplayObject.texture)
    copyObj.anchor.set(0.5)
    copyObj.position = originalDisplayObject.position
    copyObj.rotation = originalDisplayObject.rotation
    copyObj.scale = originalDisplayObject.scale

    return copyObj
  }

  removeByUUID(uuid) {
    this.sprites.delete(uuid)
    this.spritesBackgroundCopies.delete(uuid)
  }

  clear() {
    this.sprites.clear()
    this.spritesBackgroundCopies.clear()
  }

  static createRope(originalDisplayObject) {
    const rope = new SimpleRope(
      originalDisplayObject.texture,
      originalDisplayObject.geometry.points
    )

    rope.position.set(originalDisplayObject.x, originalDisplayObject.y)
    rope.scale.set(originalDisplayObject.scale.x, originalDisplayObject.scale.y)
    rope.rotation = originalDisplayObject.rotation
    rope.pivot.y = originalDisplayObject.pivot.y
    rope.pivot.x = originalDisplayObject.pivot.x

    return rope
  }
}

export default new SpriteController()
