import storageHelper from '../utils/storage'
import Extract from '../../module/Extract'

const DEFAULT_SAVE_KEY = 'creator-data'
const DEFAULT_USER_KEY = 'creator-u-k'

export function saveData(key = DEFAULT_SAVE_KEY) {
  return storageHelper.local.save(key, JSON.stringify(Extract.data()))
}

export function getSavedData(key = DEFAULT_SAVE_KEY) {
  return JSON.parse(storageHelper.local.get(key))
}

export function removeSavedData(key = DEFAULT_SAVE_KEY) {
  return storageHelper.local.remove(key)
}

export function saveUserHash(hash) {
  return storageHelper.local.save(DEFAULT_USER_KEY, hash)
}

export function getUserHash() {
  return storageHelper.local.get(DEFAULT_USER_KEY)
}

export function removeUserHash() {
  return storageHelper.local.remove(DEFAULT_USER_KEY)
}
