import * as types from '../constants/ActionTypes'
import * as historyConst from '../constants/HistoryConstants'
import Console from '../core-module/utils/console'
import {
  createPrimitiveObject,
  createSpriteObject,
  createSpriteObjectFromData
} from './helpers/DisplayObjectReducer'
import HistoryState from './helpers/HistoryState'
import {
  backStep,
  forwardStep,
  insertToArray,
  replaceInArrayOtherwiseAdd
} from './helpers/HistorySpriteContainerHelper'
import EditorManager from '../core-module/editor/EditorManager'

/**
 *
 * @name SpritesConfig
 * @type {{activeSprite: ?PrimitiveObjectReducer, sprites: PrimitiveObjectReducer[], history: HistoryState[], future: Array}}
 */
const spritesConfig = {
  activeSprite: null,
  sprites: [],
  removeBg: false,
  history: [],
  future: []
}

export function getDisplayObjectReducerFromDisplayObject(
  displayObject,
  spritesContainer
) {
  for (let i = 0; i < spritesContainer.length; i += 1) {
    if (displayObject.uuid === spritesContainer[i].uuid) {
      return spritesContainer[i]
    }
  }

  return null
}

export function handleSprites(state = spritesConfig, action) {
  switch (action.type) {
    case types.ADD_PRIMITIVE_OBJECT: {
      Console.log('EDITOR', 'Added primitive object')

      const sideIndex = EditorManager.isProductEditorActive()
        ? EditorManager.getActiveProductEditor().getActiveSide().index
        : 0

      return {
        ...state,
        sprites: insertToArray(
          state.sprites,
          createPrimitiveObject(action.uuid, sideIndex)
        )
      }
    }

    case types.TOGGLE_REMOVE_BACKGROUND_OPTION: {
      Console.log("EDITOR", "Remove BG toggled")
      return {
        ...state,
        removeBg: !state.removeBg
      }
    }

    case types.ADD_SPRITE_ON_PRODUCT: {
      Console.log('EDITOR', 'Added|Replaced sprite object')

      if (!EditorManager.isProductEditorActive()) {
        Console.error('Sprite', 'Sprite was not added. Editor is not active.')
        return state
      }

      const addedSprite = createSpriteObject(
        action.imageSource,
        action.width,
        action.height,
        action.naturalWidth,
        action.naturalHeight,
        action.myMotive,
        action.id,
        action.svgClasses
      )

      if (action.uuid) {
        addedSprite.setUUID(action.uuid)
      }

      return {
        ...state,
        sprites: replaceInArrayOtherwiseAdd(state.sprites, addedSprite),
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_ADD_SPRITE, addedSprite)
        ],
        future: []
      }
    }

    case types.ADD_SPRITE_WITH_DATA: {
      if (!EditorManager.isProductEditorActive()) {
        Console.error('Sprite', 'Sprite was not added. Editor is not active.')
        return state
      }

      Console.log('EDITOR', 'Added|Replaced sprite object width data')

      const newSprite = createSpriteObjectFromData(
        action.width,
        action.height,
        action.naturalWidth,
        action.naturalHeight,
        action.myMotive,
        action.data
      )

      if (action.uuid) {
        newSprite.setUUID(action.uuid)
      }

      return {
        activeSprite: state.activeSprite,
        sprites: replaceInArrayOtherwiseAdd(state.sprites, newSprite),
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_ADD_SPRITE, newSprite)
        ],
        future: []
      }
    }

    case types.ADD_TEXT_ON_PRODUCT:
      if (!EditorManager.isProductEditorActive()) {
        Console.error('TEXT', 'Text was not added. Editor is not active.')
        return state
      }

      Console.log('EDITOR', 'Added text object')

      return {
        ...state,
        sprites: [...state.sprites, action.textState],
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_ADD_TEXT, action.textState)
        ],
        future: []
      }

    case types.ACTIVE_SPRITE_CHANGED:
      return {
        ...state,
        activeSprite: !action.displayObject
          ? null
          : getDisplayObjectReducerFromDisplayObject(
              action.displayObject,
              state.sprites
            )
      }

    case types.UPDATE_SVG_SPRITE_CLASSES: {
      state.sprites.forEach(sprite => {
        if (
          sprite.uuid === action.uuid &&
          sprite.isMotive() &&
          sprite.isSvg()
        ) {
          sprite.setSvgClasses(action.classes)
        }
      })

      return state
    }

    case types.UPDATE_ACTIVE_TEXT_COLOR: {
      Console.log('EDITOR', 'Text color was updated of active text.')

      if (!state.activeSprite || !state.activeSprite.isText()) {
        return state
      }

      const previousColor = state.activeSprite.color
      state.activeSprite.setColor(action.color)

      return {
        ...state,
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_CHANGE_TEXT_COLOR, {
            previousColor,
            newColor: action.color,
            sprite: state.activeSprite
          })
        ],
        future: []
      }
    }

    case types.UPDATE_SVG_COLOR_ACTIVE: {
      Console.log('EDITOR', 'SVG color was updated of active sprite.')

      if (!state.activeSprite) {
        return state
      }

      if (!state.activeSprite.isMotive() || !state.activeSprite.isSvg()) {
        return state
      }

      const prevSvgColors = JSON.stringify(state.activeSprite.svgClasses)
      state.activeSprite.updateSVGColor(action.className, action.color)

      return {
        ...state,
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_CHANGE_SVG_COLOR, {
            sprite: state.activeSprite,
            previousColors: prevSvgColors,
            newColors: JSON.stringify(state.activeSprite.svgClasses)
          })
        ],
        future: []
      }
    }

    case types.REPLACE_ACTIVE_TEXT: {
      const history = [...state.history]
      let { activeSprite } = state;
      if(activeSprite){
        action.replace.rotation = activeSprite.rotation;
        action.replace.flipHorizontal = activeSprite.flipHorizontal;
      }
      return {
        ...state,
        sprites: state.sprites.map(sprite => {
          if (action.replace.uuid !== sprite.uuid) {
            return sprite
          }

          if (action.addToHistory) {
            history.push(
              new HistoryState(historyConst.HISTORY_REPLACE_TEXT, {
                prevSprite: activeSprite,
                nextSprite: action.replace
              })
            )
          }

          activeSprite = action.replace
          return action.replace
        }),
        history,
        activeSprite,
        future: []
      }
    }

    case types.REMOVE_ACTIVE_OBJECT: {
      Console.log('EDITOR', 'Active sprite was removed')
      const { activeSprite } = state

      if (!activeSprite) {
        return state
      }

      const index = state.sprites.indexOf(activeSprite)

      if (index === -1) {
        return state
      }

      return {
        ...state,
        activeSprite: null,
        sprites: state.sprites.filter(
          sprite => activeSprite.uuid !== sprite.uuid
        ),
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_REMOVE_OBJECT, {
            sprites: [{ order: index, sprite: activeSprite }]
          })
        ],
        future: []
      }
    }

    case types.REMOVE_ALL_SPRITES: {
      Console.log('EDITOR', 'All sprites were removed.')
      return {
        ...state,
        activeSprite: null,
        sprites: [],
        future: [],
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_REMOVE_OBJECT, {
            sprites: state.sprites.map((sprite, index) => ({
              order: index,
              sprite
            }))
          })
        ]
      }
    }

    case types.REMOVE_SPRITES_BY_ID: {
      Console.log('EDITOR', 'Sprite was removed by id.')

      const finalSprites = state.sprites.reduce(
        // eslint-disable-next-line array-callback-return
        (data, sprite, index) => {
          if (sprite.isMotive() && sprite.id === action.id) {
            data.removed.push({ order: index, sprite })
          } else {
            data.sprites.push(sprite)
          }

          return data
        },
        { removed: [], sprites: [] }
      )

      if (finalSprites.removed.length === 0) {
        return state
      }

      return {
        ...state,
        activeSprite: null,
        sprites: finalSprites.sprites,
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_REMOVE_OBJECT, {
            sprites: finalSprites.removed
          })
        ],
        future: []
      }
    }

    case types.UPDATE_SIZE_ACTIVE_OBJECT:
      Console.log('EDITOR', 'Scale was updated of active sprite.')

      if (state.activeSprite) {
        const { scale } = state.activeSprite
        state.activeSprite.setScale(action.scale)

        return {
          ...state,
          history: [
            ...state.history,
            new HistoryState(historyConst.HISTORY_SPRITE_SET_SCALE, {
              sprite: state.activeSprite,
              prevScale: scale,
              nextScale: action.scale
            })
          ],
          future: []
        }
      }

      return state

    case types.UPDATE_FONT_SIZE_ACTIVE_OBJECT:
      Console.log('EDITOR', 'Font size was updated')
      if (state.activeSprite && state.activeSprite.isText()) {
        const prevFontSize = state.activeSprite.fontSize

        state.activeSprite.setFontSize(action.fontSize)

        return {
          ...state,
          history: [
            ...state.history,
            new HistoryState(historyConst.HISTORY_SPRITE_SET_FONT_SIZE, {
              sprite: state.activeSprite,
              prevFontSize,
              nextFontSize: action.fontSize
            })
          ]
        }
      }
      return state

    case types.UPDATE_ROTATION_ACTIVE_OBJECT:
      Console.log('EDITOR', 'Rotation was updated of active sprite')
      if (state.activeSprite) {
        const { rotation } = state.activeSprite
        state.activeSprite.setRotation(action.rotation)

        return {
          ...state,
          history: [
            ...state.history,
            new HistoryState(historyConst.HISTORY_SPRITE_SET_ROTATION, {
              sprite: state.activeSprite,
              prevRotation: rotation,
              nextRotation: action.rotation
            })
          ],
          future: []
        }
      }

      return state

    case types.UPDATE_POSITION_ACTIVE_OBJECT: {
      Console.log('EDITOR', 'Position was updated of active sprite')
      if (state.activeSprite) {
        const { x, y } = state.activeSprite
        state.activeSprite.setPosition(action.x, action.y)
        return {
          ...state,
          history: [
            ...state.history,
            new HistoryState(historyConst.HISTORY_SPRITE_SET_POSITION, {
              sprite: state.activeSprite,
              prevPosition: { x, y },
              nextPosition: { x: action.x, y: action.y }
            })
          ],
          future: []
        }
      }
      return state
    }

    case types.UPDATE_TO_BOTTOM_LAYER_ACTIVE_OBJECT:
    case types.UPDATE_TO_TOP_LAYER_ACTIVE_OBJECT: {
      Console.log(
        'EDITOR',
        `Order was updated [${
          action.type === types.UPDATE_TO_BOTTOM_LAYER_ACTIVE_OBJECT
            ? 'bottom'
            : 'top'
        } layer]`
      )
      if (!state.activeSprite) {
        return state
      }

      const indexOfActiveSprite = state.sprites.indexOf(state.activeSprite)

      state.sprites.splice(indexOfActiveSprite, 1)

      const history =
        action.type === types.UPDATE_TO_BOTTOM_LAYER_ACTIVE_OBJECT
          ? historyConst.HISTORY_MOVE_SPRITE_TO_BOTTOM_LAYER
          : historyConst.HISTORY_MOVE_SPRITE_TO_TOP_LAYER

      if (action.type === types.UPDATE_TO_BOTTOM_LAYER_ACTIVE_OBJECT) {
        state.sprites.unshift(state.activeSprite)
      } else {
        state.sprites.push(state.activeSprite)
      }

      return {
        ...state,
        history: [
          ...state.history,
          new HistoryState(history, {
            sprite: state.activeSprite,
            prevOrder: indexOfActiveSprite
          })
        ],
        future: []
      }
    }

    case types.FLIP_ACTIVE_OBJECT: {
      Console.log('EDITOR', 'Active sprite was flipped.')

      if (!state.activeSprite) {
        return state
      }

      state.activeSprite.flipHorizontal()
      return {
        ...state,
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_FLIP_SPRITE, state.activeSprite)
        ],
        future: []
      }
    }

    case types.CREATE_COPY_ACTIVE_OBJECT: {
      Console.log('EDITOR', 'Copy created')
      if (!state.activeSprite) {
        return state
      }

      const clone = state.activeSprite.clone()

      return {
        activeSprite: state.activeSprite,
        sprites: insertToArray(state.sprites, clone),
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_ADD_SPRITE, clone)
        ],
        future: []
      }
    }

    case types.APPLY_FILTER_ON_ACTIVE_OBJECT: {
      const updatedActiveSprite = Object.assign(state.activeSprite, {filters: [action.filter]})
      return {
        ...state,
        activeSprite: updatedActiveSprite,
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_APPLY_FILTER, updatedActiveSprite)
        ]
      }
    }

    case types.REMOVE_FILTER_FROM_ACTIVE_OBJECT: {
      const updatedActiveSprite = Object.assign(state.activeSprite, {filters: []})
      return {
        ... state,
        activeSprite: updatedActiveSprite,
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_APPLY_FILTER, updatedActiveSprite)
        ]
      }
    }

    case types.UPDATE_BUNCH_CHANGE_COLOR_TEXT_AND_SVG: {
      const historySpriteColor = []

      state.sprites.forEach(sprite => {
        if (sprite.isText()) {
          historySpriteColor.push({ uuid: sprite.uuid, color: sprite.color })
          sprite.setColor(action.color)
        } else if (sprite.isSvg()) {
          historySpriteColor.push({
            uuid: sprite.uuid,
            svgClasses: sprite.getSvgClassesAsString()
          })

          sprite.changeSvgToColor(action.color)
        }
      })

      const history = historySpriteColor.length
        ? [
            ...state.history,
            new HistoryState(
              historyConst.HISTORY_UPDATE_BUNCH_COLORS_TEXT_AND_SVG,
              { data: historySpriteColor, color: action.color }
            )
          ]
        : state.history

      return {
        ...state,
        history
      }
    }

    case types.SET_SPRITES_AT_CENTER_AND_SCALE_BY_ZONE: {
      return {
        ...state,
        sprites: state.sprites.map(sprite => {
          if (!sprite || sprite.sideIndex !== action.sideIndex) {
            return sprite
          }

          sprite.setPositionAtCenterZone(action.zone)

          if (sprite.isText()) {
            sprite.setFontSize(
              sprite.fontSize * action.fontSizeScaleMultiplicator
            )
          }

          if (sprite.isMotive()) {
            sprite.setSizeByZone(action.zone)
          }

          return sprite
        }),
        history: [
          ...state.history,
          new HistoryState(historyConst.HISTORY_UPDATE_SPRITES, state.sprites)
        ],
        future: []
      }
    }

    case types.UPDATE_ID:
      Console.log('EDITOR', `Updated ID of sprites to ${action.id}`)

      return {
        ...state,
        activeSprite: state.activeSprite,
        sprites: state.sprites.map(sprite => {
          if (sprite.id !== action.id) {
            return sprite
          }

          sprite.setId(action.newId)

          /**
           * When you uploaded a custom image:
           * So first it was uploaded to memory as base64 and saved to variable `sprite.imageSource`.
           * Then image was uploaded to the server and reponse url is `sprite.temporalImageUrl`.
           * This is necessary due to Creator.Extract.data()
           */
          // eslint-disable-next-line no-param-reassign
          sprite.temporalImageUrl = action.temporalImageUrl // fixme: one day
          return sprite
        })
      }

    case types.HISTORY_CLEAR:
      return {
        ...state,
        history: [],
        future: []
      }

    case types.CLEAR:
      return {
        activeSprite: null,
        sprites: [],

        history: [],
        future: []
      }

    case types.BACK_STEP:
      return backStep(state)

    case types.FORWARD_STEP:
      return forwardStep(state)

    default:
      return state
  }
}
