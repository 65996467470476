import React from 'react'
import LeftSide from './index'

const menuItems = []

menuItems.push({
  key: 'loading-0',
  className: 'green',
  isLoading: true
})

menuItems.push({
  key: 'loading-1',
  className: 'yellow',
  isLoading: true
})

menuItems.push({
  key: 'loading-2',
  className: 'purple',
  isLoading: true
})

menuItems.push({
  key: 'loading-3',
  className: 'red',
  isLoading: true
})

export default function LeftLoading() {
  return <LeftSide menuItems={menuItems} />
}
