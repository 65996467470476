export function createViewWrapper(view) {
  const area = view.areas[0]
  return {
    viewId: parseInt(view.id, 10),
    image: view.image,
    areas: [
      {
        x: area.x,
        y: area.y,
        width: area.w,
        height: area.h,
        realWidth: area.width,
        realHeight: area.height,
        percent: {
          x: area['x%'],
          y: area['y%'],
          width: area['w%'],
          height: area['h%']
        }
      }
    ]
  }
}

export default function(bodyJson) {
  return bodyJson.map(view => createViewWrapper(view))
}
