import configs from '../module/config'

export const ALL_PRINTINGS_ID = 6

export const API_PATH = 'rest/'

const URL = {
  get PAGE() {
    return `${configs.data.apiUrl}/`
  },
  get API() {
    return `${this.PAGE}${API_PATH}${configs.data.language}/`
  },
  get API_NO_LANG() {
    return `${configs.data.apiUrl}/${API_PATH}`
  }
}

export function getApiTranslations() {
  return `${URL.API}config/translations`
}

export function getApiCMS() {
  return `${URL.API}cms/27`
}

export function getApiProductUrl(id, combinationId = 0) {
  return `${URL.API}config/${id}/${combinationId}`
}

export function getApiPrintingsUrl(productTypeCategory, categoryId) {
  return `${URL.API}category/${productTypeCategory}/${categoryId}/printings`
}

export function getApiViews(productId, combinationId) {
  return `${URL.API}product/${productId}/${combinationId}/views`
}

export function getApiProducts(productTypeCategory, manufacture) {
  const manufacturePartUrl =
    typeof manufacture !== 'undefined' ? `/${manufacture}` : ''

  return `${URL.API}category/${productTypeCategory}/products${manufacturePartUrl}`
}

export function getApiAllPrintings(productTypeCategory) {
  return getApiPrintingsUrl(productTypeCategory, ALL_PRINTINGS_ID)
}

export function getApiCategories(productTypeCategory) {
  return `${URL.API}category/${productTypeCategory}/categories`
}

export function getApiPriceUrl() {
  return `${URL.API_NO_LANG}v2/price`
}

export function getApiProductsFromCategory(category) {
  return getApiCategories(category)
}

export function getApiUploadImage() {
  return `${URL.API_NO_LANG}v2/file`
}

export function getApiFeatured() {
  return `${URL.API}config/featured`
}

export function getApiCart() {
  return `${URL.API}v2/cart`
}

export function getApiSaveDesign() {
  return `${URL.API_NO_LANG}v2/design`
}

export function getApiLoadDesign(key) {
  return `${URL.API_NO_LANG}v2/design/${key}`
}

export function getApiSendMail(id) {
  return `${URL.API}v2/design/${id}/mail`
}

export function getApiProductAndPrintingCategories() {
  return `${URL.API}category/tree`
}

export function getApiStockData(combinationID) {
  return `${URL.API}product/${combinationID}/shipping`
}

export function generateImageURL(combinationID, printingID, engraving = false) {
  return `${URL.API}file/image/${combinationID}/${printingID}?.png${
    engraving ? '&filter=engraving' : ''
  }`
}
