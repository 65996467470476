import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import config from '../../../../module/config'
import ProductComponent from './Product'
import { fetchProductConfigAndHandleCurrentSprites } from '../../../utils/FetchProductConfigAndHandleCurrentSprites'
import { STATE } from '../../../../constants/ProductConstants'

function enableQuantityForCategory(category) {
  return (
    category !== -1 &&
    config.data.productDisplayStockQuantityInCategories.includes(category)
  )
}

function ProductList({
  category,
  activeProductID,
  setLoading,
  products,
  isAnyProductLoading,
  currentMotivePrices
}) {
  const [productIdClicked, setProductIdClick] = useState(-1)

  if (typeof category === 'undefined') {
    return <div className="productContainer" />
  }

  function onProductClick(id, canMoveObjectsToCenter) {
    setLoading(true)
    setProductIdClick(id)

    fetchProductConfigAndHandleCurrentSprites(id, canMoveObjectsToCenter)
      .then(() => {
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const canDisplayQuantity = enableQuantityForCategory(category)

  return (
    <div className="productContainer">
      {products.map(product => (
        <ProductComponent
          productData={product}
          key={product.id}
          active={parseInt(product.id, 10) === activeProductID}
          displayQuantity={canDisplayQuantity}
          onClick={onProductClick}
          isAnyProductLoading={isAnyProductLoading}
          loading={productIdClicked === product.id && isAnyProductLoading}
          currentMotivePrices={currentMotivePrices}
        />
      ))}
    </div>
  )
}

ProductList.propTypes = {
  activeProductID: PropTypes.number.isRequired,
  isAnyProductLoading: PropTypes.bool.isRequired,
  category: PropTypes.number,
  currentMotivePrices: PropTypes.number,
  setLoading: PropTypes.func,
  products: PropTypes.array
}

ProductList.defaultProps = {
  category: undefined,
  setLoading: f => f,
  products: [],
  currentMotivePrices: 0
}

const mapStateToProps = state => ({
  activeProductID: state.product.id,
  isAnyProductLoading: state.product.state === STATE.LOADING
})

export default connect(mapStateToProps)(React.memo(ProductList))
