import React, { Suspense } from 'react'
import Console from '../../../core-module/utils/console'
import {
  FooterPanel,
  InfoPanel,
  LeftPanel,
  RightPanel,
  SimilarProductsPanel
} from './DisplayPanel'

import '../../utils/Shortcuts'
import LeftLoading from '../LeftContainer/LeftLoading'
import RightContainerWithLoading from '../RightContainer/RightContainerWithLoading'
import { PANEL } from '../../../module/Constants'
import configs from '../../../module/config'
import MidContainerWithPixi from '../MidContainer/MidContainerWithPixi'
import DebugInfoComponent from '../../../core-module/components/DebugInfoComponent'
import DebugFilterComponent from '../../../core-module/components/DebugFilterComponent'
import FeaturedLoading from '../../../core-module/containers/FeaturedProductsContainer/FeaturedLoading'
import FiltersModalContainer from '../../../core-module/containers/FiltersModalContainer'

/**
 *
 * @param {PANEL} panel
 * @returns {number[]|Array|boolean}
 */
function isPanelDisable(panel) {
  return (
    configs.data.disablePanel &&
    Array.isArray(configs.data.disablePanel) &&
    configs.data.disablePanel.includes(panel)
  )
}

const WebContainer = () => {
  Console.info(
    'DEVELOPMENT',
    'Web version active - stage: ',
    process.env.NODE_ENV
  )

  return (
    <>
      <section>
        {isPanelDisable(PANEL.LEFT) ? null : (
          <Suspense fallback={<LeftLoading />}>
            <LeftPanel />
          </Suspense>
        )}
        <MidContainerWithPixi
          enableProductSides={configs.data.enableProductSides}
        />
        <FiltersModalContainer />
        {isPanelDisable(PANEL.RIGHT) ? null : (
          <Suspense fallback={<RightContainerWithLoading />}>
            <RightPanel />
          </Suspense>
        )}
      </section>
      {isPanelDisable(PANEL.INFO) ? null : (
        <Suspense fallback="">
          <InfoPanel />
        </Suspense>
      )}
      {isPanelDisable(PANEL.SIMILAR_PRODUCTS) ? null : (
        <Suspense fallback={<FeaturedLoading translateKey="similarProducts" />}>
          <SimilarProductsPanel />
        </Suspense>
      )}
      {isPanelDisable(PANEL.FEATURED_PRODUCTS) ? null : (
        <Suspense fallback={<FeaturedLoading />}>
          <FooterPanel />
        </Suspense>
      )}

      <DebugInfoComponent />
      <DebugFilterComponent />
    </>
  )
}

export default WebContainer
