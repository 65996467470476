import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class MenuItemComponent extends React.PureComponent {
  onClickHandle() {
    const { onClick, index } = this.props
    onClick(index)
  }

  render() {
    const {
      className,
      icon,
      backgroundColor,
      iconBorderColor,
      text,
      isActive,
      component,
      iconAsComponent,
      visibleInMenu
    } = this.props
    return (
      <li
        className={classNames(className, {
          active: isActive,
          hiddenItem: !visibleInMenu
        })}>
        {visibleInMenu && (
          <a role="menuitem" onClick={() => this.onClickHandle()}>
            {iconAsComponent ? icon : null}

            {!iconAsComponent && icon && (
              <img
                alt="icon"
                src={icon}
                style={{
                  backgroundColor,
                  borderColor: iconBorderColor
                }}
              />
            )}
            <span>{text}</span>
          </a>
        )}
        <div className="menu-component">{component}</div>
      </li>
    )
  }
}

MenuItemComponent.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func
  ]),
  iconAsComponent: PropTypes.bool,
  text: PropTypes.string,
  isActive: PropTypes.bool,
  index: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  component: PropTypes.object,
  className: PropTypes.string,
  iconBorderColor: PropTypes.string,
  visibleInMenu: PropTypes.bool
}

MenuItemComponent.defaultProps = {
  icon: '',
  isActive: false,
  component: null,
  className: null,
  iconBorderColor: 'rgba(0, 0, 0, 0)',
  iconAsComponent: false,
  backgroundColor: 'transparent',
  text: '',
  onClick: f => f,
  visibleInMenu: true
}

export default MenuItemComponent
