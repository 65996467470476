export const TYPE_LOADING = 'CATEGORY_LOADING'
export const TYPE_FETCHED = 'CATEGORY_FETCHED'
export const TYPE_ERROR = 'CATEGORY_ERROR'

/**
 *
 * @name CategoryState
 * @readonly
 * @enum {number}
 * @type {{INIT: number, ERROR: number, FETCHED: number, LOADING: number}}
 */
export const STATE = {
  INIT: 0,
  ERROR: 1,
  FETCHED: 2,
  LOADING: 3
}
