import React from 'react'
import PropTypes from 'prop-types'
import {t} from "i18next";
import "../../../styles/components/filters-modal.css"

const SpriteFilterDisplay = ({ image, filterName, onButtonClick, backgroundColor }) => {

  return (
    <div className="sprite-filter-display">
      <h6>{filterName}</h6>
        {image ? <img style={{background: backgroundColor}}  src={image}/> : <img src={"https://i.gifer.com/4V0b.gif"}/>}
      <button
        className="appButton disable-propagation buttonBlue"
        onClick={onButtonClick}>
        {t("filter.choose")}
      </button>
    </div>
  )
}

SpriteFilterDisplay.propTypes = {
  sprite: PropTypes.object,
  filterName: PropTypes.string,
  onButtonClick: PropTypes.func,
  backgroundColor: PropTypes.string
}

export default SpriteFilterDisplay
