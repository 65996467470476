import chroma from 'chroma-js'
import uuidv4 from 'uuid/v4'
import { toast } from 'react-toastify'

import store from '../../store/MyStore'
import * as action from '../../actions/editor'

import { PixiController } from '../pixi/PixiController'

import Console from '../utils/console'

import * as history from '../../constants/HistoryConstants'
import * as productConstant from '../../constants/ProductConstants'
import { changeSideIndex } from '../../actions/product'
import { getFiltersBasedOnTechnology } from '../pixi/helper/FiltersByTechnology'
import { EditorLoadingTool } from '../components/config/FullyLoadedEditor'
import configs from '../../module/config'
import { COLOR_TYPE } from '../../module/Constants'
import { calculateQualityBySprite } from '../utils/calculateQuality'
import { notify } from '../containers/PixiActionContainer'
import ActiveZoneImage from '../pixi/module/ActiveZoneImage'
import AutoThumbnail from '../pixi/module/AutoThumbnail'

/**
 *
 * @type {number}
 */
const CONTROLS_LINE_COLOR_WHITE = 0xffffff
const CONTROLS_LINE_COLOR_BLACK = 0x000000
const CONTROLS_LINE_COLOR_DARK = 0x336699

/**
 *
 * @class
 */
class ProductEditor {
  /**
   *
   * @type {ProductSide[]}
   */
  side

  /**
   *
   * @type {PIXI.DisplayObject}
   */
  mainSprite

  mainSpriteUUID

  mainSpriteLoading = false

  mainSpriteImageSource = ''

  /**
   *
   * @type {ProductSide}
   */
  activeSide

  /**
   *
   * @type {PIXI.DisplayObject}
   */
  activePixiSprite

  /**
   *
   * @type {number}
   * @private
   */
  _historyEditorCount

  /**
   *
   * @type {number}
   * @private
   */
  _futureEditorCount

  /**
   *
   * @type {number}
   * @private
   */
  _historyProductCount

  /**
   *
   * @type {number}
   * @private
   */
  _canStoreUpdateSemaphore = 0

  /**
   *
   * @function {Unsubscribe}
   * @private
   */
  _storeUnsubscribe

  _storePrevProduct

  _color = 0xffffff

  /**
   *
   * @param {ProductSide[]} productSides
   * @param activeSideIndex
   */
  constructor(productSides, activeSideIndex) {
    if (productSides.length === 0) {
      throw new Error(
        `Product Sides has not been set - id: ${store.getState().product.id}`
      )
    }

    ActiveZoneImage.clearCache()
    AutoThumbnail.clearCache()

    this.canvasChanged = this.canvasChanged.bind(this)
    this.objectScaled = this.objectScaled.bind(this)
    this.objectScaled = this.objectScaled.bind(this)
    this.side = productSides

    if (PixiController.isPixiAppended) {
      this._updateSideByIndexAsync(activeSideIndex)
    } else {
      Console.error('Editor', 'PIXI was not appended')
    }

    const { spritesContainer, product } = store.getState()
    // init history data
    this._historyEditorCount = spritesContainer.history.length
    this._futureEditorCount = spritesContainer.future.length
    this._historyProductCount = product.history.length

    this._handleChangeStore = this._handleChangeStore.bind(this)
    this._storeUnsubscribe = store.subscribe(this._handleChangeStore)

    PixiController.zoomObservers.subscribe(this.canvasChanged, this)
    PixiController.previewRotationObserver.subscribe(this.canvasChanged, this)
    PixiController.controlsManager.resizeObserver.subscribe(
      this.objectScaled,
      this
    )

    this.setFilters()
  }

  setFilters() {
    const { product } = store.getState()
    PixiController.setFilters(
      getFiltersBasedOnTechnology(product.active.technology, product.material)
    )
  }

  get canStoreUpdate() {
    return this._canStoreUpdateSemaphore === 0
  }

  canvasChanged() {
    if (this.activePixiSprite) {
      PixiController.setControlsPositionAroundSprite(this.activePixiSprite)
    }
  }

  objectScaled() {
    this.createQualityStarsIfPossible()
  }

  async createQualityStarsIfPossible() {
    if (this.activePixiSprite && this.activePixiSprite.myMotive) {
      const stars = await calculateQualityBySprite(
        this.activePixiSprite,
        this.getActiveSide().getZoneToInsert()
      )
      PixiController.removeOldAndCreateNewQualityStars(stars)
    }
  }

  /**
   *
   * @public
   */
  disableStoreUpdate() {
    this._canStoreUpdateSemaphore += 1
  }

  /**
   *
   * @public
   */
  enableStoreUpdate() {
    if (this._canStoreUpdateSemaphore > 0) {
      this._canStoreUpdateSemaphore -= 1
    }

    const {
      spritesContainer: { history: historyActions, future },
      product: { history: productHistory }
    } = store.getState()

    this._historyEditorCount = historyActions.length
    this._futureEditorCount = future.length
    this._historyProductCount = productHistory.length
  }

  /**
   *
   * @private
   */
  _handleChangeStore() {
    const {
      history: historyActions,
      future
    } = store.getState().spritesContainer

    if (historyActions.length > 0) {
      PixiController.displayBackControl()
    } else {
      PixiController.hideBackControl()
    }

    if (future.length > 0) {
      PixiController.displayForwardControl()
    } else {
      PixiController.hideForwardControl()
    }

    if (!this.canStoreUpdate || !PixiController.isPixiAppended) {
      return
    }

    if (
      historyActions.length !== this._historyEditorCount ||
      this._futureEditorCount !== future.length
    ) {
      const isForwardStep =
        historyActions.length < this._historyEditorCount ||
        this._futureEditorCount < future.length
      this._historyEditorCount = historyActions.length
      this._futureEditorCount = future.length

      if (this._historyEditorCount === 0 && this._futureEditorCount === 0) {
        this.disableActiveSprite()
        return
      }

      if (isForwardStep) {
        // GO BACK STEP
        this.disableActiveSprite()
      }

      const lastTimeAction = isForwardStep
        ? ProductEditor.getLast(future)
        : ProductEditor.getLast(historyActions)

      // Add Sprite/Text
      if (
        lastTimeAction.type === history.HISTORY_ADD_TEXT ||
        lastTimeAction.type === history.HISTORY_ADD_SPRITE
      ) {
        if (isForwardStep) {
          this.updateSideIndexIfNecessary(lastTimeAction.state.sideIndex)

          PixiController.removeSpriteFromContainerByUUID(
            lastTimeAction.state.uuid
          )
        } else {
          this.updateSideIndexIfNecessary(lastTimeAction.state.sideIndex)
          this.addObjectOnActiveSide(lastTimeAction.state, true)
        }

        this.checkIfSpritesAreReady()
      }

      // Update Product
      if (lastTimeAction.type === history.HISTORY_REPLACE_TEXT) {
        if (isForwardStep) {
          this.updateSideIndexIfNecessary(
            lastTimeAction.state.prevSprite.sideIndex
          )
          this.replaceObject(lastTimeAction.state.prevSprite)
        } else {
          this.updateSideIndexIfNecessary(
            lastTimeAction.state.nextSprite.sideIndex
          )
          this.replaceObject(lastTimeAction.state.nextSprite)
        }
      }

      if(lastTimeAction.type === history.HISTORY_APPLY_FILTER){
        if (isForwardStep) {
          // TODO
        } else {
          this.replaceObject(lastTimeAction.state);
        }
      }

      if (lastTimeAction.type === history.HISTORY_CHANGE_SVG_COLOR) {
        this.updateSideIndexIfNecessary(lastTimeAction.state.sprite.sideIndex)
        this.replaceObject(lastTimeAction.state.sprite)
      }

      if (lastTimeAction.type === history.HISTORY_FLIP_SPRITE) {
        this.updateSideIndexIfNecessary(lastTimeAction.state.sideIndex)

        const sprite = PixiController.getSpriteFromContainerByUUID(
          lastTimeAction.state.uuid
        )
        if (sprite) {
          PixiController.flipSprite(sprite)
        }
      }

      if (lastTimeAction.type === history.HISTORY_REMOVE_OBJECT) {
        this.disableActiveSprite()

        if (isForwardStep) {
          lastTimeAction.state.sprites.forEach(item => {
            this.updateSideIndexIfNecessary(item.sprite.sideIndex)
            this.addObjectOnActiveSide(item.sprite, false)
          })
        } else {
          lastTimeAction.state.sprites.forEach(item => {
            if (item.sprite.isUnknown()) {
              return
            }

            this.updateSideIndexIfNecessary(item.sprite.sideIndex)
            PixiController.removeSpriteFromContainerByUUID(item.sprite.uuid)
          })
        }
      }

      if (
        lastTimeAction.type ===
          history.HISTORY_UPDATE_BUNCH_COLORS_TEXT_AND_SVG ||
        lastTimeAction.type === history.HISTORY_UPDATE_SPRITES
      ) {
        this.invalidateContent()
      }

      if (
        lastTimeAction.type === history.HISTORY_MOVE_SPRITE_TO_BOTTOM_LAYER ||
        lastTimeAction.type === history.HISTORY_MOVE_SPRITE_TO_TOP_LAYER
      ) {
        const sprite = PixiController.getSpriteFromContainerByUUID(
          lastTimeAction.state.sprite.uuid
        )

        if (sprite) {
          this.updateSideIndexIfNecessary(lastTimeAction.state.sprite.sideIndex)

          if (isForwardStep) {
            PixiController.setSpriteToLayerPosition(
              sprite,
              lastTimeAction.state.prevOrder
            )
          } else if (
            lastTimeAction.type === history.HISTORY_MOVE_SPRITE_TO_BOTTOM_LAYER
          ) {
            PixiController.setSpriteToBottomLayer(sprite)
          } else {
            PixiController.setSpriteToTopLayer(sprite)
          }
        }
      }

      if (
        lastTimeAction.type === history.HISTORY_CHANGE_TEXT_COLOR ||
        lastTimeAction.type === history.HISTORY_SPRITE_SET_POSITION ||
        lastTimeAction.type === history.HISTORY_SPRITE_SET_ROTATION ||
        lastTimeAction.type === history.HISTORY_SPRITE_SET_SCALE ||
        lastTimeAction.type === history.HISTORY_SPRITE_SET_FONT_SIZE
      ) {
        this.updateSideIndexIfNecessary(lastTimeAction.state.sprite.sideIndex)
        this.replaceObject(lastTimeAction.state.sprite)
      }
    }

    const { product } = store.getState()
    const productActions = product.history

    if (productActions.length !== this._historyProductCount) {
      // Change Side
      if (
        ProductEditor.getLast(productActions).type ===
        history.HISTORY_CHANGE_PRODUCT_SIDE
      ) {
        this._updateSideByIndexAsync(store.getState().product.active.sideIndex)
      }

      this._historyProductCount = productActions.length
    }

    if (
      product.state === productConstant.STATE.LOADING &&
      (!this._storePrevProduct ||
        product.state !== this._storePrevProduct.state)
    ) {
      PixiController.resetScaleAndPositionOfContentContainer()
    }

    this._storePrevProduct = store.getState().product
  }

  /**
   *
   * @param array
   * @return {*}
   */
  static getLast(array) {
    if (array.length === 0) return null

    return array[array.length - 1]
  }

  checkIfSpritesAreReady() {
    /*
      Observer to send all objects are ready.
      Number of objects is: all motives, my motives, texts and background object ( product )
   */

    const {
      spritesContainer: { sprites }
    } = store.getState()

    let spritesOnActiveSide = 0
    sprites.forEach(
      sprite =>
        this.activeSide &&
        this.activeSide.index === sprite.sideIndex &&
        ++spritesOnActiveSide // eslint-disable-line no-plusplus
    )

    EditorLoadingTool.handleProductLoading(true, spritesOnActiveSide + 1)
  }

  /**
   *
   * @param {PrimitiveObjectReducer} displayObjectReducer
   * @param {boolean} setAsActiveSprite
   */
  addObjectOnActiveSide(displayObjectReducer, setAsActiveSprite = true) {
    // bug: text which contains only white chars
    if (
      displayObjectReducer.isText() &&
      !/\S/.test(displayObjectReducer.text)
    ) {
      throw new Error('Object contains only white chars!')
    }

    if (displayObjectReducer.isUnknown()) {
      throw new Error('Cannot add unknown object.')
    }

    if (this.getActiveSide().index !== displayObjectReducer.sideIndex) {
      return null
    }

    return this.getInteractiveSprite(displayObjectReducer).then(sprite => {
      if (
        !this.getActiveSide() ||
        this.getActiveSide().index !== displayObjectReducer.sideIndex
      ) {
        return null
      }

      /*
        [BUG] Async pipeline can create duplicate object.
        Primary while config enableAutomaticChangeSide is true.
        Repeat: Create complicate object with many sprites on different sides. Save object and reload it with data.
       */
      const duplicateSprite = PixiController.getSpriteFromContainerByUUID(
        displayObjectReducer.uuid
      )
      if (duplicateSprite) {
        PixiController.removeSpriteFromContainer(duplicateSprite)
      }

      PixiController.addToSpriteContainer(sprite)

      if (setAsActiveSprite) {
        this.setActiveSprite(sprite)
      }

      return sprite
    })
  }

  /**
   *
   * @param {(SpriteState|TextState)} displayObjectReducer
   */
  replaceObject(displayObjectReducer) {
    const index = PixiController.getSpriteIndexFromContainerByUUID(
      displayObjectReducer.uuid
    )

    if (typeof index !== 'number' || index === -1) {
      Console.error('RENDER', 'Object has not been found!')
      toast.error('Please reload editor application.')
      return null
    }

    return this.getInteractiveSprite(displayObjectReducer)
      .then(sprite => {
        PixiController.addToSpriteContainerAt(sprite, index)
        if (sprite.isText()) {
          PixiController.updateTextInputShadowSize(sprite, displayObjectReducer)
        }
        return sprite
      })
      .then(sprite => {
        if (
          this.activePixiSprite &&
          sprite &&
          this.activePixiSprite.uuid === displayObjectReducer.uuid
        ) {
          this.setActiveSprite(sprite)
        }

        return sprite
      })
      .then(sprite => {
        notify.stateOfObjectZone(PixiController.isObjectOutOfZone(sprite))
      })
  }

  forceUpdateActiveObject() {
    const displayObjectReducerActive = store.getState().spritesContainer
      .activeSprite
    if (displayObjectReducerActive) {
      this.replaceObject(displayObjectReducerActive)
    }
  }

  /**
   * Parse PrimitiveObjectReducer and return new interactive display object (sprite or text) which is ready for canvas.
   * @param {PrimitiveObjectReducer} primitiveObjectReducer
   * @return {Promise<PIXI.DisplayObject>}
   * @private
   */
  // eslint-disable-next-line
  getInteractiveSprite(primitiveObjectReducer) {
    return new Promise((resolve, reject) => {
      if (!primitiveObjectReducer) {
        reject(new Error('Value has not been set'))
      } else if (primitiveObjectReducer.isMotive()) {
        PixiController.createSprite(primitiveObjectReducer).then(sprite => {
          PixiController.setSpritesInteractive(sprite)
          resolve(sprite)
        })
      } else if (primitiveObjectReducer.isText()) {
        const sprite = PixiController.createText(primitiveObjectReducer)
        PixiController.setSpritesInteractive(sprite)
        resolve(sprite)
      } else {
        reject(new Error('wrong sprite'))
      }
    })
  }

  /**
   *
   * @return {?ProductSide}
   */
  getActiveSide() {
    return this.activeSide
  }

  updateSideIndexIfNecessary(index) {
    if (
      configs.data.__enableAutomaticChangeSide && // eslint-disable-line
      this.activeSide.index !== index
    ) {
      this._updateSideByIndexAsync(index, true)
    }
  }

  invalidateContent() {
    if (!this.getActiveSide()) {
      Console.error('Any active side set!')
      return
    }

    const {
      spritesContainer: { activeSprite }
    } = store.getState()

    const activeSpriteUUID = activeSprite && activeSprite.uuid

    this._updateSideByIndexAsync(this.getActiveSide().index, false)

    if (activeSpriteUUID) {
      const sprite = PixiController.getSpriteFromContainerByUUID(
        activeSpriteUUID
      )
      this.setActiveSprite(sprite, false)
    }
  }

  _updateSideByIndexAsync(index, dispatch = false) {
    this.disableStoreUpdate()

    const side = this.side[index]

    this.clearActiveSpriteAndDispatch()
    PixiController.clearSpriteContainerNotMask()
    PixiController.resetScaleAndPositionOfContentContainer()

    this.activeSide = side

    if (!this.canDiscardRender(side.getImageSource())) {
      this.render()
    }

    this.checkIfSpritesAreReady()

    const { sprites } = store.getState().spritesContainer

    sprites.forEach(sprite => {
      if (sprite.sideIndex === index && !sprite.isUnknown()) {
        this.addObjectOnActiveSide(sprite, false)
      }
    })

    if (dispatch) {
      store.dispatch(changeSideIndex(index))
    }

    this.enableStoreUpdate()
  }

  /**
   *
   * @param {string} imageSource
   * @param promiseUUID
   */
  renderMainSpriteAtCenterAndActiveZones(imageSource, promiseUUID) {
    return PixiController.createMainSpriteAtCenter(
      imageSource,
      this.activeSide.index
    )
      .then(sprite => {
        if (this.mainSpriteUUID !== promiseUUID || !this.getActiveSide()) {
          return
        }

        this.removeActiveZone()
        PixiController.clearLineControlsContainer()
        PixiController.clearQualityStars()

        this.mainSprite = sprite
        PixiController.changeProductContainerChildren(this.mainSprite)

        this.getActiveSide().calculateAndResetZones()

        this._color = ProductEditor.getLineColorByProduct()
        PixiController.createLineControls(this._color)

        PixiController.setActiveZoneMask(
          this.getActiveSide().getActiveZones(),
          this._color
        )

        PixiController.resetScaleAndPositionOfContentContainer()
      })
      .then(() => {
        this.mainSpriteLoading = false
        PixiController.graphicUpdatedNotifyAll()
      })
      .catch(error => {
        this.mainSpriteLoading = false
        EditorLoadingTool.setDataFailed('Main sprite is null.')
        Console.error('RENDER', error.stack)
        Console.error('RENDER', 'Main sprite is null.')
      })
  }

  removeActiveZone() {
    if (this.getActiveSide()) {
      this.getActiveSide().activeZones = []
    }
    PixiController.clearActiveZone()
  }

  removeActiveSprite() {
    if (this.activePixiSprite) {
      PixiController.removeSpriteFromContainer(this.activePixiSprite)
    }
  }

  clearActiveSpriteAndDispatch() {
    this.disableActiveSprite(true)
  }

  /**
   *
   * @param {boolean} dispatch
   */
  disableActiveSprite(dispatch = false) {
    this.setActiveSprite(null, dispatch)
  }

  /**
   *
   * @param {PIXI.DisplayObject} sprite
   * @param {boolean} dispatch
   */
  setActiveSprite(sprite, dispatch = true) {
    this.activePixiSprite = sprite

    if (sprite) {
      PixiController.clearQualityStars()
      PixiController.showControlsAndSetPosition(sprite)
      this.createQualityStarsIfPossible()
    } else {
      PixiController.hideControls()
      PixiController.clearQualityStars()
    }

    if (dispatch) {
      store.dispatch(action.activeSpriteChanged(sprite))
    }
  }

  static getLineColorByProduct() {
    const activeColor = store.getState().product.active.color

    if (!activeColor || activeColor.type !== COLOR_TYPE.HEX) {
      return CONTROLS_LINE_COLOR_WHITE
    }

    const contrast = chroma.contrast(chroma(activeColor.data), 'white')
    if (contrast < 1.2) return CONTROLS_LINE_COLOR_DARK
    if (contrast < 2.5) return CONTROLS_LINE_COLOR_BLACK

    return CONTROLS_LINE_COLOR_WHITE
  }

  canDiscardRender(imageSource) {
    return this.mainSpriteImageSource === imageSource
  }

  render() {
    this.mainSpriteUUID = uuidv4()
    this.mainSpriteLoading = true
    this.mainSpriteImageSource = this.activeSide.getImageSource()

    Console.log(
      'Editor',
      `Rendering background [${
        this.mainSpriteUUID
      }] - ${this.activeSide.getImageSource()}`
    )

    this.renderMainSpriteAtCenterAndActiveZones(
      this.activeSide.getImageSource(),
      this.mainSpriteUUID
    )
  }

  clear() {
    this.removeActiveZone()
    this.side = null
    this.activeSide = null
    this.activePixiSprite = null
    this._storeUnsubscribe()
    PixiController.zoomObservers.unsubscribe(this.canvasChanged)
    PixiController.previewRotationObserver.unsubscribe(this.canvasChanged)
    PixiController.controlsManager.resizeObserver.unsubscribe(this.objectScaled)
  }
}

export default ProductEditor
