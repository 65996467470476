import store from '../../store/MyStore'

const CMPI = 2.54

function getQualityByDpi(dpi) {
  if (dpi >= 150) {
    return 4
  }

  if (dpi >= 133) {
    return 3
  }

  if (dpi >= 99) {
    return 2
  }

  if (dpi >= 56) {
    return 1
  }

  return 0
}

function getDpi(objectWidth, objectNaturalWidth, areaRealWidth, areaWidth) {
  const inchWidth = areaRealWidth / CMPI
  const objIWidth = inchWidth * (objectWidth / areaWidth)

  return objectNaturalWidth / objIWidth
}

function calculateQuality(
  objectWidth,
  objectNaturalWidth,
  areaRealWidth,
  areaWidth
) {
  return getQualityByDpi(
    getDpi(objectWidth, objectNaturalWidth, areaRealWidth, areaWidth)
  )
}

export async function calculateQualityBySprite(sprite, zone) {
  if (!sprite || !zone) {
    return 0
  }

  const { sprites } = store.getState().spritesContainer

  const spriteReducer = sprites.find(
    _spriteReducer => sprite.uuid === _spriteReducer.uuid
  )

  if (!spriteReducer) {
    return 0
  }

  return Math.min(
    calculateQuality(
      sprite.width,
      spriteReducer.naturalWidth,
      zone.realWidth,
      zone.width
    ),
    calculateQuality(
      sprite.height,
      spriteReducer.naturalHeight,
      zone.realHeight,
      zone.height
    )
  )
}
