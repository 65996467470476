import { connect } from 'react-redux'
import {
  MATERIAL_GLASS,
  MATERIAL_METAL,
  MATERIAL_WOOD,
  TECHNOLOGY_DIGITAL,
  TECHNOLOGY_ERROR,
  TECHNOLOGY_STAMPS,
  TECHNOLOGY_SUBLIMITY
} from '../../utils/technologies'

export const defaultColors = [
  { label: 'Black', value: '#000000' },
  { label: 'Red', value: '#FF0000' },
  { label: '#573F3F', value: '#573F3F' },
  { label: '#7A072C', value: '#7A072C' },
  { label: '#FF6633', value: '#FF6633' },
  { label: '#A757B5', value: '#A757B5' },
  { label: '#2B70C4', value: '#2B70C4' },
  { label: '#999999', value: '#999999' },
  { label: '#1C3752', value: '#1C3752' },
  { label: '#FFFF00', value: '#FFFF00' },
  { label: '#808080', value: '#808080' },
  { label: '#C70C18', value: '#C70C18' },
  { label: '#669933', value: '#669933' },
  { label: '#E8BE94', value: '#E8BE94' },
  { label: '#FFC300', value: '#FFC300' },
  { label: 'Yellow', value: '#FCDF03' },
  { label: '#FFFFFF', value: '#FFFFFF' }
]

export function selectAvailableMaterial(material) {
  switch (material) {
    case 'wood':
      return MATERIAL_WOOD
    case 'glass':
      return MATERIAL_GLASS
    case 'metal':
      return MATERIAL_METAL
    default:
      return undefined
  }
}

export const engravingColors = {
  wood: [{ label: 'Wood', value: '#a48468' }],
  metal: [{ label: 'Metal', value: '#727272' }],
  glass: [{ label: 'Glass', value: '#969696' }]
}

export const stampsColors = [{ label: 'Black', value: '#000000' }]

export function tryToGetColor(colors, colorToFind) {
  return (color => color && color.value)(
    colors.find(color => color.value === colorToFind) || colors[0]
  )
}

export const withColors = Component =>
  connect(state => ({
    colors: (() => {
      const activeTechnology = state.product.active.technology

      if (activeTechnology === TECHNOLOGY_ERROR) {
        return []
      }

      if (activeTechnology === TECHNOLOGY_DIGITAL) {
        return defaultColors
      }

      if (activeTechnology === TECHNOLOGY_STAMPS) {
        return stampsColors
      }

      if (activeTechnology === TECHNOLOGY_SUBLIMITY) {
        return defaultColors
      }

      const material = selectAvailableMaterial(state.product.material)

      switch (material) {
        case MATERIAL_WOOD:
          return engravingColors.wood
        case MATERIAL_METAL:
          return engravingColors.metal
        case MATERIAL_GLASS:
          return engravingColors.glass
        default:
          return defaultColors
      }
    })()
  }))(Component)
