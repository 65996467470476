import { combineReducers } from 'redux'
import { handleSprites } from './spritesContainer'
import { handleProduct } from './product'
import { handleCategories } from './categories'
import { handleImageLibrary } from './imageLibrary'
import { handleBottomProduct } from './bottomProduct'
import { handleMenuContainer } from './menuContainer'
import { handleEditor } from './editor'
import { handleUser } from './user'
import { handleShortcuts } from './shortcuts'

/**
 *
 * @name RootReducer
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const rootReducer = combineReducers({
  spritesContainer: handleSprites,
  imageLibrary: handleImageLibrary,
  product: handleProduct,
  categories: handleCategories,
  bottomProduct: handleBottomProduct,
  menuContainer: handleMenuContainer,
  editor: handleEditor,
  user: handleUser,
  shortcuts: handleShortcuts
})

export default rootReducer
