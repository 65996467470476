import debounce from 'lodash/debounce'
import { PixiController } from '../PixiController'
import { saveData } from '../../editor/StorageManager'
import Console from '../../utils/console'
import configs from '../../../module/config'

function autoSave() {
  if (!configs.data.autoSave) {
    return
  }

  Console.log('AUTOSAVE', 'Data saved.')
  saveData()
}

const debouncedAutoSave = debounce(autoSave, 2000, { maxWait: 7000 })

PixiController.registerGraphicUpdated(() => {
  debouncedAutoSave()
}, this)

window.addEventListener('unload', () => {
  autoSave()
})

export default debouncedAutoSave
