import React from 'react'
import { t } from 'i18next'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import '../../../styles/components/product-title-info.css'
import { scrollIntoFirstTab } from '../../../actions/bottomProduct'

class ProductTitleInfo extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    enableExtendButton: PropTypes.bool,
    isLoading: PropTypes.bool
  }

  static defaultProps = {
    name: '',
    description: '',
    enableExtendButton: true,
    isLoading: false
  }

  constructor(props) {
    super(props)

    this.descriptionRef = React.createRef()
  }

  state = {
    displayExtend: false
  }

  componentDidMount() {
    this.updateDisplayExtend()
  }

  componentDidUpdate() {
    this.updateDisplayExtend()
  }

  updateDisplayExtend() {
    const { enableExtendButton } = this.props
    if (!enableExtendButton) {
      return
    }

    if (this.descriptionRef && this.descriptionRef.current) {
      this.setState({
        displayExtend: this.descriptionRef.current.clientHeight >= 100
      })
    } else {
      this.setState({ displayExtend: false })
    }
  }

  render() {
    const { name, description, enableExtendButton, isLoading } = this.props
    const { displayExtend } = this.state

    return (
      <div className="product-title-info">
        <h2 className={classNames('title', { pageLoading: isLoading })}>
          {name}
        </h2>
        <div
          className={classNames('description', {
            shade: displayExtend,
            pageLoading: isLoading
          })}
          ref={this.descriptionRef}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {enableExtendButton && displayExtend ? (
          <button
            type="button"
            className="appButton buttonWhite"
            onClick={scrollIntoFirstTab}>
            {t('btn.product', 'More info')}
          </button>
        ) : null}
      </div>
    )
  }
}

export default ProductTitleInfo
