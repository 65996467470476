// CANVAS HISTORY
export const HISTORY_ADD_TEXT = 'HISTORY_ADD_TEXT'
export const HISTORY_ADD_SPRITE = 'HISTORY_ADD_SPRITE'
export const HISTORY_UPDATE_BUNCH_COLORS_TEXT_AND_SVG =
  'HISTORY_UPDATE_BUNCH_COLORS_TEXT_AND_SVG'
export const HISTORY_UPDATE_SPRITES = 'HISTORY_UPDATE_SPRITES'
export const HISTORY_REPLACE_TEXT = 'HISTORY_REPLACE_TEXT'
export const HISTORY_FLIP_SPRITE = 'HISTORY_FLIP_SPRITE'
export const HISTORY_CHANGE_SVG_COLOR = 'HISTORY_CHANGE_SVG_COLOR'
export const HISTORY_CHANGE_TEXT_COLOR = 'HISTORY_CHANGE_TEXT_COLOR'
export const HISTORY_SPRITE_SET_POSITION = 'HISTORY_SPRITE_SET_POSITION'
export const HISTORY_SPRITE_SET_ROTATION = 'HISTORY_SPRITE_SET_ROTATION'
export const HISTORY_SPRITE_SET_FONT_SIZE = 'HISTORY_SPRITE_SET_FONT_SIZE'
export const HISTORY_SPRITE_SET_SCALE = 'HISTORY_SPRITE_SET_SCALE'
export const HISTORY_MOVE_SPRITE_TO_TOP_LAYER =
  'HISTORY_MOVE_SPRITE_TO_TOP_LAYER'
export const HISTORY_MOVE_SPRITE_TO_BOTTOM_LAYER =
  'HISTORY_MOVE_SPRITE_TO_BOTTOM_LAYER'
export const HISTORY_REMOVE_OBJECT = 'HISTORY_REMOVE_OBJECT'

// PRODUCT HISTORY
export const HISTORY_CHANGE_PRODUCT_SIDE = 'HISTORY_CHANGE_PRODUCT_SIDE'
export const HISTORY_CHANGE_PRODUCT_ID = 'HISTORY_CHANGE_PRODUCT_ID'
export const INNER_ACTION_UPDATE_SPRITES_SCALE_AND_POSITION =
  'INNER_ACTION_UPDATE_SPRITES_SCALE_AND_POSITION'

export const HISTORY_APPLY_FILTER = 'HISTORY_APPLY_FILTER'
