import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from '../../utils/QueryString'

import { fetchProductConfig } from '../../../actions/product'
import { loadPrintingAndProductCategories } from '../../../actions/categories'
import Console from '../../utils/console'
import configs from '../../../module/config'
import { getSavedData } from '../../editor/StorageManager'
import Extract from '../../../module/Extract'
import { getDesign } from '../../../api/DesignRequest'
import { load } from './Load'
import { handleUserHash } from './ConfigUser'
import { EditorLoadingTool } from './FullyLoadedEditor'
import ActiveZoneImage from '../../pixi/module/ActiveZoneImage'
import AutoThumbnail from '../../pixi/module/AutoThumbnail'

class Config extends React.PureComponent {
  componentDidMount() {
    Console.log('Config', 'Application initialized.')

    handleUserHash()

    ActiveZoneImage.start()
    AutoThumbnail.start()

    this.props.getCategories()

    this.loadAppropriateProduct()
  }

  fetchProduct(id, combinationId = 0) {
    const { getProduct } = this.props
    getProduct(id, combinationId).then(result => {
      EditorLoadingTool.handleProductLoading(result, 1)
      EditorLoadingTool.setStartReady()
      EditorLoadingTool.setFontReady()
    })
  }

  /**
   * Initialization for Webs
   */
  async loadAppropriateProduct() {
    Console.log('DEVELOPMENT', 'BUILD')
    // eslint-disable-next-line no-restricted-globals
    Console.log('DEVELOPMENT', `Screen: [${screen.width}, ${screen.height}]`)

    const lastSavedData = getSavedData()

    let preInitData = configs.data.initData
    const parsedQueryString = queryString.parse(location.search)

    if (!!parsedQueryString[configs.data.queryDataUrl]) {
      const designData = await getDesign(
        parsedQueryString[configs.data.queryDataUrl]
      )
      preInitData = designData.canvas
    }

    const forceProductID = configs.data.productId

    if (!!preInitData) {
      /**
       *
       * 1. LOAD INIT DATA
       *
       */

      const initData =
        typeof preInitData === 'string' ? JSON.parse(preInitData) : preInitData

      const isInputValid = await Extract.areExtractedDataOkay(initData)

      if (isInputValid && (forceProductID || initData.product.id > 0)) {
        Console.log('CONFIG', `Input data was found ${initData.product.id}.`)
        initData.product.id = forceProductID || initData.product.id
        load(initData)

        return
      }

      EditorLoadingTool.setDataFailed('Input Data are corrupted.')
      Extract.validatedExtractedData(initData).catch(error => {
        Console.error('Input Data', error.message)
        Console.error('SavedData', lastSavedData)
      })
    } else if (configs.data.autoLoadLastSave && lastSavedData) {
      /**
       *
       * 2. LOAD LAST SAVE DATA
       *
       */

      const isInputValid = await Extract.areExtractedDataOkay(lastSavedData)

      if (isInputValid && (forceProductID || lastSavedData.product.id > 0)) {
        lastSavedData.product.id = forceProductID || lastSavedData.product.id
        Console.log('CONFIG', `Last save found ${lastSavedData.product.id}.`)
        load(lastSavedData)

        return
      }

      EditorLoadingTool.setDataFailed('Last save data are corrupted.')
      Extract.validatedExtractedData(lastSavedData).catch(error => {
        Console.error('Input Data', error.message)
        Console.error('Saved Data', JSON.stringify(lastSavedData))
      })
    }

    /**
     *
     * 3. LOAD DEFAULT PRODUCT
     *
     */

    const productID = forceProductID || 4212
    Console.log('CONFIG', `Default product loading ${productID}.`)

    this.fetchProduct(productID)
  }

  render() {
    return null
  }
}

Config.propTypes = {
  getProduct: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired
}

/**
 *
 * @param {RootReducer} state
 * @returns {{product: ProductConfig}}
 */
const mapStateToProps = state => ({
  product: state.product
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProduct: fetchProductConfig,
      getCategories: loadPrintingAndProductCategories
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(Config)
