class Locker {
  constructor(lockCounter = 0) {
    this.lockCounter = lockCounter
  }

  lock() {
    this.lockCounter += 1
  }

  unlock() {
    if (this.lockCounter > 0) {
      this.lockCounter -= 1
    }
  }

  isLocked() {
    return this.lockCounter !== 0
  }
}

export default Locker
