/**
 *
 * @readonly
 * @enum {number}
 */
export const SPRITE_TYPE = {
  OBJECT: 0,
  TEXT: 1,
  UNKNOWN: 2
}

/**
 *
 * @readonly
 * @enum {number}
 * @type {{NONE: number, BOTTOM: number, TOP: number}}
 */
export const CURVED_TEXT = {
  NONE: 0,
  BOTTOM: 1,
  TOP: 2
}
