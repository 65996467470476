import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SelectProductCategory from './SelectProductCategory'
import ProductList from './ProductList'
import configs from '../../../../module/config'
import { myFetch } from '../../../utils/fetch'
import { getApiProducts } from '../../../../constants/api'
import ProductsWrapper from '../../../../api/ProductsWrapper'
import withPrice from '../../../containers/PriceContainer/PriceHandlerContainer'

export const ALL_MANUFACTURES = -4321

function fetchProducts(category, manufacture) {
  return myFetch(getApiProducts(category, manufacture))
    .then(result => result.json())
    .then(products => ProductsWrapper(products))
    .then(wrapper => wrapper)
}

function getProductsData(category, manufacture, setLoading) {
  const [products, setProducts] = useState({})
  const [manufactures, setManufactures] = useState({})

  const finalManufacture =
    manufacture !== ALL_MANUFACTURES ? manufacture : undefined

  const key = `${category}-${
    typeof finalManufacture === 'undefined' ? 'none' : finalManufacture
  }`

  useEffect(() => {
    if (
      category === -1 ||
      typeof category === 'undefined' ||
      typeof products[key] !== 'undefined' ||
      typeof manufactures[key] !== 'undefined'
    ) {
      return
    }

    setLoading(true)
    fetchProducts(category, finalManufacture)
      .then(result => {
        if (!result) return

        const newProductsState = { ...products }
        newProductsState[key] = result.products
        setProducts(newProductsState)

        const newManufacturesState = { ...manufactures }
        newManufacturesState[key] = result.manufactures
        setManufactures(newManufacturesState)

        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [key])

  return {
    products: products[key] || [],
    manufactures: manufactures[key] || []
  }
}

function ProductContainer({ price: { motivePriceRaw } }) {
  const defaultCategory = configs.data.defaultCategoryID
  const defaultSubCategory = configs.data.defaultSubCategoryID
  const defaultManufacture = configs.data.defaultManufactureID

  const [categoryID, setCategoryID] = useState(defaultSubCategory)
  const [manufactureID, setManufactureID] = useState(
    defaultManufacture || ALL_MANUFACTURES
  )
  const [isLoading, setLoading] = useState(false)

  const { products, manufactures } = getProductsData(
    categoryID,
    manufactureID,
    setLoading
  )

  function updateCategoryIDs(id) {
    setCategoryID(id)
    setManufactureID(ALL_MANUFACTURES)
  }

  return (
    <>
      <SelectProductCategory
        onCategoryChanged={updateCategoryIDs}
        loading={isLoading}
        manufactures={manufactures}
        manufacture={manufactureID}
        setManufacture={setManufactureID}
        defaultCategory={defaultCategory}
        defaultSubCategory={defaultSubCategory}
      />
      <ProductList
        category={categoryID}
        setLoading={setLoading}
        products={products}
        currentMotivePrices={motivePriceRaw}
      />
    </>
  )
}

ProductContainer.propTypes = {
  price: PropTypes.object.isRequired
}

export default withPrice(ProductContainer)
