import * as PIXI from 'pixi.js-legacy'

import store from '../store/MyStore'
import { PixiController } from '../core-module/pixi/PixiController'
import EditorManager from '../core-module/editor/EditorManager'
import {
  actionGoBackStep,
  actionGoForwardStep,
  actionRemoveAllSprites,
  addSpriteOnProduct
} from '../actions/editor'
import { EditorLoadingTool } from '../core-module/components/config/FullyLoadedEditor'
import { waitForEditorReady } from './Editor/EditorHelper'
import {
  actionToggleDisplayInfo,
  actionToggleFilterSettings
} from '../actions/shortcuts'
import { getCanvasExtraInfo } from '../core-module/pixi/extract/utils/getCanvasExtraInfo'
import { addMyImageToLibrary } from '../actions/imageLibrary'
import Console from '../core-module/utils/console'
import configs, { setConfig } from './config'

/**
 * Class to handle product editor
 *
 * @example
 * // Get number of objects on active side
 * console.log(Creator.Editor.numberOfObjectsOnActiveSide());
 *
 * @class
 * @memberOf Creator
 */
class Editor {
  /**
   * Get number of elements on active side.
   * @returns {number}
   */
  static get numberOfObjectsOnActiveSide() {
    return PixiController.spriteCopiesContainer
      ? PixiController.spriteCopiesContainer.children.length
      : 0
  }

  /**
   * Get instance of PIXI.Application.
   * Documentation {@link http://pixijs.download/release/docs/PIXI.Application.html}.
   *
   * @returns {PIXI.Application}
   */
  static get pixiApplication() {
    return PixiController.app
  }

  /**
   * Get instance of PixiController. The brain of the editor!
   *
   * @returns {object}
   */
  static get pixiController() {
    return PixiController
  }

  /**
   * Subscribe to listen on editor graphic updates.
   * @param {requestCallback} listener
   * @param {scope} scope
   *
   * @example
   * Creator.Editor.subscribeToGraphicUpdates(this.myAwesomeFunction, this);
   */
  static subscribeToGraphicUpdates(listener, scope) {
    PixiController.registerGraphicUpdated(listener, scope)
  }

  /**
   * Unsubscribe of graphical updates.
   * @param {requestCallback} listener
   */
  static unsubscribeGraphicUpdates(listener) {
    PixiController.unregisterGraphicUpdated(listener)
  }

  /**
   * Editor is usable after fetch of product config.
   * @returns {boolean}
   */
  static isEditorActive() {
    return EditorManager.isProductEditorActive()
  }

  /**
   * Go one step back
   */
  static goBack() {
    store.dispatch(actionGoBackStep())
  }

  /**
   * Go one step forward
   */
  static goForward() {
    store.dispatch(actionGoForwardStep())
  }

  /**
   * Zoom in to center of canvas
   */
  static zoomIn() {
    PixiController.zoomIn()
  }

  /**
   * Zoom out of canvas
   */
  static zoomOut() {
    PixiController.zoomOut()
  }

  /**
   * Zoom to default view
   */
  static zoomDefault() {
    PixiController.zoomDefault()
  }

  /**
   * Zoom to position in canvas
   *
   * @param {number} x location of x coordinate in canvas
   * @param {number} y location of y coordinate in canvas
   * @param {number} distance
   *
   * @example
   * Creator.Editor.zoomToPosition(70, 40, 1.05) // zoom in
   * Creator.Editor.zoomToPosition(70, 40, 0.95) // zoom out
   */
  static zoomToPosition(x, y, distance) {
    PixiController.zoomToPosition({ x, y }, distance)
  }

  /**
   * Rotate preview clockwise
   */
  static rotatePreviewClockwise() {
    PixiController.rotatePreviewClockwise()
  }

  /**
   * Return Promise which tell you when editor is ready.
   *
   * @async
   * @return {Promise<any>}
   */
  static sayWhenTheEditorIsReady() {
    return waitForEditorReady()
  }

  /**
   * Check if editor is fully loaded included data and necessary fonts.
   * It's working when side is changed as well.
   *
   * @returns {{loaded: boolean, error: boolean}}
   */
  static isEditorFullyLoadedIncludedData() {
    return EditorLoadingTool.isEditorReadyIncludedDataAndFonts
  }

  /**
   * Redraw current content
   */
  static invalidateContent() {
    if (EditorManager.isProductEditorActive()) {
      EditorManager.getActiveProductEditor().invalidateContent()
    }
  }

  /**
   * Remove all added sprites and texts from active product.
   */
  static clearObjects() {
    store.dispatch(actionRemoveAllSprites())
  }

  /**
   * Display/Hide information window about product/objects.
   */
  static toggleObjectsInfo() {
    store.dispatch(actionToggleDisplayInfo())
  }

  /**
   * Display/Hide window with filter settings.
   */
  static toggleFilterSettings() {
    store.dispatch(actionToggleFilterSettings())
  }

  /**
   * Additional info about canvas.
   * For example: when you want to crop canvas by yourself :)
   * @returns {{extractPoints, spritesBorderInfo, activeZones, zonesBorderInfo}}
   */
  static get canvasExtraInfo() {
    return getCanvasExtraInfo()
  }

  /**
   * Add image object to editor.
   *
   * @param {string} imageSource url of image
   * @param {number} id universally unique identifier for a image
   * @param {boolean} [myMotive=false]
   */
  static addImage(imageSource, id, myMotive = false) {
    if (myMotive) {
      store.dispatch(addMyImageToLibrary(id, imageSource, true))
    }

    store.dispatch(addSpriteOnProduct(imageSource, myMotive, id))

    Console.info('Editor', 'Sprite added')
  }

  static addText() {
    Console.warn('Editor', 'Not implemented')
  }

  /**
   * Update text paddings and invalidate content
   * @param {number} padding
   */
  static updateTextPadding(padding) {
    const currentText = configs.data.text
    setConfig('text', { ...currentText, padding })
    Editor.invalidateContent()

    Console.info(
      'Editor',
      `Padding updated from ${currentText.padding} to ${padding}`
    )
  }

  /**
   * Update resolution of PIXI Canvas
   * @param {number} resolution
   */
  static updateResolution(resolution) {
    Console.info(
      'Editor',
      `Resolution updated from ${PIXI.settings.RESOLUTION} to ${resolution}`
    )
    PIXI.settings.RESOLUTION = resolution
  }
}

export default Editor
