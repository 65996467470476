import EditorManager from '../../core-module/editor/EditorManager'

function waitForEditor(resolver) {
  return result => {
    if (result.isReady) {
      EditorManager.editorStateChanged.unsubscribe(this)
      resolver()
    }
  }
}

export function waitForEditorReady() {
  return new Promise(resolve => {
    if (EditorManager.isProductEditorActive()) {
      resolve()
    } else {
      EditorManager.editorStateChanged.subscribe(waitForEditor(resolve))
    }
  })
}
