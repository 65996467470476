import { lazy } from 'react'

export const LeftPanel = lazy(() =>
  import('../LeftContainer/LeftContainerWithItems')
)

export const RightPanel = lazy(() =>
  import('../RightContainer/RightContainerWithData')
)

export const InfoPanel = lazy(() =>
  import('../../../core-module/containers/ProductInfoBottomContainer')
)

export const FooterPanel = lazy(() =>
  import('../../../core-module/containers/FeaturedProductsContainer')
)

export const SimilarProductsPanel = lazy(() =>
  import('../../../core-module/containers/SimilarProductsContainer')
)
