import * as yup from 'yup'

yup.addMethod(yup.string, 'json', () => {
  return this.test(
    'is-string-json',
    '${path} is not json object.', // eslint-disable-line
    async value => {
      try {
        const parsed = JSON.parse(value)
        return parsed ? typeof parsed === 'object' : false
      } catch (e) {
        return false
      }
    }
  )
})

const productSchema = {
  id: yup
    .number()
    .required()
    .positive()
    .integer(),
  color: yup.string().required(),
  quantity: yup
    .number()
    .required()
    .positive()
    .integer(),
  size: yup.string().min(0),
  combinationId: yup
    .number()
    .required()
    .integer()
    .min(0),
  mainCategory: yup
    .number()
    .required()
    .integer()
    .min(0)
}

const objectGeneral = {
  x: yup.number().required(),
  y: yup.number().required(),
  sideIndex: yup
    .number()
    .required()
    .integer()
    .min(0),
  order: yup
    .number()
    .required()
    .integer()
    .min(0),
  rotation: yup.number().required(),
  flipHorizontal: yup.boolean().required(),
  scale: yup.number().required()
}

const generalSpriteSchema = {
  ...objectGeneral,
  id: yup
    .number()
    .required()
    .positive()
    .integer(),
  imageSource: yup
    .string()
    .required()
}

const spriteSchema = {
  ...generalSpriteSchema,
  svg: yup.boolean().required()
}

const textSchema = {
  ...objectGeneral,
  text: yup.string().min(1).required(),
  curvedText: yup
    .number()
    .required()
    .min(0)
    .integer(),
  radius: yup
    .number()
    .required()
    .min(0)
    .integer(),
  fontSize: yup
    .number()
    .required()
    .min(0),
  color: yup.string().required(),
  fontFamily: yup.string().required(),
  bold: yup.boolean().required(),
  italic: yup.boolean().required(),
  align: yup.mixed().oneOf(['center', 'left', 'right'])
}

const dataSchema = yup.object().shape({
  product: yup.object().shape(productSchema),
  motives: yup
    .array()
    .min(0)
    .of(yup.object().shape(spriteSchema)),
  myMotives: yup
    .array()
    .min(0)
    .of(yup.object().shape(generalSpriteSchema)),
  texts: yup
    .array()
    .min(0)
    .of(yup.object().shape(textSchema))
})

export { dataSchema }
