import configs from '../config'
import { getCanvasExtraInfo } from '../../core-module/pixi/extract/utils/getCanvasExtraInfo'

export function CutZonesFromCanvas(canvas) {
  const canvasInfo = getCanvasExtraInfo()

  const { pixelRatio } = configs.data

  const final = {
    width: canvasInfo.activeZones.width * pixelRatio,
    height: canvasInfo.activeZones.height * pixelRatio,
    x: canvasInfo.extractPoints.x * pixelRatio,
    y: canvasInfo.extractPoints.y * pixelRatio
  }

  const canvasCopy = document.createElement('canvas')
  const ctxCopy = canvasCopy.getContext('2d')
  canvasCopy.width = final.width
  canvasCopy.height = final.height
  ctxCopy.drawImage(
    canvas,
    final.x,
    final.y,
    final.width,
    final.height,
    0,
    0,
    final.width,
    final.height
  )

  return canvasCopy
}
