import React, { useState, useEffect, useCallback } from 'react'

import { t } from 'i18next'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  closeFiltersModal,
  actionApplyFilterOnActiveObject,
  deleteFilterFromActiveObject
} from '../../../actions/editor'
import SpriteFilterDisplay from '../../components/SpriteFilterDisplay'
import { filters } from '../../pixi/helper/glfxFilters'
import ModalWithBackgroundAndClose from '../../components/ModalComponent/ModalBackground'

const defaultFilters = Object.entries(filters).map(f => ({
  key: f[0],
  name: `filterName.${f[0]}`
}))

const FiltersModalContainer = ({
  spriteFilterChoosing,
  closeFiltersModal: closeModal,
  applyFilterOnActiveObject,
  removeFilter,
  backgroundColor
}) => {
  const [renderedFilters, setRenderedFilters] = useState(defaultFilters)
  const [initialImage, setInitialImage] = useState(undefined);

  useEffect(() => {
    if (spriteFilterChoosing) {
      const timers = []
      let img
      if (spriteFilterChoosing.isSvg()) {
        fetch(spriteFilterChoosing.imageSource).then(res => res.text()).then((svgText) => {
          let parsedText = svgText
          Object.entries(spriteFilterChoosing.svgClasses).map(([clazz, value]) => {
            parsedText = parsedText.replace(new RegExp(`${clazz}.*\{.*\}`), `${clazz}{fill: ${value.fill}}`)
          })
          const blob = new Blob([parsedText], { type: 'image/svg+xml' });
          const url = URL.createObjectURL(blob);
          img = document.createElement('img');
          img.src = url
          img.onload = () => {
            Object.entries(filters).forEach((f, i) => {
              timers.push(setTimeout(() => renderSingleImage(f, i), 0))
            })
          }
          setInitialImage(URL.createObjectURL(blob));
        })
      } else {
        setInitialImage(spriteFilterChoosing.imageSource)
        img = document.createElement('img')
        img.crossOrigin = 'anonymous'
        img.src = spriteFilterChoosing.imageSource
        img.onload = () => {
          Object.entries(filters).forEach((f, i) => {
            timers.push(setTimeout(() => renderSingleImage(f, i), 0))
          })
        }
      }

      function renderSingleImage(f, i) {
        renderedFilters[i] = {
          key: f[0],
          loading: false,
          name: t(f[1] ? `filterName.${f[0]}` : 'filterName.original'),
          image: f[1](img)
        }
        setRenderedFilters([...renderedFilters])
      }

      return () => {
        timers.forEach(timer => clearTimeout(timer))
        setRenderedFilters(
          Object.entries(filters).map(f => ({ key: f[0], name: f[1].tName }))
        )
      }
    }
  }, [spriteFilterChoosing, filters])

  const selectFilter = useCallback(
    filter => {
      applyFilterOnActiveObject(filter)
      closeModal()
    },
    [closeModal, applyFilterOnActiveObject]
  )

  return spriteFilterChoosing ? (
    <ModalWithBackgroundAndClose className="modal-lg" onClose={closeModal}>
      <h4 className="title">{t('filtersModal.title')} </h4>
      <div className="filterModal-preview-container">
        <SpriteFilterDisplay
          filterName={t("filterName.original")}
          backgroundColor={backgroundColor}
          image={initialImage}
          onButtonClick={() => {
            removeFilter()
            closeModal()
          }}
        />
        {renderedFilters.map(f => (
          <SpriteFilterDisplay
            key={f.key}
            backgroundColor={backgroundColor}
            filterName={f.name}
            image={f.image}
            onButtonClick={() => selectFilter(f.key)}
          />
        ))}
      </div>
    </ModalWithBackgroundAndClose>
  ) : (
      <div />
    )
}

FiltersModalContainer.propTypes = {
  spriteFilterChoosing: PropTypes.object,
  closeFiltersModal: PropTypes.func,
  applyFilterOnActiveObject: PropTypes.func,
  removeFilter: PropTypes.func,
  backgroundColor: PropTypes.string
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeFiltersModal,
      removeFilter: deleteFilterFromActiveObject,
      applyFilterOnActiveObject: actionApplyFilterOnActiveObject
    },
    dispatch
  )
}

const mapStateToProps = state => ({
  spriteFilterChoosing: state.editor.spriteFilterChoosing,
  spriteReducer: state.spritesContainer.activeSprite,
  backgroundColor: state.product.active.color.data
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersModalContainer)
