import ProductEditor from './ProductEditor'
import ProductSide from './ProductSide'
import Console from '../utils/console'
import store from '../../store/MyStore'
import {
  actionEditorIsNotReady,
  actionEditorIsReady
} from '../../actions/editor'
import Observer from '../pixi/helper/Observer'

/**
 *
 * @class
 * @singleton
 */
class _EditorManager {
  /**
   *
   * @private
   * @type {?ProductEditor}
   */
  _activeProductEditor

  editorStateChanged = new Observer()

  createProduct(views, sideIndex) {
    this.removeProduct()
    const sides = views.map((view, index) => new ProductSide(index, view))
    this._activeProductEditor = new ProductEditor(sides, sideIndex)
    store.dispatch(actionEditorIsReady())
    this.editorStateChanged.notifyAll({ isReady: true })
  }

  removeProduct() {
    if (this._activeProductEditor) {
      this._activeProductEditor.clear()
    }

    this._activeProductEditor = null
    store.dispatch(actionEditorIsNotReady())
    this.editorStateChanged.notifyAll({ isReady: false })
  }

  /**
   *
   * @return {ProductEditor}
   */
  getActiveProductEditor() {
    if (!this._activeProductEditor) {
      Console.error(
        'EditorManager',
        'Active Product editor has not been initialized. First need to run `createProduct`.'
      )
    }

    return this._activeProductEditor
  }

  isProductEditorActive() {
    return !!this._activeProductEditor
  }
}

export default new _EditorManager()
