import * as constant from '../constants/EditorConstant'

const initState = {
  ready: false,
  spriteFilterChoosing: undefined,
}

export function handleEditor(state = initState, action) {
  switch (action.type) {
    case constant.TYPE_EDITOR_SET_STATE:
      return { ...state, ready: action.ready }
    case constant.TYPE_EDITOR_SPRITE_FILTER_CLOSED:
      return { ...state, spriteFilterChoosing: undefined}
    case constant.TYPE_EDITOR_SPRITE_FILTER_CHOOSING:
      return { ...state, spriteFilterChoosing: action.sprite}
    default:
      return state
  }
}
