import {
  SET_ACTIVE_TAB,
  TYPE_CHANGE_REF
} from '../constants/ButtonProductConstant'

import store from '../store/MyStore'

export const actionUpdateRef = ref => ({
  type: TYPE_CHANGE_REF,
  ref
})

export const actionSetTab = tab => ({
  type: SET_ACTIVE_TAB,
  tab
})

export function scrollIntoFirstTab() {
  const { ref, tab } = store.getState().bottomProduct

  if (!ref) {
    return
  }

  if (tab !== 0) {
    store.dispatch(actionSetTab(0))
  }

  ref.scrollIntoView({ behavior: 'smooth' })
}
