import React from 'react'
import { t } from 'i18next'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Modal from './index'
import { generateDefaultClassNames } from '../../utils/device-detect'
import Close from '../../../assets/icons/close.svg'

export const ModalContext = React.createContext({ ref: null })

// eslint-disable-next-line react/prop-types
export const ModalBackground = ({ children }) => (
  <Modal>
    <div
      className={classNames(
        'modal-background',
        'fixed',
        generateDefaultClassNames()
      )}>
      {children}
    </div>
  </Modal>
)

const ModalWithBackgroundAndClose = ({
  children,
  onClick,
  className,
  onClose,
  fixed,
  onInnerClick
}) => {
  const ref = React.createRef()

  return (
    <Modal>
      <div
        className={classNames(
          'modal-background',
          generateDefaultClassNames(),
          className,
          { fixed }
        )}
        onClick={onClick}
        ref={ref}>
        <div onClick={onInnerClick}>
          <button
            type="button"
            onClick={e => onClose(e)}
            className="modal-close disable-propagation">
            <img src={Close} alt={t('close.window', 'Close window')} />
          </button>
          <ModalContext.Provider value={{ ref }}>
            {children}
          </ModalContext.Provider>
        </div>
      </div>
    </Modal>
  )
}

ModalWithBackgroundAndClose.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  onInnerClick: PropTypes.func,
  fixed: PropTypes.bool
}

ModalWithBackgroundAndClose.defaultProps = {
  onClose: f => f,
  onClick: f => f,
  className: '',
  onInnerClick: f => f,
  fixed: false
}

export default ModalWithBackgroundAndClose
