import { filters } from 'pixi.js-legacy'
import { BevelFilter } from '@pixi/filter-bevel'
import { EmbossFilter } from '@pixi/filter-emboss'
import { compact } from 'lodash/array'

import chroma from 'chroma-js'

import { TECHNOLOGY_ENGRAVING } from '../../utils/technologies'
import {
  engravingColors,
  selectAvailableMaterial
} from '../../containers/ColorContainer'
import configs from '../../../module/config'

export function getFiltersBasedOnTechnology(technology, material) {
  switch (technology) {
    case TECHNOLOGY_ENGRAVING: {
      const parsedMaterial = selectAvailableMaterial(material)

      if (!parsedMaterial) {
        return []
      }

      const chromaColor = chroma(engravingColors[parsedMaterial][0].value)

      return compact([
        configs.data.filters[parsedMaterial].bevelFilter.enable
          ? new BevelFilter({
              ...configs.data.filters[parsedMaterial].bevelFilter,
              lightColor: chromaColor.darken().hex()
            })
          : null,
        configs.data.filters[parsedMaterial].embossFilter.enable
          ? new EmbossFilter(
              configs.data.filters[parsedMaterial].embossFilter.strength
            )
          : null,
        configs.data.filters[parsedMaterial].colorMatrix.enable
          ? (() => {
              const colorMatrix = new filters.ColorMatrixFilter()
              if (configs.data.filters[parsedMaterial].colorMatrix.desaturate) {
                colorMatrix.desaturate()
              }

              if (
                configs.data.filters[parsedMaterial].colorMatrix.sepia.enable
              ) {
                colorMatrix.sepia(
                  configs.data.filters[parsedMaterial].colorMatrix.sepia
                    .multiply
                )
              }

              if (
                configs.data.filters[parsedMaterial].colorMatrix.vintage.enable
              ) {
                colorMatrix.vintage(
                  configs.data.filters[parsedMaterial].colorMatrix.vintage
                    .multiply
                )
              }

              if (
                configs.data.filters[parsedMaterial].colorMatrix.browni.enable
              ) {
                colorMatrix.browni(
                  configs.data.filters[parsedMaterial].colorMatrix.browni
                    .multiply
                )
              }

              if (
                configs.data.filters[parsedMaterial].colorMatrix.greyscale
                  .enable
              ) {
                colorMatrix.greyscale(
                  configs.data.filters[parsedMaterial].colorMatrix.greyscale
                    .scale,
                  configs.data.filters[parsedMaterial].colorMatrix.greyscale
                    .multiply
                )
              }

              if (
                configs.data.filters[parsedMaterial].colorMatrix.contrast.enable
              ) {
                colorMatrix.contrast(
                  configs.data.filters[parsedMaterial].colorMatrix.contrast
                    .amount,
                  configs.data.filters[parsedMaterial].colorMatrix.contrast
                    .multiply
                )
              }

              if (
                configs.data.filters[parsedMaterial].colorMatrix.saturate.enable
              ) {
                colorMatrix.saturate(
                  configs.data.filters[parsedMaterial].colorMatrix.saturate
                    .amount,
                  configs.data.filters[parsedMaterial].colorMatrix.saturate
                    .multiply
                )
              }

              return colorMatrix
            })()
          : null,
        configs.data.filters[parsedMaterial].alphaFilter.enable
          ? new filters.AlphaFilter(
              configs.data.filters[parsedMaterial].alphaFilter.alpha
            )
          : null
      ])
    }

    default:
      return []
  }
}
