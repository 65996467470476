import React from 'react'
import * as Sentry from '@sentry/browser'
import configs from '../../../module/config'
import Extract from '../../../module/Extract'
import Console from '../../utils/console'

Sentry.init({
  dsn: 'https://2de8f9262d25435a85dd0bdf2646f845@sentry.inqool.cz/9',
  release: `${process.env.PROJECT_NAME}@${process.env.VERSION}#${process.env.REVISION}`,
  environment: process.env.NODE_ENV
})

export class SentryErrorComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })

    if (configs.data.captureExceptions) {
      Sentry.configureScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          Console.error(key, errorInfo[key])
          scope.setExtra(key, errorInfo[key])
        })
        scope.setExtra('init_data', JSON.stringify(configs.data.initData))
        scope.setExtra('force_product_id', configs.data.productId)
        scope.setExtra('current_data', JSON.stringify(Extract.data()))
        scope.setExtra(
          'pixel_ratio',
          JSON.stringify({
            web: configs.data.pixelRatio,
            mobile: configs.data.mobilePixelRatio
          })
        )

        scope.setTag('language', configs.data.language)
        scope.setTag('is_generator', configs.data.generatorMode)
      })

      Sentry.captureException(error)
    }

    console.error(error)
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return <div>Nastala chyba</div>
    }

    // eslint-disable-next-line
    return this.props.children
  }
}
