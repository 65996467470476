import React from 'react'

export const MenuContext = React.createContext({
  onDone: f => f
})

export const withMenuContext = Component => props => (
  <MenuContext.Consumer>
    {value => <Component {...props} {...value} />}
  </MenuContext.Consumer>
)
