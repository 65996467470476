let step = 0

/**
 *
 * @class
 */
class HistoryState {
  constructor(type, savedState = null) {
    this.type = type
    this.state = savedState
    this.order = step
    step += 1
  }
}

export default HistoryState
