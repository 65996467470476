/**
 * This namespace provides whole Creator Application with all necessary exports.
 * Creator is assigned to window object.
 * If you're gonna to use import then you should use ```import {Creator} from "creator-app"```.
 *
 * @example
 * import { Creator } from "creator-app";
 *
 * console.log(Creator);
 *
 * @namespace Creator
 */

export { default as Constants } from './Constants'
export { default as Application } from './Application'
export { default as Generator } from './Generator'
export { default as Extract } from './Extract'
export { default as Editor } from './Editor'
export { default as Product } from './Product'
export { default as Cart } from './Cart'
export { default as Category } from './Category'
export { default as Extra } from './Extra'

/**
 * Version of application
 * @name {VERSION}
 * @constant {string}
 * @memberOf Creator
 *
 * @example
 * const creatorVersion = Creator.VERSION;
 */
// eslint-disable-next-line
export const VERSION = process.env.VERSION

/**
 * Last commit number (8 chars)
 * @name {REVISION}
 * @constant {string}
 * @memberOf Creator
 */
// eslint-disable-next-line
export const REVISION = process.env.REVISION
