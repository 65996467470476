import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import FeaturedProductComponent from '../../../web/components/FeaturedProductComponent'

const FeaturedLoading = ({ translateKey }) => (
  <div id="similar-product-container">
    <h4 className="title">{t(translateKey)}</h4>
    <div>
      <div className="similar-product-container-content">
        <FeaturedProductComponent
          displayPrice={false}
          className="pageLoading"
        />
        <FeaturedProductComponent
          displayPrice={false}
          className="pageLoading"
        />
      </div>
    </div>
  </div>
)

FeaturedLoading.propTypes = {
  translateKey: PropTypes.string
}

FeaturedLoading.defaultProps = {
  translateKey: 'getInspired'
}

export default FeaturedLoading
