import { categoryIDChanged } from '../core-module/components/EditorComponents/ProductContainer/SelectProductCategory'

/**
 * Class to handle categories
 *
 * @class
 * @memberOf Creator
 */
class Category {
  /**
   * Subscribe to listen when user change category.
   * @param {categoryChangedCallback} listener
   * @param {scope} scope
   *
   * @example
   * myAwesomeFunction(categoryID) {
   *   console.log(`Category was changed to ${categoryID}`);
   * }
   *
   * Creator.Category.subscribeToCategoryChanged(this.myAwesomeFunction, this);
   */
  static subscribeToCategoryChanged(listener, scope) {
    categoryIDChanged.subscribe(listener, scope)
  }

  /**
   * Unsubscribe from `user changed category`.
   * @param {categoryChangedCallback} listener
   */
  static unsubscribeCategoryChanged(listener) {
    categoryIDChanged.unregisterGraphicUpdated(listener)
  }
}

export default Category
