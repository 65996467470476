import { toast } from 'react-toastify'
import { t } from 'i18next'

import store from '../../../store/MyStore'
import { addSpriteOnProduct } from '../../../actions/editor'
import { addMyImageToLibrary, uploadImage } from '../../../actions/imageLibrary'
import ImageCacheFactory from '../../utils/ImageCacheFactory'
import CreateID from '../../utils/createID'
import Observer from '../../pixi/helper/Observer'

const ValidImageTypes = ['image/jpeg', 'image/png']

export const ImageUploadingObserver = new Observer()

const MIN_DIMENSION_OF_MY_IMAGE = 500

class DragAndDropHelper {
  imageData = null

  myMotive = false

  _canResetImage = true

  constructor() {
    this.onDragStartImage = this.onDragStartImage.bind(this)
    this.displayAndUploadImage = this.displayAndUploadImage.bind(this)
  }

  /**
   * @public
   * @param {object} imageData
   * @param myMotive
   */
  onDragStartImage(imageData, myMotive) {
    this.imageData = imageData
    this.myMotive = myMotive
  }

  onDragEndImage(event) {
    event.preventDefault()
    if (this._canResetImage) {
      this.resetImage()
    }
  }

  onDragEnterCanvas(e) {
    e.preventDefault()
    this._canResetImage = false
  }

  onDragLeaveCanvas(e) {
    e.preventDefault()
    this._canResetImage = true
  }

  displayAndUploadImage(files) {
    return new Promise(resolve => {
      const promises = []

      if (this.imageData) {
        const {
          myMotive,
          imageData: { id }
        } = this

        const imageURL = this.imageData.image
        promises.push(
          ImageCacheFactory.getObject(imageURL).then(
            ({ cached: image, naturalWidth, naturalHeight }) => {
              store.dispatch(
                addSpriteOnProduct(
                  imageURL,
                  myMotive,
                  id,
                  image.width,
                  image.height,
                  naturalWidth,
                  naturalHeight
                )
              )
            }
          )
        )
      } else {
        if (files.length) {
          ImageUploadingObserver.notifyAll({ myMotive: true, loading: true })
        }
        let fileProcessed = 0

        for (let i = 0; i < files.length; i += 1) {
          promises.push(
            this.uploadImageToMyLibrary(files[i])
              .then(({ image, id, naturalWidth, naturalHeight }) => {
                store.dispatch(
                  addSpriteOnProduct(
                    image.src,
                    true,
                    id,
                    image.width,
                    image.height,
                    naturalWidth,
                    naturalHeight
                  )
                )
                store.dispatch(uploadImage(id, files[i]))

                fileProcessed += 1
                if (fileProcessed === files.length) {
                  ImageUploadingObserver.notifyAll({
                    myMotive: true,
                    loading: false
                  })
                }
              })
              .catch(error => {
                ImageUploadingObserver.notifyAll({
                  myMotive: true,
                  loading: false
                })
                throw new Error(error)
              })
          )
        }
      }

      this.resetImage()

      Promise.all(promises)
        .then(resolve)
        .catch(resolve)
    })
  }

  uploadImageToMyLibrary(file) {
    return this.handleFile(file)
      .then(({ image, naturalWidth, naturalHeight }) => {
        const id = CreateID()
        store.dispatch(addMyImageToLibrary(id, image.src))
        return { image, id, naturalWidth, naturalHeight }
      })
      .catch(error => {
        toast.error(error.message)
        return error
      })
  }

  // eslint-disable-next-line class-methods-use-this
  handleFile(file) {
    return new Promise((resolve, reject) => {
      if (!ValidImageTypes.some(type => file.type === type)) {
        reject(new Error(t('imageUpload.formatInvalid')))
      }

      const reader = new FileReader()
      reader.onerror = () => reject(new Error('File reader error'))
      reader.onload = event => {
        ImageCacheFactory.getObject(event.target.result)
          .then(({ cached: image, naturalWidth, naturalHeight }) => {
            if (
              naturalWidth < MIN_DIMENSION_OF_MY_IMAGE &&
              naturalHeight &&
              MIN_DIMENSION_OF_MY_IMAGE
            ) {
              reject(
                new Error(
                  t(
                    'image.too.small',
                    'The image is too small, the minimum size is {{size}}px.',
                    { size: MIN_DIMENSION_OF_MY_IMAGE }
                  )
                )
              )
            } else {
              resolve({ image, naturalWidth, naturalHeight })
            }
          })
          .catch(error => reject(error))
      }
      reader.readAsDataURL(file)
    })
  }

  resetImage() {
    this.imageData = null
    this.myMotive = false
    this._canResetImage = true
  }
}

export default new DragAndDropHelper()
