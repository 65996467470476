import { render } from 'react-dom'

import data from '../core-module/creator-generator-init'
import configs, { mergeConfigs, setConfig } from './config'

/**
 * Convenience class to create a new Creator generator.
 * @example
 * // Create the application
 * new Creator.Generator();
 *
 * @class
 * @memberOf Creator
 */
class Generator {
  /**
   * Check Application configs
   * @param {object} config
   */
  constructor(config = {}) {
    mergeConfigs({
      generatorMode: true,
      autoSave: false,
      enableBackgroundPattern: false,
      enableShortcuts: false,
      enableGenerateImageAfterChange: false,
      enableAutomaticThumbnail: false,
      captureExceptions: true,
      ...config
    })
    setConfig('initConfig', { ...config })

    Generator.instance = this

    render(
      data(config.enableProductSide),
      typeof configs.data.rootElement === 'string'
        ? document.getElementById(configs.data.rootElement)
        : configs.data.rootElement
    )
  }
}

export default Generator
