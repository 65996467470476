import { TYPE_CHANGE_REF_MENU } from '../constants/MenuContainerConstant'

export function handleMenuContainer(state = { menuInstanceRef: null }, action) {
  switch (action.type) {
    case TYPE_CHANGE_REF_MENU: {
      return {
        ...state,
        menuInstanceRef: action.menuInstanceRef
      }
    }

    default:
      return state
  }
}
