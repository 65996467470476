import uuidv4 from 'uuid/v4'
import { canvasCenter } from '../../core-module/pixi/PixiController'

import { SPRITE_TYPE, CURVED_TEXT } from '../../constants/SpriteConstants'
import EditorManager from '../../core-module/editor/EditorManager'
import Tools from '../../core-module/utils/Tools'
import { changeColorInCSSObject } from '../../core-module/utils/changeColorInSvg'
import { getHashKey } from '../../core-module/utils/GetHashKey'
import { calculateRatioByMaxDimensions } from '../../core-module/utils/calculateRatioByMaxDimensions'

export const ALIGN_CENTER = 'center'
export const ALIGN_LEFT = 'left'
export const ALIGN_RIGHT = 'right'

/**
 *
 * @class
 */
class PrimitiveObjectReducer {
  /**
   *
   * @type {number}
   */
  sideIndex

  scale

  /**
   *
   * @type {boolean}
   */
  _flipHorizontal

  x

  y

  /**
   *
   * @constructor
   * @param {SPRITE_TYPE} type
   * @param {number} [rotation=0]
   * @param sideIndex
   * @param flipHorizontal
   * @param uuid
   */
  constructor(
    type,
    rotation = 0,
    flipHorizontal = false,
    sideIndex = undefined,
    uuid = undefined
  ) {
    this.uuid = uuid || uuidv4()
    this.type = type
    this.rotation = rotation

    if (typeof sideIndex === 'number' && sideIndex >= 0) {
      this.sideIndex = sideIndex
    } else if (EditorManager.isProductEditorActive()) {
      this.sideIndex = EditorManager.getActiveProductEditor().getActiveSide().index
    } else {
      this.sideIndex = 0
    }

    this.setPositionAtCenterZone()

    // fixme: stop scaling all object instead of this specify requirements for each class separately (example: TextState set fontSize, not scale)
    this.scale = 1

    this._flipHorizontal = flipHorizontal
  }

  setScale(scale) {
    this.scale = scale
  }

  setUUID(uuid) {
    this.uuid = uuid
  }

  /**
   *
   * @param {number} scale
   */
  multiplyScale(scale) {
    this.scale = this.scale * scale
  }

  multiplyPositionAndChangeByActiveZone(x, y) {
    this.x = this.x * x
    this.y = this.y * y
  }

  setPosition(x, y) {
    this.x = x
    this.y = y
  }

  flipHorizontal() {
    this._flipHorizontal = !this._flipHorizontal
  }

  setFlipHorizontal(flipHorizontal) {
    this._flipHorizontal = flipHorizontal
  }

  setSideIndex(sideIndex) {
    this.sideIndex = sideIndex
  }

  isHorizontalFlipped() {
    return this._flipHorizontal
  }

  getScale() {
    return {
      x: (this._flipHorizontal ? -1 : 1) * this.scale,
      y: this.scale
    }
  }

  setPositionAtCenterZone(zone) {
    this.x = 0
    this.y = 0

    if (zone) {
      this.x = zone.x + zone.width / 2
      this.y = zone.y + zone.height / 2
      return
    }

    if (!EditorManager.isProductEditorActive()) {
      // If product doesnt exist ( due to product loading ), suppose new position
      this.x = 250
      this.y = 237
      return
    }

    const side = EditorManager.getActiveProductEditor().side[this.sideIndex]
    zone = side.getZoneToInsert() // eslint-disable-line no-param-reassign

    if (!zone) {
      // If zone doesnt exist ( due to product loading ), suppose new position
      this.x = 250
      this.y = 237
      return
    }

    this.x = zone.x + zone.width / 2
    this.y = zone.y + zone.height / 2
  }

  /**
   *
   * @return {boolean}
   */
  isText() {
    return this.type === SPRITE_TYPE.TEXT
  }

  isMotive() {
    return this.type === SPRITE_TYPE.OBJECT
  }

  isUnknown() {
    return this.type === SPRITE_TYPE.UNKNOWN
  }

  setRotation(rotation) {
    this.rotation = rotation
  }

  /**
   *
   * @param {number} offsetX
   * @param {number} offsetY
   */
  setPositionToCenter(offsetX = 0, offsetY = 0) {
    this.x = canvasCenter.x() + offsetX
    this.y = canvasCenter.y() + offsetY
  }

  /**
   *
   * @abstract
   */
  // eslint-disable-next-line class-methods-use-this
  clone() {
    throw new Error('Abstract class can not be instantiated.')
  }
}

class SpriteState extends PrimitiveObjectReducer {
  constructor(
    imageSource,
    id = -1,
    width,
    height,
    naturalWidth,
    naturalHeight,
    scale,
    rotation = 0,
    flipHorizontal = false,
    myMotive = false,
    sideIndex = -1,
    filters=[]
  ) {
    super(SPRITE_TYPE.OBJECT, rotation, flipHorizontal, sideIndex)
    this.imageSource = imageSource

    this.width = width
    this.height = height
    this.filters = filters
    this.naturalWidth = naturalWidth
    this.naturalHeight = naturalHeight

    if (typeof scale === 'undefined') {
      this.setSizeByZone()
    } else {
      this.scale = scale
    }

    this.svg = false
    this.myMotive = myMotive
    this.id = id
  }

  setSizeByZone(zone) {
    if (zone) {
      return this.setScale(
        calculateRatioByMaxDimensions(
          this.width,
          this.height,
          zone.width,
          zone.height
        )
      )
    }

    const side = EditorManager.getActiveProductEditor().getActiveSide()

    if (!side) {
      // If size doesnt exist ( due to product loading ), trying to estimate new size of image with MAGIC NUMBER
      const MAGIC_NUMBER = 200
      return this.setScale(
        this.width <= this.height
          ? MAGIC_NUMBER / this.height
          : MAGIC_NUMBER / this.width
      )
    }

    // eslint-disable-next-line no-param-reassign
    zone = side.getZoneToInsert()

    if (!zone) {
      // If zone doesnt exist ( due to product loading ), trying to estimate new size of image with MAGIC NUMBER
      const MAGIC_NUMBER = 200
      return this.setScale(
        this.width <= this.height
          ? MAGIC_NUMBER / this.height
          : MAGIC_NUMBER / this.width
      )
    }

    return this.setScale(
      calculateRatioByMaxDimensions(
        this.width,
        this.height,
        zone.width,
        zone.height
      )
    )
  }

  isSvg() {
    return this.svg
  }

  setId(id) {
    this.id = id
  }

  clone() {
    return new SpriteState(
      this.imageSource,
      this.id,
      this.width,
      this.height,
      this.naturalWidth,
      this.naturalHeight,
      this.scale,
      this.rotation,
      this.isHorizontalFlipped(),
      this.myMotive,
      this.sideIndex,
      this.filters,
    )
  }
}

export class SvgSpriteState extends SpriteState {
  /**
   * svgClasses will be initialized after svg loaded file.
   * @type {{}}
   */
  svgClasses

  constructor(
    imageSource,
    id,
    width,
    height,
    naturalWidth,
    naturalHeight,
    scale,
    rotation = 0,
    flipHorizontal = false,
    myMotive = false,
    svgClasses,
    sideIndex = -1,
    filters=[]
  ) {
    super(
      imageSource,
      id,
      width,
      height,
      naturalWidth,
      naturalHeight,
      scale,
      rotation,
      flipHorizontal,
      myMotive,
      sideIndex,
      filters
    )

    this.svg = true

    this.setSvgClasses(svgClasses || {})
  }

  setSvgClasses(classes) {
    this.svgClasses =
      typeof classes === 'string' ? JSON.parse(classes) : classes
  }

  changeSvgToColor(color) {
    changeColorInCSSObject(this.svgClasses, color)
  }

  getHashKey() {
    return getHashKey(this.imageSource, this.svgClasses)
  }

  getSvgClassesAsString() {
    return JSON.stringify(this.svgClasses)
  }

  updateSVGColor(className, color) {
    this.svgClasses[className].fill = color
  }

  clone() {
    return new SvgSpriteState(
      this.imageSource,
      this.id,
      this.width,
      this.height,
      this.naturalWidth,
      this.naturalHeight,
      this.scale,
      this.rotation,
      this.isHorizontalFlipped(),
      this.myMotive,
      this.svgClasses ? JSON.stringify(this.svgClasses) : undefined,
      this.sideIndex,
      this.filters
    )
  }
}

class TextState extends PrimitiveObjectReducer {
  constructor(
    text,
    sideIndex = -1,
    rotation = 0,
    flipHorizontal = false,
    curvedText = CURVED_TEXT.NONE,
    radius = 0,
    fontSize = 28,
    color = '#000000',
    fontFamily = 'Sans-serif',
    scale = 1,
    bold = false,
    italic = false,
    align = ALIGN_CENTER,
    filters = []
  ) {
    super(SPRITE_TYPE.TEXT, rotation, flipHorizontal, sideIndex)
    this.text = text
    this.curvedText = radius > 0 ? curvedText : CURVED_TEXT.NONE
    this.radius = radius
    this.fontSize = fontSize
    this.color = color
    this.fontFamily = fontFamily
    this.scale = scale
    this.bold = bold
    this.italic = italic
    this.align = align,
    this.filters = filters
  }

  /**
   *
   * @return {boolean}
   */
  isCurved() {
    return this.radius > 0 && this.curvedText !== CURVED_TEXT.NONE
  }

  setColor(color) {
    this.color = color
  }

  setFontSize(fontSize) {
    this.fontSize = fontSize
  }

  clone() {
    return new TextState(
      this.text,
      this.sideIndex,
      this.rotation,
      this.isHorizontalFlipped(),
      this.curvedText,
      this.radius,
      this.fontSize,
      this.color,
      this.fontFamily,
      this.scale,
      this.bold,
      this.italic,
      this.align,
      this.filters,
    )
  }
}

class TextStateBuilder {
  constructor(textValue = '', sideIndex = -1) {
    this.textState = new TextState(textValue, sideIndex)
  }

  setText(text) {
    this.textState.text = text
    return this
  }

  setFilters(filters){
    this.textState.filters = filters
    return this;
  }

  setCurvedText(curvedText) {
    this.textState.curvedText = curvedText
    return this
  }

  setRadius(radius) {
    this.textState.radius = radius
    return this
  }

  setFontSize(fontSize) {
    this.textState.fontSize = fontSize
    return this
  }

  setFontFamily(fontFamily) {
    this.textState.fontFamily = fontFamily
    return this
  }

  setColor(color) {
    this.textState.color = color
    return this
  }

  setBold(bold) {
    this.textState.bold = bold
    return this
  }

  setItalic(italic) {
    this.textState.italic = italic
    return this
  }

  setAlign(align) {
    this.textState.align = align
    return this
  }

  setRotation(rotation) {
    this.textState.rotation = rotation
    return this
  }

  setSideIndex(sideIndex) {
    this.textState.setSideIndex(sideIndex)
    return this
  }

  setFlipHorizontal(flipHorizontal) {
    this.textState.setFlipHorizontal(flipHorizontal)
    return this
  }

  setScale(scale) {
    this.textState.setScale(scale)
    return this
  }

  setPosition(x, y) {
    this.textState.setPosition(x, y)
    return this
  }

  build() {
    return this.textState
  }
}

export function createSpriteObject(
  imageSource,
  width,
  height,
  naturalWidth,
  naturalHeight,
  myMotive = false,
  id = -1,
  svgClasses = undefined
) {
  if (Tools.isLinkSvg(imageSource)) {
    return new SvgSpriteState(
      imageSource,
      id,
      width,
      height,
      naturalWidth,
      naturalHeight,
      undefined,
      0,
      false,
      myMotive,
      svgClasses,
    )
  }
  return new SpriteState(
    imageSource,
    id,
    width,
    height,
    naturalWidth,
    naturalHeight,
    undefined,
    0,
    false,
    myMotive
  )
}

export function createSpriteObjectFromData(
  width,
  height,
  naturalWidth,
  naturalHeight,
  myMotive,
  data
) {
  let sprite
  if (data.svg) {
    sprite = new SvgSpriteState(
      data.imageSource,
      data.id,
      width,
      height,
      naturalWidth,
      naturalHeight,
      data.scale,
      data.rotation,
      data.flipHorizontal,
      myMotive,
      data.svgClasses,
      data.sideIndex,
      data.filters
    )
  } else {
    sprite = new SpriteState(
      data.imageSource,
      data.id,
      width,
      height,
      naturalWidth,
      naturalHeight,
      data.scale,
      data.rotation,
      data.flipHorizontal,
      myMotive,
      data.sideIndex,
      data.filters
    )
  }

  sprite.setPosition(data.x, data.y)
  sprite.setFlipHorizontal(data.flipHorizontal)
  sprite.setSideIndex(data.sideIndex)

  return sprite
}

export function createPrimitiveObject(uuid, sideIndex) {
  return new PrimitiveObjectReducer(
    SPRITE_TYPE.UNKNOWN,
    0,
    false,
    sideIndex,
    uuid
  )
}

export { SpriteState, TextState, TextStateBuilder }
