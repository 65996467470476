import store from '../store/MyStore'
import {
  MATERIAL_GLASS,
  MATERIAL_METAL,
  MATERIAL_WOOD,
  TECHNOLOGY_ENGRAVING,
  TECHNOLOGY_STAMPS
} from '../core-module/utils/technologies'
import {
  engravingColors,
  selectAvailableMaterial,
  stampsColors
} from '../core-module/containers/ColorContainer'
import { changeColorInCSSObject } from '../core-module/utils/changeColorInSvg'

/**
 *
 * @param {SvgParser} parser
 */
export function computeSvgStyleAttributesIncludeTechnology(
  parser,
  defaultSvgClasses
) {
  const svgClasses =
    !defaultSvgClasses || Object.entries(defaultSvgClasses).length === 0
      ? parser.getSvgStyleAttrsAsClone()
      : defaultSvgClasses

  const { product } = store.getState()

  // eslint-disable-next-line default-case
  switch (product.active.technology) {
    case TECHNOLOGY_ENGRAVING: {
      const material = selectAvailableMaterial(product.material)

      // eslint-disable-next-line
      switch (material) {
        case MATERIAL_WOOD:
          changeColorInCSSObject(svgClasses, engravingColors.wood[0].value)
          break
        case MATERIAL_METAL:
          changeColorInCSSObject(svgClasses, engravingColors.metal[0].value)
          break
        case MATERIAL_GLASS:
          changeColorInCSSObject(svgClasses, engravingColors.glass[0].value)
          break
      }
      break
    }
    case TECHNOLOGY_STAMPS: {
      changeColorInCSSObject(svgClasses, stampsColors[0].value)
      break
    }
  }

  return svgClasses
}
