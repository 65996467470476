import { PixiController } from '../PixiController'
import { getSpritesBorderInfo } from '../extract/utils/getCanvasExtraInfo'

export function getRelationBetweenSpritesAndProduct() {
  if (
    !PixiController.spriteCopiesContainer ||
    PixiController.spriteCopiesContainer.children.length === 0
  ) {
    throw new Error('No sprite added.')
  }

  const spritesBorder = getSpritesBorderInfo()
  const productContainerBounds = PixiController.productContainer.getBounds()

  const spritesOffsetFromProduct = {
    left: spritesBorder.left - productContainerBounds.x,
    top: spritesBorder.top - productContainerBounds.y
  }

  return {
    spritesOffsetFromProduct,
    productContainerBounds
  }
}

export function getRelationBetweenSpritesAndProductInPercentages() {
  const relationInPixels = getRelationBetweenSpritesAndProduct()

  const spritesSizes = PixiController.spriteCopiesContainer.getBounds()

  const spritesOffsetFromProduct = {
    left:
      relationInPixels.spritesOffsetFromProduct.left /
      relationInPixels.productContainerBounds.width,
    top:
      relationInPixels.spritesOffsetFromProduct.top /
      relationInPixels.productContainerBounds.height
  }

  const spritesSizeToProductSize = {
    width: spritesSizes.width / relationInPixels.productContainerBounds.width,
    height: spritesSizes.height / relationInPixels.productContainerBounds.height
  }

  return {
    spritesOffsetFromProduct,
    spritesSizeToProductSize
  }
}
