import { Graphics } from 'pixi.js-legacy'

const starData = [
  0,
  0,
  20,
  50,
  80,
  55,
  35,
  95,
  50,
  150,
  0,
  120,
  -50,
  150,
  -35,
  95,
  -80,
  55,
  -20,
  50
]

function createStar(color = 0xf1c40f) {
  const starSprite = new Graphics()
  starSprite.beginFill(color)
  starSprite.drawPolygon(starData)
  starSprite.endFill()

  return starSprite
}

function createEmptyStar(color = 0xf1c40f) {
  const starSprite = new Graphics()
  starSprite.lineStyle(20, color, 1, 0.5, true)
  starSprite.moveTo(0, 0)

  for (let index = 2; index < starData.length; index += 2) {
    starSprite.lineTo(starData[index], starData[index + 1])
  }
  starSprite.lineTo(0, 0)

  return starSprite
}

export { createStar, createEmptyStar }
