import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import configs from '../../module/config'
import {
  calculateScaleMultiplicand,
  calculateOptimalImageDimensions
} from './calculateRatioByMaxDimensions'

const REG_EX_STYLE = /([.#]?[0-9a-z_-]+)\s*{([\s.#-_:]*)}/gim
const REG_EX_ONE_MATCH_PATTERN = /\s*([0-9a-z_-]+):\s*([0-9a-z#,._-]+);?\s?/gim

function removeAllChildren(element) {
  while (element.firstChild) {
    element.removeChild(element.firstChild)
  }
}

class SvgParser {
  constructor(svgString) {
    this.svgContent = svgString
    this._styleAttrs = null
    this.scaledMultiplicand = 1 // calculated after optimizeSvg()
  }

  toSvgString(styleObject = null) {
    const xml = this.getDOMParser()

    if (styleObject != null) {
      const styles = this._getStylesFromXml(xml)

      if (styles && styles[0]) {
        let styleResult = ''
        Object.keys(styleObject).forEach(key => {
          const classObject = styleObject[key]
          styleResult += `${key} {`
          Object.keys(classObject).forEach(attrKey => {
            styleResult += `${attrKey}:${classObject[attrKey]};`
          })
          styleResult += `}`
        })

        removeAllChildren(styles[0])
        styles[0].appendChild(xml.createTextNode(styleResult))
      }
    }

    const xmlString = encodeURIComponent(
      new XMLSerializer().serializeToString(xml)
    )

    return `data:image/svg+xml;charset=utf8,${xmlString}`
  }

  getSvgStyleAttrs() {
    return this._styleAttrs ? this._styleAttrs : this._parseSvgAndCache()
  }

  getSvgStyleAttrsAsClone() {
    return cloneDeep(this.getSvgStyleAttrs())
  }

  // eslint-disable-next-line class-methods-use-this
  _getStylesFromXml(xml) {
    const defs = xml.getElementsByTagName('defs')
    if (defs.length === 0) {
      return []
    }

    const styles = defs[0].getElementsByTagName('style')
    if (styles.length === 0) {
      return []
    }

    return styles
  }

  // eslint-disable-next-line class-methods-use-this
  _getStyleValueFromStyle(outerStyle) {
    let value = ''
    if (outerStyle.childNodes && outerStyle.childNodes.length !== 0) {
      outerStyle.childNodes.forEach(child => {
        if (child.nodeValue.trim()) {
          value += child.nodeValue
        }
      })

      return value
    }

    return value
  }

  getDOMParser() {
    if (!this.domParser) {
      this.domParser = new DOMParser().parseFromString(
        this.svgContent,
        'text/xml'
      )
    }

    return this.domParser
  }

  _parseSvgAndCache() {
    const styles = this._getStylesFromXml(this.getDOMParser())

    const results = {}

    for (let i = 0; i < styles.length; i += 1) {
      const style = this._getStyleValueFromStyle(styles[i])
      if (style) {
        let classMath

        // eslint-disable-next-line no-cond-assign
        while ((classMath = REG_EX_STYLE.exec(style)) !== null) {
          if (classMath.length === 3) {
            const className = classMath[1]

            results[className] = get(results, className, {})

            let styleAttributeMatch

            // eslint-disable-next-line no-cond-assign
            while (
              (styleAttributeMatch = REG_EX_ONE_MATCH_PATTERN.exec(
                classMath[2]
              )) !== null
            ) {
              if (styleAttributeMatch.length === 3) {
                const attributeName = styleAttributeMatch[1].toLowerCase()
                results[className][attributeName] = styleAttributeMatch[2] // eslint-disable-line
              }
            }
          }
        }
      }
    }

    this._styleAttrs = results // cache result
    return this._styleAttrs
  }

  optimizeSvg() {
    const xml = this.getDOMParser()
    const svgElements = xml.getElementsByTagName('svg')

    if (svgElements.length === 0) {
      return
    }

    const svg = svgElements[0]

    const widthInPx = svg.getAttribute('width')
    const heightInPx = svg.getAttribute('height')

    if (!widthInPx || !heightInPx) {
      return
    }

    const width = parseInt(widthInPx, 10)
    const height = parseInt(heightInPx, 10)

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(width) || isNaN(height) || width <= 0 || height <= 0) {
      return
    }

    let { maxWidth, maxHeight } = configs.data.printingSize

    if (
      configs.data.generatorMode &&
      configs.data.printingSize.generatorMaxHeight &&
      configs.data.printingSize.generatorMaxWidth
    ) {
      maxWidth = configs.data.printingSize.generatorMaxWidth
      maxHeight = configs.data.printingSize.generatorMaxHeight
    }

    const dimensions = calculateOptimalImageDimensions(
      width,
      height,
      maxWidth,
      maxHeight
    )

    this.scaledMultiplicand = calculateScaleMultiplicand(width, height)

    /**
      [BUG] optimizer doesn't consider type of units other than pixels.
     Hard to fix due to older orders and their sprites scale
     */
    svg.setAttribute('width', `${dimensions.width}px`)
    svg.setAttribute('height', `${dimensions.height}px`)
  }
}

export default SvgParser
