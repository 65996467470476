import { connect } from 'react-redux'
import {
  TECHNOLOGY_DIGITAL,
  TECHNOLOGY_ENGRAVING,
  TECHNOLOGY_UV,
  TECHNOLOGY_STAMPS
} from '../../utils/technologies'

export const withActiveTechnology = Component =>
  connect(({ product: { active: { technology } } }) => ({
    isActiveDigitalTechnology: technology === TECHNOLOGY_DIGITAL,
    isActiveStampTechnology: technology === TECHNOLOGY_STAMPS,
    isActiveEngravingTechnology: technology === TECHNOLOGY_ENGRAVING,
    isActiveUVTechnology: technology === TECHNOLOGY_UV
  }))(Component)
