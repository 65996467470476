import React from 'react'
import Menu from './index'
import Console from '../../utils/console'
import { MenuContext } from './MenuContext'

// fixme: menuRef is saved in Redux -> update component
class MenuWithCallbackComponent extends React.Component {
  static propTypes = {}

  static defaultProps = {}

  constructor(props) {
    super(props)
    this.onDone = this.onDone.bind(this)
    this.menuRef = React.createRef()
  }

  onDone() {
    this.hideMenu()
  }

  hideMenu() {
    if (this.menuRef && this.menuRef.current) {
      if (typeof this.menuRef.current.hideMenu !== 'function') {
        Console.error('ref', 'menuRef.current.hideMenu is not function')
        return
      }
      this.menuRef.current.hideMenu()
    }
  }

  render() {
    return (
      <MenuContext.Provider value={{ onDone: this.onDone }}>
        <Menu {...this.props} menuRef={this.menuRef} />
      </MenuContext.Provider>
    )
  }
}

export default MenuWithCallbackComponent
