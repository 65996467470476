import { getApiCart } from '../../../constants/api'
import { fetchWithBasicAuth } from '../../utils/fetch'
import Extract from '../../../module/Extract'
import { getAppropriatePreviewOfProduct } from './GetAppropriatePreviewOfProduct'
import { technologyToString } from '../../utils/technologies'

/**
 *
 * @param {{id_user: string, id_design: string, currency: string, combinations: Array, motives: Array, ownMotives: Array, ownTexts: Array}} data
 */
export function addToCart(data) {
  return fetchWithBasicAuth(getApiCart(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
}

export class DataBuilder {
  constructor() {
    this.data = { combinations: [] }
  }

  setUserId(id) {
    this.data.id_user = id
    return this
  }

  setRemoveBg(removeBg) {
    this.data.removeBg = removeBg
    return this
  }

  setDesignId(id) {
    this.data.id_design = id
    return this
  }

  addCombination(id, quantity) {
    this.data.combinations.push({ id_combination: id, quantity })
    return this
  }

  addCurrentDataToOrder() {
    this.data = {
      ...this.data,
      ...Extract.data(true)
    }
  }

  addTechnology(technology) {
    this.data.technology = technologyToString(technology)
  }

  findAndSetAppropriateThumbnail() {
    return getAppropriatePreviewOfProduct().then(base64 => {
      if (base64) {
        this.data = {
          ...this.data,
          thumb: base64
        }
      }

      return this
    })
  }

  build() {
    return this.data
  }
}
