/**
 *
 * @class
 * @memberOf Core
 */
class Rect {
  /**
   *
   * @public
   * @type number
   */
  x

  /**
   *
   * @public
   * @type number
   */
  y

  /**
   *
   * @public
   * @type number
   */
  width

  /**
   *
   * @public
   * @type number
   */
  height

  /**
   *
   * @param {Core.Rect} rect
   * @returns {Core.Rect}
   * @constructor
   */
  static Copy(rect) {
    return new Rect({
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height
    })
  }

  constructor(...args) {
    switch (args.length) {
      case 1: {
        const [{ x, y, width, height }] = args
        this.x = x
        this.y = y

        this.width = width
        this.height = height

        break
      }

      case 4: {
        const [x, y, width, height] = args
        this.x = x
        this.y = y

        this.width = width
        this.height = height
        break
      }

      default: {
        throw new Error(`Wrongs number of arguments for Rect ${args.length}`)
      }
    }
  }
}

export default Rect
