import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PixiActionComponent from '../../components/PixiActionComponent'
import Observer from '../../pixi/helper/Observer'
import Console from '../../utils/console'
import Tools from '../../utils/Tools'
import { updateTextColor } from '../../../actions/editor'
import EditorManager from '../../editor/EditorManager'

export const actionObserver = new Observer()

export const types = {
  DISPLAY_COLOR_PICKER: 'DISPLAY_COLOR_PICKER',
  DISPLAY_MENU_TEXT: 'DISPLAY_MENU_TEXT',
  STATE_OF_OBJECT_ZONE: 'STATE_OF_OBJECT_ZONE'
}

export const notify = {
  displayColorPicker: (x, y) => {
    actionObserver.notifyAll({ type: types.DISPLAY_COLOR_PICKER, x, y })
  },
  displayMenuText: () =>
    actionObserver.notifyAll({ type: types.DISPLAY_MENU_TEXT }),
  stateOfObjectZone: outOfZone =>
    actionObserver.notifyAll({ type: types.STATE_OF_OBJECT_ZONE, outOfZone })
}

class _PixiActionContainer extends React.Component {
  state = {
    colorPicker: {
      x: 0,
      y: 0,
      visible: false
    },
    objectOutOfZone: false
  }

  static propTypes = {
    canvasRef: PropTypes.object,
    updateColor: PropTypes.func.isRequired,
    activeSprite: PropTypes.object
  }

  static defaultProps = {
    canvasRef: null,
    activeSprite: null
  }

  constructor(props) {
    super(props)

    this.observer = this.observer.bind(this)
    this.onColorPick = this.onColorPick.bind(this)

    actionObserver.subscribe(this.observer, this)
  }

  componentWillUnmount() {
    actionObserver.unsubscribe(this.observer)
  }

  onColorPick(value) {
    if (value) {
      const { updateColor } = this.props
      updateColor(value)
      EditorManager.getActiveProductEditor().forceUpdateActiveObject()
    }
    this.setState({ colorPicker: { visible: false } })
  }

  observer(data) {
    switch (data.type) {
      case types.DISPLAY_COLOR_PICKER: {
        const { canvasRef } = this.props

        const position =
          canvasRef && canvasRef.current
            ? Tools.getPageTopLeft(canvasRef.current)
            : { left: 0, top: 0 }

        const colorPicker = {
          x: data.x + position.left,
          y: data.y + position.top,
          visible: true
        }

        this.setState({ colorPicker })
        break
      }
      case types.STATE_OF_OBJECT_ZONE: {
        const { objectOutOfZone } = this.state
        if (data.outOfZone !== objectOutOfZone) {
          this.setState({ objectOutOfZone: data.outOfZone })
        }
        break
      }
      default: {
        Console.error('ActionContainer', `Unexpected data type ${data.type}`)
      }
    }
  }

  render() {
    const { colorPicker, objectOutOfZone } = this.state
    const { activeSprite } = this.props

    return (
      <PixiActionComponent
        colorPicker={colorPicker}
        onColorPick={this.onColorPick}
        objectOutOfZone={!!activeSprite && objectOutOfZone}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateColor: updateTextColor
    },
    dispatch
  )
}

const mapStateToProps = state => ({
  activeSprite: state.spritesContainer.activeSprite
})

const PixiActionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(_PixiActionContainer)

export { PixiActionContainer }
