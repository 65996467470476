import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import { PixiController } from '../../PixiController'

function findDisplayObjectsBorders(sprites) {
  return {
    left: minBy(sprites, sprite => sprite.left).left,
    right: maxBy(sprites, sprite => sprite.right).right,
    top: minBy(sprites, sprite => sprite.top).top,
    bottom: maxBy(sprites, sprite => sprite.bottom).bottom
  }
}

export function getSpritesBorderInfo() {
  return findDisplayObjectsBorders(
    PixiController.spriteContainer.children
      .filter(sprite => !sprite.MASK_FLAG)
      .map(sprite => sprite.getBounds())
  )
}

export function getCanvasExtraInfo() {
  const activeZone = PixiController.spriteContainer.children.find(
    sprite => sprite.MASK_FLAG
  )

  if (!PixiController.spriteCopiesContainer.children.length) {
    throw new Error('No sprites added.')
  }

  if (!activeZone) {
    throw new Error('Active zone was not added')
  }

  const canvasBorder = findDisplayObjectsBorders([activeZone.getBounds()])

  const spritesBorder = getSpritesBorderInfo()

  const x =
    canvasBorder.left > spritesBorder.left
      ? canvasBorder.left - spritesBorder.left
      : 0

  const y =
    canvasBorder.top > spritesBorder.top
      ? canvasBorder.top - spritesBorder.top
      : 0

  const points = {
    left: Math.max(canvasBorder.left, spritesBorder.left),
    top: Math.max(canvasBorder.top, spritesBorder.top),
    bottom: Math.min(canvasBorder.bottom, spritesBorder.bottom),
    right: Math.min(canvasBorder.right, spritesBorder.right)
  }

  const width = points.right - points.left
  const height = points.bottom - points.top

  return {
    extractPoints: {
      x,
      y
    },
    activeZones: {
      width,
      height
    },
    zonesBorderInfo: canvasBorder,
    spritesBorderInfo: spritesBorder
  }
}
