import flow from 'lodash/flow'
import { Sprite, Graphics } from 'pixi.js-legacy'
import { calculateRatioByMaxDimensions } from './calculateRatioByMaxDimensions'

/**
 * @class
 * @namespace Tools
 */
class Tools {
  static calculateScale(target, mousePointer, startPosition) {
    const distanceFromTargetToStartPoint = Math.sqrt(
      (target.x - startPosition.x) * (target.x - startPosition.x) +
        (target.y - startPosition.y) * (target.y - startPosition.y)
    )
    const distanceFromTargetToMouse = Math.sqrt(
      (target.x - mousePointer.x) * (target.x - mousePointer.x) +
        (target.y - mousePointer.y) * (target.y - mousePointer.y)
    )

    return distanceFromTargetToMouse / distanceFromTargetToStartPoint
  }

  static calculateAngle(target, mousePointer, startRotation) {
    const angle =
      Math.atan2(target.y - mousePointer.y, target.x - mousePointer.x) -
      Math.atan2(target.height, target.width)
    return (angle + startRotation) % (2 * Math.PI)
  }

  static getPageTopLeft(el) {
    const rect = el.getBoundingClientRect()
    const docEl = document.documentElement
    return {
      left: rect.left + (window.pageXOffset || docEl.scrollLeft || 0),
      top: rect.top + (window.pageYOffset || docEl.scrollTop || 0)
    }
  }

  static createControl(imageSource, scale = 0.5) {
    const pixiSprite = Sprite.from(imageSource)
    pixiSprite.anchor.x = 0.5
    pixiSprite.anchor.y = 0.5
    pixiSprite.scale.set(scale)
    pixiSprite.interactive = true
    pixiSprite.buttonMode = true

    return pixiSprite
  }

  static createCircle(color = 0xffffff) {
    const circle = new Graphics()
    circle.lineStyle(1, 0xcccccc, 1, 0.5, true)
    circle.beginFill(color, 1)
    circle.drawCircle(0, 0, 10)
    circle.endFill()

    return circle
  }

  static resolveImage(image) {
    return new Promise((resolve, reject) => {
      if (typeof image === 'string') {
        const img = new Image()
        img.src = image
        img.onerror = () => {
          reject(new Error('Image loading failed.'))
        }

        img.onload = () => {
          resolve(img)
        }
      } else {
        resolve(image)
      }
    })
  }

  static thumbnailWithMaxDimensions(
    base64,
    maxWidth = 500,
    maxHeight = 500,
    backgroundColor = undefined
  ) {
    return Tools.resolveImage(base64).then(img => {
      // Determine new ratio based on max size
      const ratio = calculateRatioByMaxDimensions(
        img.width,
        img.height,
        maxWidth,
        maxHeight
      )

      return Tools.thumbnail(img, ratio, backgroundColor)
    })
  }

  static thumbnail(image, ratio, backgroundColor) {
    return Tools.resolveImage(image).then(img => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      // Copy and resize second canvas to first canvas
      canvas.width = img.width * ratio
      canvas.height = img.height * ratio

      if (backgroundColor) {
        ctx.fillStyle = backgroundColor
        ctx.fillRect(0, 0, canvas.width, canvas.height)
      }

      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        canvas.width,
        canvas.height
      )

      return canvas
    })
  }

  static addClass(object, className) {
    const classes = !object.className ? [] : object.className.split(' ')

    if (!classes.find(name => name === className)) {
      // eslint-disable-next-line no-param-reassign
      object.className = `${object.className}${
        classes.length > 0 ? ' ' : ''
      }${className}`
    }
  }

  static removeClass(object, className) {
    const classes = !object.className ? [] : object.className.split(' ')
    const index = classes.indexOf(className)

    if (index !== -1) {
      // eslint-disable-next-line no-param-reassign
      object.className = classes.splice(index, 1).join(' ')
    }
  }

  static preConnectTo(url) {
    const hint = document.createElement('link')
    hint.rel = 'preconnect'
    hint.href = url
    document.head.appendChild(hint)
  }

  static arrayMoveElement(array, from, to) {
    array.splice(to, 0, array.splice(from, 1)[0])
    return array
  }

  static isLinkSvg(link) {
    return (
      typeof link === 'string' &&
      flow([
        imageName => imageName.split('.'),
        data => data[data.length - 1],
        extension => extension.toLowerCase(),
        extension => extension === 'svg'
      ])(link)
    )
  }

  static getIndexOfSpriteOnSide(sprite, spritesContainer) {
    const side = sprite.sideIndex
    let index = -1

    spritesContainer.find(spriteInContainer => {
      if (spriteInContainer.sideIndex === side) {
        index += 1
      }

      return spriteInContainer.uuid === sprite.uuid
    })

    return index
  }
}

export default Tools
