import React from 'react'
import PropTypes from 'prop-types'
import Select, { mergeStyles } from 'react-select'
import { isMobile } from '../../utils/device-detect'

const colorStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  dropdownIndicator: styles => ({ ...styles, color: 'black' })
}

const SmartSelect = ({ styles, ...props }) => (
  <Select
    {...props}
    classNames="disable-propagation"
    styles={mergeStyles(colorStyles, styles)}
    isSearchable={!isMobile}
  />
)

SmartSelect.propTypes = {
  styles: PropTypes.object
}

SmartSelect.defaultProps = {
  styles: {}
}

export default SmartSelect
