import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import SimpleColorPickerComponent, { POSITION } from '../ColorPickerComponent'
import { OutOfZoneComponent } from '../OutOfZoneComponent'

/**
 * @return {null}
 */
function PixiActionComponent({ colorPicker, onColorPick, objectOutOfZone }) {
  if (!colorPicker) {
    return null
  }

  return (
    <>
      {objectOutOfZone && <OutOfZoneComponent />}
      {colorPicker.visible && (
        <SimpleColorPickerComponent
          x={colorPicker.x}
          y={colorPicker.y}
          onClick={onColorPick}
          title={t('select.color', 'Select color')}
          automaticPosition
          clickedElementHeight={20}
          clickedElementWidth={10}
          defaultHorizontalPosition={POSITION.LEFT}
          defaultVerticalPosition={POSITION.BOTTOM}
        />
      )}
    </>
  )
}

PixiActionComponent.propTypes = {
  colorPicker: PropTypes.object,
  onColorPick: PropTypes.func,
  objectOutOfZone: PropTypes.bool
}

PixiActionComponent.defaultProps = {
  colorPicker: null,
  onColorPick: f => f,
  objectOutOfZone: false
}

export default PixiActionComponent
