import WebFont from 'webfontloader'
import { compact } from 'lodash/array'
import EditorManager from '../editor/EditorManager'
import { FONT_TYPE } from '../../module/Constants'

export function getWebFontsByType(type = FONT_TYPE.DEFAULT) {
  switch (type) {
    case FONT_TYPE.CYRILLIC:
      return [
        {
          webFont: 'Roboto::cyrillic',
          value: 'Roboto',
          label: 'Roboto'
        },
        {
          webFont: 'Montserrat::cyrillic',
          value: 'Montserrat',
          label: 'Montserrat'
        },
        {
          webFont: 'Merriweather::cyrillic',
          value: 'Merriweather',
          label: 'Merriweather'
        },
        {
          webFont: 'Fira+Sans::cyrillic',
          value: 'Fira Sans',
          label: 'Fira Sans'
        },
        {
          webFont: 'Lobster::cyrillic',
          value: 'Lobster',
          label: 'Lobster'
        },
        {
          webFont: 'Comfortaa::cyrillic',
          value: 'Comfortaa',
          label: 'Comfortaa'
        },
        {
          webFont: 'Alegreya::cyrillic',
          value: 'Alegreya',
          label: 'Alegreya'
        },
        {
          webFont: 'Fira+Sans+Extra+Condensed::cyrillic',
          value: 'Fira Sans Condensed',
          label: 'Fira Sans Condensed'
        },
        {
          webFont: 'Philosopher::cyrillic',
          value: 'Philosopher',
          label: 'Philosopher'
        },
        {
          webFont: 'Montserrat+Alternates::cyrillic',
          value: 'Montserrat Alternates',
          label: 'Montserrat Alternates'
        },
        {
          webFont: 'Alice::cyrillic',
          value: 'Alice',
          label: 'Alice'
        },
        {
          webFont: 'Jura::cyrillic',
          value: 'Jura',
          label: 'Jura'
        },
        {
          webFont: 'Kurale::cyrillic',
          value: 'Kurale',
          label: 'Kurale'
        },
        {
          webFont: 'Yeseva+One::cyrillic',
          value: 'Yeseva',
          label: 'Yeseva'
        },
        {
          webFont: 'Cormorant+Infant::cyrillic',
          value: 'Cormorant Infant',
          label: 'Cormorant Infant'
        },
        {
          webFont: 'Podkova::cyrillic',
          value: 'Podkova',
          label: 'Nosifer'
        },
        {
          webFont: 'Cormorant+Unicase::cyrillic',
          value: 'Cormorant Unicase',
          label: 'Cormorant Unicase'
        }
      ]

    case FONT_TYPE.STAMPS: {
      const fonts = getWebFontsByType(FONT_TYPE.DEFAULT)
      return compact(
        [
          'Open Sans',
          'Josefin Sans',
          'Signika',
          'Alegreya sans',
          'Montserrat alternates'
        ].map(font => fonts.find(defaultFont => font === defaultFont.value))
      )
    }

    case FONT_TYPE.MATS: {
      const fonts = getWebFontsByType(FONT_TYPE.DEFAULT)
      return compact(
        [
          'Marcellus SC',
          'Patrick Hand',
          'Jockey One',
          'New Rocker',
          'Sonsie One',
          'Armata',
          'Cantora One',
          'Glass Antiqua',
          'Ruslan Display',
          'Patrick Hand SC',
          'Life Savers',
          'Russo One',
          'Racing Sans One',
          'Autour One',
          'Audiowide',
          'Titan One',
          'Joti One',
          'Alegreya SC',
          'Courgette',
          'Quando',
          'Oregano',
          'Sancreek',
          'Black Ops One',
          'Press Start 2P',
          'Freckle Face'
        ].map(font => fonts.find(defaultFont => font === defaultFont.value))
      )
    }

    default:
      return [
        {
          webFont: 'Nosifer::latin-ext',
          value: 'Nosifer',
          label: 'Nosifer'
        },
        {
          webFont: 'Marcellus+SC::latin-ext',
          value: 'Marcellus SC',
          label: 'Marcellus SC'
        },
        {
          webFont: 'Patrick+Hand::latin-ext',
          value: 'Patrick Hand',
          label: 'Patrick Hand'
        },
        {
          webFont: 'Jockey+One::latin-ext',
          value: 'Jockey One',
          label: 'Jockey One'
        },
        {
          webFont: 'Hanalei+Fill::latin-ext',
          value: 'Hanalei Fill',
          label: 'Hanalei Fill'
        },
        {
          webFont: 'Eater::latin-ext',
          value: 'Eater',
          label: 'Eater'
        },
        {
          webFont: 'New+Rocker::latin-ext',
          value: 'New Rocker',
          label: 'New Rocker'
        },
        {
          webFont: 'Sonsie+One::latin-ext',
          value: 'Sonsie One',
          label: 'Sonsie One'
        },
        {
          webFont: 'Croissant+One::latin-ext',
          value: 'Croissant One',
          label: 'Croissant One'
        },
        {
          webFont: 'Quintessential::latin-ext',
          value: 'Quintessential',
          label: 'Quintessential'
        },
        {
          webFont: 'Armata::latin-ext',
          value: 'Armata',
          label: 'Armata'
        },
        {
          webFont: 'Cantora+One::latin-ext',
          value: 'Cantora One',
          label: 'Cantora One'
        },
        {
          webFont: 'Glass+Antiqua::latin-ext',
          value: 'Glass Antiqua',
          label: 'Glass Antiqua'
        },
        {
          webFont: 'Ruslan+Display::latin-ext',
          value: 'Ruslan Display',
          label: 'Ruslan Display'
        },
        {
          webFont: 'Patrick+Hand+SC::latin-ext',
          value: 'Patrick Hand SC',
          label: 'Patrick Hand SC'
        },
        {
          webFont: 'Life+Savers::latin-ext',
          value: 'Life Savers',
          label: 'Life Savers'
        },
        {
          webFont: 'Butcherman::latin-ext',
          value: 'Butcherman',
          label: 'Butcherman'
        },
        {
          webFont: 'Russo+One::latin-ext',
          value: 'Russo One',
          label: 'Russo One'
        },
        {
          webFont: 'Racing+Sans+One::latin-ext',
          value: 'Racing Sans One',
          label: 'Racing Sans One'
        },
        {
          webFont: 'Grand+Hotel::latin-ext',
          value: 'Grand Hotel',
          label: 'Grand Hotel'
        },
        {
          webFont: 'Dynalight::latin-ext',
          value: 'Dynalight',
          label: 'Dynalight'
        },
        {
          webFont: 'Clicker+Script::latin-ext',
          value: 'Clicker Script',
          label: 'Clicker Script'
        },
        {
          webFont: 'Autour+One::latin-ext',
          value: 'Autour One',
          label: 'Autour One'
        },
        {
          webFont: 'Audiowide::latin-ext',
          value: 'Audiowide',
          label: 'Audiowide'
        },
        {
          webFont: 'Titan+One::latin-ext',
          value: 'Titan One',
          label: 'Titan One'
        },
        {
          webFont: 'Purple+Purse::latin-ext',
          value: 'Purple Purse',
          label: 'Purple Purse'
        },
        {
          webFont: 'Joti+One::latin-ext',
          value: 'Joti One',
          label: 'Joti One'
        },
        {
          webFont: 'Alegreya+SC::latin-ext',
          value: 'Alegreya SC',
          label: 'Alegreya SC'
        },
        {
          webFont: 'Fruktur::latin-ext',
          value: 'Fruktur',
          label: 'Fruktur'
        },
        {
          webFont: 'Courgette::latin-ext',
          value: 'Courgette',
          label: 'Courgette'
        },
        {
          webFont: 'Quando::latin-ext',
          value: 'Quando',
          label: 'Quando'
        },
        {
          webFont: 'Oregano::latin-ext',
          value: 'Oregano',
          label: 'Oregano'
        },
        {
          webFont: 'Sancreek::latin-ext',
          value: 'Sancreek',
          label: 'Sancreek'
        },
        {
          webFont: 'Akronim::latin-ext',
          value: 'Akronim',
          label: 'Akronim'
        },
        {
          webFont: 'Hanalei::latin-ext',
          value: 'Hanalei',
          label: 'Hanalei'
        },
        {
          webFont: 'Black+Ops+One::latin-ext',
          value: 'Black Ops One',
          label: 'Black Ops One'
        },
        {
          webFont: 'Raleway+Dots::latin-ext',
          value: 'Raleway Dots',
          label: 'Raleway Dots'
        },
        {
          webFont: 'Plaster::latin-ext',
          value: 'Plaster',
          label: 'Plaster'
        },
        {
          webFont: 'Press+Start+2P::latin-ext',
          value: 'Press Start 2P',
          label: 'Press Start 2P'
        },
        {
          webFont: 'Freckle+Face::latin-ext',
          value: 'Freckle Face',
          label: 'Freckle Face'
        },
        {
          webFont: 'Alegreya+Sans+SC::latin-ext',
          value: 'Alegreya Sans SC',
          label: 'Alegreya Sans SC'
        },
        {
          webFont: 'Josefin+Sans::latin-ext',
          value: 'Josefin Sans',
          label: 'Josefin Sans'
        },
        {
          webFont: 'Montserrat+Alternates::latin-ext',
          value: 'Montserrat Alternates',
          label: 'Montserrat Alternates'
        },
        {
          webFont: 'Open+Sans::latin-ext',
          value: 'Open Sans',
          label: 'Open Sans'
        },
        {
          webFont: 'Signika::latin-ext',
          value: 'Signika',
          label: 'Signika'
        }
      ]
  }
}

function getAllFonts() {
  return [
    ...getWebFontsByType(FONT_TYPE.DEFAULT),
    ...getWebFontsByType(FONT_TYPE.CYRILLIC)
  ]
}

const loadedFonts = []

export function loadFonts(myFonts, callback) {
  let getFontsToLoad

  const fonts = getAllFonts()

  if (Array.isArray(myFonts)) {
    getFontsToLoad = myFonts.map(myFont => {
      const foundFont = fonts.find(font => myFont === font.value)

      if (!foundFont) {
        return undefined
      }

      if (loadedFonts.find(font => font === foundFont.webFont)) {
        return undefined
      }

      return foundFont.webFont
    })
  } else {
    getFontsToLoad = fonts.map(font => {
      if (loadedFonts.find(loadedFont => loadedFont === font.webFont)) {
        return undefined
      }

      return font.webFont
    })
  }

  const fontsToLoad = compact(getFontsToLoad)
  loadedFonts.push(...fontsToLoad)

  if (!fontsToLoad.length) {
    if (callback) {
      callback()
    }
    return
  }

  WebFont.load({
    google: {
      families: fontsToLoad
    },
    active: () => {
      if (!EditorManager.isProductEditorActive()) {
        if (callback) {
          callback()
        }
        return
      }

      EditorManager.getActiveProductEditor().invalidateContent()
      if (callback) {
        callback()
      }
    },
    classes: false
  })
}

export function loadAllFonts() {
  loadFonts()
}
