import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import PreviewImageContainer from '../../../core-module/containers/PreviewImageContainer'
import configs from '../../../module/config'

class FeaturedProductComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      image: { width: 0, height: 0 }
    }

    this.previewRef = React.createRef()
  }

  render() {
    const {
      active,
      onClick,
      onKeyPress,
      name,
      price,
      image,
      areas,
      allowPreview,
      className,
      displayPrice
    } = this.props
    const {
      image: { width, height }
    } = this.state
    return (
      <div
        tabIndex={-1}
        role="option"
        className={classNames(className, { active })}
        onClick={() =>
          onClick(this.previewRef.current.canMoveObjectsToCenter())
        }
        onKeyPress={onKeyPress}
        aria-selected={active}>
        <div>
          {image ? (
            <img
              alt={t('Product preview')}
              src={image}
              onLoad={({ target: img }) =>
                this.setState({
                  image: { width: img.clientWidth, height: img.clientHeight }
                })
              }
            />
          ) : null}
          {allowPreview && image && width && height ? (
            <PreviewImageContainer
              width={width}
              height={height}
              areas={areas}
              ref={this.previewRef}
            />
          ) : null}
        </div>
        <div>
          <h4 className="title">{name}</h4>
          {displayPrice ? (
            <span>
              {`${price.toFixed(configs.data.currency.decimalPlaces)} ${
                configs.data.currency.symbol
              }`}
            </span>
          ) : null}
        </div>
      </div>
    )
  }
}

FeaturedProductComponent.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  name: PropTypes.string,
  price: PropTypes.number,
  image: PropTypes.string,
  areas: PropTypes.array,
  allowPreview: PropTypes.bool,
  displayPrice: PropTypes.bool,
  className: PropTypes.string
}

FeaturedProductComponent.defaultProps = {
  active: false,
  onClick: f => f,
  onKeyPress: f => f,
  name: '',
  price: 0,
  image: '',
  areas: [],
  allowPreview: true,
  className: '',
  displayPrice: true
}

export default FeaturedProductComponent
