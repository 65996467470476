const TECHNOLOGY_STAMPS = 0
const TECHNOLOGY_ENGRAVING = 1
const TECHNOLOGY_DIGITAL = 2
const TECHNOLOGY_UV = 3
const TECHNOLOGY_SUBLIMITY= 4 

const TECHNOLOGY_ERROR = -1

const MATERIAL_WOOD = 'wood'
const MATERIAL_METAL = 'metal'
const MATERIAL_GLASS = 'glass'

export function technologyToString(technology) {
  switch (technology) {
    case TECHNOLOGY_DIGITAL:
      return 'digital'
    case TECHNOLOGY_STAMPS:
      return 'stamps'
    case TECHNOLOGY_ENGRAVING:
      return 'engraving'
    case TECHNOLOGY_UV:
      return 'uv'
    case TECHNOLOGY_SUBLIMITY:
      return 'sublimity'
    default:
      return 'unknown'
  }
}

export {
  TECHNOLOGY_DIGITAL,
  TECHNOLOGY_ENGRAVING,
  TECHNOLOGY_ERROR,
  TECHNOLOGY_UV,
  TECHNOLOGY_STAMPS,
  TECHNOLOGY_SUBLIMITY,
  MATERIAL_GLASS,
  MATERIAL_METAL,
  MATERIAL_WOOD
}
