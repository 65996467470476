export function sortEditorData(data) {
  const finalResult = []

  if (data.motives) {
    data.motives.forEach(sprite => {
      finalResult[sprite.order] = { sprite }
    })
  }

  if (data.myMotives) {
    data.myMotives.forEach(sprite => {
      finalResult[sprite.order] = { mySprite: sprite }
    })
  }

  if (data.texts) {
    data.texts.forEach(text => {
      finalResult[text.order] = { text }
    })
  }

  return finalResult
}
