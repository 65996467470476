import { calculateOptimalImageDimensions } from '../core-module/utils/calculateRatioByMaxDimensions'

/**
 * Class with extra functions
 *
 * @class
 * @memberOf Creator
 */

class Extra {
  /**
   * Calculate optimal image dimensions used in Canvas
   *
   * @param {number} width
   * @param {number} height
   * @param {number} maxWidth
   * @param {number} maxHeight
   * @returns {{width: number, height: number, ratio: number}}
   */
  static calculateOptimalImageDimensions(width, height, maxWidth, maxHeight) {
    return calculateOptimalImageDimensions(width, height, maxWidth, maxHeight)
  }
}

export default Extra
