/**
 * This namespace provides constants.
 *
 * @example
 * Print constant
 * console.log(Creator.Constants.PANEL.LEFT)
 *
 *
 * @namespace Creator.Constants
 */

/**
 * @name PANEL
 * @constant
 * @property {number} LEFT ( only web )
 * @property {number} RIGHT ( only web )
 * @property {number} INFO
 * @property {number} FEATURED_PRODUCTS
 * @property {number} SIMILAR_PRODUCTS
 * @property {number} MOBILE_MENU ( only mobile )
 * @property {number} MOBILE_PRICE_FOOTER ( only mobile )
 * @memberOf Creator.Constants
 */
export const PANEL = {
  LEFT: 0,
  RIGHT: 2,
  INFO: 3,
  FEATURED_PRODUCTS: 4,
  SIMILAR_PRODUCTS: 7,
  MOBILE_MENU: 5,
  MOBILE_PRICE_FOOTER: 6
}

/**
 * @name MENU
 * @constant
 * @property {number} INSERT_MOTIVE
 * @property {number} INSERT_MY_MOTIVE
 * @property {number} INSERT_TEXT
 * @property {number} PRODUCTS
 * @property {number} MOBILE_COLORS ( only mobile )
 * @memberOf Creator.Constants
 */
export const MENU = {
  INSERT_MOTIVE: 0,
  INSERT_MY_MOTIVE: 1,
  INSERT_TEXT: 2,
  PRODUCTS: 3,
  MOBILE_COLORS: 4,
  INSERT_TEMPLATE: 5
}

/**
 * @name RIGHT_MENU
 * @constant
 * @property {number} PRODUCT_INFO
 * @property {number} PRODUCT_COLORS
 * @property {number} PRODUCT_SIZES
 * @property {number} PRODUCT_PRICE
 * @property {number} PRODUCT_QUANTITY
 * @property {number} SAVE_DESIGN
 * @property {number} INSERT_TO_CART
 * @memberOf Creator.Constants
 */
export const RIGHT_MENU = {
  PRODUCT_INFO: 0,
  PRODUCT_COLORS: 1,
  PRODUCT_SIZES: 2,
  PRODUCT_PRICE: 3,
  PRODUCT_QUANTITY: 4,
  SAVE_DESIGN: 5,
  INSERT_TO_CART: 6
}

/**
 * @name COLOR_TYPE
 * @property {number} UNDEFINED
 * @property {number} HEX
 * @property {number} IMAGE
 * @memberOf Creator.Constants
 */
export const COLOR_TYPE = {
  UNDEFINED: 0,
  HEX: 1,
  IMAGE: 2
}

/**
 * @name FONT_TYPE
 * @property {number} DEFAULT
 * @property {number} CYRILLIC
 * @property {number} STAMPS
 * @property {number} MATS
 * @memberOf Creator.Constants
 */
export const FONT_TYPE = {
  DEFAULT: 0,
  CYRILLIC: 1,
  STAMPS: 2,
  MATS: 3
}

export default {
  PANEL,
  MENU,
  RIGHT_MENU,
  COLOR_TYPE,
  FONT_TYPE
}
