import Observer from '../../pixi/helper/Observer'
import Console from '../../utils/console'
import { PixiController } from '../../pixi/PixiController'
import EditorManager from '../../editor/EditorManager'

let numberOfReadyObjects = 0
let numberOfAllObjects = 0

const fullyLoadedEditorIncludedDataAndFontsObserver = new Observer()

const isEditorReadyIncludedDataAndFonts = {
  error: false,
  loaded: false
}

function createReadyObject() {
  const data = {
    error: false,
    loaded: false
  }

  function set(loaded, error) {
    data.error = error
    data.loaded = loaded
  }

  return {
    data,
    set
  }
}

const readyObjects = {
  font: createReadyObject(),
  data: createReadyObject(),
  waitingToStart: createReadyObject()
}

function setFontReady(ready = true, error = false) {
  Console.log('FONTS', 'Necessary web fonts are ready')
  readyObjects.font.set(ready, error)
  notifyIfReadyOrFailed()
}

function setDataReady(ready = true, error = false) {
  readyObjects.data.set(ready, error)
  notifyIfReadyOrFailed()
}

function setStartReady() {
  readyObjects.waitingToStart.set(true)
  notifyIfReadyOrFailed()
}

function setDataFailed(message = '') {
  Console.error('EditorLoading', message)

  setDataReady(true, true)
}

function setEditorToLoading() {
  isEditorReadyIncludedDataAndFonts.loaded = false
  isEditorReadyIncludedDataAndFonts.error = false
}

function notifyIfReadyOrFailed() {
  let error = false
  let notLoaded = false

  Object.keys(readyObjects).forEach(function(key) {
    error |= readyObjects[key].data.error
    notLoaded |= !readyObjects[key].data.loaded
  })

  if (error || !notLoaded) {
    isEditorReadyIncludedDataAndFonts.error = !!error
    isEditorReadyIncludedDataAndFonts.loaded = !notLoaded

    fullyLoadedEditorIncludedDataAndFontsObserver.notifyAll(
      isEditorReadyIncludedDataAndFonts,
      EditorManager.isProductEditorActive()
        ? EditorManager.getActiveProductEditor().getActiveSide().index
        : undefined
    )
  }
}

function updateNumberOfReadyObjects() {
  if (!EditorManager.isProductEditorActive()) {
    numberOfReadyObjects = 0
    return
  }

  const sideIndex = EditorManager.getActiveProductEditor().getActiveSide().index
  const editor = EditorManager.getActiveProductEditor()

  let addMainSprite = 0

  if (
    !editor.mainSpriteLoading &&
    PixiController.isMainSpriteReady &&
    editor.mainSprite.sideIndex === sideIndex
  ) {
    addMainSprite = 1
  }

  numberOfReadyObjects =
    PixiController.numberOfUniqueSprites(sideIndex) + addMainSprite
}

function graphicUpdateListener() {
  updateNumberOfReadyObjects()

  if (
    numberOfReadyObjects !== numberOfAllObjects ||
    numberOfAllObjects <= 0 ||
    !PixiController.isActiveZoneAdded
  ) {
    return false
  }

  PixiController.unregisterGraphicUpdated(graphicUpdateListener)
  setDataReady()

  return true
}

function handleProductLoading(productLoaded, numberOfObjects) {
  PixiController.unregisterGraphicUpdated(graphicUpdateListener)
  updateNumberOfReadyObjects()
  numberOfAllObjects = numberOfObjects

  if (!productLoaded) {
    setDataFailed('Product loading failed.')
    return
  }

  if (typeof numberOfObjects !== 'number') {
    setDataFailed('Number of objects is irrelevant.')
    return
  }

  if (!graphicUpdateListener()) {
    setEditorToLoading()
    PixiController.registerGraphicUpdated(graphicUpdateListener, this)
  }
}

const EditorLoadingTool = {
  observer: fullyLoadedEditorIncludedDataAndFontsObserver,
  setDataReady,
  setFontReady,
  isEditorReadyIncludedDataAndFonts,
  data: readyObjects,
  setDataFailed,
  setStartReady,
  handleProductLoading
}

export { EditorLoadingTool }
