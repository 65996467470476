import get from 'lodash/get'
import defaultTo from 'lodash/defaultTo'
import { createViewWrapper } from './ViewWrapper'
import configs from '../module/config'
import { TECHNOLOGY_ERROR } from '../core-module/utils/technologies'
import { findAppropriateSideIndex } from '../core-module/utils/findAppropriateSideIndex'
import { COLOR_TYPE } from '../module/Constants'

function createColor(combination) {
  const result = {
    name: combination.name,
    hex: combination.color || '',
    image: combination.image || '',
    id: combination.id,
    sizes: [],
    quantity: combination.quantity
  }

  if (Object.prototype.hasOwnProperty.call(combination, 'attributes')) {
    combination.attributes.forEach(size => {
      result.sizes.push({
        id: parseInt(size.id, 10),
        quantity: size.quantity,
        name: size.name
      })
    })
  }

  if (result.sizes.length === 0) {
    result.id = combination.id
  }

  return result
}

function createColors(combinations) {
  return combinations.combinations.map(color => createColor(color))
}

function createViews(combinations) {
  return combinations.default.views.map(view => createViewWrapper(view))
}

function createActive(defaultCombination, technologies, views) {
  const defaultSize = defaultCombination.attributes.find(
    size => size.id === defaultCombination.id
  )

  return {
    color: {
      data: defaultCombination.image || defaultCombination.color,
      type: defaultCombination.image ? COLOR_TYPE.IMAGE : COLOR_TYPE.HEX
    },
    size: defaultSize ? defaultSize.name : null,
    technology: technologies.length ? technologies[0].id : TECHNOLOGY_ERROR,
    sideIndex: findAppropriateSideIndex(views)
  }
}

export default function ProductWrapper(bodyJson) {
  const { product } = bodyJson

  const technologies = product.technologies.length
    ? product.technologies // eslint-disable-next-line no-underscore-dangle
    : [configs.data.__DEFAULT_TECHNOLOGY.id]

  const views = createViews(product.combinations)

  return {
    id: parseInt(product.id, 10),
    name: product.name,
    description: product.description,
    width: null,
    height: null,
    categoryId: product.id_category,
    mainCategory: configs.data.mapCategoryIDToMainCategory.includes(
      product.id_category
    )
      ? product.id_category
      : product.id_parent_category,
    price: get(product, 'combinations.default.price', 0),
    stockQuantity: get(product, 'combinations.default.quantity', 0),
    images: {
      previews: product.images.previews,
      tableSizes: Object.prototype.hasOwnProperty.call(product.images, 'table')
        ? product.images.table
        : null
    },
    views,
    colors: createColors(product.combinations),
    active: createActive(product.combinations.default, technologies, views),
    discounts: product.discounts,
    taxRate: product.tax_rate,
    technologies,
    templates: {
      designs: defaultTo(get(product, 'templates.designs', []), []),
      filters: defaultTo(get(product, 'templates.filters', []), [])
    },
    material: product.material
  }
}
