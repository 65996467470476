import {
  postDataWithAuth,
  fetchWithBasicAuth
} from '../core-module/utils/fetch'
import { getApiLoadDesign, getApiSaveDesign } from '../constants/api'
import Console from '../core-module/utils/console'

export function saveDesignRequest(data) {
  return postDataWithAuth(getApiSaveDesign(), { canvas: data })
}

export function getDesign(key) {
  return fetchWithBasicAuth(getApiLoadDesign(key))
    .then(result => result.json())
    .catch(error => {
      Console.error('GET_DESIGN', error)
      return {}
    })
}
