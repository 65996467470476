import Rect from '../core-module/utils/Rect'

/**
 *
 * @param {{x: number, y: number, h: number, w: number}} area data are written in percentage
 * @return {*[]}
 */
function createArea(area) {
  /** [BAD ARCHITECTURE] Area contains attributes ( width && height ) || ( w || h ) */

  return [new Rect({ x: area.x, y: area.y, height: area.h, width: area.w })]
}

function createManufacture(manufacture) {
  return {
    id: parseInt(manufacture.id, 10),
    name: manufacture.name
  }
}

function createProduct(product) {
  return {
    id: parseInt(product.id, 10),
    name: product.name,
    image: product.cover,
    thumbnail: product.thumbnail,
    sizeRange: product.range,
    countColors: product.colors,
    price: product.price,
    areas: createArea(product.area),
    quantity: product.stock ? product.stock : undefined
  }
}

export default function ProductsWrapper(json) {
  return {
    products: json.products.map(product => createProduct(product)),
    manufactures: json.manufacturers.map(manufacture =>
      createManufacture(manufacture)
    )
  }
}
