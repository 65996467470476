import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleRemoveBackgroundFromSprite } from '../../../actions/editor'
import CheckBox from '../../components/Checkbox'

const RemoveBackgroundRequestContainer = ({ removeBg, toggleRemoveBg }) => (
  <div className="RemoveBackgroundRequestContainer">
    <CheckBox value={removeBg} onChange={toggleRemoveBg} label={t("removeBG.label")} />
  </div>
)

RemoveBackgroundRequestContainer.propTypes = {
  removeBg: PropTypes.bool,
  toggleRemoveBg: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleRemoveBg: toggleRemoveBackgroundFromSprite
    },
    dispatch
  )
}

const mapStateToProps = state => ({
  removeBg: state.spritesContainer.removeBg
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveBackgroundRequestContainer)
