import Console from '../core-module/utils/console'

export default function PrintingWrapper(bodyJson) {
  return bodyJson.printings
    .filter(printing => {
      if (!printing.image) {
        Console.error('IMAGE', `image missing ${printing.id}.`)
        return false
      }

      return true
    })
    .map(printing => {
      return {
        id: printing.id,
        type: printing.type,
        image: printing.image.url,
        thumbnail: printing.image.thumbnail
      }
    })
}
