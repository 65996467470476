const localStorageAvailable = typeof Storage !== 'undefined'

const storage = {
  local: {
    save(key, data) {
      if (!localStorageAvailable) {
        return false
      }

      localStorage.setItem(key, data)
      return true
    },
    get(key) {
      if (!localStorageAvailable || !key) {
        return null
      }

      return localStorage.getItem(key)
    },

    remove(key) {
      if (!localStorageAvailable || !key) {
        return false
      }

      localStorage.removeItem(key)
      return true
    }
  }
}

export default storage
