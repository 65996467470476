/**
 * Observer pattern
 */
class Observer {
  listeners

  constructor() {
    this.listeners = []
  }

  /**
   * Subscribe to event
   * @param listener
   * @param scope
   */
  subscribe(listener, scope) {
    this.listeners.push({ scope, listener })
  }

  /**
   * Unsubscribe from event
   * @param listener
   */
  unsubscribe(listener) {
    this.listeners = this.listeners.filter(
      _listener => _listener.listener !== listener
    )
  }

  /**
   * Notify all listeners
   * @param args
   */
  notifyAll(...args) {
    this.listeners.forEach(listener => {
      listener.listener.apply(listener.scope, args)
    })
  }

  /**
   * Notify all listeners with callback to caller
   * @param args
   * @returns {boolean}
   */
  notifyAllWithReturn(...args) {
    let result = true
    this.listeners.forEach(listener => {
      if (!listener.listener.apply(listener.scope, args)) {
        result = false
      }
    })

    return result
  }
}

export default Observer
