import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'

import '../../../styles/components/debugInfo.css'
import Modal from '../ModalComponent'
import { PixiController } from '../../pixi/PixiController'
import EditorManager from '../../editor/EditorManager'
import { getCombinationId } from '../../../filters/product'

function graphicUpdate() {
  const [ping, updatePing] = useState(false)

  function graphicUpdated() {
    updatePing(!ping)
  }

  useEffect(() => {
    PixiController.registerGraphicUpdated(graphicUpdated)
    return () => {
      PixiController.unregisterGraphicUpdated(graphicUpdated)
    }
  })

  return ping
}

function getType(sprite) {
  if (sprite.isText()) {
    return 'Text'
  }

  if (sprite.isMotive()) {
    return 'Sprite'
  }

  return 'Unknown'
}

function getLayerOrder(sprites, sideIndex, uuid) {
  let index = 0

  sprites.find(sprite => {
    if (sprite.uuid === uuid) {
      return true
    }

    if (sprite.sideIndex === sideIndex) {
      index += 1
    }

    return false
  })

  return index
}

function displaySpriteData(sprite) {
  return (
    <>
      <span>ID: {sprite.id}</span>
      <span>Svg: {sprite.isSvg() ? 'yes' : 'no'}</span>
      <span>My motive: {sprite.myMotive ? 'yes' : 'no'}</span>
      <span>
        WxH: {sprite.width}x{sprite.height}
      </span>
    </>
  )
}

function displayTextData(text) {
  return (
    <>
      <span>Font size: {Number(text.fontSize).toFixed(4)}</span>
      <span>Color: {text.color}</span>
      <span>Font family: {text.fontFamily}</span>
      <span>Radius: {text.radius}</span>
    </>
  )
}

// eslint-disable-next-line react/prop-types
function DisplaySpriteInfo({ activeSprite, sprites }) {
  graphicUpdate()

  if (!activeSprite) {
    return 'none'
  }

  const activeSpritePixi = EditorManager.getActiveProductEditor()
    .activePixiSprite

  return (
    <div>
      <span>Uuid: {activeSprite.uuid}</span>
      <span>
        Position: {Number(activeSpritePixi.x).toFixed(4)},{' '}
        {Number(activeSpritePixi.y).toFixed(4)}
      </span>
      <span>Rotation: {Number(activeSpritePixi.rotation).toFixed(4)}</span>
      <span>Type: {getType(activeSprite)}</span>
      <span>Scale: {Number(activeSpritePixi.scale.x).toFixed(4)}</span>
      <span>Side Index: {activeSprite.sideIndex}</span>
      <span>
        Layer order:{' '}
        {getLayerOrder(sprites, activeSprite.sideIndex, activeSprite.uuid)}
      </span>
      <span>
        Horizontal flip: {activeSprite.isHorizontalFlipped() ? 'yes' : 'no'}
      </span>
      {activeSprite.isMotive() ? displaySpriteData(activeSprite) : null}
      {activeSprite.isText() ? displayTextData(activeSprite) : null}
    </div>
  )
}

// eslint-disable-next-line react/prop-types
function DisplayProduct({ product, sprites }) {
  const numberOfSpriteBySide = Object.entries(
    sprites.reduce((acc, sprite) => {
      acc[sprite.sideIndex] = get(acc, sprite.sideIndex, 0) + 1
      return acc
    }, {})
  ).map(([key, value]) => (
    <span key={key}>
      Side {key}: {value} objects
    </span>
  ))

  return (
    <div>
      <span>ID: {product.id}</span>
      <span>Category: {product.categoryId}</span>
      <span>Main category: {product.mainCategory}</span>
      <span>Active Side: {product.active.sideIndex}</span>
      <span>Sides: {product.views.length}</span>
      <span>Color type: {product.active.color.type}</span>
      <span>Color: {product.active.color.data}</span>
      <span>Size: {product.active.size}</span>
      <span>Combination ID: {getCombinationId(product)}</span>
      <span>All objects: {sprites.length}</span>
      {numberOfSpriteBySide}
    </div>
  )
}

function DebugInfoComponent({ activeSprite, sprites, product, displayInfo }) {
  if (!displayInfo) {
    return null
  }

  return (
    <Modal>
      <div className="debug-info debug-right">
        <div className="content">
          <h2>Active object:</h2>
          <DisplaySpriteInfo activeSprite={activeSprite} sprites={sprites} />
        </div>
        <div className="content">
          <h2>Product:</h2>
          <DisplayProduct product={product} sprites={sprites} />
        </div>
      </div>
    </Modal>
  )
}

DebugInfoComponent.propTypes = {
  activeSprite: PropTypes.object,
  sprites: PropTypes.array,
  product: PropTypes.object,
  displayInfo: PropTypes.bool.isRequired
}

DebugInfoComponent.defaultProps = {
  activeSprite: null,
  sprites: [],
  product: null
}

const mapStateToProps = state => ({
  activeSprite: state.spritesContainer.activeSprite,
  sprites: state.spritesContainer.sprites,
  product: state.product,
  displayInfo: state.shortcuts.displayInfo
})

export default connect(mapStateToProps)(DebugInfoComponent)
