export default json => ({
  printings: json.printings.map(printing => ({
    value: printing.id,
    label: printing.name
  })),
  products: json.products.map(product => ({
    value: product.id,
    label: product.name,
    children: product.children
      ? product.children.map(child => ({
          value: child.id,
          label: child.name
        }))
      : []
  }))
})
