function setEvent(e) {
  e.stopPropagation()
  return e
}

function stopPropagationForAllEvents() {
  return {
    onClick: e => setEvent(e),
    onContextMenu: e => setEvent(e),
    onDoubleClick: e => setEvent(e),
    onDrag: e => setEvent(e),
    onDragEnd: e => setEvent(e),
    onDragEnter: e => setEvent(e),
    onDragExit: e => setEvent(e),
    onDragLeave: e => setEvent(e),
    onDragOver: e => setEvent(e),
    onDragStart: e => setEvent(e),
    onDrop: e => setEvent(e),
    onMouseDown: e => setEvent(e),
    onMouseEnter: e => setEvent(e),
    onMouseLeave: e => setEvent(e),
    onMouseMove: e => setEvent(e),
    onMouseOut: e => setEvent(e),
    onMouseOver: e => setEvent(e),
    onMouseUp: e => setEvent(e),
    onPointerDown: e => setEvent(e),
    onPointerMove: e => setEvent(e),
    onPointerUp: e => setEvent(e),
    onPointerCancel: e => setEvent(e),
    onGotPointerCapture: e => setEvent(e),
    onLostPointerCapture: e => setEvent(e),
    onPointerEnter: e => setEvent(e),
    onPointerLeave: e => setEvent(e),
    onPointerOver: e => setEvent(e),
    onPointerOut: e => setEvent(e),
    onTouchCancel: e => setEvent(e),
    onTouchEnd: e => setEvent(e),
    onTouchMove: e => setEvent(e),
    onTouchStart: e => setEvent(e)
  }
}

export { stopPropagationForAllEvents }
