import * as device from 'react-device-detect'
import get from 'lodash/get'
import classNames from 'classnames'
import config from '../../module/config'

const configIsMobile = get(config.data, 'device.isMobile', device.isMobile)
const configIsTable = get(config.data, 'device.isTablet', device.isTablet)

const deviceMock = {
  isMobileOnly: configIsMobile && !configIsTable,
  isMobile: configIsMobile || configIsTable,
  isTablet: configIsTable,
  isAndroid: get(config.data, 'device.isAndroid', device.isAndroid),
  isIOS: get(config.data, 'device.isIOS', device.isIOS),
  isSafari: get(config.data, 'device.isSafari', device.isSafari),
  isFirefox: get(config.data, 'device.isSafari', device.isFirefox)
  // isMac: get(config.data, 'device.isMac', device.is)
}

const deviceProduction = {
  isMobileOnly: device.isMobileOnly,
  isMobile: device.isMobile,
  isTablet: device.isTablet,
  isIOS: device.isIOS,
  isAndroid: device.isAndroid,
  isSafari: device.isSafari,
  isFirefox: device.isFirefox
}

const deviceConfig =
  process.env.NODE_ENV === 'production' ? deviceProduction : deviceMock

const generateDefaultClassNames = () =>
  classNames('creator', {
    tabletView: deviceConfig.isTablet,
    mobileView: deviceConfig.isMobileOnly,
    browserView: !deviceConfig.isMobileOnly,
    iosView: deviceConfig.isIOS
  })

export { generateDefaultClassNames }

// eslint-disable-next-line no-unused-vars
export const {
  isMobile,
  isMobileOnly,
  isTablet,
  isIOS,
  isSafari,
  isFirefox
} = deviceConfig
