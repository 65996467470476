import { ACTION_SET_HASH } from '../constants/UserContansts'

const user = {
  id: undefined
}

export function handleUser(state = user, action) {
  switch (action.type) {
    case ACTION_SET_HASH:
      return {
        ...user,
        id: action.hash
      }
    default:
      return state
  }
}
