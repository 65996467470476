import store from '../../../store/MyStore'

import configs from '../../../module/config'
import { getUserHash, saveUserHash } from '../../editor/StorageManager'
import { createUserHash, setUserHash } from '../../../actions/user'

export function handleUserHash() {
  if (configs.data.userId && typeof configs.data.userId === 'string') {
    store.dispatch(setUserHash(configs.data.userId))
    saveUserHash(configs.data.userId)

    return
  }

  const savedHash = getUserHash()
  if (!savedHash || typeof savedHash !== 'string') {
    const { hash } = store.dispatch(createUserHash())
    saveUserHash(hash)
  } else {
    store.dispatch(setUserHash(savedHash))
  }
}
