import { DisplayObject, Text, Rectangle } from 'pixi.js-legacy'
import * as constant from '../../../constants/SpriteConstants'
import config from '../../../module/config'
import { PixiController } from '../PixiController'

/** ************ PIXI.DisplayObject ************* */

/**
 *
 * @type {SPRITE_TYPE}
 */
DisplayObject.prototype.kType = constant.SPRITE_TYPE.OBJECT

/**
 *
 * @type {CURVED_TEXT}
 */
DisplayObject.prototype.kCurvedText = constant.CURVED_TEXT.NONE

/**
 *
 * @type {number}
 */
DisplayObject.prototype.kRadius = 0

/**
 *
 * @type {string}
 */
DisplayObject.prototype.uuid = ''

DisplayObject.prototype.myMotive = false

/**
 *
 * @return {boolean}
 */
DisplayObject.prototype.isText = function() {
  if (this.kType == null) {
    return false
  }

  return this.kType === constant.SPRITE_TYPE.TEXT
}

/**
 *
 * @return {boolean}
 */
DisplayObject.prototype.isMotive = function() {
  if (this.kType == null) {
    return false
  }

  return this.kType === constant.SPRITE_TYPE.OBJECT
}

/**
 *
 * @return {boolean}
 */
DisplayObject.prototype.isCurved = function() {
  if (this.kCurvedText == null) {
    return false
  }

  return this.kCurvedText !== constant.CURVED_TEXT.NONE
}

Text.prototype.getTrimmedBounds = function() {
  const bounds = this.getBounds()

  const scale = PixiController.contentContainer.scale.x
  const padding = config.data.text.padding * scale

  const relativePadding =
    (1 - Math.abs(((Math.abs(this.angle) % 90) / 90 - 0.5) * 2)) * padding +
    padding

  return new Rectangle(
    bounds.x + relativePadding,
    bounds.y + relativePadding,
    bounds.width - relativePadding * 2,
    bounds.height - relativePadding * 2
  )
}
