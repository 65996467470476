import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionUpdateRef } from '../../../actions/menuContainer'
import Observer from '../../pixi/helper/Observer'
import HistoryObserver from '../../utils/HistoryManager'
import {
  actionObserver as canvasObserver,
  types as canvasTypes
} from '../../containers/PixiActionContainer'

const TEXT_KEY = 'doormat'

// Sorry for this code :/
class Menu extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedTabId: props.defaultSelected,
      componentFirstRun: true
    }
    this.onMenuItemClick = this.onMenuItemClick.bind(this)
    this.onMenuItemClickObserver = new Observer()
    this.historyGoBackListener = this.historyGoBackListener.bind(this)
    this.canvasObserver = this.canvasObserver.bind(this)
  }

  componentDidMount() {
    const { updateRef, enableHandleHistory } = this.props
    updateRef(this)

    if (enableHandleHistory) {
      HistoryObserver.subscribe(this.historyGoBackListener, this)
    }

    canvasObserver.subscribe(this.canvasObserver, this)
  }

  componentWillUnmount() {
    HistoryObserver.unsubscribe(this.historyGoBackListener)
    canvasObserver.unsubscribe(this.canvasObserver)
  }

  onMenuItemClick = id => {
    const { selectedTabId } = this.state
    if (selectedTabId !== id) {
      this.setActive(id)
    } else {
      this.hideMenu()
    }

    this.onMenuItemClickObserver.notifyAll()
  }

  setActive = id => {
    this.setState({ selectedTabId: id, componentFirstRun: false })
  }

  hideMenu = () => {
    this.setActive('')
  }

  historyGoBackListener() {
    const { selectedTabId } = this.state
    if (selectedTabId !== '') {
      this.hideMenu()
      return false
    }

    return true
  }

  canvasObserver({ type }) {
    if (type === canvasTypes.DISPLAY_MENU_TEXT) {
      this.setActive(TEXT_KEY)
    }
  }

  render() {
    const WrappedComponent = (Component, props) => <Component {...props} />
    const { items, menuItem } = this.props
    const { componentFirstRun, selectedTabId } = this.state

    const menuItems = items.map(item => {
      return WrappedComponent(menuItem, {
        ...item,
        key: item.key,
        isActive: selectedTabId === item.key,
        onClick: item.onClick || this.onMenuItemClick,
        index: item.key,
        menuComponentFirstRun: componentFirstRun
      })
    })

    return (
      <div>
        <ul className="navigation">{menuItems}</ul>
      </div>
    )
  }
}

Menu.defaultProps = {
  items: [],
  defaultSelected: '',
  className: '',
  enableHandleHistory: false
}

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      iconBorderColor: PropTypes.string,
      key: PropTypes.string.isRequired,
      component: PropTypes.object,
      backgroundColor: PropTypes.string,
      visibleInMenu: PropTypes.bool
    })
  ),
  className: PropTypes.string,
  menuItem: PropTypes.func.isRequired,
  defaultSelected: PropTypes.string,
  updateRef: PropTypes.func.isRequired,
  enableHandleHistory: PropTypes.bool
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateRef: actionUpdateRef }, dispatch)
}

function withMenuRef(Component) {
  const enhanced = ({ menuRef, ...props }) => {
    if (menuRef) {
      return <Component {...props} ref={menuRef} />
    }

    return <Component {...props} />
  }

  enhanced.propTypes = {
    menuRef: PropTypes.object
  }

  enhanced.defaultProps = {
    menuRef: null
  }

  return enhanced
}

export default connect(
  null,
  mapDispatchToProps
)(withMenuRef(Menu))
