import set from 'lodash/set'
import { FONT_TYPE } from './Constants'

const prod = {
  rootElement: 'root',
  homePage: 'http://kreativator-app.inqool.cz/',
  loadDesignUrl: 'http://kreativator-app.inqool.cz/?{key}',
  apiUrl: 'https://kreativator.inqool.cz',
  language: 'sk',
  currency: {
    title: 'eur',
    symbol: 'Є',
    decimalPlaces: 2
  },
  initConfig: {},
  text: { padding: 35, miterLimit: 10 },
  helloMessage: true,
  initData: null,
  pixelRatio: 1.9,
  mobilePixelRatio: 2,
  importBodyCss: true,
  importAdditionalCss: true,
  autoLoadLastSave: true,
  autoSave: true,
  productId: undefined,
  buttonZoneSwitcher: true,
  mobileMenuVisible: 'auto',
  productDisplayStockQuantityInCategories: [],
  queryDataUrl: 'editor-data',
  __DEFAULT_TECHNOLOGY: {
    id: 2,
    key: 'digital',
    name: 'Digitálná tlač'
  },
  technologies: [
    { id: 2, key: 'digital', name: 'Digitálná tlač' },
    { id: 1, key: 'engraving', name: 'Gravírovanie' },
    { id: 3, key: 'uv', name: 'UV tlač' }
  ],
  disablePanel: [],
  disableMenuItem: [],
  disableRightMenuItem: [],
  enableProductSides: true,
  enableGenerateImageAfterChange: true,
  title: 'Designer',
  mainProductOpacity: 1,
  spritesAlphaOutOfZone: 0.1,
  enableBackgroundPattern: false,
  userId: undefined,
  designId: 'design1',
  captureExceptions: false,
  defaultCategoryID: undefined,
  defaultSubCategoryID: undefined,
  defaultManufactureID: undefined,
  __enableAutomaticChangeSide: false,
  cartThumbnail: {
    maxWidth: 200,
    maxHeight: 200,
    quality: 0.92,
    backgroundColor: '#FFFFFF'
  },
  minPriceToFreeShipping: 33,
  enableAutomaticThumbnail: true,
  enableLogs: false,
  enableShortcuts: true,
  printingSize: {
    maxHeight: 1100,
    maxWidth: 1100,
    generatorMaxHeight: 2000,
    generatorMaxWidth: 2000
  },
  generatorMode: false,
  exportMissingTranslations: false,
  fonts: {
    default: FONT_TYPE.DEFAULT,
    categoryRules: []
  },
  useTemplatesForCategories: [],
  mapCategoryIDToMainCategory: [],
  scaleMultiply: 1,
  forceCanvas: false,
  transparentBackground: false,
  preserveDrawingBuffer: false,
  backgroundColor: 0xffffff,
  legacy: false,
  autoResize: true,
  displayMessageOnAddToCart: false,
  editDesign: {
    enable: false,
    redirectLink: ''
  },
  filters: {
    wood: {
      colorMatrix: {
        enable: true,
        sepia: {
          enable: false,
          multiply: false
        },
        vintage: {
          enable: true,
          multiply: true
        },
        desaturate: false,
        browni: {
          enable: false,
          multiply: false
        },
        saturate: {
          enable: false,
          amount: 0,
          multiply: false
        },
        greyscale: {
          enable: true,
          scale: 0.4,
          multiply: true
        },
        contrast: {
          enable: false,
          amount: 0,
          multiply: false
        }
      },
      alphaFilter: {
        enable: true,
        alpha: 0.55
      },
      embossFilter: {
        enable: false,
        strength: 2
      },
      bevelFilter: {
        enable: false,
        rotation: 0,
        thickness: 1.9,
        lightAlpha: 0.1,
        shadowAlpha: 0.1,
        shadowColor: 0x000000
      }
    },
    glass: {
      colorMatrix: {
        enable: true,
        sepia: {
          enable: false,
          multiply: false
        },
        vintage: {
          enable: false,
          multiply: false
        },
        desaturate: true,
        browni: {
          enable: false,
          multiply: false
        },
        saturate: {
          enable: false,
          amount: 0,
          multiply: false
        },
        greyscale: {
          enable: true,
          scale: 0.35,
          multiply: true
        },
        contrast: {
          enable: false,
          amount: 0,
          multiply: false
        }
      },
      alphaFilter: {
        enable: false,
        alpha: 0.25
      },
      embossFilter: {
        enable: false,
        strength: 2
      },
      bevelFilter: {
        enable: false,
        rotation: 337,
        thickness: 0.8,
        lightAlpha: 0.3,
        shadowAlpha: 0.3,
        shadowColor: 0x000000
      }
    },
    metal: {
      colorMatrix: {
        enable: true,
        sepia: {
          enable: true,
          multiply: true
        },
        vintage: {
          enable: false,
          multiply: false
        },
        desaturate: true,
        browni: {
          enable: false,
          multiply: false
        },
        saturate: {
          enable: false,
          amount: 0,
          multiply: false
        },
        greyscale: {
          enable: true,
          scale: 0.2,
          multiply: true
        },
        contrast: {
          enable: false,
          amount: 0,
          multiply: false
        }
      },
      alphaFilter: {
        enable: true,
        alpha: 0.71
      },
      embossFilter: {
        enable: false,
        strength: 3
      },
      bevelFilter: {
        enable: false,
        rotation: 337,
        thickness: 0.8,
        lightAlpha: 0.3,
        shadowAlpha: 0.3,
        shadowColor: 0x000000
      }
    }

  },
  enableSpriteFilters: false
}

const dev = {
  ...prod,
  homePage: 'http://localhost:3000/',
  loadDesignUrl: 'http://localhost:3000/?{key}',
  displayMessageOnAddToCart: true,
  device: {
    // isMobile: true,
    // isTablet: true
  }
}

let config = process.env.NODE_ENV === 'production' ? prod : dev

function mergeConfigs(target) {
  config = { ...config, ...target }
}

function setConfig(path, value) {
  set(config, path, value)
}

const configs = {
  get data() {
    return config
  }
}

export { mergeConfigs, setConfig }

export default configs
