import store from '../../../store/MyStore'
import AutoThumbnail from '../../pixi/module/AutoThumbnail'
import Tools from '../../utils/Tools'
import configs from '../../../module/config'

function isImageScreenExists(index) {
  return !!AutoThumbnail.extractedImage.get(index)
}

function getAppropriatePreviewOfProduct() {
  return new Promise(resolve => {
    const { sprites } = store.getState().spritesContainer

    if (!sprites.length) {
      resolve(undefined)
      return
    }

    const result = {
      index: Number.MAX_SAFE_INTEGER,
      image: undefined
    }

    sprites.forEach(sprite => {
      if (
        sprite.sideIndex < result.index &&
        isImageScreenExists(sprite.sideIndex)
      ) {
        result.image = AutoThumbnail.extractedImage.get(sprite.sideIndex)
        result.index = sprite.sideIndex
      }
    })

    if (!result.image) {
      resolve(undefined)
      return
    }

    Tools.thumbnailWithMaxDimensions(
      result.image,
      configs.data.cartThumbnail.maxWidth,
      configs.data.cartThumbnail.maxHeight,
      configs.data.cartThumbnail.backgroundColor
    ).then(canvas => {
      resolve(
        canvas.toDataURL('image/jpeg', configs.data.cartThumbnail.quality)
      )
    })
  })
}

export { getAppropriatePreviewOfProduct }
