import React from 'react'
import RightContainer from './index'
import ProductTitleInfo from '../../components/ProductTitleInfo'

const RightContainerWithLoading = () => (
  <RightContainer>
    <ProductTitleInfo isLoading enableExtendButton={false} />
  </RightContainer>
)

export default RightContainerWithLoading
