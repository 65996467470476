import store from '../store/MyStore'
import { listOfSpritesUpdatedObserver } from '../store/SpritesMiddleware'
import { changeProductSideObserver } from '../store/ProductMiddleware'

import EditorManager from '../core-module/editor/EditorManager'
import { changeSideIndex, fetchProductConfig } from '../actions/product'
import { getRelationBetweenSpritesAndProductInPercentages } from '../core-module/pixi/helper/ActiveZoneHelper'
import { waitForReadySide } from './Product/ProductHelper'
import Console from '../core-module/utils/console'

/**
 * @typedef {object} RelationOfEmbeddedObjectsToProduct
 * @property {{left: number, top: number}} spritesOffsetFromProduct offset of objects from product ( in % )
 * @property {{width: number, height: number}} spritesSizeToProductSize size of sprites to product size ( in % )
 * @memberOf Creator.Product
 */

/**
 * Class to handle product
 *
 * @example
 * // Get number of sides of product
 * console.log(Creator.Product.numberOfSides);
 *
 * @class
 * @memberOf Creator
 */
class Product {
  /**
   * Get number of product sides if product editor is ready.
   * @returns {(undefined|number)}
   */
  static get numberOfSides() {
    if (!EditorManager.isProductEditorActive()) {
      return undefined
    }

    return EditorManager.getActiveProductEditor().side.length
  }

  /**
   * Change product side
   *
   * @async
   * @param {number} index
   * @return {Promise<boolean>} Promise with value whether side was changed successfully.
   * @throws Will throw an error if the editor is inactive or index is out of range.
   *
   */
  static changeSide(index) {
    const sides = Product.numberOfSides

    if (typeof sides === 'undefined') {
      throw new Error('Product editor is not active.')
    }

    if (index >= sides || index < 0) {
      throw new Error('Index is out of range.')
    }

    Console.log('Product', 'Side index changed to ', index)

    store.dispatch(changeSideIndex(index))

    return waitForReadySide(index)
  }


  static changeSideById(id){
    const sides = Product.numberOfSides

    if (typeof sides === 'undefined') {
      throw new Error('Product editor is not active.')
    }

    const views = store.getState().product.views;
    
    const newSideIndex = views.findIndex(({viewId})=>viewId === id);
    if(newSideIndex === -1){
      throw new Error('Id not found.')
    } 
    return this.changeSide(newSideIndex);
  }

  /**
   * Load another product.
   *
   * @param {number} id product id
   * @param {number} combination combination id
   */
  static loadProduct(id, combination = 0) {
    store.dispatch(fetchProductConfig(id, combination))
  }

  /**
   * Get data about added sprites. It returns object with 2 information.
   * First: spritesOffsetFromProduct - offset of most left and most top sprite from left-top product coordinate 0,0 (in %).
   * Second: spritesSizeToProductSize - ratio of size of sprite container to product (in %).
   * Suitable to use with screenshots.
   * @returns {Creator.Product.RelationOfEmbeddedObjectsToProduct}
   * @throws Will throw an error if the sprite container is empty.
   */
  static get relationBetweenSpritesAndProduct() {
    return getRelationBetweenSpritesAndProductInPercentages()
  }

  /**
   * Get index of active side.
   *
   * @returns {number}
   * @throws Will throw an error if the editor has not been initialized.
   */
  static get activeSideIndex() {
    if (!EditorManager.isProductEditorActive()) {
      throw new Error(
        'Active Product editor has not been initialized. First need to run `createProduct`.'
      )
    }

    return EditorManager.getActiveProductEditor().getActiveSide().index
  }

  /**
   * State of product ( exported from redux )
   * @returns {*}
   */
  static get state() {
    return store.getState().product
  }

  /**
   * Observer to watching added/removed sprites from application
   * @returns {Observer}
   */
  static get objectsContainerObserver() {
    return listOfSpritesUpdatedObserver
  }

  /**
   * Observer to listen on product side change.
   * @returns {Observer}
   */
  static get productSideChangedObserver() {
    return changeProductSideObserver
  }

  /**
   * Get list of objects at current side.
   * @returns {Objects[]}
   */
  static get listOfObjectsAtCurrentSide() {
    const state = store.getState()
    const { activeSideIndex } = Product

    return state.spritesContainer.sprites.filter(
      sprite => sprite.sideIndex === activeSideIndex
    )
  }
}

export default Product
