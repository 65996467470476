import { toast } from 'react-toastify'

import store from '../../../store/MyStore'
import { addMyImageToLibrary } from '../../../actions/imageLibrary'
import {
  createTextFromDataAndAddOnProduct,
  loadAndAddMySpriteWithData,
  loadAndAddSpriteWithData
} from '../../../actions/editor'
import { loadSaveProductConfig } from '../../../actions/product'
import { EditorLoadingTool } from './FullyLoadedEditor'
import { loadFonts } from '../../utils/fonts'
import EditorManager from '../../editor/EditorManager'
import { sortEditorData } from '../../utils/SortEditorData'

function waitForEditor(resolver) {
  return result => {
    if (result.isReady) {
      EditorManager.editorStateChanged.unsubscribe(this)
      resolver()
    }
  }
}

export function load(data) {
  store
    .dispatch(
      loadSaveProductConfig(
        data.product.id,
        data.product.combinationId,
        data.product.quantity,
        data.product.color,
        data.product.size
      )
    )
    .then(
      () =>
        new Promise(resolve => {
          if (EditorManager.isProductEditorActive()) {
            return resolve()
          }
          EditorManager.editorStateChanged.subscribe(waitForEditor(resolve))
        })
    )
    .then(() => {
      const fontFamilies = []

      const finalData = sortEditorData(data)
      finalData.forEach(object => {
        if (!object) {
          return
        }

        if (object.sprite) {
          store.dispatch(loadAndAddSpriteWithData(object.sprite))
        } else if (object.mySprite) {
          store.dispatch(
            addMyImageToLibrary(
              object.mySprite.id,
              object.mySprite.imageSource,
              true
            )
          )
          store.dispatch(loadAndAddMySpriteWithData(object.mySprite))
        } else if (object.text) {
          fontFamilies.push(object.text.fontFamily)
          store.dispatch(createTextFromDataAndAddOnProduct(object.text))
        }
      })
      EditorLoadingTool.handleProductLoading(true, ++finalData.length)
      loadFonts(fontFamilies, () => EditorLoadingTool.setFontReady())
    })
    .then(() => EditorLoadingTool.setStartReady())
    .catch(() => {
      toast.error('Product loading failed. Please, try it later.')
      EditorLoadingTool.setDataFailed(
        'Product loading failed. Please, try it later.'
      )
    })
}
