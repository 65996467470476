import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ColorComponent from './Color'
import Modal from '../ModalComponent'
import Close from '../../../assets/icons/close.svg'
import { withColors } from '../../containers/ColorContainer'
import { stopPropagationForAllEvents } from '../../utils/StopPropagation'

export const POSITION = {
  TOP: 0,
  RIGHT: 1,
  BOTTOM: 2,
  LEFT: 3
}

function getTriangleStyle(position) {
  switch (position) {
    case POSITION.TOP:
      return 'bottom'
    case POSITION.RIGHT:
      return 'left'
    case POSITION.LEFT:
      return 'right'
    case POSITION.BOTTOM:
      return 'top'
    default:
      return ''
  }
}

class SimpleColorPicker extends React.PureComponent {
  static propTypes = {
    colors: PropTypes.array.isRequired,
    x: PropTypes.number,
    y: PropTypes.number,
    onClick: PropTypes.func,
    defaultColor: PropTypes.string,
    title: PropTypes.string,
    automaticPosition: PropTypes.bool,
    clickedElementWidth: PropTypes.number,
    clickedElementHeight: PropTypes.number,
    defaultVerticalPosition: PropTypes.number,
    defaultHorizontalPosition: PropTypes.number
  }

  static defaultProps = {
    onClick: () => {},
    defaultColor: '',
    x: 0,
    y: 0,
    title: '',
    automaticPosition: false,
    clickedElementWidth: 0,
    clickedElementHeight: 0,
    defaultVerticalPosition: POSITION.BOTTOM,
    defaultHorizontalPosition: POSITION.RIGHT
  }

  constructor(props) {
    super(props)

    this.state = {
      verticalPosition: props.defaultVerticalPosition,
      horizontalPosition: props.defaultHorizontalPosition,
      height: 0,
      width: 0
    }

    this.ref = React.createRef()
  }

  componentDidMount() {
    const {
      automaticPosition,
      clickedElementHeight,
      clickedElementWidth,
      defaultHorizontalPosition,
      defaultVerticalPosition
    } = this.props

    if (automaticPosition && window.innerWidth && window.innerHeight) {
      const { current } = this.ref

      const colorPicker = {
        height: current.clientHeight,
        width: current.clientWidth,
        left: parseFloat(current.style.left),
        top: parseFloat(current.style.top)
      }

      const { scrollTop, scrollLeft } = document.documentElement

      let horizontal
      switch (defaultHorizontalPosition) {
        case POSITION.LEFT:
          horizontal =
            colorPicker.left -
              colorPicker.width -
              clickedElementWidth +
              scrollLeft >=
            0
              ? POSITION.LEFT
              : POSITION.RIGHT
          break
        default:
          horizontal =
            colorPicker.width +
              colorPicker.left +
              clickedElementWidth -
              scrollLeft <=
            window.innerWidth
              ? POSITION.RIGHT
              : POSITION.LEFT
      }

      let vertical
      switch (defaultVerticalPosition) {
        case POSITION.BOTTOM:
          vertical =
            colorPicker.height +
              colorPicker.top +
              clickedElementHeight -
              scrollTop <=
            window.innerHeight
              ? POSITION.BOTTOM
              : POSITION.TOP
          break
        default:
          vertical =
            colorPicker.top -
              colorPicker.height -
              clickedElementHeight +
              scrollTop >=
            0
              ? POSITION.TOP
              : POSITION.BOTTOM
      }

      this.setState({
        horizontalPosition: horizontal,
        verticalPosition: vertical,
        height: colorPicker.height,
        width: colorPicker.width
      })
    }
  }

  getStylePosition() {
    const {
      x,
      y,
      automaticPosition,
      clickedElementHeight,
      clickedElementWidth
    } = this.props

    if (!automaticPosition) {
      return { left: x, top: y }
    }

    const { verticalPosition, horizontalPosition, height, width } = this.state

    const finalX =
      horizontalPosition === POSITION.RIGHT
        ? x + clickedElementWidth
        : x - clickedElementWidth - width
    const finalY =
      verticalPosition === POSITION.TOP
        ? y - height + clickedElementHeight
        : y - clickedElementHeight

    return { left: finalX, top: finalY }
  }

  printColors() {
    const { colors, onClick } = this.props
    return colors.map(color => (
      <ColorComponent
        key={`one-color-${color.value}`}
        color={color.value}
        label={color.label}
        onClick={onClick}
      />
    ))
  }

  render() {
    const { defaultColor, onClick, title } = this.props

    const { verticalPosition, horizontalPosition } = this.state

    return (
      <Modal>
        <div className="color-picker-background" onClick={() => onClick(null)}>
          <div
            className={classNames(
              'color-picker-component',
              getTriangleStyle(verticalPosition),
              getTriangleStyle(horizontalPosition)
            )}
            style={this.getStylePosition()}
            ref={this.ref}>
            <div className="title">
              {defaultColor ? (
                <ColorComponent
                  key={`one-color-${defaultColor}`}
                  color={defaultColor}
                  onClick={onClick}
                />
              ) : null}
              <span>{title}</span>
              <button
                type="button"
                className="close disable-propagation"
                {...stopPropagationForAllEvents()}
                onClick={() => onClick(null)}>
                <img src={Close} alt="Close color picker" />
              </button>
            </div>
            <div className="content">{this.printColors()}</div>
          </div>
        </div>
      </Modal>
    )
  }
}

const SimpleColorPickerComponent = withColors(SimpleColorPicker)

export default SimpleColorPickerComponent
