import * as apiV1 from '../constants/api'
import * as constant from '../constants/ProductConstants'
import ProductWrapper from '../api/ProductWrapper'
import ViewWrapper from '../api/ViewWrapper'
import { computeAndUpdateColorsBasedOnTechnology } from './preProduct'
import { myJsonFetchWithCache } from '../core-module/utils/fetch'
import { COLOR_TYPE } from '../module/Constants'

function internalFetchProduct(
  type,
  productId,
  combinationId = 0,
  additionalArgs = {}
) {
  return async dispatch => {
    dispatch({
      type: constant.TYPE_LOADING,
      result: constant.STATE.LOADING
    })

    try {
      const body = await myJsonFetchWithCache(
        apiV1.getApiProductUrl(productId, combinationId)
      )

      const productWrapper = ProductWrapper(body)

      computeAndUpdateColorsBasedOnTechnology(productWrapper)

      dispatch({
        type,
        result: constant.STATE.FETCHED,
        body: productWrapper,
        args: additionalArgs
      })

      return productWrapper
    } catch (e) {
      dispatch({
        type: constant.TYPE_ERROR,
        result: constant.STATE.ERROR
      })

      return null
    }
  }
}

export function fetchProductConfig(productId, combinationId = 0) {
  return internalFetchProduct(constant.TYPE_FETCHED, productId, combinationId)
}

export function loadSaveProductConfig(
  productId,
  combinationId = 0,
  quantity,
  color,
  size
) {
  return internalFetchProduct(
    constant.TYPE_LOAD_SAVE,
    productId,
    combinationId,
    { quantity, color, size }
  )
}

function changeColorAction(color, type, productId, combinationId) {
  return async dispatch => {
    dispatch({
      type: constant.TYPE_COLOR_LOADING,
      data: color,
      colorType: type
    })

    const response = await myJsonFetchWithCache(
      apiV1.getApiViews(productId, combinationId)
    )

    return dispatch({
      type: constant.TYPE_CHANGE_COLOR_FETCHED,
      body: ViewWrapper(response)
    })
  }
}

export function changeHexColorAction(color, productId, combinationId) {
  return changeColorAction(color, COLOR_TYPE.HEX, productId, combinationId)
}

export function changeURLColorAction(url, productId, combinationId) {
  return changeColorAction(url, COLOR_TYPE.IMAGE, productId, combinationId)
}

export function setSize(size) {
  return {
    type: constant.ACTION_SET_SIZE,
    size
  }
}

/**
 *
 * @param {number} quantity
 * @returns {{type: string, quantity: number}}
 */
export function setQuantity(quantity) {
  return {
    type: constant.ACTION_SET_QUANTITY,
    quantity
  }
}

export function setProductId(id) {
  return {
    type: constant.ACTION_SET_ID,
    id
  }
}

export function changeSideIndex(index) {
  return {
    type: constant.ACTION_CHANGE_SIDE_INDEX,
    index
  }
}
