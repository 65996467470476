import uuidv4 from 'uuid/v4'
import * as types from '../constants/ActionTypes'
import * as editorTypes from '../constants/EditorConstant'
import ImageCacheFactory from '../core-module/utils/ImageCacheFactory'
import * as editor from '../constants/EditorConstant'
import Tools from '../core-module/utils/Tools'
import { computeSvgStyleAttributesIncludeTechnology } from './preSpritesContainer'
import { TextStateBuilder } from '../reducers/helpers/DisplayObjectReducer'
import configs from '../module/config'
import Console from '../core-module/utils/console'

function getSvgDataWhenObjectIsSvg(imageSource) {
  const isSvg = Tools.isLinkSvg(imageSource)

  if (!isSvg) {
    return {}
  }

  return {
    svgClasses: (() => {
      const cache = ImageCacheFactory.getCache(imageSource)
      return cache.parser
        ? computeSvgStyleAttributesIncludeTechnology(cache.parser)
        : {}
    })()
  }
}

export function toggleRemoveBackgroundFromSprite(){
  return {
    type: types.TOGGLE_REMOVE_BACKGROUND_OPTION
  }
}

// todo: add natural width/height ( now width and height point to optimized values )
export function addSpriteOnProduct(
  imageSource,
  myMotive = false,
  id = -1,
  width = -1,
  height = -1,
  naturalWidth = -1,
  naturalHeight = -1
) {
  return async dispatch => {
    if (width >= 0 && height >= 0 && naturalWidth >= 0 && naturalHeight >= 0) {
      return dispatch({
        type: types.ADD_SPRITE_ON_PRODUCT,
        imageSource,
        width,
        height,
        myMotive,
        naturalWidth,
        naturalHeight,
        id,
        ...getSvgDataWhenObjectIsSvg(imageSource)
      })
    }

    const uuid = uuidv4()

    dispatch({
      type: types.ADD_PRIMITIVE_OBJECT,
      uuid
    })

    return ImageCacheFactory.getObject(imageSource).then(
      ({
        cached: image,
        naturalWidth: _naturalWidth,
        naturalHeight: _naturalHeight
      }) =>
        dispatch({
          type: types.ADD_SPRITE_ON_PRODUCT,
          imageSource,
          width: image.width,
          height: image.height,
          naturalWidth: _naturalWidth,
          naturalHeight: _naturalHeight,
          myMotive,
          id,
          uuid,
          ...getSvgDataWhenObjectIsSvg(imageSource)
        })
    )
  }
}

export function addTextOnProduct(textState) {
  return {
    type: types.ADD_TEXT_ON_PRODUCT,
    textState
  }
}

export function createTextFromDataAndAddOnProduct(text) {
  return addTextOnProduct(
    new TextStateBuilder(text.text, text.sideIndex)
      .setBold(text.bold)
      .setFontSize(text.fontSize)
      .setPosition(text.x, text.y)
      .setFontFamily(text.fontFamily)
      .setRotation(text.rotation)
      .setScale(text.scale)
      .setColor(text.color)
      .setFlipHorizontal(text.flipHorizontal)
      .setSideIndex(text.sideIndex)
      .setCurvedText(text.curvedText)
      .setRadius(text.radius)
      .setItalic(text.italic)
      .setAlign(text.align)
      .build()
  )
}

export function loadAndAddSpriteWithData(data, myMotive = false) {
  return async dispatch => {
    const uuid = uuidv4()

    dispatch({
      type: types.ADD_PRIMITIVE_OBJECT,
      uuid,
      sideIndex: data.sideIndex
    })

    ImageCacheFactory.getObject(data.imageSource).then(object => {
      if (data.svg) {
        // eslint-disable-next-line
        data.svgClasses = computeSvgStyleAttributesIncludeTechnology(
          object.parser,
          data.svgClasses
        )
      }
      if (
        configs.data.generatorMode &&
        object.scaledMultiplicand &&
        object.scaledMultiplicand !== 1
      ) {
        const newScale = data.scale * object.scaledMultiplicand
        Console.log(
          'Sprite',
          `Scale was updated from ${data.scale} to ${newScale}. Multiplicand ${object.scaledMultiplicand}`
        )
        data.scale = newScale // eslint-disable-line no-param-reassign
      }

      return dispatch({
        type: types.ADD_SPRITE_WITH_DATA,
        data,
        width: object.cached.width,
        height: object.cached.height,
        naturalWidth: object.naturalWidth,
        naturalHeight: object.naturalHeight,
        myMotive,
        uuid
      })
    })
  }
}

export function loadAndAddMySpriteWithData(data) {
  return loadAndAddSpriteWithData(data, true)
}

export function actionSetSpritesAtCenterAndScaleToZone(
  sideIndex,
  zone,
  fontSizeScaleMultiplicator = 1
) {
  return {
    type: types.SET_SPRITES_AT_CENTER_AND_SCALE_BY_ZONE,
    fontSizeScaleMultiplicator,
    zone,
    sideIndex
  }
}

export function updateTextColor(color) {
  return {
    type: types.UPDATE_ACTIVE_TEXT_COLOR,
    color
  }
}

/**
 *
 * @param {string} uuid
 * @param {{className: string, color: string}[]}classes
 * @return {{type: string, uuid: {string}, style: {className: string, color: string}[]}}
 */
export function updateSvgClasses(uuid, classes) {
  return {
    type: types.UPDATE_SVG_SPRITE_CLASSES,
    uuid,
    classes
  }
}

export function updateSvgClassColorOnActive(className, newColor) {
  return {
    type: types.UPDATE_SVG_COLOR_ACTIVE,
    className,
    color: newColor
  }
}

export function activeSpriteChanged(displayObject) {
  return {
    type: types.ACTIVE_SPRITE_CHANGED,
    displayObject
  }
}

export function replaceText(displayObjectReducer, addToHistory = true) {
  return {
    type: types.REPLACE_ACTIVE_TEXT,
    replace: displayObjectReducer,
    addToHistory
  }
}

export function actionRemoveActiveObject() {
  return {
    type: types.REMOVE_ACTIVE_OBJECT
  }
}

export function actionRemoveAllSprites() {
  return {
    type: types.REMOVE_ALL_SPRITES
  }
}

export function updateSizeOnActiveObject(scale) {
  return {
    type: types.UPDATE_SIZE_ACTIVE_OBJECT,
    scale
  }
}

export function updateFontSizeOnActiveObject(fontSize) {
  return {
    type: types.UPDATE_FONT_SIZE_ACTIVE_OBJECT,
    fontSize
  }
}

export function changeSvgAndTextToColor(color) {
  return {
    type: types.UPDATE_BUNCH_CHANGE_COLOR_TEXT_AND_SVG,
    color
  }
}

export function removeSpriteById(id) {
  return {
    type: types.REMOVE_SPRITES_BY_ID,
    id
  }
}

export function updateRotationOnActiveObject(rotation) {
  return {
    type: types.UPDATE_ROTATION_ACTIVE_OBJECT,
    rotation
  }
}

export function updatePositionOnActiveObject(x, y) {
  return {
    type: types.UPDATE_POSITION_ACTIVE_OBJECT,
    x,
    y
  }
}

export function setActiveObjectToTopLayer() {
  return {
    type: types.UPDATE_TO_TOP_LAYER_ACTIVE_OBJECT
  }
}

export function setActiveObjectToBottomLayer() {
  return {
    type: types.UPDATE_TO_BOTTOM_LAYER_ACTIVE_OBJECT
  }
}

export function flipActiveObject() {
  return {
    type: types.FLIP_ACTIVE_OBJECT
  }
}

export function createCopyOfActiveObject() {
  return {
    type: types.CREATE_COPY_ACTIVE_OBJECT
  }
}

export function actionEditorIsReady() {
  return {
    type: editor.TYPE_EDITOR_SET_STATE,
    ready: true
  }
}

export function actionEditorIsNotReady() {
  return {
    type: editor.TYPE_EDITOR_SET_STATE,
    ready: false
  }
}

export function actionGoBackStep() {
  return {
    type: types.BACK_STEP
  }
}

export function actionGoForwardStep() {
  return {
    type: types.FORWARD_STEP
  }
}

export function actionHistoryClear() {
  return {
    type: types.HISTORY_CLEAR
  }
}

export function actionShowFiltersModal(sprite){
  return {
    type: editorTypes.TYPE_EDITOR_SPRITE_FILTER_CHOOSING,
    sprite
  }
}

export function closeFiltersModal(){
  return {
    type: editorTypes.TYPE_EDITOR_SPRITE_FILTER_CLOSED
  }
}

export function actionApplyFilterOnActiveObject(filterName){
  return {
    type: types.APPLY_FILTER_ON_ACTIVE_OBJECT,
    filter:filterName
  }
}

export function deleteFilterFromActiveObject(){
  return {
    type: types.REMOVE_FILTER_FROM_ACTIVE_OBJECT
  }
}