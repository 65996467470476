import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const MidContainer = ({ children, className }) => (
  <section className={classNames('mid', className)}>{children}</section>
)

MidContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
}

MidContainer.defaultProps = {
  className: '',
  children: null
}

export default MidContainer
