import * as type from '../../constants/HistoryConstants'
import Tools from '../../core-module/utils/Tools'

export function insertToArray(array, item, index = undefined) {
  if (typeof index !== 'number' || index < 0 || index >= array.length) {
    return [...array, item]
  }

  const result = array.slice()
  result.splice(index, 0, item)
  return result
}

export function replaceInArrayOtherwiseAdd(array, replace) {
  const indexFound = array.findIndex(sprite => sprite.uuid === replace.uuid)

  if (indexFound === -1) {
    return [...array, replace]
  }

  return array.map((object, index) => (index !== indexFound ? object : replace))
}

function getUpdatedSpritesContainerByAction(
  historyAction,
  spriteContainer,
  isForwardStep
) {
  switch (historyAction.type) {
    case type.HISTORY_ADD_SPRITE:
    case type.HISTORY_ADD_TEXT:
      if (isForwardStep) {
        return spriteContainer.filter(
          sprite => sprite.uuid !== historyAction.state.uuid
        )
      }

      spriteContainer.push(historyAction.state)
      return spriteContainer

    case type.HISTORY_REPLACE_TEXT:
      return spriteContainer.map(sprite => {
        if (isForwardStep) {
          if (sprite.uuid === historyAction.state.prevSprite.uuid) {
            return historyAction.state.prevSprite
          }
        } else if (sprite.uuid === historyAction.state.nextSprite.uuid) {
          return historyAction.state.nextSprite
        }

        return sprite
      })

    case type.HISTORY_FLIP_SPRITE:
      return spriteContainer.map(sprite => {
        if (sprite.uuid !== historyAction.state.uuid) {
          return sprite
        }

        sprite.flipHorizontal()

        return sprite
      })

    case type.HISTORY_CHANGE_TEXT_COLOR:
      return spriteContainer.map(sprite => {
        if (sprite.uuid !== historyAction.state.sprite.uuid) {
          return sprite
        }

        sprite.setColor(
          isForwardStep
            ? historyAction.state.previousColor
            : historyAction.state.newColor
        )

        return sprite
      })

    case type.HISTORY_SPRITE_SET_POSITION:
      return spriteContainer.map(sprite => {
        if (sprite.uuid !== historyAction.state.sprite.uuid) {
          return sprite
        }

        sprite.setPosition(
          isForwardStep
            ? historyAction.state.prevPosition.x
            : historyAction.state.nextPosition.x,
          isForwardStep
            ? historyAction.state.prevPosition.y
            : historyAction.state.nextPosition.y
        )

        return sprite
      })

    case type.HISTORY_SPRITE_SET_ROTATION:
      return spriteContainer.map(sprite => {
        if (sprite.uuid !== historyAction.state.sprite.uuid) {
          return sprite
        }

        sprite.setRotation(
          isForwardStep
            ? historyAction.state.prevRotation
            : historyAction.state.nextRotation
        )

        return sprite
      })

    case type.HISTORY_SPRITE_SET_FONT_SIZE:
      return spriteContainer.map(sprite => {
        if (
          !sprite.isText() ||
          sprite.uuid !== historyAction.state.sprite.uuid
        ) {
          return sprite
        }

        sprite.setFontSize(
          isForwardStep
            ? historyAction.state.prevFontSize
            : historyAction.state.nextFontSize
        )

        return sprite
      })

    case type.HISTORY_SPRITE_SET_SCALE:
      return spriteContainer.map(sprite => {
        if (sprite.uuid !== historyAction.state.sprite.uuid) {
          return sprite
        }

        sprite.setScale(
          isForwardStep
            ? historyAction.state.prevScale
            : historyAction.state.nextScale
        )

        return sprite
      })

    case type.HISTORY_CHANGE_SVG_COLOR:
      return spriteContainer.map(sprite => {
        if (sprite.uuid !== historyAction.state.sprite.uuid) {
          return sprite
        }

        sprite.setSvgClasses(
          isForwardStep
            ? historyAction.state.previousColors
            : historyAction.state.newColors
        )

        return sprite
      })

    case type.HISTORY_REMOVE_OBJECT: {
      if (isForwardStep) {
        historyAction.state.sprites.forEach(item => {
          spriteContainer.splice(item.order, 0, item.sprite)
        })
      } else {
        historyAction.state.sprites.forEach(item => {
          spriteContainer.splice(item.order, 1)
        })
      }

      return spriteContainer
    }

    case type.HISTORY_MOVE_SPRITE_TO_TOP_LAYER:
    case type.HISTORY_MOVE_SPRITE_TO_BOTTOM_LAYER: {
      if (isForwardStep) {
        const from =
          historyAction.type === type.HISTORY_MOVE_SPRITE_TO_BOTTOM_LAYER
            ? 0
            : spriteContainer.length - 1

        Tools.arrayMoveElement(
          spriteContainer,
          from,
          historyAction.state.prevOrder
        )
      } else {
        const indexOfActiveSprite = spriteContainer.indexOf(
          historyAction.state.sprite
        )
        spriteContainer.splice(indexOfActiveSprite, 1)

        if (historyAction.type === type.HISTORY_MOVE_SPRITE_TO_BOTTOM_LAYER) {
          spriteContainer.unshift(historyAction.state.sprite)
        } else {
          spriteContainer.push(historyAction.state.sprite)
        }
      }

      return spriteContainer
    }

    case type.HISTORY_UPDATE_BUNCH_COLORS_TEXT_AND_SVG:
      if (isForwardStep) {
        spriteContainer.forEach(sprite => {
          if (sprite.isText()) {
            historyAction.state.data.forEach(text => {
              if (text.uuid === sprite.uuid) {
                sprite.setColor(text.color)
              }
            })
          } else if (sprite.isSvg()) {
            historyAction.state.data.forEach(text => {
              if (text.uuid === sprite.uuid) {
                sprite.setSvgClasses(text.svgClasses)
              }
            })
          }
        })
      } else {
        spriteContainer.forEach(sprite => {
          if (sprite.isText()) {
            sprite.setColor(historyAction.state.color)
          } else if (sprite.isSvg()) {
            sprite.changeSvgToColor(historyAction.state.color)
          }
        })
      }
      return spriteContainer

    default:
      return spriteContainer
  }
}

function getUpdatedSpritesContainerByActionOnBack(action, spriteContainer) {
  return getUpdatedSpritesContainerByAction(action, spriteContainer, true)
}

function getUpdatedSpritesContainerByActionOnForward(action, spriteContainer) {
  return getUpdatedSpritesContainerByAction(action, spriteContainer, false)
}

export function backStep(state) {
  if (state.history.length === 0) {
    return state
  }

  const lastHistory = state.history.pop()

  const sprites = getUpdatedSpritesContainerByActionOnBack(
    lastHistory,
    state.sprites
  )

  state.future.push(lastHistory)

  return {
    ...state,
    activeSprite: null,
    sprites,
    history: state.history,
    future: state.future
  }
}

export function forwardStep(state) {
  if (state.future.length === 0) {
    return state
  }

  const lastFuture = state.future.pop()

  const sprites = getUpdatedSpritesContainerByActionOnForward(
    lastFuture,
    state.sprites
  )

  state.history.push(lastFuture)

  return {
    ...state,
    activeSprite: null,
    sprites,
    history: state.history,
    future: state.future
  }
}
