import sha1 from 'sha1'

export function getHashKey(url, classes) {
  let classesResultString = ''
  Object.keys(classes).forEach(key => {
    const classObject = classes[key]
    classesResultString += key
    Object.keys(classObject).forEach(attrKey => {
      classesResultString += `${attrKey}.${classObject[attrKey]};`
    })
  })

  const string = `${url}-${classesResultString}`

  return sha1(string)
}
