

import fx  from "glfx";


const glfxCanvas = fx.canvas();


//LIGHTER
export const lighterFilter = (image)=>{
    const texture = glfxCanvas.texture(image);
    glfxCanvas.draw(texture).brightnessContrast(0.5,0).update();
    return glfxCanvas.toDataURL();
}

export const LIGHTER_FILTER_KEY = "lighter";

//SATURATE
export const saturateFilter = (image)=>{
    const texture = glfxCanvas.texture(image);
    glfxCanvas.draw(texture).hueSaturation(0,0.6).update();
    return glfxCanvas.toDataURL();
}
export const SATURATE_FILTER_KEY = "saturate";



//BLUR
export const blurFilter = (image)=>{
    const texture = glfxCanvas.texture(image);
    glfxCanvas.draw(texture).triangleBlur(8).update();
    return glfxCanvas.toDataURL();
}
export const BLUR_FILTER_KEY =  "blur";

//SEPIA
export const sepiaFilter = (image)=>{
    const texture = glfxCanvas.texture(image);
    glfxCanvas.draw(texture).sepia(1).update();
    return glfxCanvas.toDataURL();
}
export const SEPIA_FILTER_KEY="sepia"


//BLACK AND WHITE
export const blackAndWhiteFilter = (image)=>{
    const texture = glfxCanvas.texture(image);
    glfxCanvas.draw(texture).hueSaturation(0,-1).update();
    return glfxCanvas.toDataURL();
}
export const BLACK_AND_WHITE_FILTER_KEY = "blackAndWhite"


//CONTRAST
export const contrastFilter = (image)=>{
    const texture = glfxCanvas.texture(image);
    glfxCanvas.draw(texture).brightnessContrast(0,0.5).update();
    return glfxCanvas.toDataURL();
}
export const CONTRAST_FILTER_KEY = "constrast"



//CROSS_HATCH
export const crossHatchFilter = (image)=>{
    const texture = glfxCanvas.texture(image);
    glfxCanvas.draw(texture).dotScreen(320, 239.5, 1.1, 3).update();
    return glfxCanvas.toDataURL();
}
export const CROSS_HATCH_FILTER_KEY = "cross_hatch"


//PIXELATE
export const pixelate = (image)=>{
    const texture = glfxCanvas.texture(image);
    glfxCanvas.draw(texture).hexagonalPixelate(320, 239.5, 8).update();
    return glfxCanvas.toDataURL();
}
export const PIXELATE_FILTER_KEY = "pixelate"


//NOISE
export const noise = (image)=>{
    const texture = glfxCanvas.texture(image);
    glfxCanvas.draw(texture).noise(1).update();
    return glfxCanvas.toDataURL();
}
export const NOISE_FILTER_KEY = "noise"

//VIGNETTE
export const vignette = (image)=>{
    const texture = glfxCanvas.texture(image);
    glfxCanvas.draw(texture).vignette(0.6,0.6).update();
    return glfxCanvas.toDataURL();
}
export const VIGNETTE_FILTER_KEY = "vignette"

//INK
export const ink = (image)=>{
    const texture = glfxCanvas.texture(image);
    glfxCanvas.draw(texture).ink(0.7).update();
    return glfxCanvas.toDataURL();
}
export const INK_FILTER_KEY = "ink"




export const filters = {
    [LIGHTER_FILTER_KEY]: lighterFilter,
    [SATURATE_FILTER_KEY]: saturateFilter,
    [BLUR_FILTER_KEY]: blurFilter,
    [SEPIA_FILTER_KEY]: sepiaFilter,
    [BLACK_AND_WHITE_FILTER_KEY]: blackAndWhiteFilter,
    [CONTRAST_FILTER_KEY]: contrastFilter,
    [CROSS_HATCH_FILTER_KEY]: crossHatchFilter,
    [PIXELATE_FILTER_KEY]: pixelate,
    [NOISE_FILTER_KEY]: noise,
    [VIGNETTE_FILTER_KEY]: vignette,
    [INK_FILTER_KEY]: ink,
}
