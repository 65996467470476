import * as types from '../constants/ActionTypes'
import * as apiV1 from '../constants/api'
import Console from '../core-module/utils/console'
import {
  fetchWithBasicAuth,
  myJsonFetchWithCache
} from '../core-module/utils/fetch'
import PrintingWrapper from '../api/PrintingWrapper'
import debouncedAutoSave from '../core-module/pixi/helper/AutoSave'

let actualUrlFetch = ''

function internalSetImagesInLibrary(type, data) {
  return {
    type,
    ...data
  }
}

export function addImagesToLibrary(
  imageSources,
  visible = true,
  myMotive = false
) {
  return internalSetImagesInLibrary(types.ADD_IMAGE_TO_LIBRARY, {
    imageSources,
    myMotive,
    visible
  })
}

export function replaceCommercialImagesInLibrary(
  imageSources,
  visible = true,
  myMotive = false
) {
  return internalSetImagesInLibrary(
    types.REPLACE_COMMERCIAL_IMAGES_IN_LIBRARY,
    {
      imageSources,
      myMotive,
      visible
    }
  )
}

/**
 *
 * @param {string} id
 * @returns {{type: string, image: string}}
 */
export function removeImageFromLibrary(id) {
  return {
    type: types.REMOVE_IMAGE_FROM_LIBRARY,
    id
  }
}

export function uploadImage(id, file) {
  return async dispatch => {
    dispatch({
      type: types.SET_IMAGE_STATE_TO_UPLOADING,
      id
    })

    const formData = new FormData()
    formData.append('customFile', file)

    try {
      const response = await fetchWithBasicAuth(apiV1.getApiUploadImage(), {
        method: 'POST',
        body: formData
      })

      const body = await response.json()

      dispatch({
        type: types.SET_IMAGE_STATE_TO_UPLOADED,
        id,
        newId: body.uid,
        image: body.path
      })

      dispatch({
        type: types.UPDATE_ID,
        id,
        newId: body.uid,
        temporalImageUrl: body.path
      })

      debouncedAutoSave()
    } catch (e) {
      Console.error('IMAGE_UPLOAD', e)
      dispatch({
        type: types.SET_IMAGE_STATE_TO_ERROR_UPLOAD,
        id
      })
    }
  }
}

export function addMyImageToLibrary(id, image, uploaded = false) {
  return addImagesToLibrary([{ image, id, uploaded }], true, true)
}

export function setImageVisible(from, limit) {
  return {
    type: types.SET_IMAGE_VISIBILITY,
    from,
    limit,
    visibility: true
  }
}

async function fetchPrintings(url) {
  const body = await myJsonFetchWithCache(url)
  return PrintingWrapper(body)
}

function internalFetchPrintingsAndShow(url, numberOfVisible = 100, from = 0) {
  return async dispatch => {
    actualUrlFetch = url
    fetchPrintings(url).then(printings => {
      if (!printings) return
      if (actualUrlFetch !== url) return

      dispatch(replaceCommercialImagesInLibrary(printings, false))
      dispatch(setImageVisible(from, numberOfVisible))
    })
  }
}

/**
 * Download all printings from API and then display just some of them
 * @param {number} parentCategory parent category - textile, ...
 * @param {number} numberOfVisible count of visible printings
 * @returns {*}
 */
export function fetchAllPrintingsAndShow(
  parentCategory,
  numberOfVisible = 100
) {
  return internalFetchPrintingsAndShow(
    apiV1.getApiAllPrintings(parentCategory),
    numberOfVisible
  )
}

export function fetchPrintingsAndShow(
  parentCategory,
  category,
  numberOfVisible = 100
) {
  return internalFetchPrintingsAndShow(
    apiV1.getApiPrintingsUrl(parentCategory, category),
    numberOfVisible
  )
}

export function countVisiblePrintings(imageLibrary) {
  let visible = 0
  imageLibrary.forEach(image => {
    if (image.visible) {
      visible += 1
    }
  })

  return visible
}
