import base64 from 'base-64'
import CancelablePromise from 'cancelable-promise'
import Console from './console'
import { EditorLoadingTool } from '../components/config/FullyLoadedEditor'

const cache = new Map()

let fetchQueue = 0

function decreaseFetchQueue(result) {
  fetchQueue -= 1
  return result
}

function increaseFetchQueue(result) {
  fetchQueue += 1
  return result
}

export function anyInNetworkQueue() {
  return fetchQueue !== 0
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText)
  }
  return response
}

export function myFetch(url, args = {}) {
  increaseFetchQueue()

  return fetch(url, {
    ...args,
    headers: new Headers({
      ...(args.headers ? args.headers : {})
    })
  })
    .then(handleErrors)
    .then(decreaseFetchQueue)
    .catch(result => {
      decreaseFetchQueue(result)
      Console.error(result)
      EditorLoadingTool.setDataFailed(`Fetch failed ${url}`)
      throw result
    })
}

export function myJsonFetchWithCache(url, args = {}, cacheID) {
  return new CancelablePromise(resolve => {
    if (cache.get(cacheID || url)) {
      return resolve(cache.get(cacheID || url))
    }

    return myFetch(url, args)
      .then(response => response.json())
      .then(result => {
        cache.set(cacheID || url, result)
        return result
      })
      .then(resolve)
  })
}

export function fetchWithBasicAuth(url, args = {}) {
  return myFetch(url, {
    ...args,
    headers: {
      ...(args.headers ? args.headers : {}),
      Authorization: `Basic ${base64.encode(
        'kreativator-app:SMWHE4HQF884IR1W5CYC7H5JQTIZ4PWH'
      )}`
    }
  })
}

export function postData(url, data) {
  return myFetch(url, {
    body: JSON.stringify(data),
    cache: 'no-cache',
    credentials: 'same-origin',
    method: 'POST',
    redirect: 'follow',
    referrer: 'no-referrer'
  }).then(response => response.json())
}

export function postDataWithCache(url, data, cacheID) {
  return myJsonFetchWithCache(
    url,
    {
      body: JSON.stringify(data),
      cache: 'no-cache',
      credentials: 'same-origin',
      method: 'POST',
      redirect: 'follow',
      referrer: 'no-referrer'
    },
    cacheID
  )
}

export function postDataWithAuth(url, data) {
  return fetchWithBasicAuth(url, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => response.json())
}
