import React from 'react'
import { Helmet } from 'react-helmet'

function Header() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link
        href='https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-
awesome.min.css" rel="stylesheet" integrity="sha384-
wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN'
        crossOrigin="anonymous"
      />
    </Helmet>
  )
}

export default Header
