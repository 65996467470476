import { connect } from 'react-redux'

import { MY_IMAGE_STATE_UPLOADING } from '../../constants/ActionTypes'

/**
 * Add prop imageIsUploading
 * @param Component
 * @returns {function(*)}
 */
export const withImageIsUploading = Component => {
  const mapStateToProps = state => ({
    imageIsUploading: !!state.imageLibrary.find(
      image => image.myMotive && image.state === MY_IMAGE_STATE_UPLOADING
    )
  })

  return connect(mapStateToProps)(Component)
}
