class ProductSide {
  /**
   *
   * @private
   */
  _activeZones = []

  /**
   *
   * @type {string}
   */
  imageSource

  /**
   *
   * @type {number}
   */
  index

  /**
   *
   * @private
   */
  _view

  /**
   *
   * @param {number} index
   */
  constructor(index, view) {
    this.index = index
    this.imageSource = view.image
    this._view = view
  }

  /**
   *
   * @return {string}
   */
  getImageSource() {
    return this.imageSource
  }

  getActiveZones() {
    return this._activeZones
  }

  getZoneToInsert() {
    if (this._activeZones === null || !this._activeZones.length) {
      return null
    }

    return this._activeZones[0]
  }

  /**
   *
   * FIX: Currently only one rectangle is assumed.
   * @see {Utils.subtractRelativeRectangleFromCanvas}
   * @param {{x: number, y: number}} offset
   */
  addActiveZone(area) {
    const immutableRect = Object.assign({}, area)
    this._activeZones.push(immutableRect)
  }

  calculateAndResetZones() {
    this._activeZones = []
    this._view.areas.forEach(area => this.addActiveZone(area))
  }

  getView() {
    return this._view
  }
}

export default ProductSide
