import * as constant from '../constants/CategoriesConstant'

/**
 *
 * @name CategoriesConfig
 * @type {{state: CategoryState, printings: {id: number, name: string}[], products: {id: number, name: string}[]}}
 */
const CategoriesConfig = {
  state: constant.STATE.INIT,
  printings: [],
  products: []
}

export function handleCategories(state = CategoriesConfig, action) {
  switch (action.type) {
    case constant.TYPE_LOADING:
      return { ...state, state: constant.STATE.LOADING }
    case constant.TYPE_FETCHED:
      return {
        ...state,
        state: constant.STATE.FETCHED,
        printings: action.body.printings,
        products: action.body.products
      }
    case constant.TYPE_ERROR:
      return { ...state, state: constant.STATE.ERROR }
    default:
      return state
  }
}
