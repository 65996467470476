import React from 'react'
import { Provider } from 'react-redux'
import styled from 'styled-components'
import store from '../store/MyStore'
import { SentryErrorComponent } from './components/SentryErrorComponent'
import ConfigComponent from './components/config'
import { generateDefaultClassNames } from './utils/device-detect'
import MidContainerWithPixi from '../web/containers/MidContainer/MidContainerWithPixi'

const Container = styled.div`
  text-align: center;
`

const data =(enableProductSides)=> (
  <SentryErrorComponent>
    <Provider store={store}>
      <ConfigComponent />
      <Container className={`${generateDefaultClassNames()} generatorView`}>
        <MidContainerWithPixi enableProductSides={enableProductSides} />
      </Container>
    </Provider>
  </SentryErrorComponent>
)

export default data
