import store from '../../store/MyStore'
import {
  actionGoBackStep,
  actionGoForwardStep,
  actionRemoveActiveObject,
  actionRemoveAllSprites,
  flipActiveObject,
  updateFontSizeOnActiveObject,
  updatePositionOnActiveObject,
  updateRotationOnActiveObject,
  updateSizeOnActiveObject
} from '../../actions/editor'
import { PixiController } from '../../core-module/pixi/PixiController'
import EditorManager from '../../core-module/editor/EditorManager'
import { changeSideIndex } from '../../actions/product'
import Extract from '../../module/Extract'
import configs from '../../module/config'
import { actionToggleDisplayInfo } from '../../actions/shortcuts'
import Locker from './Locker'

export const shortcutLocker = new Locker()

const isMac = navigator.platform.toUpperCase().includes('MAC')

const KEYS = {
  BACKSPACE: 8,
  DELETE: 46,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  EQUAL: 187,
  MINUS: 189,
  ZERO: 48,
  ONE: 49,
  NINE: 57,
  F: 102,
  Z: 90,
  Y: 89,
  B: 98,
  I: 105
}

const isCTRL = event => {
  return isMac ? event.metaKey : event.ctrlKey
}

function setKeys(event) {
  return { isCtrl: isCTRL(event), isAlt: event.altKey, isShift: event.shiftKey }
}

function handleKeyDown(
  keyCode,
  { isCtrl, isAlt },
  { spritesContainer: { activeSprite, sprites } }
) {
  if (isCtrl) {
    // eslint-disable-next-line
    switch (keyCode) {
      case KEYS.EQUAL:
        PixiController.zoomIn()
        return true
      case KEYS.MINUS:
        PixiController.zoomOut()
        return true

      case KEYS.ZERO:
        PixiController.zoomDefault()
        return true

      case KEYS.BACKSPACE:
      case KEYS.DELETE:
        if (!sprites.length) {
          return false
        }

        store.dispatch(actionRemoveAllSprites())
        return true

      case KEYS.Z:
        store.dispatch(actionGoBackStep())
        return true
      case KEYS.Y:
        store.dispatch(actionGoForwardStep())
        return true
    }
  }

  if (!activeSprite) {
    return false
  }

  switch (keyCode) {
    case KEYS.ARROW_LEFT:
      if (isAlt) {
        store.dispatch(updateRotationOnActiveObject(1.5 * Math.PI))
        return true
      }

      if (isCtrl) {
        store.dispatch(
          updateRotationOnActiveObject(
            activeSprite.rotation - (1 / 180) * Math.PI
          )
        )
        return true
      }

      store.dispatch(
        updatePositionOnActiveObject(activeSprite.x - 1, activeSprite.y)
      )
      return true
    case KEYS.ARROW_RIGHT:
      if (isAlt) {
        store.dispatch(updateRotationOnActiveObject(Math.PI / 2))
        return true
      }

      if (isCtrl) {
        store.dispatch(
          updateRotationOnActiveObject(
            activeSprite.rotation + (1 / 180) * Math.PI
          )
        )
        return true
      }

      store.dispatch(
        updatePositionOnActiveObject(activeSprite.x + 1, activeSprite.y)
      )
      return true
    case KEYS.ARROW_UP:
      if (isAlt) {
        store.dispatch(updateRotationOnActiveObject(0))
        return true
      }
      store.dispatch(
        updatePositionOnActiveObject(activeSprite.x, activeSprite.y - 1)
      )
      return true
    case KEYS.ARROW_DOWN:
      if (isAlt) {
        store.dispatch(updateRotationOnActiveObject(Math.PI))
        return true
      }
      store.dispatch(
        updatePositionOnActiveObject(activeSprite.x, activeSprite.y + 1)
      )
      return true
    case KEYS.MINUS:
      if (activeSprite.isText() && activeSprite.fontSize > 5) {
        store.dispatch(updateFontSizeOnActiveObject(activeSprite.fontSize - 1))
      }

      if (activeSprite.isMotive() && activeSprite.scale > 0.03) {
        store.dispatch(updateSizeOnActiveObject(activeSprite.scale - 0.005))
        return true
      }

      return true

    case KEYS.EQUAL:
      if (activeSprite.isText() && activeSprite.fontSize < 150) {
        store.dispatch(updateFontSizeOnActiveObject(activeSprite.fontSize + 1))
      }

      if (activeSprite.isMotive() && activeSprite.scale < 4) {
        store.dispatch(updateSizeOnActiveObject(activeSprite.scale + 0.01))
        return true
      }

      return true
    default:
      return false
  }
}

function handleKeyUp(keyCode, { spritesContainer: { activeSprite } }) {
  if (keyCode >= KEYS.ONE && keyCode <= KEYS.NINE) {
    const index = keyCode - KEYS.ONE

    if (index >= EditorManager.getActiveProductEditor().side.length) {
      return false
    }

    if (index === EditorManager.getActiveProductEditor().activeSide.index) {
      return false
    }

    store.dispatch(changeSideIndex(index))

    return true
  }

  switch (keyCode) {
    case KEYS.DELETE:
    case KEYS.BACKSPACE:
      if (!activeSprite) {
        return false
      }

      store.dispatch(actionRemoveActiveObject())
      return true

    default:
      return false
  }
}

function handleKeyPress(
  code,
  { isCtrl, isShift },
  { spritesContainer: { activeSprite } }
) {
  if (!isCtrl || !isShift) {
    return false
  }

  switch (code) {
    case KEYS.I: {
      store.dispatch(actionToggleDisplayInfo())
      return true
    }

    case KEYS.B: {
      const index = EditorManager.getActiveProductEditor().activeSide.index + 1

      Extract.thumbnail({ backgroundColor: '#ffffff', maxHeight: 518 }).then(
        result => result.download(`screenshot-${index}`, 'image/jpeg', 1)
      )
      return true
    }

    case KEYS.F:
      if (!activeSprite) {
        return false
      }

      store.dispatch(flipActiveObject())
      return true
    default:
      return false
  }
}

function keyup(event) {
  if (
    !configs.data.enableShortcuts ||
    !EditorManager.isProductEditorActive() ||
    shortcutLocker.isLocked() ||
    PixiController.isInputFocussed()
  ) {
    return event
  }

  const code = event.keyCode || event.which
  const state = store.getState()

  if (handleKeyUp(code, state)) {
    event.preventDefault()

    return false
  }

  return event
}

function keydown(event) {
  if (
    !configs.data.enableShortcuts ||
    !EditorManager.isProductEditorActive() ||
    shortcutLocker.isLocked() ||
    PixiController.isInputFocussed()
  ) {
    return event
  }

  const code = event.keyCode || event.which
  const state = store.getState()

  if (handleKeyDown(code, setKeys(event), state)) {
    event.preventDefault()
    return false
  }

  return event
}

function keypress(event) {
  if (
    !configs.data.enableShortcuts ||
    !EditorManager.isProductEditorActive() ||
    shortcutLocker.isLocked() ||
    PixiController.isInputFocussed()
  ) {
    return event
  }

  const code = event.keyCode || event.which
  const state = store.getState()

  if (handleKeyPress(code, setKeys(event), state)) {
    event.preventDefault()
    return false
  }

  return event
}

document.addEventListener('keydown', keydown)
document.addEventListener('keyup', keyup)
document.addEventListener('keypress', keypress)
