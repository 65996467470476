import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import className from 'classnames'

import { PixiController } from '../../pixi/PixiController'
import EditorManager from '../../editor/EditorManager'
import DragAndDropHelper, { ImageUploadingObserver } from './DragAndDropHelper'
import SVGColorComponent from '../../../web/components/SVGColorComponent'
import * as productConstant from '../../../constants/ProductConstants'
import { isMobile } from '../../utils/device-detect'
import { PixiActionContainer } from '../../containers/PixiActionContainer'
import configs from '../../../module/config'
import { ModalBackground } from '../ModalComponent/ModalBackground'

const SCROLLING_TIMEOUT = 150

function handleMouseEvents() {
  return {
    onDragEnter: e => DragAndDropHelper.onDragEnterCanvas(e),
    onDragOver: e => e.preventDefault(),
    onDragLeave: e => DragAndDropHelper.onDragLeaveCanvas(e),
    onDrop: e => {
      e.preventDefault()
      DragAndDropHelper.displayAndUploadImage(e.dataTransfer.files)
    },
    onMouseEnter() {
      if (isMobile) {
        return
      }
      PixiController.switchToEditMode()
    },
    onMouseLeave() {
      if (isMobile) {
        return
      }

      PixiController.switchToPreviewMode()
    }
  }
}

function isNotContainer(event) {
  // fixme: create something smarter
  return !!event.target && !event.target.texture
}

function isFirstProductLoaded() {
  const [firstProductLoaded, setFirstProductLoaded] = useState(false)

  function productLoadedListener() {
    setFirstProductLoaded(true)
    PixiController.productContainerObserver.unsubscribe(productLoadedListener)
  }

  useEffect(() => {
    if (firstProductLoaded) {
      return
    }

    if (
      EditorManager.isProductEditorActive() &&
      EditorManager.getActiveProductEditor().mainSprite
    ) {
      setFirstProductLoaded(true)
    } else {
      PixiController.productContainerObserver.subscribe(productLoadedListener)
    }
  }, [firstProductLoaded])

  return firstProductLoaded
}

function setupPixiController() {
  let deactivateActiveSprite = null

  function handlePointerUpOnStage(event) {
    if (isNotContainer(event) && EditorManager.isProductEditorActive()) {
      if (isMobile) {
        if (deactivateActiveSprite) {
          // PixiController.switchToPreviewMode()
          EditorManager.getActiveProductEditor().clearActiveSpriteAndDispatch()
          return
        }
        PixiController.switchToEditMode()
        PixiController.showControlsAndSetPosition(
          EditorManager.getActiveProductEditor().activePixiSprite
        )

        return
      }

      EditorManager.getActiveProductEditor().clearActiveSpriteAndDispatch()
    }
  }

  function handlePointerDownOnStage(event) {
    if (isNotContainer(event)) {
      PixiController.hideSpriteAndLineControls()

      // Timer to recognize tap from scroll on mobiles/tablets.
      deactivateActiveSprite = setTimeout(() => {
        deactivateActiveSprite = null
      }, SCROLLING_TIMEOUT)
      event.stopPropagation()
    }
  }

  PixiController.app.stage.interactive = true
  PixiController.app.stage.on('pointerdown', handlePointerDownOnStage)
  PixiController.app.stage.on('pointerup', handlePointerUpOnStage)
  PixiController.app.stage.on('pointerupoutside', handlePointerUpOnStage)
}

function PixiComponent({
  product: {
    state,
    views,
    active: { sideIndex }
  },
  children
}) {
  const canvasRef = useRef(null)
  const productLoaded = isFirstProductLoaded()
  const [loading, setLoading] = useState(false)

  function setLoadingSubrscription(loadingState) {
    setLoading(loadingState.loading)
  }

  useEffect(() => {
    PixiController.initializeApplication(canvasRef.current)
    setupPixiController()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', PixiController.windowResize)
    ImageUploadingObserver.subscribe(setLoadingSubrscription)
    return () => {
      window.removeEventListener('resize', PixiController.windowResize)
      ImageUploadingObserver.unsubscribe(setLoadingSubrscription)
    }
  })

  useEffect(() => {
    if (productConstant.isReady(state)) {
      EditorManager.createProduct(views, sideIndex)
    }
  }, [state])

  return (
    <div
      id="canvas-container"
      className={className({
        loading: state === productConstant.STATE.LOADING,
        display: productLoaded || configs.data.generatorMode
      })}
      ref={canvasRef}
      {...handleMouseEvents()}>
      {loading && (
        <ModalBackground>
          <section className="loading-in-middle">
            <div className="lds-ellipsis">
              <div />
              <div />
              <div />
              <div />
            </div>
          </section>
        </ModalBackground>
      )}
      <SVGColorComponent />
      <PixiActionContainer canvasRef={canvasRef} />
      {children}
    </div>
  )
}

PixiComponent.propTypes = {
  product: PropTypes.object.isRequired,
  children: PropTypes.element
}

PixiComponent.defaultProps = {
  children: undefined
}

const mapStateToProps = state => ({
  product: state.product
})

export default connect(
  mapStateToProps,
  null
)(PixiComponent)
