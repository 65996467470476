import minBy from 'lodash/minBy'
import debounce from 'lodash/debounce'

import Observer from '../helper/Observer'
import { PixiController } from '../PixiController'
import EditorManager from '../../editor/EditorManager'
import configs from '../../../module/config'
import { getRelationBetweenSpritesAndProductInPercentages } from '../helper/ActiveZoneHelper'
import Extract from '../../../module/Extract'

const IMAGE_DIMENSION = {
  maxWidth: 170,
  maxHeight: 170
}

class ActiveZoneImage {
  currentExtractedImage = new Map()

  productImageObserver = new Observer()

  start() {
    this.listenerGraphicUpdated = debounce(this.listenerGraphicUpdated, 650)
    PixiController.registerGraphicUpdated(this.listenerGraphicUpdated, this)
  }

  clearCache() {
    this.currentExtractedImage = new Map()
  }

  listenerGraphicUpdated() {
    if (
      !configs.data.enableGenerateImageAfterChange ||
      !PixiController.isPixiAppended
    ) {
      return
    }

    this.executeImageUpdate()
  }

  async executeImageUpdate() {
    if (!EditorManager.isProductEditorActive()) {
      return
    }

    const { index } = EditorManager.getActiveProductEditor().getActiveSide()

    const extractedObject = { image: null, leftPoint: null, style: null }
    this.currentExtractedImage.set(index, extractedObject)

    if (
      !PixiController.spriteCopiesContainer.width ||
      !PixiController.spriteCopiesContainer.height ||
      PixiController.spriteCopiesContainer.children.length === 0
    ) {
      this.productImageObserver.notifyAll(index)
      return
    }

    const mostLeftZone = PixiController.activeZoneMask
      ? minBy(PixiController.activeZoneMask.graphicsData, 'shape.x')
      : null

    // Extract Container
    extractedObject.data = {
      zone: mostLeftZone && mostLeftZone.shape,
      ...getRelationBetweenSpritesAndProductInPercentages()
    }

    Extract.thumbnail({
      maxWidth: IMAGE_DIMENSION.maxWidth,
      maxHeight: IMAGE_DIMENSION.maxHeight,
      productIncluded: false,
      clipSpritesByActiveZones: false,
      includedActiveZonesSpaces: false
    })
      .then(result => result.base64())
      .then(base64 => {
        extractedObject.image = new Image()
        extractedObject.image.src = base64
        extractedObject.image.onload = () => {
          extractedObject.style = {
            width: extractedObject.image.width,
            height: extractedObject.image.height,
            left: extractedObject.image.left,
            top: extractedObject.image.top,
            clientWidth: extractedObject.image.clientWidth,
            clientHeight: extractedObject.image.clientHeight
          }

          if (
            EditorManager.isProductEditorActive() &&
            EditorManager.getActiveProductEditor().getActiveSide().index ===
              index
          ) {
            this.currentExtractedImage.set(index, extractedObject)
            this.productImageObserver.notifyAll(index)
          }
        }
      })
      .catch(f => f)
  }
}

export default new ActiveZoneImage()
