export const TYPE_LOADING = 'PRODUCT_LOADING'
export const TYPE_FETCHED = 'PRODUCT_FETCHED'
export const TYPE_LOAD_SAVE = 'TYPE_LOAD_SAVE'
export const TYPE_ERROR = 'PRODUCT_ERROR'
export const TYPE_CHANGE_COLOR_FETCHED = 'PRODUCT_CHANGE_COLOR_FETCHED'
export const TYPE_COLOR_LOADING = 'PRODUCT_COLOR_LOADING'

/**
 *
 * @enum {number}
 * @type {{INIT: number, ERROR: number, FETCHED: number, LOADING: number}}
 */
export const STATE = {
  INIT: 0,
  ERROR: 1,
  FETCHED: 2,
  LOADING: 3
}

/**
 *
 * @param {STATE} state
 * @returns {boolean}
 */
export const isReady = state => state === STATE.FETCHED

export const ACTION_SET_SIZE = 'ACTION_SET_SIZE'
export const ACTION_SET_QUANTITY = 'ACTION_SET_QUANTITY'
export const ACTION_SET_ID = 'ACTION_SET_ID'
export const ACTION_CHANGE_SIDE_INDEX = 'ACTION_CHANGE_SIDE_INDEX'
