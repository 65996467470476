export const ADD_SPRITE_ON_PRODUCT = 'ADD_SPRITE_ON_PRODUCT'
export const ADD_SPRITE_WITH_DATA = 'ADD_SPRITE_WITH_DATA'
export const ADD_TEXT_ON_PRODUCT = 'ADD_TEXT_ON_PRODUCT'

export const ADD_PRIMITIVE_OBJECT = 'ADD_PRIMITIVE_OBJECT'

export const UPDATE_SVG_SPRITE_CLASSES = 'UPDATE_SVG_SPRITE_CLASSES'
export const UPDATE_ACTIVE_TEXT_COLOR = 'UPDATE_ACTIVE_TEXT_COLOR'
export const UPDATE_SVG_COLOR_ACTIVE = 'UPDATE_SVG_COLOR_ACTIVE'
export const UPDATE_ID = 'UPDATE_ID'
export const UPDATE_SIZE_ACTIVE_OBJECT = 'UPDATE_SIZE_ACTIVE_OBJECT'
export const UPDATE_FONT_SIZE_ACTIVE_OBJECT = 'UPDATE_FONT_SIZE_ACTIVE_OBJECT'
export const UPDATE_ROTATION_ACTIVE_OBJECT = 'UPDATE_ROTATION_ACTIVE_OBJECT'
export const UPDATE_POSITION_ACTIVE_OBJECT = 'UPDATE_POSITION_ACTIVE_OBJECT'
export const UPDATE_TO_BOTTOM_LAYER_ACTIVE_OBJECT =
  'UPDATE_TO_BOTTOM_LAYER_ACTIVE_OBJECT'
export const UPDATE_TO_TOP_LAYER_ACTIVE_OBJECT =
  'UPDATE_TO_TOP_LAYER_ACTIVE_OBJECT'
export const UPDATE_BUNCH_CHANGE_COLOR_TEXT_AND_SVG =
  'UPDATE_BUNCH_CHANGE_COLOR_TEXT_AND_SVG'

export const HISTORY_CLEAR = 'HISTORY_CLEAR'
export const REMOVE_ACTIVE_OBJECT = 'REMOVE_ACTIVE_OBJECT'
export const REMOVE_SPRITES_BY_ID = 'REMOVE_SPRITES_BY_ID'
export const REMOVE_ALL_SPRITES = 'REMOVE_ALL_SPRITES'
export const CLEAR = 'CLEAR'

export const BACK_STEP = 'BACK_STEP'
export const FORWARD_STEP = 'FORWARD_STEP'

export const FLIP_ACTIVE_OBJECT = 'FLIP_ACTIVE_OBJECT'
export const CREATE_COPY_ACTIVE_OBJECT = 'CREATE_COPY_ACTIVE_OBJECT'

export const ACTIVE_SPRITE_CHANGED = 'ACTIVE_SPRITE_CHANGED'
export const REPLACE_ACTIVE_TEXT = 'REPLACE_ACTIVE_TEXT'

export const ADD_IMAGE_TO_LIBRARY = 'ADD_IMAGE_TO_LIBRARY'
export const REPLACE_COMMERCIAL_IMAGES_IN_LIBRARY =
  'REPLACE_COMMERCIAL_IMAGES_IN_LIBRARY'
export const SET_IMAGE_VISIBILITY = 'SET_IMAGE_VISIBILITY'
export const SET_IMAGE_STATE_TO_UPLOADING = 'SET_IMAGE_STATE_TO_UPLOADING'
export const SET_IMAGE_STATE_TO_ERROR_UPLOAD = 'SET_IMAGE_STATE_TO_ERROR_UPLOAD'
export const SET_IMAGE_STATE_TO_UPLOADED = 'SET_IMAGE_STATE_TO_UPLOADED'
export const REMOVE_IMAGE_FROM_LIBRARY = 'REMOVE_IMAGE_FROM_LIBRARY'
export const SET_SPRITES_AT_CENTER_AND_SCALE_BY_ZONE = 'SET_SPRITES_AT_CENTER_AND_SCALE_BY_ZONE'

export const IMAGE_STATE_COMMERCIAL = 'IMAGE_STATE_COMMERCIAL'
export const MY_IMAGE_STATE_UPLOADED = 'MY_IMAGE_STATE_UPLOADED'
export const MY_IMAGE_STATE_UPLOADING = 'MY_IMAGE_STATE_UPLOADING'
export const MY_IMAGE_STATE_UPLOAD_ERROR = 'MY_IMAGE_STATE_UPLOAD_ERROR'
export const MY_IMAGE_STATE_INIT = 'MY_IMAGE_STATE_INIT'

export const TOGGLE_REMOVE_BACKGROUND_OPTION = 'TOGGLE_REMOVE_BACKGROUND_OPTION'

export const APPLY_FILTER_ON_ACTIVE_OBJECT = 'APPLY_FILTER_ON_ACTIVE_OBJECT';
export const REMOVE_FILTER_FROM_ACTIVE_OBJECT = 'REMOVE_FILTER_FROM_ACTIVE_OBJECT';
