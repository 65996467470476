import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { captureMessage } from '@sentry/browser'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { myFetch } from '../../utils/fetch'
import { getApiTranslations } from '../../../constants/api'
import configs from '../../../module/config'
import LoadingComponent from '../../components/LoadingComponent'

function initTranslations(language, resources) {
  i18n
    .use(initReactI18next)
    .on('missingKey', (lngs, namespace, key, res) => {
      captureMessage(`[${namespace}][${key}] = ${res}`)
    })
    .init({
      resources: {
        [language]: { translation: resources }
      },
      lng: language,
      keySeparator: false,
      interpolation: {
        escapeValue: false // react already safes from xss
      },
      saveMissing: configs.data.exportMissingTranslations
    })
}

function WaitForTranslations({ children }) {
  const [resources, setResources] = useState({})
  const [loaded, setLoaded] = useState(false);
  const [timeoutFinished, setTimeoutFinished] = useState(false);

  useEffect(() => {
    if (loaded) {
      return
    }
  
    myFetch(getApiTranslations())
      .then(response => response.json())
      .then(result => {
        setResources(result)
        setLoaded(true)
      })
  }, [loaded])

  useEffect(()=>{
    setTimeout(()=>{
      setTimeoutFinished(true)
    },2000);
  },[])

  if (!loaded || !timeoutFinished) {
    return <LoadingComponent />
  }

  initTranslations(configs.data.language, resources)

  return children
}

WaitForTranslations.propTypes = {
  children: PropTypes.any.isRequired
}

export { WaitForTranslations }
