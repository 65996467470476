import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import get from 'lodash/get'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import EditorManager from '../../editor/EditorManager'
import { changeSideIndex } from '../../../actions/product'
import * as constant from '../../../constants/ProductConstants'
import config from '../../../module/config'
import PreviewImageContainer from '../../containers/PreviewImageContainer/index'

class PreviewSideComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      imageData: {}
    }
  }

  onPreviewClick(side) {
    const { changeSideIndex: changeIndex } = this.props
    changeIndex(side.index)
  }

  updateImageData({ target: img }, sideIndex) {
    const { imageData } = this.state
    this.setState({
      imageData: {
        ...imageData,
        [sideIndex]: { width: img.clientWidth, height: img.clientHeight }
      }
    })
  }

  printPreviews() {
    const { product, isEditorReady, sprites } = this.props
    if (!constant.isReady(product.state) || !isEditorReady) {
      return null
    }
    return EditorManager.getActiveProductEditor().side.map((side, index) => {
      const { imageData } = this.state
      const spritesOnThisSide = sprites.filter(s=>s.sideIndex === side.index);
      return (
        <button
          tabIndex={index}
          type="button"
          key={side.imageSource}
          className={classNames('productPreviewSide', {
            active: side.index === product.active.sideIndex,
            printed: spritesOnThisSide.length > 0 && config.data.generatorMode
          })}
          onClick={() => this.onPreviewClick(side)}>
          <img
            alt="Product Side"
            src={side.imageSource}
            onLoad={img => this.updateImageData(img, side.index)}
          />
          <PreviewImageContainer
            sideIndex={side.index}
            width={get(imageData, `${side.index}.width`, 0)}
            height={get(imageData, `${side.index}.height`, 0)}
            areas={side.getView().areas.map(area => area.percent)}
            similarZone
          />
        </button>
      )
    })
  }

  render() {
    return <div className="previews">{this.printPreviews()}</div>
  }
}

PreviewSideComponent.propTypes = {
  product: PropTypes.object.isRequired,
  changeSideIndex: PropTypes.func.isRequired,
  isEditorReady: PropTypes.bool.isRequired,
  sprites: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  product: state.product,
  sprites: state.spritesContainer.sprites,
  isEditorReady: state.editor.ready
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeSideIndex }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewSideComponent)
