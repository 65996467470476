import React from 'react'
import { t } from 'i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { DataBuilder, addToCart } from './CartRequest'
import { getCombinationId, getSizes } from '../../../filters/product'
import configs from '../../../module/config'
import Observer from '../../pixi/helper/Observer'
import { COLOR_TYPE } from '../../../module/Constants'
import Console from '../../utils/console'
import { isMobileOnly } from '../../utils/device-detect'
import { withImageIsUploading } from '../../utils/withImageIsUploading'

export const addToCartObserver = new Observer()

const withCartContainerData = Component => {
  const CartContainer = class extends React.PureComponent {
    static propTypes = {
      spritesContainer: PropTypes.object.isRequired,
      user: PropTypes.object.isRequired,
      product: PropTypes.object.isRequired,
      sizes: PropTypes.array.isRequired,
      discounts: PropTypes.array.isRequired,
      currentQuantity: PropTypes.number.isRequired,
      unitPrice: PropTypes.number.isRequired,
      taxRate: PropTypes.number.isRequired,
      color: PropTypes.shape({
        data: PropTypes.string,
        type: PropTypes.number
      }),
      imageIsUploading: PropTypes.bool.isRequired
    }

    static defaultProps = {
      color: {
        data: '#FFFFFF',
        type: COLOR_TYPE.HEX
      }
    }

    constructor(props) {
      super(props)
      this.onButtonClick = this.onButtonClick.bind(this)
      this.canDirectInsertToCart = this.canDirectInsertToCart.bind(this)
      this.onButtonClick = this.onButtonClick.bind(this)
      this.canAddToCart = this.canAddToCart.bind(this)
    }

    onButtonClick(quantity, sizes) {
      const isEditDesignEnable = configs.data.editDesign.enable

      if (quantity) {
        this.addToCartIfPossible(sizes)
          .then(() => {
            if (isEditDesignEnable && configs.data.editDesign.redirectLink) {
              window.location.replace(configs.data.editDesign.redirectLink)
            } else {
              if (configs.data.displayMessageOnAddToCart) {
                toast.success(
                  isEditDesignEnable
                    ? t('order.updated', 'Order has been updated.')
                    : t('order.create.success', 'Product was added to cart.'),
                  {
                    position: isMobileOnly
                      ? toast.POSITION.BOTTOM_LEFT
                      : toast.POSITION.TOP_RIGHT
                  }
                )
              }
              Console.info(
                'order',
                isEditDesignEnable
                  ? t('order.updated', 'Order has been updated.')
                  : t('order.create.success', 'Product was added to cart.')
              )
            }
          })
          .catch(result => {
            Console.error('CART', result)
            toast.error(
              isEditDesignEnable
                ? t('order.not.updated', 'Order has not been updated.')
                : t('product.not.added')
            )
          })
      }
    }

    addToCartIfPossible(sizes) {
      return new Promise((resolve, reject) => {
        const finalSizes = (!sizes || !sizes.length
          ? this.props.sizes // eslint-disable-line react/destructuring-assignment
          : sizes
        ).filter(size => size.value > 0)

        const {
          user: { id },
          spritesContainer,
          product: {
            active: { technology }
          }
        } = this.props

        if (!this.canAddToCart()) {
          return reject(new Error(t('image.is.uploading')))
        }

        const dataBuilder = new DataBuilder()
        dataBuilder.setUserId(id)
        dataBuilder.setDesignId(configs.data.designId)

        finalSizes.forEach(size =>
          dataBuilder.addCombination(size.id, size.value)
        )

        dataBuilder.addCurrentDataToOrder()
        dataBuilder.addTechnology(technology)
        dataBuilder.setRemoveBg(spritesContainer.removeBg);
        return resolve(dataBuilder)
      })
        .then(dataBuilder => dataBuilder.findAndSetAppropriateThumbnail())
        .then(dataBuilder => addToCart(dataBuilder.build()))
        .then(result => {
          addToCartObserver.notifyAll(true)
          return result
        })
        .catch(result => {
          Console.error('CART', result)
          addToCartObserver.notifyAll(false)
          throw result
        })
    }

    canDirectInsertToCart() {
      const { sizes, discounts } = this.props
      return sizes.length < 2 && !discounts.length
    }

    canAddToCart() {
      // eslint-disable-next-line react/destructuring-assignment
      return !this.props.imageIsUploading
    }

    render() {
      return (
        <Component
          {...this.props}
          canDirectInsertToCart={this.canDirectInsertToCart}
          canAddToCart={this.canAddToCart}
          onButtonClick={this.onButtonClick}
        />
      )
    }
  }

  const mapStateToProps = state => ({
    spritesContainer: state.spritesContainer,
    product: state.product,
    user: state.user,
    color: state.product.active.color,
    sizes: (() => {
      const sizes = getSizes(state.product).map(size => ({
        ...size,
        quantity: Math.max(size.quantity, 0),
        value:
          state.product.active.size === size.name
            ? state.product.active.quantity
            : 0
      }))

      if (sizes.length) {
        return sizes
      }

      return [
        {
          id: getCombinationId(state.product),
          name: '',
          value: state.product.active.quantity,
          quantity: state.product.stockQuantity
        }
      ]
    })(),
    discounts: state.product.discounts,
    get currentQuantity() {
      return this.sizes.reduce((sum, size) => sum + size.value, 0)
    },
    unitPrice: state.product.price,
    taxRate: state.product.taxRate
  })

  return withImageIsUploading(
    connect(
      mapStateToProps,
      null
    )(CartContainer)
  )
}

export default withCartContainerData
