import { lazy } from 'react'

export const MenuPanel = lazy(() => import('../MenuContainer/MenuWithData'))

export const CartPricePanel = lazy(() => import('../AddToCartContainer'))

export const InfoPanel = lazy(() =>
  import('../../../core-module/containers/ProductInfoBottomContainer')
)

export const FeaturedPanel = lazy(() =>
  import('../../../core-module/containers/FeaturedProductsContainer')
)

export const SimilarProductsPanel = lazy(() =>
  import('../../../core-module/containers/SimilarProductsContainer')
)
