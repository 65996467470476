/* eslint-disable no-restricted-globals */

import Observer from '../pixi/helper/Observer'

const HistoryObserver = new Observer()

history.pushState(null, null, document.URL)

window.addEventListener('popstate', () => {
  if (HistoryObserver.notifyAllWithReturn()) {
    history.back()
  } else {
    history.pushState(null, null, document.URL)
  }
})

export default HistoryObserver
