import store from '../store/MyStore'
import {
  MATERIAL_GLASS,
  MATERIAL_METAL,
  MATERIAL_WOOD,
  TECHNOLOGY_DIGITAL,
  TECHNOLOGY_ENGRAVING,
  TECHNOLOGY_STAMPS
} from '../core-module/utils/technologies'
import { changeSvgAndTextToColor } from './editor'
import {
  engravingColors,
  selectAvailableMaterial,
  stampsColors,
  defaultColors
} from '../core-module/containers/ColorContainer'

export function computeAndUpdateColorsBasedOnTechnology(nextProductWrapper) {
  const { product } = store.getState()

  // eslint-disable-next-line default-case
  switch (nextProductWrapper.active.technology) {
    case TECHNOLOGY_ENGRAVING: {
      let newColor

      const oldMaterial = selectAvailableMaterial(product.material)
      const material = selectAvailableMaterial(nextProductWrapper.material)

      if (oldMaterial === material) {
        break
      }

      // eslint-disable-next-line
      switch (material) {
        case MATERIAL_WOOD:
          newColor = engravingColors.wood[0].value
          break
        case MATERIAL_METAL:
          newColor = engravingColors.metal[0].value
          break
        case MATERIAL_GLASS:
          newColor = engravingColors.glass[0].value
          break
      }

      if (newColor) {
        store.dispatch(changeSvgAndTextToColor(newColor))
      }
      break
    }
    case TECHNOLOGY_STAMPS: {
      if (product.active.technology === TECHNOLOGY_STAMPS) {
        break
      }

      store.dispatch(changeSvgAndTextToColor(stampsColors[0].value))
      break
    }
    case TECHNOLOGY_DIGITAL: {
      if (product.active.technology === TECHNOLOGY_DIGITAL) {
        break
      }

      store.dispatch(changeSvgAndTextToColor(defaultColors[0].value))
      break
    }
  }
}
