import Editor from '../Editor'
import Product from '../Product'
import EditorManager from '../../core-module/editor/EditorManager'
import { EditorLoadingTool } from '../../core-module/components/config/FullyLoadedEditor'

function waitForSideListener(resolver, index) {
  return function listener(result) {
    EditorLoadingTool.observer.unsubscribe(this)
    resolver(
      EditorManager.isProductEditorActive() &&
        EditorManager.getActiveProductEditor().getActiveSide().index ===
          index &&
        !result.error &&
        result.loaded
    )
  }
}

export function waitForReadySide(index) {
  const activeIndex = Product.activeSideIndex
  return new Promise(resolve => {
    if (
      EditorLoadingTool.isEditorReadyIncludedDataAndFonts.loaded &&
      activeIndex === index
    ) {
      resolve(!Editor.isEditorFullyLoadedIncludedData().error)
    } else {
      EditorLoadingTool.observer.subscribe(waitForSideListener(resolve, index))
    }
  })
}
