import * as constant from '../constants/ProductConstants'
import * as history from '../constants/HistoryConstants'
import { CLEAR } from '../constants/ActionTypes'
import { COLOR_TYPE } from '../module/Constants'
import Console from '../core-module/utils/console'
import { findAndSetDefaultSize, isActiveSizeAvailableInStock } from './helpers/Sizes'

/**
 *
 * @name ProductConfig
 */
const productConfig = {
  state: constant.STATE.INIT,
  id: 0,
  categoryId: -1,
  mainCategory: -1,
  stockQuantity: -1, // when product does not have sizes
  name: '',
  description: '',
  width: null,
  height: null,
  views: [],
  price: 0,
  images: {
    previews: [],
    tableSizes: null
  },
  colors: [],
  templates: {
    designs: [],
    filters: []
  },
  discounts: [],
  taxRate: 1,
  active: {
    color: {
      data: '',
      type: COLOR_TYPE.UNDEFINED
    },
    size: null,
    sideIndex: -1,
    quantity: 1,
    technology: -1
  },
  future: [],
  history: [],
  technologies: [],
  material: ''
}

function buildProductConfigFromAction(body, prevState) {
  const state = Object.assign({}, productConfig)
  state.state = constant.STATE.FETCHED

  state.id = body.id
  state.name = body.name
  state.description = body.description
  state.categoryId = body.categoryId
  state.width = body.width
  state.height = body.height
  state.views = body.views
  state.images = body.images
  state.colors = body.colors
  state.active = body.active
  state.mainCategory = body.mainCategory
  state.discounts = body.discounts
  state.price = body.price
  state.taxRate = body.taxRate
  state.stockQuantity = body.stockQuantity
  state.technologies = body.technologies
  state.active.sideIndex = body.active.sideIndex
  state.active.technology = body.active.technology
  state.templates = body.templates
  state.material = body.material

  state.active.quantity = prevState.active.quantity
  state.future = prevState.future
  state.history = prevState.history

  const size = body.active.size || prevState.active.size;
  if (isActiveSizeAvailableInStock(
    size,
    state
  )) {
    state.active.size = size;
  } else {
    state.active.size = findAndSetDefaultSize(state);
  }

  return state
}

export function handleProduct(state = productConfig, action) {
  switch (action.type) {
    case constant.TYPE_LOADING:
      return { ...state, state: constant.STATE.LOADING }

    case constant.TYPE_COLOR_LOADING:
      return Object.assign({}, state, {
        state: constant.STATE.LOADING,
        active: Object.assign({}, state.active, {
          color: {
            type: action.colorType,
            data: action.data
          }
        })
      })

    case constant.TYPE_FETCHED:
      return buildProductConfigFromAction(action.body, state)

    case constant.TYPE_LOAD_SAVE: {
      const newState = buildProductConfigFromAction(action.body, state)

      if (action.args.quantity) {
        newState.active.quantity = action.args.quantity
      }

      if (action.args.color) {
        newState.active.color = action.args.color
      }

      const stateColor = newState.active.color

      const foundColor = newState.colors.find(
        color =>
          (stateColor.type === COLOR_TYPE.IMAGE &&
            color.image === stateColor.data) ||
          (stateColor.type === COLOR_TYPE.HEX && color.hex === stateColor.data)
      )

      if (!foundColor) {
        Console.warn(
          'Product',
          'Saved color combination has not been found in product colors.'
        )
      }

      if (action.args.size) {
        newState.active.size = action.args.size
      }

      return newState
    }

    case constant.TYPE_ERROR:
      return Object.assign({}, state, {
        state: constant.STATE.ERROR
      })

    case constant.ACTION_SET_SIZE:
      return Object.assign({}, state, {
        active: Object.assign({}, state.active, { size: action.size })
      })

    case constant.ACTION_SET_QUANTITY:
      return Object.assign({}, state, {
        active: Object.assign({}, state.active, { quantity: action.quantity })
      })

    case constant.ACTION_CHANGE_SIDE_INDEX:
      state.history.push({ type: history.HISTORY_CHANGE_PRODUCT_SIDE })
      return Object.assign({}, state, {
        active: Object.assign({}, state.active, { sideIndex: action.index })
      })

    case constant.TYPE_CHANGE_COLOR_FETCHED:
      return Object.assign({}, state, {
        state: constant.STATE.FETCHED,
        views: action.body
      })

    case constant.ACTION_SET_ID:
      state.history.push({
        type: history.HISTORY_CHANGE_PRODUCT_ID,
        id: state.id
      })
      return Object.assign({}, state, {
        id: parseInt(action.id, 10)
      })

    case CLEAR:
      return Object.assign({}, productConfig)

    default:
      return state
  }
}
