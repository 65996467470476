import SpriteController from './SpriteController'
import { PixiController } from '../PixiController'
import store from '../../../store/MyStore'
import EditorManager from '../../editor/EditorManager'

function sortChildren(a, b) {
  if (a.zIndex === b.zIndex) {
    return a._lastSortedIndex - b._lastSortedIndex // eslint-disable-line
  }
  return a.zIndex - b.zIndex
}

function isSortRequired(container) {
  for (let i = 0, j = container.children.length; i < j; i += 1) {
    const child = container.children[i]
    child._lastSortedIndex = i // eslint-disable-line
    if (child.zIndex !== 0) {
      return true
    }
  }

  return false
}

export function sortSpritesByReducer() {
  if (!EditorManager.isProductEditorActive()) {
    return
  }

  // BUG: Check PixiController.setActiveZoneMask
  let zIndex = 1

  const {
    spritesContainer: { sprites }
  } = store.getState()

  const sideIndex = EditorManager.getActiveProductEditor().getActiveSide().index

  sprites.forEach(sprite => {
    if (sprite.sideIndex === sideIndex && !sprite.isUnknown()) {
      const spriteControl = SpriteController.get(sprite)
      if (spriteControl) {
        spriteControl.zIndex = zIndex
      }

      const spriteCopyControl = SpriteController.getCopy(sprite)
      if (spriteCopyControl) {
        spriteCopyControl.zIndex = zIndex
      }

      zIndex += 1
    }
  })

  if (
    PixiController.spriteCopiesContainer.children.length > 1 &&
    isSortRequired(PixiController.spriteCopiesContainer)
  ) {
    PixiController.spriteCopiesContainer.children.sort(sortChildren)
    PixiController.spriteContainer.children.sort(sortChildren)
  }
}
